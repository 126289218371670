import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Account from './Account'
import AllContracts from './AllContracts'
import Bom from './Bom'
import AllBugReports from './AllBugReports'
import AllDocuments from './AllDocuments'
import AllInvoices from './AllInvoices'
import BugReport from './BugReport'
import Bus from '../utils/Bus'
import CheckCode from './CheckCode'
import Contract from './Contract'
import Customer from '../pages/Customer'
import Dashboard from './Dashboard'
import Document from './Document'
import Echelon from './Echelon'
import Equipment from '../pages/Equipment'
import Flash from './Flash'
import ForgotPassword from './ForgotPassword'
import GlobalNav from './GlobalNav'
import InvoicedWorkOrders from './InvoicedWorkOrders'
import logo from '../assets/images/legacy_logo.png'
import NewContract from '../pages/NewContract'
import NewCustomer from './NewCustomer'
import NewDocument from './NewDocument'
import NewWorkOrder1 from '../pages/NewWorkOrder1'
import NewWorkOrder2 from '../pages/NewWorkOrder2'
import NewWorkOrder3 from '../pages/NewWorkOrder3'
import NewUser from './NewUser'
import Report from './Report'
import ResetPassword from './ResetPassword'
import Review from './Review'
import ReviewWorkOrders from './ReviewWorkOrders'
import ReviewsHome from './ReviewsHome'
import SignInUser from './SignInUser'
import WorkOrder from './WorkOrder'

import '../styles/components.css'
import '../styles/flash.css'
import '../styles/sidebar.css'
import '../styles/modal.css'

window.flash = (message, type = 'success') =>
  Bus.emit('flash', { message, type })

export default function App() {
  return (
    <div>
      <GlobalNav />
      <Flash />
      <div id="topHider"></div>
      <div>
        <div id="leftSidebar">
          <div id="logo">
            <img src={logo} alt={logo} />
          </div>
        </div>
        <div id="mainContainer">
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/sign-in" component={SignInUser} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/check-code" component={CheckCode} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/new-user" component={NewUser} />
            <Route exact path="/account" component={Account} />
            <Route exact path="/reviews" component={ReviewsHome} />
            <Route exact path="/reviews/p/:page" component={ReviewsHome} />
            <Route exact path="/reviews/:id" component={Review} />
            <Route exact path="/equipment/:id" component={Equipment} />
            <Route exact path="/work-order/:id" component={WorkOrder} />
            <Route exact path="/work-orders" component={InvoicedWorkOrders} />
            <Route
              exact
              path="/review-work-orders"
              component={ReviewWorkOrders}
            />
            <Route exact path="/customer/:id" component={Customer} />
            <Route exact path="/new-contract" component={NewContract} />
            <Route exact path="/new-work-order/1" component={NewWorkOrder1} />
            <Route exact path="/new-work-order/2" component={NewWorkOrder2} />
            <Route exact path="/new-work-order/3" component={NewWorkOrder3} />
            <Route exact path="/report" component={Report} />
            <Route exact path="/report/:id" component={Report} />
            <Route exact path="/summary/:id" component={Bom} />
            <Route exact path="/summary/new" component={Bom} />
            <Route exact path="/contracts" component={AllContracts} />
            <Route exact path="/contract/:id" component={Contract} />
            <Route exact path="/bug-reports" component={AllBugReports} />
            <Route exact path="/bug-report/:id" component={BugReport} />
            <Route exact path="/new-customer" component={NewCustomer} />
            <Route exact path="/echelons/:id" component={Echelon} />
            <Route exact path="/echelons" component={Echelon} />
            <Route exact path="/invoices" component={AllInvoices} />
            <Route exact path="/documents" component={AllDocuments} />
            <Route exact path="/documents/new" component={NewDocument} />
            <Route exact path="/documents/:id" component={Document} />
          </Switch>
        </div>
      </div>
    </div>
  )
}
