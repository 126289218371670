export const AUTH_TOKEN = 'auth-token'
export const CURRENT_EMAIL = 'current-email'
export const RESET_TOKEN = 'reset-token'
export const WO_CUSTOMER_ID = 'wo-customer-id'
export const WO_CUSTOMER_NAME = 'wo-customer-name'
export const WO_EQUIP_ID = 'wo-equip-id'
export const WO_EQUIP_SERIAL_NUM = 'wo-equip-serial-num'
export const WO_INTERNAL_ID = 'wo-internal-id'

// Reviewable Types
export const REVIEWABLE_TYPE_EQUIPMENT = 'Equipment'
export const REVIEWABLE_TYPE_WORKORDER = 'WorkOrder'
export const REVIEWABLE_TYPE_CUSTOMER = 'Customer'
