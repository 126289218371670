import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome'
import { createBrowserHistory } from 'history'
import { Link } from 'react-router-dom'
import { Query, Mutation } from 'react-apollo'
import PropType, { object } from 'prop-types'
import i18n from 'i18n-js'

import Button from './Button'
import DateField from './DateField'
import SignInUser from './SignInUser'
import TextArea from './TextArea'
import TextField from './TextField'

import { AUTH_TOKEN } from '../constants'
import {
  CUSTOMER_SEARCH,
  CREATE_SUMMARY,
  UPDATE_SUMMARY,
  VALIDATE_SUMMARY_TITLE,
  ADJUST_SUMMARY_CUSTOMERS,
  GENERATE_SUMMARY_PDF,
} from '../queries'
import '../styles/report.css'

class BomSettings extends Component {
  constructor(props) {
    super(props)

    let history = createBrowserHistory()

    history.replace(history.location.pathname, {
      selectedNav: 'report-settings',
    })

    this.changeLocalSearch = this.changeLocalSearch.bind(this)

    let pageAction = 'update'
    if (props.history.location.pathname.split('/')[2] === 'new') {
      pageAction = 'create'
    }

    let summaryId = props.summaryId

    if (summaryId === null && pageAction === 'update') {
      summaryId = parseInt(props.history.location.pathname.split('/')[2], 10)
    }

    this.state = {
      summaryId: summaryId,
      customerIds: props.customerIds,
      customers: props.customers,
      summarySettings: props.summarySettings,
      disallowedEquip: props.disallowedEquip,
      avoidableWO: props.avoidableWO,
      excludedWO: props.excludedWO,
      showcasedWO: props.showcasedWO,
      localSearch: null,
      localSearchHide: true,
      history: props.history,
      payload: props.payload,
      pageAction: pageAction,
    }
  }

  changeLocalSearch = async (event) => {
    const value = event.target.value

    const hideVal = value === ''

    this.setState({
      localSearch: value,
      localSearchHide: hideVal,
    })
    return ''
  }

  render() {
    const authToken = localStorage.getItem(AUTH_TOKEN)
    const {
      summaryId,
      customers,
      customerIds,
      summarySettings,
      disallowedEquip,
      localSearch,
      localSearchHide,
      avoidableWO,
      excludedWO,
      showcasedWO,
      history,
      payload,
      pageAction,
    } = this.state

    if (authToken === null) return <SignInUser />

    const titleValidation = { token: authToken, title: summarySettings.title }

    const localSearchClass = localSearchHide ? 'hidden' : 'visible'

    if (summaryId !== null) {
      titleValidation.id = parseInt(summaryId, 10)
    }

    let custIds = ''

    const allVisibleSelected =
      summarySettings.lastWoDateVisible &&
      summarySettings.firstWoDateVisible &&
      summarySettings.periodHoursVisible &&
      summarySettings.pmCphVisible &&
      summarySettings.repairCphVisible &&
      summarySettings.tiresCphVisible &&
      summarySettings.damageCphVisible &&
      summarySettings.requestCphVisible &&
      summarySettings.attachCphVisible &&
      summarySettings.mcCphVisible &&
      summarySettings.plannedHoursVisible &&
      summarySettings.pmCountVisible &&
      summarySettings.avgPmIntervalVisible &&
      summarySettings.mfcCountVisible &&
      summarySettings.avgMfcCountVisible

    customerIds.map((cust) => {
      if (custIds === '') {
        custIds = `${cust}`
      } else {
        custIds += `,${cust}`
      }
      return ''
    })

    return (
      <div>
        <div>
          <h4>{i18n.t('selectedCustomers')}</h4>
          <ul>
            {customers.map((customer) => {
              return (
                <li key={customer.id}>
                  <Link to={{ pathname: `/customer/${customer.id}` }}>
                    {customer.name} - {customer.internalName} (
                    {customer.parentRelationships.length > 0 &&
                     customer.parentRelationships[0].customerInternalVal})
                  </Link>
                  {customerIds.length > 1 && (
                    <Mutation mutation={ADJUST_SUMMARY_CUSTOMERS}>
                      {(adjustSummaryCustomers) => {
                        return (
                          <Link
                            to="#"
                            onClick={async (event) => {
                              event.preventDefault()
                              customerIds.splice(
                                customerIds.indexOf(customer.id),
                                1,
                              )

                              if (summaryId !== null) {
                                let allCustomers = ''
                                customerIds.map((cId) => {
                                  if (allCustomers === '') {
                                    allCustomers = `${cId}`
                                  } else {
                                    allCustomers += `,${cId}`
                                  }
                                  return ''
                                })

                                adjustSummaryCustomers({
                                  variables: {
                                    token: authToken,
                                    id: parseInt(summaryId, 10),
                                    customers: allCustomers,
                                  },
                                }).then(() => {
                                  document.location.reload()
                                })
                              }

                              this.setState({ customerIds })
                            }}
                          >
                            {' '}
                            X
                          </Link>
                        )
                      }}
                    </Mutation>
                  )}
                </li>
              )
            })}
          </ul>
          <div id="localSearchContainer">
            <input
              id="local-search-field"
              type="text"
              className="text-field"
              placeholder={i18n.t('searchCustomerByNameNumber')}
              onChange={this.changeLocalSearch.bind(this)}
            />
            <span id="local-search-error" className="hidden field-error">
              {i18n.t('pleaseSelectACustomer')}
            </span>
          </div>

          <div id="localSearchResults" className={localSearchClass}>
            <Query
              query={CUSTOMER_SEARCH}
              variables={{
                token: authToken,
                search: localSearch,
                excluding: custIds,
              }}
            >
              {({ loading, error, data }) => {
                if (loading)
                  return <div className="report">{i18n.t('fetching')}...</div>
                if (error) return <div className="report">{error.message}</div>

                const results = data.searchCustomers

                if (localSearch.length < 4) {
                  return (
                    <div className="report">
                      {i18n.t('pleaseTypeFourOrMore')}
                    </div>
                  )
                }

                if (results.length === 0) {
                  return (
                    <div className="report">{i18n.t('noResultsFound')}</div>
                  )
                }

                return (
                  <div>
                    {results.map((result) => (
                      <div key={result.id} className="searchResult">
                        <Mutation mutation={ADJUST_SUMMARY_CUSTOMERS}>
                          {(adjustSummaryCustomers) => {
                            return (
                              <Link
                                to="#"
                                onClick={async (event) => {
                                  event.preventDefault()

                                  customerIds.push(result.id)
                                  customers.push(result)

                                  if (summaryId !== null) {
                                    let allCustomers = ''
                                    customerIds.map((cId) => {
                                      if (allCustomers === '') {
                                        allCustomers = `${cId}`
                                      } else {
                                        allCustomers += `,${cId}`
                                      }
                                      return ''
                                    })

                                    adjustSummaryCustomers({
                                      variables: {
                                        token: authToken,
                                        id: parseInt(summaryId, 10),
                                        customers: allCustomers,
                                      },
                                    }).then(() => {
                                      document.location.reload()
                                    })
                                  }

                                  let field = document.getElementById(
                                    'local-search-field',
                                  )
                                  let errField = document.getElementById(
                                    'local-search-error',
                                  )

                                  field.classList.remove('error')
                                  errField.classList.add('hidden')
                                  errField.classList.remove('show')

                                  if (summarySettings.title === '') {
                                    let first = summarySettings.startRange
                                      .split(' ')[0]
                                      .split('-')
                                    let last = summarySettings.endRange
                                      .split(' ')[0]
                                      .split('-')
                                    let title = `${result.name}`

                                    switch (first[1]) {
                                      case '01':
                                        first[1] = 'Jan'
                                        break
                                      case '02':
                                        first[1] = 'Feb'
                                        break
                                      case '03':
                                        first[1] = 'Mar'
                                        break
                                      case '04':
                                        first[1] = 'Apr'
                                        break
                                      case '05':
                                        first[1] = 'May'
                                        break
                                      case '06':
                                        first[1] = 'Jun'
                                        break
                                      case '07':
                                        first[1] = 'Jul'
                                        break
                                      case '08':
                                        first[1] = 'Aug'
                                        break
                                      case '09':
                                        first[1] = 'Sep'
                                        break
                                      case '10':
                                        first[1] = 'Oct'
                                        break
                                      case '11':
                                        first[1] = 'Nov'
                                        break
                                      case '12':
                                        first[1] = 'Dec'
                                        break
                                      default:
                                        break
                                    }

                                    switch (last[1]) {
                                      case '01':
                                        last[1] = 'Jan'
                                        break
                                      case '02':
                                        last[1] = 'Feb'
                                        break
                                      case '03':
                                        last[1] = 'Mar'
                                        break
                                      case '04':
                                        last[1] = 'Apr'
                                        break
                                      case '05':
                                        last[1] = 'May'
                                        break
                                      case '06':
                                        last[1] = 'Jun'
                                        break
                                      case '07':
                                        last[1] = 'Jul'
                                        break
                                      case '08':
                                        last[1] = 'Aug'
                                        break
                                      case '09':
                                        last[1] = 'Sep'
                                        break
                                      case '10':
                                        last[1] = 'Oct'
                                        break
                                      case '11':
                                        last[1] = 'Nov'
                                        break
                                      case '12':
                                        last[1] = 'Dec'
                                        break
                                      default:
                                        break
                                    }

                                    if (first[0] !== last[0]) {
                                      title += ` ${first[1]} ${first[2]}, ${first[0]} - ${last[1]} ${last[2]}, ${last[0]}`
                                    } else if (first[1] !== last[1]) {
                                      title += ` ${first[1]} ${first[2]} - ${last[1]} ${last[2]}, ${first[0]}`
                                    } else {
                                      title += ` ${first[1]} ${first[2]} - ${last[2]}, ${first[0]}`
                                    }

                                    summarySettings.title = title
                                  }

                                  this.setState({ customerIds })
                                }}
                              >
                                { (result.parentRelationships.length > 0) &&
                                  result.parentRelationships[0]
                                    .customerInternalVal
                                }
                                <br />
                                {result.name} - {result.internalName}
                              </Link>
                            )
                          }}
                        </Mutation>
                      </div>
                    ))}
                  </div>
                )
              }}
            </Query>
          </div>
        </div>

        <div>
          <h4>{i18n.t('dateRange')}</h4>
          <Mutation mutation={UPDATE_SUMMARY}>
            {(updateSummary) => {
              return (
                <DateField
                  id="startRange"
                  label={i18n.t('startRange')}
                  value={summarySettings.startRange}
                  onDateChange={(date) => {
                    if (
                      summaryId !== null &&
                      summarySettings.validForm === true
                    ) {
                      updateSummary({
                        variables: {
                          token: authToken,
                          id: parseInt(summaryId, 10),
                          startRange: date,
                        },
                      })
                    }
                    summarySettings.startRange = date
                    this.setState({ summarySettings })
                  }}
                />
              )
            }}
          </Mutation>
          <Mutation mutation={UPDATE_SUMMARY}>
            {(updateSummary) => {
              return (
                <DateField
                  id="endRange"
                  label={i18n.t('endRange')}
                  value={summarySettings.endRange}
                  onDateChange={(date) => {
                    if (
                      summaryId !== null &&
                      summarySettings.validForm === true
                    ) {
                      updateSummary({
                        variables: {
                          token: authToken,
                          id: parseInt(summaryId, 10),
                          endRange: date,
                        },
                      })
                    }
                    summarySettings.endRange = date
                    this.setState({ summarySettings })
                  }}
                />
              )
            }}
          </Mutation>
        </div>

        <div>
          <h4>{i18n.t('invoiceType')}</h4>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.isCustomer}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary({
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                isCustomer: !summarySettings.isCustomer,
                              },
                            })
                          }
                          summarySettings.isCustomer = !summarySettings.isCustomer
                          this.setState({ summarySettings })
                        }}
                      />
                    )
                  }}
                </Mutation>
                {i18n.t('customer')}
              </label>
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.isTC}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary({
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                isTc: !summarySettings.isTC,
                              },
                            })
                          }
                          summarySettings.isTC = !summarySettings.isTC
                          this.setState({ summarySettings })
                        }}
                      />
                    )
                  }}
                </Mutation>
                {i18n.t('totalCare')}
              </label>
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.isEC}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary({
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                isEc: !summarySettings.isEC,
                              },
                            })
                          }
                          summarySettings.isEC = !summarySettings.isEC
                          this.setState({ summarySettings })
                        }}
                      />
                    )
                  }}
                </Mutation>
                {i18n.t('extraCare')}
              </label>
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.isInternal}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary({
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                isInternal: !summarySettings.isInternal,
                              },
                            })
                          }
                          summarySettings.isInternal = !summarySettings.isInternal
                          this.setState({ summarySettings })
                        }}
                      />
                    )
                  }}
                </Mutation>
                {i18n.t('internal')}
              </label>
            </div>
          </div>
        </div>

        <div>
          <h4>{i18n.t('kpiPeriod')}</h4>
          <div className="fieldContainer">
            <Mutation mutation={UPDATE_SUMMARY}>
              {(updateSummary) => {
                return(
                  <select
                    value={summarySettings.kpiPeriod}
                    id='kpi-period'
                    onChange={(res) => {
                      if (
                        summaryId !== null &&
                        summarySettings.validForm === true
                      ) {
                        updateSummary({
                          variables: {
                            token: authToken,
                            id: parseInt(summaryId, 10),
                            kpiPeriod: res.target.value,
                          },
                        })
                      }
                      summarySettings.kpiPeriod = res.target.value
                      this.setState({ summarySettings })
                    } }
                  >
                    <option value="monthly">
                      {i18n.t('monthly')}
                    </option>
                    <option value="quarterly">
                      {i18n.t('quarterly')}
                    </option>
                    <option value="yearly">
                      {i18n.t('yearly')}
                    </option>
                  </select>
                )
              } }
            </Mutation>
          </div>
        </div>

        <div>
          <h4>{i18n.t('summaryViewCustomization')}</h4>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.firstWoDateVisible}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary({
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                firstWoDateVisible: !summarySettings.firstWoDateVisible,
                              },
                            })
                          }
                          summarySettings.firstWoDateVisible = !summarySettings.firstWoDateVisible
                          this.setState({ summarySettings })
                        }}
                      />
                    )
                  }}
                </Mutation>
                {i18n.t('firstWorkOrderDate')}
              </label>
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.lastWoDateVisible}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary({
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                lastWoDateVisible: !summarySettings.lastWoDateVisible,
                              },
                            })
                          }
                          summarySettings.lastWoDateVisible = !summarySettings.lastWoDateVisible
                          this.setState({ summarySettings })
                        }}
                      />
                    )
                  }}
                </Mutation>
                {i18n.t('lastWorkOrderDate')}
              </label>
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.periodHoursVisible}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary({
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                periodHoursVisible: !summarySettings.periodHoursVisible,
                              },
                            })
                          }
                          summarySettings.periodHoursVisible = !summarySettings.periodHoursVisible
                          this.setState({ summarySettings })
                        }}
                      />
                    )
                  }}
                </Mutation>
                {i18n.t('periodHours')}
              </label>
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.pmCountVisible}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary({
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                pmCountVisible: !summarySettings.pmCountVisible,
                              },
                            })
                          }
                          summarySettings.pmCountVisible = !summarySettings.pmCountVisible
                          this.setState({ summarySettings })
                        }}
                      />
                    )
                  }}
                </Mutation>
                {i18n.t('pmCount')}
              </label>
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.avgPmIntervalVisible}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary({
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                avgPmIntervalVisible: !summarySettings.avgPmIntervalVisible,
                              },
                            })
                          }
                          summarySettings.avgPmIntervalVisible = !summarySettings.avgPmIntervalVisible
                          this.setState({ summarySettings })
                        }}
                      />
                    )
                  }}
                </Mutation>
                {i18n.t('avgPmInterval')}
              </label>
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.mfcCountVisible}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary({
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                mfcCountVisible: !summarySettings.mfcCountVisible,
                              },
                            })
                          }
                          summarySettings.mfcCountVisible = !summarySettings.mfcCountVisible
                          this.setState({ summarySettings })
                        }}
                      />
                    )
                  }}
                </Mutation>
                {i18n.t('mfcCount')}
              </label>
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.avgMfcIntervalVisible}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary({
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                avgMfcIntervalVisible: !summarySettings.avgMfcIntervalVisible,
                              },
                            })
                          }
                          summarySettings.avgMfcIntervalVisible = !summarySettings.avgMfcIntervalVisible
                          this.setState({ summarySettings })
                        }}
                      />
                    )
                  }}
                </Mutation>
                {i18n.t('avgMfcInterval')}
              </label>
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.pmCphVisible}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary({
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                pmCphVisible: !summarySettings.pmCphVisible,
                              },
                            })
                          }
                          summarySettings.pmCphVisible = !summarySettings.pmCphVisible
                          this.setState({ summarySettings })
                        }}
                      />
                    )
                  }}
                </Mutation>
                {i18n.t('pmCPH')}
              </label>
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.repairCphVisible}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary({
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                repairCphVisible: !summarySettings.repairCphVisible,
                              },
                            })
                          }
                          summarySettings.repairCphVisible = !summarySettings.repairCphVisible
                          this.setState({ summarySettings })
                        }}
                      />
                    )
                  }}
                </Mutation>
                {i18n.t('repairCPH')}
              </label>
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.tiresCphVisible}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary({
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                tiresCphVisible: !summarySettings.tiresCphVisible,
                              },
                            })
                          }
                          summarySettings.tiresCphVisible = !summarySettings.tiresCphVisible
                          this.setState({ summarySettings })
                        }}
                      />
                    )
                  }}
                </Mutation>
                {i18n.t('tiresCPH')}
              </label>
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.damageCphVisible}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary({
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                damageCphVisible: !summarySettings.damageCphVisible,
                              },
                            })
                          }
                          summarySettings.damageCphVisible = !summarySettings.damageCphVisible
                          this.setState({ summarySettings })
                        }}
                      />
                    )
                  }}
                </Mutation>
                {i18n.t('damageCPH')}
              </label>
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.requestCphVisible}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary({
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                requestCphVisible: !summarySettings.requestCphVisible,
                              },
                            })
                          }
                          summarySettings.requestCphVisible = !summarySettings.requestCphVisible
                          this.setState({ summarySettings })
                        }}
                      />
                    )
                  }}
                </Mutation>
                {i18n.t('requestCPH')}
              </label>
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.attachCphVisible}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary({
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                attachCphVisible: !summarySettings.attachCphVisible,
                              },
                            })
                          }
                          summarySettings.attachCphVisible = !summarySettings.attachCphVisible
                          this.setState({ summarySettings })
                        }}
                      />
                    )
                  }}
                </Mutation>
                {i18n.t('attachmentCPH')}
              </label>
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.mcCphVisible}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary( {
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                mcCphVisible: !summarySettings.mcCphVisible,
                              }
                            } )
                          }
                          summarySettings.mcCphVisible = !summarySettings.mcCphVisible
                          this.setState({ summarySettings })
                        } }
                      />
                    )
                  } }
                </Mutation>
                {i18n.t('mcCPH')}
              </label>
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return(
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.plannedHoursVisible}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary( {
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                plannedHoursVisible: !summarySettings.plannedHoursVisible,
                              }
                            } )
                          }
                          summarySettings.plannedHoursVisible = !summarySettings.plannedHoursVisible
                          this.setState({ summarySettings })
                        } }
                      />
                    )
                  } }
                </Mutation>
                {i18n.t('plannedHours')}
              </label>
            </div>
          </div>
          <br />
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={allVisibleSelected}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary({
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                firstWoDateVisible: !allVisibleSelected,
                                lastWoDateVisible: !allVisibleSelected,
                                periodHoursVisible: !allVisibleSelected,
                                pmCphVisible: !allVisibleSelected,
                                repairCphVisible: !allVisibleSelected,
                                tiresCphVisible: !allVisibleSelected,
                                damageCphVisible: !allVisibleSelected,
                                requestCphVisible: !allVisibleSelected,
                                attachCphVisible: !allVisibleSelected,
                                mcCphVisible: !allVisibleSelected,
                                plannedHoursVisible: !allVisibleSelected,
                                pmCountVisible: !allVisibleSelected,
                                avgPmIntervalVisible: !allVisibleSelected,
                                mfcCountVisible: !allVisibleSelected,
                                avgMfcIntervalVisible: !allVisibleSelected,
                              },
                            })
                          }
                          summarySettings.firstWoDateVisible = !allVisibleSelected
                          summarySettings.lastWoDateVisible = !allVisibleSelected
                          summarySettings.periodHoursVisible = !allVisibleSelected
                          summarySettings.pmCphVisible = !allVisibleSelected
                          summarySettings.repairCphVisible = !allVisibleSelected
                          summarySettings.tiresCphVisible = !allVisibleSelected
                          summarySettings.damageCphVisible = !allVisibleSelected
                          summarySettings.requestCphVisible = !allVisibleSelected
                          summarySettings.attachCphVisible = !allVisibleSelected
                          summarySettings.mcCphVisible = !allVisibleSelected
                          summarySettings.plannedHoursVisible = !allVisibleSelected
                          summarySettings.pmCountVisible = !allVisibleSelected
                          summarySettings.avgPmIntervalVisible = !allVisibleSelected
                          summarySettings.mfcCountVisible = !allVisibleSelected
                          summarySettings.avgMfcIntervalVisible = !allVisibleSelected
                          this.setState({ summarySettings })
                        }}
                      />
                    )
                  }}
                </Mutation>
                <strong>
                  {i18n
                    .t(allVisibleSelected ? 'deselectAll' : 'selectAll')
                    .toUpperCase()}
                </strong>
              </label>
            </div>
          </div>
        </div>

        <div>
          <h4>{i18n.t('summarySettings')}</h4>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label htmlFor="summaryTitle">{i18n.t('title')}</label>
            </div>
            <div className="field">
              <Mutation mutation={UPDATE_SUMMARY}>
                {(updateSummary) => {
                  return (
                    <input
                      id="summary-title"
                      className="text-field"
                      type="text"
                      value={summarySettings.title}
                      placeholder={i18n.t('title')}
                      onChange={(e) => {
                        if (
                          summaryId !== null &&
                          summarySettings.validForm === true
                        ) {
                          updateSummary({
                            variables: {
                              token: authToken,
                              id: parseInt(summaryId, 10),
                              title: e.target.value,
                            },
                          })
                        }
                        summarySettings.title = e.target.value
                        this.setState({ summarySettings })
                      }}
                    />
                  )
                }}
              </Mutation>
              <span id="summary-title-error" className="hidden field-error">
                {i18n.t('titleAlreadyExistsInSystem')}
              </span>
              <Query query={VALIDATE_SUMMARY_TITLE} variables={titleValidation}>
                {({ data }) => {
                  if (data) {
                    summarySettings.validForm = data.validateSummaryTitle
                    let field = document.getElementById('summary-title')
                    let errField = document.getElementById(
                      'summary-title-error',
                    )
                    if (field !== null && errField !== null) {
                      if (data.validateSummaryTitle === false) {
                        field.classList.add('error')
                        errField.classList.add('show')
                        errField.classList.remove('hidden')
                      } else {
                        field.classList.remove('error')
                        errField.classList.add('hidden')
                        errField.classList.remove('show')
                      }
                    }
                  }
                  return ''
                }}
              </Query>
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.includeAllWo}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary({
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                includeAllWo: !summarySettings.includeAllWo,
                              },
                            })
                          }
                          summarySettings.includeAllWo = !summarySettings.includeAllWo
                          this.setState({ summarySettings })
                        }}
                      />
                    )
                  }}
                </Mutation>
                {i18n.t('includeAllWorkOrdersInPDF')}
              </label>
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldLabel">
              <label>
                <Mutation mutation={UPDATE_SUMMARY}>
                  {(updateSummary) => {
                    return (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={summarySettings.pdfWoPricesVisible}
                        onChange={() => {
                          if (
                            summaryId !== null &&
                            summarySettings.validForm === true
                          ) {
                            updateSummary({
                              variables: {
                                token: authToken,
                                id: parseInt(summaryId, 10),
                                pdfWoPricesVisible: !summarySettings.pdfWoPricesVisible,
                              },
                            })
                            summarySettings.pdfWoPricesVisible = !summarySettings.pdfWoPricesVisible
                            this.setState({ summarySettings })
                          }
                        }}
                      />
                    )
                  }}
                </Mutation>
                {i18n.t('includePriceNonAvoidableWorkOrders')}
              </label>
            </div>
          </div>
          <TextField
            id="reportTitle"
            label={`${i18n.t('titleForReport')}:`}
            placeholder={i18n.t('titleForReport')}
            value={summarySettings.reportTitle}
          />
          <TextArea
            id="discussion"
            label={`${i18n.t('areasOfDiscussion')}:`}
            placeholder={i18n.t('areasOfDiscussion')}
            content={summarySettings.discussion}
          />
          <TextArea
            id="deliverPromise"
            label={`${i18n.t('deliveringThePromise')}:`}
            placeholder={i18n.t('deliveringThePromise')}
            content={summarySettings.deliverPromise}
          />
        </div>

        {pageAction === 'create' && (
          <Mutation mutation={CREATE_SUMMARY}>
            {(createSummary, { loading, error }) => {
              return (
                <div>
                  <Button
                    id="create-summary"
                    text={i18n.t('create')}
                    clickAction={() => {
                      let errorMsg = []

                      if (summarySettings.validForm === false) {
                        errorMsg.push(i18n.t('theTitleHasAnError'))
                      }

                      if (customerIds.length === 0) {
                        errorMsg.push(i18n.t('noCustomerSelected'))
                        let field = document.getElementById(
                          'local-search-field',
                        )
                        let errField = document.getElementById(
                          'local-search-error',
                        )
                        field.classList.add('error')
                        errField.classList.add('show')
                        errField.classList.remove('hidden')
                      }

                      if (errorMsg.length > 0) {
                        window.flash(
                          `${i18n.t(
                            'theFormHasTheFollowingIssues',
                          )} ${errorMsg.join('. ')}`,
                          'error',
                        )
                        return ''
                      }

                      const token = localStorage.getItem(AUTH_TOKEN)
                      let workOrders = ''
                      let equipment = ''
                      let customers = ''
                      let workOrdersExcluded = ''
                      let workOrdersShowcased = ''

                      avoidableWO.map((wo) => {
                        if (workOrders === '') {
                          workOrders = `${wo}`
                        } else {
                          workOrders += `,${wo}`
                        }
                        return ''
                      })

                      disallowedEquip.map((e) => {
                        if (equipment === '') {
                          equipment = `${e}`
                        } else {
                          equipment += `,${e}`
                        }
                        return ''
                      })

                      excludedWO.map((wo) => {
                        if (workOrdersExcluded === '') {
                          workOrdersExcluded = `${wo}`
                        } else {
                          workOrdersExcluded += `,${wo}`
                        }
                        return ''
                      })

                      showcasedWO.map((wo) => {
                        if (workOrdersShowcased === '') {
                          workOrdersShowcased = `${wo}`
                        } else {
                          workOrdersShowcased += `,${wo}`
                        }
                        return ''
                      })

                      customerIds.map((c) => {
                        if (customers === '') {
                          customers = `${c}`
                        } else {
                          customers += `,${c}`
                        }
                        return ''
                      })

                      createSummary({
                        variables: {
                          token,
                          customers,
                          workOrders,
                          equipment,
                          workOrdersExcluded,
                          workOrdersShowcased,
                          title: summarySettings.title,
                          isCustomer: summarySettings.isCustomer,
                          isEc: summarySettings.isEC,
                          isTc: summarySettings.isTC,
                          isInternal: summarySettings.isInternal,
                          startRange: summarySettings.startRange,
                          endRange: summarySettings.endRange,
                          firstWoDateVisible:
                            summarySettings.firstWoDateVisible,
                          lastWoDateVisible: summarySettings.lastWoDateVisible,
                          periodHoursVisible:
                            summarySettings.periodHoursVisible,
                          pmCountVisible: summarySettings.pmCountVisible,
                          avgPmIntervalVisible:
                            summarySettings.avgPmIntervalVisible,
                          mfcCountVisible: summarySettings.mfcCountVisible,
                          avgMfcIntervalVisible:
                            summarySettings.avgMfcIntervalVisible,
                          pdfWoPricesVisible:
                            summarySettings.pdfWoPricesVisible,
                          pmCphVisible: summarySettings.pmCphVisible,
                          repairCphVisible: summarySettings.repairCphVisible,
                          tiresCphVisible: summarySettings.tiresCphVisible,
                          damageCphVisible: summarySettings.damageCphVisible,
                          requestCphVisible: summarySettings.requestCphVisible,
                          attachCphVisible: summarySettings.attachCphVisible,
                          mcCphVisible: summarySettings.mcCphVisible,
                          plannedHoursVisible: summarySettings.plannedHoursVisible,
                          includeAllWo: summarySettings.includeAllWo,
                          kpiPeriod: 'monthly',
                          reportTitle: document.getElementById('reportTitle')
                            .value,
                          discussion: document.getElementById('discussion')
                            .value,
                          deliverPromise: document.getElementById(
                            'deliverPromise',
                          ).value,
                        },
                      }).then((res) => {
                        const id = res.data.createSummary.summary.id
                        summarySettings.initialLoad = true
                        summarySettings.reportTitle = document.getElementById(
                          'reportTitle',
                        ).value
                        summarySettings.discussion = document.getElementById(
                          'discussion',
                        ).value
                        summarySettings.deliverPromise = document.getElementById(
                          'deliverPromise',
                        ).value
                        this.setState({
                          summaryId: id,
                          summarySettings: summarySettings,
                          pageAction: 'update',
                        })
                        history.push(`/summary/${id}`)
                      })
                    }}
                  />
                  {loading && <p>{i18n.t('processing')}...</p>}
                  {error && <p>{error.message}</p>}
                </div>
              )
            }}
          </Mutation>
        )}
        {pageAction === 'update' && (
          <div>
            <Mutation mutation={UPDATE_SUMMARY}>
              {(updateSummary, { loading, error }) => {
                const token = localStorage.getItem(AUTH_TOKEN)

                return (
                  <div>
                    <Button
                      id="update-summary"
                      text={i18n.t('update')}
                      clickAction={() => {
                        let errorMsg = []
                        const reportTitle = document.getElementById(
                          'reportTitle',
                        ).value
                        const discussion = document.getElementById('discussion')
                          .value
                        const deliverPromise = document.getElementById(
                          'deliverPromise',
                        ).value

                        if (summarySettings.validForm === false) {
                          errorMsg.push(i18n.t('theTitleHasAnError'))
                        }

                        if (reportTitle === '') {
                          errorMsg.push(i18n.t('reportTitleCannotBeEmpty'))

                          document
                            .getElementById('reportTitle')
                            .classList.add('error')
                        }

                        if (discussion === '') {
                          errorMsg.push(i18n.t('areaOfDiscussionCannotBeEmpty'))

                          document
                            .getElementById('discussion')
                            .classList.add('error')
                        }

                        if (deliverPromise === '') {
                          errorMsg.push(
                            i18n.t('deliveringThePromiseCannotBeEmpty'),
                          )

                          document
                            .getElementById('deliverPromise')
                            .classList.add('error')
                        }

                        if (errorMsg.length > 0) {
                          window.flash(
                            `${i18n.t(
                              'theFormHasTheFollowingIssues',
                            )} ${errorMsg.join('. ')}`,
                            'error',
                          )
                          return ''
                        }

                        updateSummary({
                          variables: {
                            token,
                            id: parseInt(summaryId, 10),
                            title: summarySettings.title,
                            isCustomer: summarySettings.isCustomer,
                            isEc: summarySettings.isEC,
                            isTc: summarySettings.isTC,
                            isInternal: summarySettings.isInternal,
                            startRange: summarySettings.startRange,
                            endRange: summarySettings.endRange,
                            includeAllWo: summarySettings.includeAllWo,
                            firstWoDateVisible:
                              summarySettings.firstWoDateVisible,
                            lastWoDateVisible:
                              summarySettings.lastWoDateVisible,
                            periodHoursVisible:
                              summarySettings.periodHoursVisible,
                            pmCphVisible: summarySettings.pmCphVisible,
                            repairCphVisible: summarySettings.repairCphVisible,
                            tiresCphVisible: summarySettings.tiresCphVisible,
                            damageCphVisible: summarySettings.damageCphVisible,
                            requestCphVisible:
                              summarySettings.requestCphVisible,
                            attachCphVisible: summarySettings.attachCphVisible,
                            mcCphVisible: summarySettings.mcCphVisible,
                            plannedHoursVisible: summarySettings.plannedHoursVisible,
                            pdfWoPricesVisible:
                              summarySettings.pdfWoPricesVisible,
                            reportTitle,
                            discussion,
                            deliverPromise,
                          },
                        }).then(() => {
                          summarySettings.reportTitle = reportTitle
                          summarySettings.discussion = discussion
                          summarySettings.deliverPromise = deliverPromise

                          this.setState({ summarySettings })
                          window.flash(i18n.t('updatedSummary'), 'success')
                        })
                      }}
                    />
                    {loading && <p>{i18n.t('processing')}...</p>}
                    {error && <p>{error.message}</p>}
                  </div>
                )
              }}
            </Mutation>
            <Mutation mutation={GENERATE_SUMMARY_PDF}>
              {(generateSummaryPdf) => {
                const token = localStorage.getItem(AUTH_TOKEN)

                return (
                  <div>
                    <Button
                      id="generate-pdf"
                      text={i18n.t('generatePDF')}
                      clickAction={() => {
                        let errorMsg = []
                        const reportTitle = document.getElementById(
                          'reportTitle',
                        ).value
                        const discussion = document.getElementById('discussion')
                          .value
                        const deliverPromise = document.getElementById(
                          'deliverPromise',
                        ).value

                        if (summarySettings.validForm === false) {
                          errorMsg.push(i18n.t('theTitleHasAnError'))
                        }

                        if (reportTitle === '') {
                          errorMsg.push(i18n.t('reportTitleCannotBeEmpty'))

                          document
                            .getElementById('reportTitle')
                            .classList.add('error')
                        }

                        if (discussion === '') {
                          errorMsg.push(i18n.t('areaOfDiscussionCannotBeEmpty'))

                          document
                            .getElementById('discussion')
                            .classList.add('error')
                        }

                        if (deliverPromise === '') {
                          errorMsg.push(
                            i18n.t('deliveringThePromiseCannotBeEmpty'),
                          )

                          document
                            .getElementById('deliverPromise')
                            .classList.add('error')
                        }

                        if (errorMsg.length > 0) {
                          window.flash(
                            `${i18n.t(
                              'theFormHasTheFollowingIssues',
                            )} ${errorMsg.join('. ')}`,
                            'error',
                          )
                          return ''
                        }

                        summarySettings.executiveSummaryUrl = null
                        this.setState({ summarySettings })

                        generateSummaryPdf({
                          variables: {
                            id: parseInt(summaryId, 10),
                            token: token,
                            summaryPayload: JSON.stringify(payload),
                          },
                        }).then((res) => {
                          summarySettings.executiveSummaryUrl =
                            res.data.generateSummaryPdf.summary.executiveSummaryUrl
                          this.setState({ summarySettings })
                          window.flash(i18n.t('successfullyGeneratedNewPDF'))
                        })
                      }}
                    />
                  </div>
                )
              }}
            </Mutation>
          </div>
        )}
        {summarySettings.executiveSummaryUrl && (
          <div>
            <a
              href={summarySettings.executiveSummaryUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesome name="download" />
              &nbsp; {i18n.t('downloadPDF')}
            </a>
          </div>
        )}
      </div>
    )
  }
}

BomSettings.defaultProps = {
  history: {},
  summaryId: {},
  customerIds: {},
  customers: {},
  summarySettings: {},
  disallowedEquip: {},
  avoidableWO: {},
  excludedWO: {},
  showcasedWO: {},
  payload: {},
}

BomSettings.propTypes = {
  history: object,
  summaryId: PropType.string,
  customerIds: PropType.array,
  customers: PropType.array,
  summarySettings: object,
  disallowedEquip: PropType.array,
  avoidableWO: PropType.array,
  excludedWO: PropType.array,
  showcasedWO: PropType.array,
  payload: object,
}

export default BomSettings
