import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Query } from 'react-apollo'
import { object } from 'prop-types'
import i18n from 'i18n-js'

import Pagination from './Pagination'
import ReviewSearchResults from './ReviewSearchResults'
import SignInUser from './SignInUser'

import { AUTH_TOKEN } from '../constants'

import {
  LIMITED_ROLES
} from '../globals'

import {
  FETCH_CURRENT_USER,
  SEARCH_REVIEWS,
} from '../queries'

import '../styles/home.css'

class ReviewsHome extends Component {
  state = {
    currentPage: 1,
    pageCount: null,
    selectedNav: 'all-reviews',
  }

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)
    const { selectedNav } = this.state

    if (token === null) return <SignInUser />

    return (
      <Query query={FETCH_CURRENT_USER} variables={{ token }}>
        {({ loading, error, data }) => {
          if (loading) return <div>{i18n.t('fetching')}</div>
          if (error) return <div>{error.message}</div>

          const currentUser = data['currentUser']

          if (LIMITED_ROLES.includes(currentUser['role'])) return <Redirect to="/" />

          return(
            <div>
              <Link
                to="#"
                onClick={() => {
                  this.setState({ selectedNav: 'all-reviews' })
                }}
              >
                <div
                  id="all-reviews"
                  className={
                    selectedNav === 'all-reviews'
                      ? 'selectedNav localNav'
                      : 'localNav'
                  }
                >
                  {i18n.t('allReviews').toUpperCase()}
                </div>
              </Link>
              <Link
                to="#"
                onClick={() => {
                  this.setState({ selectedNav: 'my-reviews' })
                }}
              >
                <div
                  id="my-reviews"
                  className={
                    selectedNav === 'my-reviews' ? 'selectedNav localNav' : 'localNav'
                  }
                >
                  {i18n.t('myReviews').toUpperCase()}
                </div>
              </Link>

              <Query query={SEARCH_REVIEWS} variables={this._getQueryVariables()}>
                {({ loading, error, data }) => {
                  if (loading) return <div>{i18n.t('fetching')}</div>
                  if (error) return <div>{error.message}</div>

                  const resultsToRender = data.searchReviews.results

                  const pageCount = parseInt((data.searchReviews.pageCount - 1), 10)
                  const currentPage = parseInt(this.props.match.params.page, 10)

                  return (
                    <div>
                      {resultsToRender.map((result) => (
                        <ReviewSearchResults
                          key={parseInt(result.id, 10)}
                          reviewId={parseInt(result.id, 10)}
                          reviewableType={result.reviewableType}
                          message={result.message}
                          createdAt={result.createdAt}
                          completed={result.completedAt === null}
                          assignee={
                            result.user === null
                              ? null
                              : `${result.user.firstName} ${result.user.lastName}`
                          }
                        />
                      ))}
                      <Pagination pageCount={pageCount} currentPage={currentPage} />
                    </div>
                  )
                } }
              </Query>
            </div>
          )
        } }
      </Query>
    )
  }

  _getQueryVariables = () => {
    const token = localStorage.getItem(AUTH_TOKEN)
    const page = parseInt(this.props.match.params.page, 10)
    const selectedNav = this.state.selectedNav

    if (selectedNav === 'all-reviews') {
      return { token, page }
    } else {
      return { token, page, limited: 'true' }
    }
  }
}

ReviewsHome.defaultProps = {
  match: {},
}

ReviewsHome.propTypes = {
  match: object,
}

export default ReviewsHome
