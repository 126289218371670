import React, { Component } from 'react'
import { Query, Mutation } from 'react-apollo'
import { Link } from 'react-router-dom'
import i18n from 'i18n-js'

import AllReportedBugs from './AllReportedBugs'
import AllReviews from './AllReviews'
import AllSummaries from './AllSummaries'
import Button from './Button'
import SignInUser from './SignInUser'

import { AUTH_TOKEN } from '../constants'

import {
  CREATE_USER_DOCUMENT_AGREEMENT,
  FETCH_USER_DASHBOARD,
  FETCH_CUSTOMER_DASHBOARD,
  FETCH_CURRENT_USER,
} from '../queries'

import '../styles/home.css'
import InvoicedWorkOrders from './InvoicedWorkOrders'
import SERVER_REDIRECTION_URL from '../config/config'

class Dashboard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      docModal: 'hidden',
      agreeClicked: false,
      initSet: false,
    }
  }

  invoicePortal = () => {
    window.location.replace(
      `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
        'auth-token',
      )}&location=invoices`,
    )
  }

  workOrderPoral = () => {
    window.location.replace(
      `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
        'auth-token',
      )}&location=workorders`,
    )
  }

  newCustomerRedirection = () => {
    window.location.replace(
      `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
        'auth-token',
      )}&location=workorders&modal=newCustomers`,
    )
  }

  createWorkOrderRedirection = () => {
    window.location.replace(
      `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
        'auth-token',
      )}&location=workorders&modal=newWorkorder`,
    )
  }

  handleSubmit = (event, sendTokenMutation) => {
    event.preventDefault()

    sendTokenMutation({
      variables: { token: localStorage.getItem('auth-token') },
    })
      .then((response) => {
        console.log(response)
        // Handle success case
      })
      .catch((error) => {
        console.error(error)
        // Handle error case
      })
  }

  render() {
    const { agreeClicked, docModal, initSet } = this.state

    const token = localStorage.getItem(AUTH_TOKEN)
    if (!token) return <SignInUser />

    return (
      <div>
        <Query query={FETCH_CURRENT_USER} variables={{ token }}>
          {({ loading, error, data }) => {
            if (loading) return <div>{i18n.t('fetching')}</div>
            if (error) return <div>{error.message}</div>

            const currentUser = data['currentUser']

            if (currentUser['documentId'] !== null && initSet === false) {
              this.setState({ docModal: 'show', initSet: true })
            }

            return (
              <div>
                {currentUser['role'] === 'customer' && (
                  <span>
                    <div
                      onClick={this.workOrderPoral}
                      id="all-sites"
                      className="localNav"
                      style={{ cursor: 'pointer' }}
                    >
                      {i18n.t('workOrder').toUpperCase()}
                    </div>
                    <div
                      // onClick={(event) =>
                      //   this.handleSubmit(event, sendTokenMutation)
                      // }
                      onClick={this.invoicePortal}
                      id="all-invoices-vendor"
                      className="localNav"
                      style={{ cursor: 'pointer' }}
                    >
                      {i18n.t('myInvoices').toUpperCase()}
                    </div>
                  </span>
                )}
                {currentUser['role'] === 'vendor' && (
                  <span>
                    <div
                      onClick={this.workOrderPoral}
                      id="all-sites"
                      className="localNav"
                      style={{ cursor: 'pointer' }}
                    >
                      {i18n.t('workOrder').toUpperCase()}
                    </div>
                    <div
                      // onClick={(event) =>
                      //   this.handleSubmit(event, sendTokenMutation)
                      // }
                      onClick={this.invoicePortal}
                      id="all-invoices-vendor"
                      style={{ cursor: 'pointer' }}
                    ></div>
                    <div
                      // onClick={(event) =>
                      //   this.handleSubmit(event, sendTokenMutation)
                      // }
                      onClick={this.invoicePortal}
                      id="all-invoices-vendor"
                      className="localNav"
                      style={{ cursor: 'pointer' }}
                    >
                      {i18n.t('myInvoices').toUpperCase()}
                    </div>
                  </span>
                )}
                {['service', 'user', 'admin'].includes(currentUser['role']) && (
                  <span>
                    {currentUser['role'] !== 'service' && (
                      <Link to="/reviews/p/1">
                        <div id="all-reviews" className="localNav">
                          {i18n.t('allReviews').toUpperCase()}
                        </div>
                      </Link>
                    )}
                    <Link to="/summary/new">
                      <div id="summary-nav" className="localNav">
                        {i18n.t('createSummary').toUpperCase()}
                      </div>
                    </Link>
                    {/* Per Halsey: This is to be removed from the service role */}

                    {currentUser['role'] === 'admin' && (
                      <span>
                        <div
                          onClick={this.createWorkOrderRedirection}
                          id="create-work-order"
                          className="localNav"
                          style={{ cursor: 'pointer' }}
                        >
                          {i18n.t('createWorkOrder').toUpperCase()}
                        </div>
                        <Link to="/new-user">
                          <div id="create-user" className="localNav">
                            {i18n.t('createUser').toUpperCase()}
                          </div>
                        </Link>
                        <Link to="/new-contract">
                          <div id="create-contract" className="localNav">
                            {i18n.t('createContract').toUpperCase()}
                          </div>
                        </Link>
                        <Link to="/contracts">
                          <div id="all-contracts" className="localNav">
                            {i18n.t('allContracts').toUpperCase()}
                          </div>
                        </Link>
                        <Link to="/bug-reports">
                          <div id="all-bug-reports" className="localNav">
                            {i18n.t('allBugReports').toUpperCase()}
                          </div>
                        </Link>
                        <Link to="/new-customer">
                          <div
                            onClick={this.newCustomerRedirection}
                            id="new-customer"
                            className="localNav"
                          >
                            {i18n.t('newCustomer').toUpperCase()}
                          </div>
                        </Link>
                        <div
                          // onClick={(event) =>
                          //   this.handleSubmit(event, sendTokenMutation)
                          // }
                          onClick={this.invoicePortal}
                          id="all-invoices"
                          className="localNav"
                          style={{ cursor: 'pointer' }}
                        >
                          {i18n.t('allInvoices').toUpperCase()}
                        </div>
                        <Link to="/documents">
                          <div id="all-documents" className="localNav">
                            {i18n.t('allDocuments').toUpperCase()}
                          </div>
                        </Link>
                        <Link to="/review-work-orders">
                          <div id="review-work-orders" className="localNav">
                            {i18n.t('reviewWorkOrders').toUpperCase()}
                          </div>
                        </Link>
                      </span>
                    )}
                  </span>
                )}

                {currentUser['role'] === 'customer' && (
                  <Query query={FETCH_CUSTOMER_DASHBOARD} variables={{ token }}>
                    {({ loading, error, data }) => {
                      if (loading) return <div>{i18n.t('fetching')}</div>
                      if (error) return <div>{error.message}</div>

                      return (
                        <div>
                          <p>
                            <Link to={{ pathname: `/report/` }}>
                              <strong>ALL LOCATIONS</strong>
                            </Link>
                          </p>
                          <ul>
                            {data.user.userGroup.customer.childRelationships.map(
                              (cr) => (
                                <li key={cr.id}>
                                  <Link
                                    to={{
                                      pathname: `/report/${cr.childCustomer.id}`,
                                    }}
                                  >
                                    <p>
                                      <strong>{cr.customerInternalVal}</strong>
                                    </p>
                                    <p>
                                      {cr.childCustomer.name} -{' '}
                                      {cr.childCustomer.internalName}
                                    </p>
                                  </Link>
                                </li>
                              ),
                            )}
                          </ul>
                        </div>
                      )
                    }}
                  </Query>
                )}
                {currentUser['role'] === 'vendor' && <InvoicedWorkOrders />}
                {['admin', 'user', 'service'].includes(currentUser['role']) && (
                  <Query query={FETCH_USER_DASHBOARD} variables={{ token }}>
                    {({ loading, error, data }) => {
                      if (loading) return <div>{i18n.t('fetching')}</div>
                      if (error) return <div>{error.message}</div>

                      return (
                        <div>
                          {i18n.t('helloName', { name: currentUser['name'] })}
                          <AllSummaries summaries={data.user.summaries} />
                          {currentUser['role'] !== 'service' && (
                            <AllReviews reviews={data.user.reviews} />
                          )}
                          <AllReportedBugs reports={data.user.reportedBugs} />
                        </div>
                      )
                    }}
                  </Query>
                )}
                <div id="document-modal" className={'modal ' + docModal}>
                  <div className="modal-content">
                    <div className="modal-header">
                      <h2>{currentUser['documentName']}</h2>
                    </div>
                    <div className="modal-body">
                      <div>{currentUser['documentContent']}</div>
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={agreeClicked}
                            onChange={() => {
                              if (agreeClicked) {
                                this.setState({ agreeClicked: false })
                              } else {
                                this.setState({ agreeClicked: true })
                              }
                            }}
                          />
                          {i18n.t('iAgree')}
                        </label>
                      </div>
                      <Mutation mutation={CREATE_USER_DOCUMENT_AGREEMENT}>
                        {(createUserDocumentAgreement) => {
                          return (
                            <Button
                              id="confirm"
                              text={i18n.t('confirm')}
                              disabled={agreeClicked ? 'false' : 'true'}
                              clickAction={() => {
                                createUserDocumentAgreement({
                                  variables: {
                                    token,
                                    documentId: parseInt(
                                      currentUser['documentId'],
                                      10,
                                    ),
                                    customers: currentUser['customers'],
                                  },
                                }).then(() => {
                                  this.setState({
                                    docModal: 'hidden',
                                  })
                                })
                              }}
                            />
                          )
                        }}
                      </Mutation>
                    </div>
                  </div>
                </div>
              </div>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default Dashboard
