import React, { Component } from 'react'
import { Query, Mutation } from 'react-apollo'
import { Redirect } from 'react-router-dom'
import parse from 'html-react-parser'
import { object } from 'prop-types'
import i18n from 'i18n-js'

import Button from './Button'
import Reviewable from './Reviewable'
import ReviewUserSelector from './ReviewUserSelector'
import SignInUser from './SignInUser'
import TextArea from './TextArea'
import TimeStamp from './TimeStamp'

import {
  AUTH_TOKEN,
} from '../constants'

import {
  LIMITED_ROLES,
} from '../globals'

import {
  FETCH_REVIEW,
  ADD_COMMENT_TO_REVIEW,
  MARK_REVIEW_AS_COMPLETE,
  FETCH_CURRENT_USER,
} from '../queries'

class Review extends Component {
  constructor(props) {
    super(props)
    this.state = {
      comment: '',
      textAreaValue: '',
    }
  }

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser />

    return (
      <div>
        <Query query={FETCH_CURRENT_USER} variables={{ token }}>
          {({ loading, error, data }) => {
            if (loading) return <div>{i18n.t('fetching')}</div>
            if (error) return <div>{error.message}</div>

            const currentUser = data['currentUser']

            if (LIMITED_ROLES.includes(currentUser['role'])) return <Redirect to='/' />

            const id = parseInt(this.props.match.params.id, 10)

            return(
              <Query query={FETCH_REVIEW} variables={{token, id}}>
                {({ loading, error, data }) => {
                  if (loading) return <div>{i18n.t('fetching')}</div>
                  if (error) return <div>{error.message}</div>

                  const reviewId = data.review.id
                  const completedAt = data.review.completedAt
                  const reviewableType = data.review.reviewableType
                  const reviewableId = data.review.reviewableId

                  var comment = data.review.comment

                  var reviewerName = null
                  var reviewerId = null

                  if (data.review.user !== null) {
                    reviewerId = data.review.user.id
                    reviewerName = `${data.review.user.firstName} ${data.review.user.lastName}`
                  }

                  return (
                    <div>
                      <h3>{i18n.t('reviewId', { id: reviewId })}</h3>
                      <ReviewUserSelector
                        reviewId={reviewId}
                        reviewerName={reviewerName}
                        reviewerId={reviewerId}
                      />
                      <div className="fieldContainer">
                        <div className="fieldLabel">
                          <label>{i18n.t('created')}</label>
                        </div>
                        <div>
                          <TimeStamp timeToFormat={data.review.createdAt} />
                        </div>
                      </div>

                      <div className="fieldContainer">
                        <div className="fieldLabel">
                          <label>{i18n.t('message')}</label>
                        </div>
                        <div>{data.review.message}</div>
                      </div>

                      <div className="fieldContainer">
                        <div className="fieldLabel">
                          <label>{i18n.t('comment')}</label>
                        </div>
                        <div>
                          {comment === null ? (
                            <span>{i18n.t('nothingAddedYet')}</span>
                          ) : (
                            parse(comment)
                          )}
                        </div>
                        <div id="reviewCommentArea">
                          <TextArea
                            id="comment"
                            placeholder={i18n.t('addAdditionalComment')}
                          />
                        </div>
                        <Mutation mutation={ADD_COMMENT_TO_REVIEW}>
                          {(addCommentToReview, { loading, error }) => {
                            return (
                              <div>
                                <Button
                                  id="submit-comment"
                                  text={i18n.t('submitComment')}
                                  clickAction={() => {
                                    let allComment = ''
                                    let today = new Date()
                                    let dd = String(today.getDate()).padStart(2, '0')
                                    let mm = String(today.getMonth() + 1).padStart(
                                      2,
                                      '0',
                                    )
                                    let yyyy = today.getFullYear()
                                    if (comment === null) {
                                      allComment = document.getElementById('comment')
                                        .value
                                    } else {
                                      allComment =
                                        comment +
                                        '<br/>' +
                                        document.getElementById('comment').value
                                    }
                                    allComment =
                                      allComment +
                                      ' - ' +
                                      currentUser['name'] +
                                      ' - ' +
                                      mm +
                                      '/' +
                                      dd +
                                      '/' +
                                      yyyy
                                    this.setState({ comment: allComment })

                                    addCommentToReview({
                                      variables: {
                                        token,
                                        reviewId,
                                        comment: allComment,
                                      },
                                    }).then(() => {
                                      comment = allComment
                                      window.flash(
                                        i18n.t('successfullyUpdatedComment'),
                                        'success',
                                      )
                                      document.getElementById('comment').value = ''
                                    })
                                  }}
                                />
                                {loading && <p>{i18n.t('processing')}...</p>}
                                {error && <p>{error.message}</p>}
                              </div>
                            )
                          }}
                        </Mutation>
                      </div>

                      <div className="fieldContainer">
                        <div className="fieldLabel">
                          <label>{i18n.t('status')}</label>
                        </div>
                        {completedAt === null ? (
                          <div>
                            {i18n.t('thisIsNotCompletedYet')}
                            <Mutation mutation={MARK_REVIEW_AS_COMPLETE}>
                              {(markReviewAsCompleted, { loading, error }) => {
                                return (
                                  <span>
                                    <Button
                                      id="mark-complete"
                                      text={i18n.t('markComplete')}
                                      clickAction={() => {
                                        let today = new Date()
                                        let completedAt = ''

                                        completedAt =
                                          today.getFullYear() +
                                          '-' +
                                          String(today.getMonth() + 1).padStart(
                                            2,
                                            '0',
                                          ) +
                                          '-' +
                                          String(today.getDate()).padStart(2, '0') +
                                          ' ' +
                                          String(today.getHours()).padStart(2, '0') +
                                          ':' +
                                          String(today.getMinutes()).padStart(2, '0')
                                        markReviewAsCompleted({
                                          variables: {
                                            token,
                                            reviewId,
                                            completedAt,
                                          },
                                        }).then(() => {
                                          window.flash(
                                            i18n.t('markedReviewAsCompleted'),
                                            'success',
                                          )
                                        })
                                      }}
                                    />
                                    {loading && <p>processing...</p>}
                                    {error && <p>{error.message}</p>}
                                  </span>
                                )
                              }}
                            </Mutation>
                          </div>
                        ) : (
                          <div>
                            {`${i18n.t('markCompleted')} `}
                            <TimeStamp timeToFormat={data.review.completedAt} />
                          </div>
                        )}
                      </div>

                      <Reviewable
                        reviewableId={reviewableId}
                        reviewableType={reviewableType}
                      />
                    </div>
                  )
                }}
              </Query>
            )
          }}
        </Query>
      </div>
    )
  }

}

Review.defaultProps = {
  match: {},
}

Review.propTypes = {
  match: object,
}

export default Review
