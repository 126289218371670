import React, { Component } from 'react'
import PropTypes, { func } from 'prop-types'

class DateField extends Component {
  constructor(props) {
    super(props)

    this.changeDateValue = this.changeDateValue.bind(this)

    this.state = {
      id: props.id || 'date',
      label: props.label || 'Date',
      klass: props.klass || '',
      date: props.value || '',
    }
  }

  changeDateValue(e) {
    this.props.onDateChange(e.target.value)
    this.setState({ date: e.target.value })
  }

  render() {
    const { id, label, klass, date } = this.state

    return (
      <div className="fieldContainer">
        <div className="fieldLabel">
          <label>{label}</label>
        </div>
        <div className="field">
          <input
            type="date"
            id={id}
            value={date}
            onChange={this.changeDateValue.bind(this)}
            className={`date-time-date ${klass}`}
          />
        </div>
      </div>
    )
  }
}

DateField.defaultProps = {
  id: 'date',
  label: 'Date',
  klass: '',
  date: '',
  value: '',
  onDateChange: () => {},
}

DateField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  klass: PropTypes.string,
  date: PropTypes.string,
  value: PropTypes.string,
  onDateChange: func,
}

export default DateField
