import React, { Component } from 'react'
import {
  Query,
  Mutation,
} from 'react-apollo'

import {
  Link,
  Redirect,
} from 'react-router-dom'

import { object } from 'prop-types'
import i18n from 'i18n-js'

import Button from './Button'
import DateField from './DateField'
import DropDown from './DropDown'
import NumberField from './NumberField'
import SignInUser from './SignInUser'
import TextField from './TextField'

import { AUTH_TOKEN } from '../constants'

import {
  AMOUNT_TYPES,
  CONTRACT_TYPES,
  INVOICE_RATES,
  PAYMENT_RATES,
  REPORT_TYPES,
} from '../dropDowns'

import {
  CREATE_CONTRACT_EQUIPMENT,
  DESTROY_CONTRACT_EQUIPMENT,
  EQUIPMENT_SEARCH,
  FETCH_CONTRACT,
  FETCH_CURRENT_USER,
  UPDATE_CONTRACT,
  UPDATE_CONTRACT_EQUIPMENT,
} from '../queries'

class Contract extends Component {
  constructor(props) {
    super(props)

    const id = parseInt(this.props.match.params.id, 10)

    this.changeLocalSearch = this.changeLocalSearch.bind(this)

    this.state = {
      id: id,
      startRange: '',
      endRange: '',
      hold: false,
      payVendor: false,
      initLoad: true,
      contractEquip: [],
      localSearch: null,
      localSearchHide: true,
      selectedEquip: null,
    }
  }

  changeLocalSearch = async (event) => {
    const value = event.target.value

    const hideVal = value === ''

    this.setState({
      localSearch: value,
      localSearchHide: hideVal,
    })
    return ''
  }

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser />

    const {
      id,
      initLoad,
      contractEquip,
      localSearch,
      localSearchHide,
      selectedEquip,
    } = this.state

    let startRange = this.state.startRange
    let endRange = this.state.endRange
    let hold = this.state.hold
    let payVendor = this.state.payVendor

    const localSearchClass = localSearchHide ? 'hidden' : 'visible'

    return(
      <Query query={FETCH_CURRENT_USER} variables={{ token }}>
        {({ loading, error, data }) => {
          if (loading) return <div>{i18n.t('fetching')}</div>
          if (error) return<div>{error.message}</div>

          const currentUser = data['currentUser']

          if (currentUser['role'] !== 'admin') return <Redirect to='/' />

          return(
            <Query query={FETCH_CONTRACT} variables={{id, token}}>
              {({ loading, error, data }) => {
                if (loading) return <div>{i18n.t('fetching')}</div>
                if (error) return <div>{error.message}</div>

                const contract = data.contract

                startRange = contract.startRange.split(" ")[0]
                endRange = contract.endRange.split(" ")[0]

                if (initLoad) {
                  this.setState({
                    startRange: contract.startRange,
                    endRange: contract.endRange,
                    hold: contract.hold,
                    payVendor: contract.payVendor,
                    contractEquip: contract.contractEquipments,
                    initLoad: false,
                  })
                }

                return(
                  <div>
                    <h3>{i18n.t('contract', { internalName: contract.internalName })}</h3>
                    <div>
                      <h4>{i18n.t('customers')}</h4>
                      <ul>
                        {contract.customers.map((cust) => {
                          cust.custName = cust.name
                          if (cust.internalName !== null) { cust.custName += ` - ${cust.internalName}` }

                          return(
                            <li key={cust.id}>
                              <Link to={`/customer/${cust.id}`}>
                                {cust.parentRelationships[0].customerInternalVal}<br/>
                                {cust.custName}
                              </Link>
                            </li>
                          )  
                        } ) }
                      </ul>
                    </div>

                    <div>
                      <h4>{i18n.t('contractAttributes')}</h4>
                      <div>
                        <TextField
                          id='billingValue'
                          label={i18n.t('billingValue')}
                          placeholder={i18n.t('billingValue')}
                          value={contract.billingValue}
                        />

                        <DropDown
                          id='contractType'
                          label={i18n.t('contractType')}
                          options={CONTRACT_TYPES}
                          allowNull="false"
                          selected={contract.contractType}
                        />

                        <div className="fieldContainer">
                          <div className="fieldLabel">
                            <label>
                              <input
                                id="payVendor"
                                type="checkbox"
                                className="form-check-input"
                                checked={payVendor}
                                onChange={() => {
                                  payVendor = !(payVendor)
                                  this.setState({payVendor})
                                } }
                              />
                              {i18n.t("payVendor")}
                            </label>
                          </div>
                        </div>

                        <div className="fieldContainer">
                          <div className="fieldLabel">
                            <label>
                              <input
                                id="hold"
                                type="checkbox"
                                className="form-check-input"
                                checked={hold}
                                onChange={() => {
                                  hold = !(hold)
                                  this.setState({hold})
                                } }
                              />
                              {i18n.t("hold")}
                            </label>
                          </div>
                        </div>

                        <div>
                          <h5>{i18n.t("dateRange")}</h5>

                          <DateField
                            id="startRange"
                            label={i18n.t("startRange")}
                            value={startRange}
                            onDateChange={(date) => {
                              this.setState({startRange: date})
                            } }
                          />

                          <DateField
                            id="endRange"
                            label={i18n.t("endRange")}
                            value={endRange}
                            onDateChange={(date) => {
                              this.setState({endRange: date})
                            } }
                          />
                        </div>

                        <NumberField
                          id="amount"
                          label={i18n.t("amount")}
                          placeholder={i18n.t("amount")}
                          value={contract.amount}
                        />

                        <DropDown
                          id="amountType"
                          label={i18n.t("amountType")}
                          options={AMOUNT_TYPES}
                          allowNull="false"
                          selected={contract.amountType}
                        />

                        <DropDown
                          id="invoiceRate"
                          label={i18n.t("invoiceRate")}
                          placeholder={i18n.t("invoiceRate")}
                          options={INVOICE_RATES}
                          allowNull="false"
                          selected={contract.invoiceRate}
                        />

                        <DropDown
                          id="paymentRate"
                          label={i18n.t("paymentRate")}
                          placeholder={i18n.t("paymentRate")}
                          options={PAYMENT_RATES}
                          allowNull="false"
                          selected={contract.paymentRate}
                        />

                        <DropDown
                          id="reportType"
                          label={i18n.t("reportType")}
                          placeholder={i18n.t("reportType")}
                          options={REPORT_TYPES}
                          allowNull="false"
                          selected={contract.reportType}
                        />

                        <TextField
                          id='customerPo'
                          label={i18n.t('customerPo')}
                          placeholder={i18n.t('customerPo')}
                          value={contract.customerPo}
                        />

                        <TextField
                          id='paymentTerms'
                          label={i18n.t('paymentTerms')}
                          placeholder={i18n.t('paymentTerms')}
                          value={contract.paymentTerms}
                        />

                      </div>

                      <div>
                        <Mutation mutation={UPDATE_CONTRACT}>
                          {(updateContract, { loading, error }) => {
                            return(
                              <div>
                                <Button
                                  id='update-contract'
                                  text={i18n.t('update')}
                                  clickAction={() => {
                                    const billingValue = document.getElementById('billingValue').value

                                    let contractType = null
                                    let sel = document.getElementById('contractType')
                                    if (
                                      sel.options[sel.selectedIndex].text !== ''
                                    ) {
                                      contractType = sel.options[sel.selectedIndex]['value']
                                    }

                                    const hold = document.getElementById('hold').checked
                                    const payVendor = document.getElementById('payVendor').checked

                                    const amount = parseFloat(document.getElementById('amount').value)

                                    let amountType = null
                                    sel = document.getElementById('amountType')
                                    if (
                                      sel.options[sel.selectedIndex].text !== ''
                                    ) {
                                      amountType = sel.options[sel.selectedIndex]['value']
                                    }

                                    let invoiceRate = null
                                    sel = document.getElementById('invoiceRate')
                                    if (
                                      sel.options[sel.selectedIndex].text !== ''
                                    ) {
                                      invoiceRate = sel.options[sel.selectedIndex]['value']
                                    }

                                    let paymentRate = null
                                    sel = document.getElementById('paymentRate')
                                    if (
                                      sel.options[sel.selectedIndex].text !== ''
                                    ) {
                                      paymentRate = sel.options[sel.selectedIndex]['value']
                                    }

                                    let reportType = null
                                    sel = document.getElementById('reportType')
                                    if (
                                      sel.options[sel.selectedIndex].text !== ''
                                    ) {
                                      reportType = sel.options[sel.selectedIndex]['value']
                                    }

                                    const customerPo = document.getElementById('customerPo').value
                                    const paymentTerms = document.getElementById('paymentTerms').value

                                    updateContract({
                                      variables: {
                                        token,
                                        id,
                                        contractType,
                                        hold,
                                        payVendor,
                                        startRange: this.state.startRange,
                                        endRange: this.state.endRange,
                                        amount,
                                        amountType,
                                        invoiceRate,
                                        paymentRate,
                                        reportType,
                                        billingValue,
                                        customerPo,
                                        paymentTerms,
                                      },
                                    }).then(() => {
                                      window.flash(
                                        i18n.t('successfullyUpdatedContract'),
                                        'success',
                                      )
                                    })
                                  } }
                                />
                                {loading && <p>{i18n.t('processing')}</p>}
                                {error && <p>{error.message}</p>}
                              </div>
                            )
                          } }
                        </Mutation>
                      </div>

                    </div>

                    <hr />

                    <div>
                      <h3>{i18n.t('equipment')}</h3>

                      <table className='contractEquipments'>
                        <tbody>
                          <tr>
                            { selectedEquip === null ?
                              <td>
                                <div id="localSearchContainer">
                                  <input
                                    id="local-search-field"
                                    type="text"
                                    className="text-field"
                                    placeholder={i18n.t('searchEquipmentByNumber')}
                                    onChange={this.changeLocalSearch.bind(this)}
                                  />
                                  <span id="local-search-error" className="hidden field-error">
                                    {i18n.t('pleaseSelectAnEquipment')}
                                  </span>
                                </div>

                                <div id="localSearchResults" className={localSearchClass}>
                                  <Query query={EQUIPMENT_SEARCH} variables={{ token, search: localSearch }}>
                                    {({ loading, error, data }) => {
                                      if (loading) return <div className="report">{i18n.t('fetching')}</div>
                                      if (error) return <div className="report">{error.message}</div>

                                      const results = data.searchEquipment

                                      if (localSearch.length < 4) {
                                        return (
                                          <div className="report">
                                            {i18n.t('pleaseTypeFourOrMore')}
                                          </div>
                                        )
                                      }

                                      if (results.length === 0) {
                                        return (
                                          <div className="report">
                                            {i18n.t('noResultsFound')}
                                          </div>
                                        )
                                      }

                                      return (
                                        <div>
                                          {results.map((result) => {
                                            return (
                                              <div key={result.id} className="searchResults">
                                                <Link
                                                  to="#"
                                                  onClick={() => {
                                                    this.setState({ selectedEquip: result })
                                                  } }
                                                 >
                                                   { result.vendorEquipment[0].vendorEquipmentId }
                                                 </Link>
                                              </div>
                                            )
                                          } ) }
                                        </div>
                                      )
                                    } }
                                  </Query>
                                </div>
                              </td> :
                              <td>
                                { selectedEquip.vendorEquipment[0].vendorEquipmentId} &nbsp;
                                <Link
                                  to="#"
                                  onClick={() => {
                                    this.setState({ selectedEquip: null })
                                  } }
                                >
                                  X
                                </Link>
                              </td>
                            }
                            <td>
                              <DateField
                                id="start-range-new"
                                label={i18n.t("startRange")}
                                onDateChange={() => { } }
                              />
                            </td>
                            <td>
                              <DateField
                                id="end-range-new"
                                label={i18n.t("endRange")}
                                onDateChange={() => { } }
                              />
                            </td>
                            <td>
                              <Mutation mutation={CREATE_CONTRACT_EQUIPMENT}>
                                {(createContractEquipment, {loading, error}) => {
                                  return (
                                    <div>
                                      <Button
                                        id='create-contract-equipment'
                                        text={i18n.t('create')}
                                        clickAction={() => {
                                          let errorMsg = []

                                          let startRange = document.getElementById('start-range-new').value
                                          let endRange = document.getElementById('end-range-new').value

                                          if (selectedEquip === null) {
                                            errorMsg.push(i18n.t('noEquipmentSelected'))
                                          }

                                          if (startRange === '') {
                                            errorMsg.push(i18n.t('startRangeCannotBeBlank'))
                                          }

                                          if (endRange === '') {
                                            errorMsg.push(i18n.t('endRangeCannotBeBlank'))
                                          }

                                          if (errorMsg.length > 0) {
                                            window.flash(
                                              `${i18n.t(
                                                'theFormHasTheFollowingIssues',
                                              )}, ${errorMsg.join(', ')}.`,
                                            )

                                            return ''
                                          }

                                          createContractEquipment({
                                            variables: {
                                              token: token,
                                              contractId: parseInt(id, 10),
                                              startRange: startRange,
                                              endRange: endRange,
                                              equipmentId: parseInt(selectedEquip.id, 10),
                                            }
                                          }).then((res) => {
                                            window.flash(i18n.t('createdNewContractEquipment'), 'success')

                                            contractEquip.unshift(res.data.createContractEquipment.contractEquipment)
                                            document.getElementById('start-range-new').value = ""
                                            document.getElementById('end-range-new').value = ""
                                            this.setState({
                                              selectedEquip: null,
                                              localSearch: null,
                                              localSearchHide: true,
                                            })
                                          })
                                        } }
                                      />
                                      {loading && <p>{i18n.t('processing')}</p>}
                                      {error && <p>{error.message}</p>}
                                    </div>
                                  )
                                } }
                              </Mutation>
                            </td>
                          </tr>
                          { contractEquip.map((ce) => {
                            return (
                              <tr key={ce.id} id={`contract-equip-${ce.id}`}>
                                <td>
                                  {ce.equipment.vendorEquipment[0].vendorEquipmentId}
                                </td>
                                <td>
                                  <DateField
                                    id={`start-range-${ce.id}`}
                                    value={ce.startRange.split(' ')[0]}
                                    label={i18n.t('startRange')}
                                    onDateChange={(date) => {
                                      ce.startRange = date
                                    } }
                                  />
                                </td>
                                <td>
                                  <DateField
                                    id={`end-range-${ce.id}`}
                                    value={ce.endRange.split(' ')[0]}
                                    label={i18n.t('endRange')}
                                    onDateChange={(date) => {
                                      ce.endRange = date
                                    } }
                                  />
                                </td>
                                <td>
                                  <Mutation mutation={UPDATE_CONTRACT_EQUIPMENT}>
                                    {(updateContractEquipment, { loading, error }) => {
                                      return(
                                        <div>
                                          <Button
                                            id={`update-contract-equipment-${ce.id}`}
                                            text={i18n.t('update')}
                                            clickAction={() => {
                                              let errorMsg = []

                                              let startRange = document.getElementById(`start-range-${ce.id}`).value
                                              let endRange = document.getElementById(`end-range-${ce.id}`).value

                                              if (startRange === '') {
                                                errorMsg.push(i18n.t('startRangeCannotBeBlank'))
                                              }

                                              if (endRange === '') {
                                                errorMsg.push(i18n.t('endRangeCannotBeBlank'))
                                              }

                                              if (errorMsg.length > 0) {
                                                window.flash(
                                                  `${i18n.t(
                                                    'theFormHasTheFollowingIssues',
                                                  )} ${errorMsg.join('. ')}.`,
                                                )
                                                return ''
                                              }

                                              updateContractEquipment( {
                                                variables: {
                                                  token,
                                                  id: parseInt(ce.id, 10),
                                                  startRange,
                                                  endRange,
                                                }
                                              } ).then(() => {
                                                window.flash(i18n.t('successfullyUpdatedContractEquipment'), 'success')
                                              } )
                                            } }
                                          />
                                          { loading && <p>{i18n.t('processing')}</p> }
                                          { error && <p>{error.message}</p> }
                                        </div>
                                      )
                                    } }
                                  </Mutation>
                                </td>
                                <td>
                                  <Button
                                    id={`destroy-contract-equip-${ce.id}`}
                                    text={i18n.t('delete')}
                                    klass="destroy"
                                    clickAction={() => {
                                      document.getElementById(`delete-modal-${ce.id}`).classList.remove("hidden")
                                    } }
                                  />
                                </td>
                                <td>
                                  <div id={`delete-modal-${ce.id}`} className="modal hidden">
                                    <div className="modal-content delete-modal">
                                      <div className="modal-header">
                                        <span
                                          className="close"
                                          onClick={() => {
                                            document.getElementById(`delete-modal-${ce.id}`).classList.add("hidden")
                                          } }
                                        >
                                          &times;
                                        </span>
                                        <h2>{i18n.t("areYouSure")}</h2>
                                      </div>
                                      <div className="modal-body">
                                        <div>
                                          {i18n.t('confirmDeleteContractEquip', {ce: ce.equipment.vendorEquipment[0].vendorEquipmentId})}
                                          <div>
                                            <div className="splitButtons">
                                              <Mutation mutation={DESTROY_CONTRACT_EQUIPMENT}>
                                                {(destroyContractEquipment) => {
                                                  return(
                                                    <div>
                                                      <Button
                                                        id={`confirm-delete-${ce.id}`}
                                                        text={i18n.t("confirm")}
                                                        klass="destroy"
                                                        clickAction={() => {
                                                          destroyContractEquipment({
                                                            variables: {
                                                              token,
                                                              id: parseInt(ce.id, 10)
                                                            }
                                                          }).then(() => {
                                                            window.flash(i18n.t('deletedContractEquip'), 'success')
                                                            document.getElementById(`contract-equip-${ce.id}`).classList.add("hidden")
                                                            document.getElementById(`delete-modal-${ce.id}`).classList.add("hidden")
                                                          })
                                                        } }
                                                      />
                                                    </div>
                                                  )
                                                } }
                                              </Mutation>
                                            </div>
                                            <div className="splitButtons">
                                              <Button
                                                id={`cancel-delete-${ce.id}`}
                                                text={i18n.t("cancel")}
                                                clickAction={() => {
                                                  document.getElementById(`contract-equip-${ce.id}`).classList.add("hidden")
                                                } }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          } ) }
                        </tbody>
                      </table>
                    </div>

                  </div>
                )
              } }
            </Query>
          )
        } }
      </Query>
    )
  }
}

Contract.defaultProps = {
  match: {},
}

Contract.propTypes = {
  match: object,
}

export default Contract
