import React from 'react'
import PropType from 'prop-types'

import NumberField from './NumberField'

const CurrencyField = ({ value, ...props } = {}) => (
  <NumberField
    {...props}
    min="0.00"
    value={value && parseFloat(value).toFixed(2)}
    klass="currency"
    step="0.01"
  />
)

CurrencyField.defaultProps = {
  value: '',
}

CurrencyField.propTypes = {
  value: PropType.oneOfType([PropType.string, PropType.number]),
}

export default CurrencyField
