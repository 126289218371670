import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'

import Button from './Button'
import TextArea from './TextArea'
import TimeStamp from './TimeStamp'

import { AUTH_TOKEN } from '../constants'
import { CREATE_NOTE } from '../queries'

class AllNotes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      notes: props.notes,
      notableType: props.notableType,
      notableId: props.notableId,
      showHideMain: 'hidden',
      showHideForm: 'hidden',
      buttonText: i18n.t('addNewNote'),
    }
  }

  render() {
    const { notes, showHideMain, showHideForm, buttonText } = this.state

    return (
      <div>
        <Link to="#" onClick={this.toggleMainDisplay.bind(this)}>
          <div className="title-toggle">
            <h4>{i18n.t('notes')}</h4>
          </div>
        </Link>
        {notes.length <= 0 ? (
          <div className={showHideMain}>{i18n.t('noNotesFound')}</div>
        ) : (
          <div className={showHideMain}>
            {notes.map((note) => (
              <div key={note.id} className="display-note">
                <div className="fieldContainer">
                  <div className="fieldLabel">
                    <label>{i18n.t('user')}</label>
                  </div>
                  {note.user === null
                    ? 'ELC'
                    : `${note.user.firstName} ${note.user.lastName}`}
                </div>
                <div className="fieldContainer">
                  <div className="fieldLabel">
                    <label>{i18n.t('createdAt')}</label>
                  </div>
                  <TimeStamp timeToFormat={note.createdAt} />
                </div>
                <div className="note-content">{note.content}</div>
              </div>
            ))}
          </div>
        )}
        <div>
          <Button
            id="open-note-space"
            text={buttonText}
            clickAction={this.toggleNoteDisplay.bind(this)}
          />
          <div className={`${showHideForm} new-note-space`}>
            <TextArea
              id="newNote"
              label={i18n.t('newNote')}
              placeholder={i18n.t('note').toUpperCase()}
            />
            <Mutation mutation={CREATE_NOTE}>
              {(createNote, { loading, error }) => {
                return (
                  <div>
                    <Button
                      id="submit-note"
                      text={i18n.t('submit')}
                      clickAction={() => {
                        const token = localStorage.getItem(AUTH_TOKEN)
                        const content = document.getElementById('newNote').value
                        const notableType = this.state.notableType
                        const notableId = parseInt(this.state.notableId, 10)

                        createNote({
                          variables: {
                            token: token,
                            content: content,
                            notableType: notableType,
                            notableId: notableId,
                          },
                        }).then((res) => {
                          notes.push(res.data.createNote.note)
                          this.setState({notes})
                          window.flash(i18n.t('successfullyCreatedNewNote'), 'success')
                        })
                      }}
                    />
                    {loading && <p>{i18n.t('processing')}...</p>}
                    {error && <p>{error.message}</p>}
                  </div>
                )
              }}
            </Mutation>
          </div>
        </div>
      </div>
    )
  }

  toggleMainDisplay() {
    var css = this.state.showHideMain === 'hidden' ? 'visible' : 'hidden'
    this.setState({ showHideMain: css })
  }

  toggleNoteDisplay() {
    var css = ''
    var buttonText = ''
    if (this.state.showHideForm === 'hidden') {
      css = 'show'
      buttonText = i18n.t('hideNote')
    } else {
      css = 'hidden'
      buttonText = i18n.t('addNewNote')
    }
    this.setState({ showHideForm: css })
    document.getElementById('open-note-space').innerText = buttonText
  }
}

AllNotes.defaultProps = {
  notes: {},
  notableType: {},
  notableId: {},
}

AllNotes.propTypes = {
  notes: PropTypes.array,
  notableType: PropTypes.string,
  notableId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default AllNotes
