import React, { Component } from 'react'
import PropType from 'prop-types'

class TextArea extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: props.id,
      placeholder: props.placeholder || '',
      required: props.required || false,
      content: props.content || '',
      label: props.label || null,
      klass: props.klass || null,
    }
  }

  changeValue(event) {
    const content = event.target.value
    event.target.classList.remove('error')

    this.setState({ content, error: '' })
  }

  render() {
    const { id, klass, placeholder, required, content, label } = this.state

    return (
      <div>
        {label !== null ? (
          <div className="fieldLabel">
            <label htmlFor={id}>{label}</label>
          </div>
        ) : (
          ''
        )}
        <textarea
          id={id}
          placeholder={placeholder}
          className={klass}
          required={required}
          onChange={this.changeValue.bind(this)}
          value={content}
        ></textarea>
      </div>
    )
  }
}

TextArea.defaultProps = {
  id: null,
  placeholder: '',
  required: false,
  content: '',
  label: '',
  klass: '',
}

TextArea.propTypes = {
  id: PropType.string,
  placeholder: PropType.string,
  required: PropType.bool,
  content: PropType.string,
  label: PropType.string,
  klass: PropType.string,
}

export default TextArea
