import React, { Component } from 'react'
import PropTypes from 'prop-types'

class TextField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: props.id,
      fieldType: props.fieldType || 'text',
      value: props.value || '',
      label: props.label || null,
      placeholder: props.placeholder || '',
      required: props.required || false,
      pattern: props.pattern || null,
      min: props.min || null,
      max: props.max || null,
      klass: props.klass || null,
      step: props.step || null,
    }
  }

  changeValue(event) {
    let value = event.target.value
    let id = event.target.id

    let errField = document.getElementById(`${id}-error`)

    event.target.classList.remove('error')
    errField.classList.add('hidden')
    errField.classList.remove('show')

    this.setState({ value, error: '' })
  }

  render() {
    const {
      id,
      value,
      label,
      fieldType,
      placeholder,
      required,
      pattern,
      min,
      max,
      klass,
      step,
    } = this.state

    return (
      <div className="fieldContainer">
        {label !== null ? (
          <div className="fieldLabel">
            <label htmlFor={id}>{label}</label>
          </div>
        ) : (
          ''
        )}
        <div className={klass === null ? 'field' : `field ${klass}`}>
          {klass === 'currency' ? <span className="currency-sign">$</span> : ''}
          <input
            id={id}
            className={klass === null ? 'text-field' : `${klass}`}
            type={fieldType}
            value={value}
            placeholder={placeholder}
            onFocus={() => this.setState({ active: true })}
            onBlur={() => this.setState({ active: false })}
            onChange={this.changeValue.bind(this)}
            required={required}
            pattern={pattern === null ? null : `${pattern}`}
            min={min === null ? null : `${min}`}
            max={max === null ? null : `${max}`}
            step={step === null ? null : `${step}`}
          />
          <span id={id + '-error'} className="hidden field-error">
            what
          </span>
        </div>
      </div>
    )
  }
}

TextField.defaultProps = {
  id: null,
  fieldType: 'text',
  value: '',
  label: '',
  placeholder: '',
  required: false,
  pattern: null,
  min: null,
  max: null,
  klass: null,
  step: null,
}

TextField.propTypes = {
  id: PropTypes.string,
  fieldType: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  pattern: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  klass: PropTypes.string,
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default TextField
