import React, { Component } from 'react'
import { CSVLink } from 'react-csv'
import { Link } from 'react-router-dom'
import FontAwesome from 'react-fontawesome'
import { createBrowserHistory } from 'history'
import PropType, { object } from 'prop-types'
import i18n from 'i18n-js'

import SignInUser from './SignInUser'
import SummaryCostChart from './SummaryCostChart'
import TimeStamp from './TimeStamp'

import { AUTH_TOKEN } from '../constants'
import '../styles/report.css'

class BomSummary extends Component {
  constructor(props) {
    super(props)

    let history = createBrowserHistory()
    history.replace(history.location.pathname, {
      selectedNav: 'report-summary',
    })

    this.changeSortBy = this.changeSortBy.bind(this)
    this.linkToFilteredWorkOrders = this.linkToFilteredWorkOrders.bind(this)
    this.monetize = this.monetize.bind(this)

    this.state = {
      customerIds: props.customerIds,
      summarySettings: props.summarySettings,
      payload: props.payload,
      customers: props.customers,
    }
  }

  changeSortBy(val) {
    const summarySettings = this.state.summarySettings
    summarySettings.sortBy = val
    this.setState({ summarySettings })
  }

  linkToFilteredWorkOrders(equipId, workCategory) {
    const summarySettings = this.state.summarySettings
    summarySettings.woFilterEquipment = equipId
    summarySettings.woFilterWorkCategory = workCategory
    summarySettings.woFilterServiceType = ''
    summarySettings.selectedNav = 'report-work-order'
    this.setState({ summarySettings })
  }

  monetize(val, defaultVal = 'N/C', includeCents = true) {
    if (isNaN(parseFloat(val))) {
      return defaultVal
    } else {
      let finParts = parseFloat(val).toFixed(2).split('.')
      finParts[0] = finParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      if (includeCents) {
        return `$${finParts.join('.')}`
      } else {
        return `$${finParts[0]}`
      }
    }
  }

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)
    const { customerIds, summarySettings, payload, customers } = this.state

    if (token === null) return <SignInUser />

    let topHeaderLength = 13

    if (customerIds.length > 1) {
      topHeaderLength += 1
    }

    if (summarySettings.lastWoDateVisible) {
      topHeaderLength += 1
    }

    if (summarySettings.firstWoDateVisible) {
      topHeaderLength += 1
    }

    if (summarySettings.periodHoursVisible) {
      topHeaderLength += 1
    }

    if (summarySettings.pmCountVisible) {
      topHeaderLength += 1
    }

    if (summarySettings.avgPmIntervalVisible) {
      topHeaderLength += 1
    }

    if (summarySettings.mfcCountVisible) {
      topHeaderLength += 1
    }

    if (summarySettings.avgMfcIntervalVisible) {
      topHeaderLength += 1
    }

    if (summarySettings.plannedHoursVisible) {
      topHeaderLength += 1
    }

    const csvHeaders = [
      { label: 'EOBJ', key: 'eobj' },
      { label: 'Maintenance Level', key: 'maintLevel' },
      { label: 'Make', key: 'make' },
      { label: 'Model', key: 'model' },
      { label: 'Serial', key: 'serial' },
      { label: 'Fleet No', key: 'fleetNo' },
      { label: 'Start KOM', key: 'startKom' },
      { label: 'End KOM', key: 'endKom' },
      { label: 'Start HM', key: 'startHm' },
      { label: 'End HM', key: 'endHm' },
      { label: 'Annual Hours', key: 'annualHours' },
      { label: 'Annual Utilization Rate', key: 'aur' },
      { label: 'Availability', key: 'availability' },
      { label: 'Total Cost', key: 'totalCost' },
      { label: 'PM', key: 'pm' },
      { label: 'Repair', key: 'repair' },
      { label: 'Tires', key: 'tires' },
      { label: 'Damage', key: 'damage' },
      { label: 'Request', key: 'request' },
      { label: 'Attachment', key: 'attach' },
      { label: 'Maintenance Contracts', key: 'mc' },
      { label: 'Total CPH', key: 'totalCph' },
    ]

    if (summarySettings.mcCphVisible) {
      csvHeaders.splice(19, 0, { label: 'MC CPH', key: 'mcCph' })
    }

    if (summarySettings.attachCphVisible) {
      csvHeaders.splice(19, 0, { label: 'Attachment CPH', key: 'attachCph' })
    }

    if (summarySettings.requestCphVisible) {
      csvHeaders.splice(19, 0, { label: 'Request CPH', key: 'requestCph' })
    }

    if (summarySettings.damageCphVisible) {
      csvHeaders.splice(19, 0, { label: 'Damage CPH', key: 'damageCph' })
    }

    if (summarySettings.tiresCphVisible) {
      csvHeaders.splice(19, 0, { label: 'Tires CPH', key: 'tiresCph' })
    }

    if (summarySettings.repairCphVisible) {
      csvHeaders.splice(19, 0, { label: 'Repair CPH', key: 'repairCph' })
    }

    if (summarySettings.pmCphVisible) {
      csvHeaders.splice(19, 0, { label: 'PM CPH', key: 'pmCph' })
    }

    if (summarySettings.avgMfcIntervalVisible) {
      csvHeaders.splice(10, 0, { label: 'Avg MFC Interval', key: 'avgMfcInt' })
    }

    if (summarySettings.mfcCountVisible) {
      csvHeaders.splice(10, 0, { label: 'MFC Count', key: 'mfcCount' })
    }

    if (summarySettings.avgPmIntervalVisible) {
      csvHeaders.splice(10, 0, { label: 'Avg PM Interval', key: 'avgPmInt' })
    }

    if (summarySettings.pmCountVisible) {
      csvHeaders.splice(10, 0, { label: 'PM Count', key: 'pmCount' })
    }

    if (summarySettings.periodHoursVisible) {
      csvHeaders.splice(8, 0, { label: 'Period Hours', key: 'periodHours' })
    }

    if (summarySettings.lastWoDateVisible) {
      csvHeaders.splice(6, 0, { label: 'Last WO Date', key: 'lastWoDate' })
    }

    if (summarySettings.firstWoDateVisible) {
      csvHeaders.splice(6, 0, { label: 'First WO Date', key: 'firstWoDate' })
    }

    if (customerIds.length > 1) {
      csvHeaders.splice(1, 0, { label: 'Customer', key: 'customer' })
    }

    const csvData = []

    let equipment = payload.equipment

    switch (summarySettings.sortBy) {
      case 'eobjDesc':
        equipment.sort((a, b) => (a.vendorEquipId < b.vendorEquipId ? 1 : -1))
        break
      case 'makeAsc':
        equipment.sort((a, b) => (a.make > b.make ? 1 : -1))
        break
      case 'makeDesc':
        equipment.sort((a, b) => (a.make < b.make ? 1 : -1))
        break
      case 'modelAsc':
        equipment.sort((a, b) => (a.model > b.model ? 1 : -1))
        break
      case 'modelDesc':
        equipment.sort((a, b) => (a.model < b.model ? 1 : -1))
        break
      case 'totalCostAsc':
        equipment.sort((a, b) => (a.totalCost < b.totalCost ? 1 : -1))
        break
      case 'totalCostDesc':
        equipment.sort((a, b) => (a.totalCost > b.totalCost ? 1 : -1))
        break
      case 'pmCostAsc':
        equipment.sort((a, b) => (a.pmCost < b.pmCost ? 1 : -1))
        break
      case 'pmCostDesc':
        equipment.sort((a, b) => (a.pmCost > b.pmCost ? 1 : -1))
        break
      case 'repairCostAsc':
        equipment.sort((a, b) => (a.repairCost < b.repairCost ? 1 : -1))
        break
      case 'repairCostDesc':
        equipment.sort((a, b) => (a.repairCost > b.repairCost ? 1 : -1))
        break
      case 'tiresCostAsc':
        equipment.sort((a, b) => (a.tiresCost < b.tiresCost ? 1 : -1))
        break
      case 'tiresCostDesc':
        equipment.sort((a, b) => (a.tiresCost > b.tiresCost ? 1 : -1))
        break
      case 'damageCostAsc':
        equipment.sort((a, b) => (a.damageCost < b.damageCost ? 1 : -1))
        break
      case 'damageCostDesc':
        equipment.sort((a, b) => (a.damageCost > b.damageCost ? 1 : -1))
        break
      case 'requestCostAsc':
        equipment.sort((a, b) => (a.requestCost < b.requestCost ? 1 : -1))
        break
      case 'requestCostDesc':
        equipment.sort((a, b) => (a.requestCost > b.requestCost ? 1 : -1))
        break
      case 'attachCostAsc':
        equipment.sort((a, b) => (a.attachCost < b.attachCost ? 1 : -1))
        break
      case 'attachCostDesc':
        equipment.sort((a, b) => (a.attachCost > b.attachCost ? 1 : -1))
        break
      case 'mcCostAsc':
        equipment.sort((a, b) => (a.mcCost < b.mcCost ? 1 : -1))
        break
      case 'mcCostDesc':
        equipment.sort((a, b) => (a.mcCost > b.mcCost ? 1 : -1))
        break
      case 'pmCphAsc':
        equipment.sort((a, b) => (a.pmCph < b.pmCph ? 1 : -1))
        break
      case 'pmCphDesc':
        equipment.sort((a, b) => (a.pmCph > b.pmCph ? 1 : -1))
        break
      case 'repairCphAsc':
        equipment.sort((a, b) => (a.repairCph < b.repairCph ? 1 : -1))
        break
      case 'repairCphDesc':
        equipment.sort((a, b) => (a.repairCph > b.repairCph ? 1 : -1))
        break
      case 'tiresCphAsc':
        equipment.sort((a, b) => (a.tiresCph < b.tiresCph ? 1 : -1))
        break
      case 'tiresCphDesc':
        equipment.sort((a, b) => (a.tiresCph > b.tiresCph ? 1 : -1))
        break
      case 'damageCphAsc':
        equipment.sort((a, b) => (a.damageCph < b.damageCph ? 1 : -1))
        break
      case 'damageCphDesc':
        equipment.sort((a, b) => (a.damageCph > b.damageCph ? 1 : -1))
        break
      case 'requestCphAsc':
        equipment.sort((a, b) => (a.requestCph < b.requestCph ? 1 : -1))
        break
      case 'requestCphDesc':
        equipment.sort((a, b) => (a.requestCph > b.requestCph ? 1 : -1))
        break
      case 'attachCphAsc':
        equipment.sort((a, b) => (a.attachCph < b.attachCph ? 1 : -1))
        break
      case 'attachCphDesc':
        equipment.sort((a, b) => (a.attachCph > b.attachCph ? 1 : -1))
        break
      case 'mcCphAsc':
        equipment.sort((a, b) => (a.mcCph < b.mcCph ? 1 : -1))
        break
      case 'mcCphDesc':
        equipment.sort((a, b) => (a.mcCph > b.mcCph ? 1 : -1))
        break
      case 'totalCphAsc':
        equipment.sort((a, b) => (a.totalCph < b.totalCph ? 1 : -1))
        break
      case 'totalCphDesc':
        equipment.sort((a, b) => (a.totalCph > b.totalCph ? 1 : -1))
        break
      case 'careAsc':
        equipment.sort((a, b) => {
          let aVal = 0
          let bVal = 0
          switch (a.maintenanceLevel) {
            case 'TC':
              aVal = 4
              break
            case 'EC':
              aVal = 3
              break
            case 'PM':
              aVal = 2
              break
            default:
              aVal = 1
              break
          }

          switch (b.maintenanceLevel) {
            case 'TC':
              bVal = 4
              break
            case 'EC':
              bVal = 3
              break
            case 'PM':
              bVal = 2
              break
            default:
              bVal = 1
              break
          }

          if (aVal < bVal) {
            return 1
          } else {
            return -1
          }
        })
        break
      case 'careDesc':
        equipment.sort((a, b) => {
          let aVal = 0
          let bVal = 0
          switch (a.maintenanceLevel) {
            case 'TC':
              aVal = 4
              break
            case 'EC':
              aVal = 3
              break
            case 'PM':
              aVal = 2
              break
            default:
              aVal = 1
              break
          }

          switch (b.maintenanceLevel) {
            case 'TC':
              bVal = 4
              break
            case 'EC':
              bVal = 3
              break
            case 'PM':
              bVal = 2
              break
            default:
              bVal = 1
              break
          }

          if (aVal > bVal) {
            return 1
          } else {
            return -1
          }
        })
        break
      default:
        equipment.sort((a, b) => (a.vendorEquipId > b.vendorEquipId ? 1 : -1))
        break
    }

    equipment.map((equip) => {
      let data = {
        eobj: equip.vendorEquipId,
        maintLevel: equip.maintenanceLevel,
        make: equip.make,
        model: equip.model,
        serial: equip.serialNumber,
        fleetNo: equip.fleetNumber,
        startKom: equip.startKom,
        endKom: equip.endKom,
        startHm: equip.startHm,
        endHm: equip.endHm,
        annualHours: equip.annualHours,
        aur: equip.aur,
        periodHours: equip.periodHours,
        availability: `${equip.availability.toFixed(1)}%`,
        totalCost: this.monetize(equip.totalCost),
        pm: this.monetize(equip.pmCost),
        repair: this.monetize(equip.repairCost),
        tires: this.monetize(equip.tiresCost),
        damage: this.monetize(equip.damageCost),
        request: this.monetize(equip.requestCost),
        attach: this.monetize(equip.attachCost),
        mc: this.monetize(equip.mcCost),
        totalCph: this.monetize(equip.totalCph),
      }

      if (summarySettings.mcCphVisible) {
        data.mcCph = this.monetize(equip.mcCph, '$0.00')
      }

      if (summarySettings.attachCphVisible) {
        data.attachCph = this.monetize(equip.attachCph, '$0.00')
      }

      if (summarySettings.requestCphVisible) {
        data.requestCph = this.monetize(equip.requestCph, '$0.00')
      }

      if (summarySettings.damageCphVisible) {
        data.damageCph = this.monetize(equip.damageCph, '$0.00')
      }

      if (summarySettings.tiresCphVisible) {
        data.tiresCph = this.monetize(equip.tiresCph, '$0.00')
      }

      if (summarySettings.repairCphVisible) {
        data.repairCph = this.monetize(equip.repairCph, '$0.00')
      }

      if (summarySettings.pmCphVisible) {
        data.pmCph = this.monetize(equip.pmCph, '$0.00')
      }

      if (summarySettings.mcCphVisible) {
        data.mcCph = this.monetize(equip.mcCph, '$0.00')
      }

      if (summarySettings.avgMfcIntervalVisible) {
        data.avgMfcInt =
          equip.avgMfcInterval === 0
            ? 'N/C'
            : parseInt(equip.avgMfcInterval, 10)
      }

      if (summarySettings.mfcCountVisible) {
        data.mfcCount = equip.mfcCount
      }

      if (summarySettings.avgPmIntervalVisible) {
        data.avgPmInt =
          equip.avgPmInterval === 0 ? 'N/C' : parseInt(equip.avgPmInterval, 10)
      }

      if (summarySettings.pmCountVisible) {
        data.pmCount = equip.pmCount
      }

      if (summarySettings.periodHoursVisible) {
        data.periodHours = equip.periodHours
      }

      if (summarySettings.lastWoDateVisible) {
        data.lastWoDate = equip.lastWorkOrder.invoicedAt
      }

      if (summarySettings.firstWoDateVisible) {
        if (equip.firstWorkOrder === null) {
          data.firstWoDate = equip.lastWorkOrder.invoicedAt
        } else {
          data.firstWoDate = equip.firstWorkOrder.invoicedAt
        }
      }

      if (customerIds.length > 1) {
        data.customer = equip.customerName
      }

      csvData.push(data)

      return ''
    })

    let hasInaccurateHours = false;

    payload.equipment.forEach((equip) => {
      if (equip.periodHours === 'N/C' || equip.periodHours <= 0) {
        hasInaccurateHours = true;
      }
    });

    return (
      <div>
        <div className="summary-header">
          <div>
            <h2>{i18n.t('summary')}</h2>
            {customers.map((customer) => {
              return (
                <li key={customer.id}>
                  <Link to={{ pathname: `/customer/${customer.id}` }}>
                    {customer.name} - {customer.internalName} (
                    {customer.parentRelationships[0].customerInternalVal})
                  </Link>
                </li>
              )
            })}
            <p>
              <b>{i18n.t('startDate')}</b> {summarySettings.startRange}
            </p>
            <p>
              <b>{i18n.t('endDate')}</b> {summarySettings.endRange}
            </p>
          </div>
          <div>
            <p>
              <b>{i18n.t('workDays')}:</b> {payload.numDays}
            </p>
            <p>
              <b>{i18n.t('daysSlashWeek')}:</b> {payload.dpw}
            </p>
            <p>
              <b>{i18n.t('hoursSlashDay')}:</b> {payload.hpd}
            </p>
            <p>
              <b>{i18n.t('trucks')}:</b> {payload.fleetSize}
            </p>
            <p>
              <b>{i18n.t('overallCPH')}:</b> $
              {parseFloat(payload.totalCph).toFixed(2)}
            </p>
            <p>
              <b>{i18n.t('cphWoDamageAndRequests')}</b> $
              {parseFloat(payload.adjustedCph).toFixed(2)}
            </p>
          </div>
          <div>
            <p>
              <b>{i18n.t('availableHours')}:</b> {payload.actualHours}
            </p>
            <p>
              <b>{i18n.t('downtime')}:</b> {payload.totalDowntime}
            </p>
            <p>
              <b>{i18n.t('uptime')}:</b> {parseFloat(payload.uptime).toFixed(2)}
              %
            </p>
            <p>
              <b>{i18n.t('averageFleetAvailability')}:</b>{' '}
              {parseFloat(payload.averageFleetAvailability).toFixed(2)}%
            </p>
            <p>
             {/* total hours */}
            <b>{i18n.t('totalHours')}: </b>
            {hasInaccurateHours ? (
              <span className="tooltip-container">
                <span className="inaccurate-hours">{payload.totalHours}</span>
                <span className="tooltip">Some equipment have invalid values for Period Hours!</span>
              </span>
            ) : (
              payload.totalHours
            )}

            </p>
            {payload.fleetSize !== payload.accurateEquipCount && (
              <p>
                <b>{i18n.t('equipmentWithProblemHours')}: </b>
                {payload.fleetSize - payload.accurateEquipCount}
              </p>
            )}
            <p>
              <b>{i18n.t('utilizationHours')}:</b>{' '}
              {parseFloat(payload.utilizationHours).toFixed(2)}%
            </p>
            <p>
              <b>{i18n.t('averageFleetHours')}:</b> {payload.averageFleetHours}
            </p>
          </div>
        </div>
        <div className="info-box">
          <div className='info-box-header'>
            <span className='info-icon'>!</span> Info
          </div>
          <ul>
            {hasInaccurateHours ? (
              <li className='important'><b>NOTE:</b> Red Total Hours means that there are equipments with invalid values in the Period Hours.</li>
            ) : null}
            <li><b>Total Hours:</b> Sum of Equipment Period Hours. </li>
            <li><b>Overall CPH:</b> (Total Cost) / (Total Hours) </li>
            <li><b>CPH w/o Damage & Requests:</b> ([Total Cost] - [Damage & Requests]) / (Total Hours)</li>
          </ul>
        </div>
        <div>
          <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={`${summarySettings.title}.csv`}
          >
            Export to CSV
          </CSVLink>
          <table border="1" id="summary-table">
            <thead>
              <tr>
                <td
                  colSpan={`${topHeaderLength}`}
                  className="summary-special-header"
                >
                  {i18n.t('totalCost').toUpperCase()}
                </td>
                <td className="financial">
                  {this.monetize(payload.totalCost, '$0', false)}
                </td>
                <td className="financial">
                  <Link
                    to="#"
                    onClick={() => this.linkToFilteredWorkOrders('', 'PM')}
                  >
                    {this.monetize(payload.totalPm, '$0', false)}
                  </Link>
                </td>
                <td className="financial">
                  <Link
                    to="#"
                    onClick={() => this.linkToFilteredWorkOrders('', 'Repair')}
                  >
                    {this.monetize(payload.totalRepair, '$0', false)}
                  </Link>
                </td>
                <td className="financial">
                  <Link
                    to="#"
                    onClick={() => this.linkToFilteredWorkOrders('', 'Tires')}
                  >
                    {this.monetize(payload.totalTires, '$0', false)}
                  </Link>
                </td>
                <td className="financial">
                  <Link
                    to="#"
                    onClick={() => this.linkToFilteredWorkOrders('', 'Damage')}
                  >
                    {this.monetize(payload.totalDamage, '$0', false)}
                  </Link>
                </td>
                <td className="financial">
                  <Link
                    to="#"
                    onClick={() => this.linkToFilteredWorkOrders('', 'Request')}
                  >
                    {this.monetize(payload.totalRequest, '$0', false)}
                  </Link>
                </td>
                <td className="financial">
                  <Link
                    to="#"
                    onClick={() =>
                      this.linkToFilteredWorkOrders('', 'Attachment')
                    }
                  >
                    {this.monetize(payload.totalAttach, '$0', false)}
                  </Link>
                </td>
                <td className="financial">
                  {this.monetize(payload.totalMc, '$0', false)}
                </td>
              </tr>
              <tr>
                <td
                  colSpan={`${topHeaderLength}`}
                  className="summary-special-header"
                >
                  {i18n.t('avgCostPerHour').toUpperCase()}
                </td>
                <td className="financial">{this.monetize(payload.totalCph)}</td>
                <td className="financial">{this.monetize(payload.pmCph)}</td>
                <td className="financial">
                  {this.monetize(payload.repairCph)}
                </td>
                <td className="financial">{this.monetize(payload.tiresCph)}</td>
                <td className="financial">
                  {this.monetize(payload.damageCph)}
                </td>
                <td className="financial">
                  {this.monetize(payload.requestCph)}
                </td>
                <td className="financial">
                  {this.monetize(payload.attachCph)}
                </td>
                <td className="financial">{this.monetize(payload.mcCph)}</td>
              </tr>
              <tr>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        summarySettings.sortBy === 'eobjAsc'
                          ? 'eobjDesc'
                          : 'eobjAsc',
                      )
                    }
                  >
                    {i18n.t('eobj')} &nbsp;
                    <FontAwesome
                      name={
                        summarySettings.sortBy === 'eobjAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                {customerIds.length > 1 && <th>{i18n.t('customer')}</th>}
                {summarySettings.plannedHoursVisible && (
                  <th>{i18n.t('plannedHours')}</th>
                )}
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        summarySettings.sortBy === 'careAsc'
                          ? 'careDesc'
                          : 'careAsc',
                      )
                    }
                  >
                    {i18n.t('maintenanceLevel')} &nbsp;
                    <FontAwesome
                      name={
                        summarySettings.sortBy === 'careAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        summarySettings.sortBy === 'makeAsc'
                          ? 'makeDesc'
                          : 'makeAsc',
                      )
                    }
                  >
                    {i18n.t('make')} &nbsp;
                    <FontAwesome
                      name={
                        summarySettings.sortBy === 'makeAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        summarySettings.sortBy === 'modelAsc'
                          ? 'modelDesc'
                          : 'modelAsc',
                      )
                    }
                  >
                    {i18n.t('model')} &nbsp;
                    <FontAwesome
                      name={
                        summarySettings.sortBy === 'modelAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>{i18n.t('serial')}</th>
                <th>{i18n.t('fleetNo')}</th>
                {summarySettings.firstWoDateVisible && (
                  <th>{i18n.t('firstWoDate')}</th>
                )}
                {summarySettings.lastWoDateVisible && (
                  <th>{i18n.t('lastWoDate')}</th>
                )}
                <th>{i18n.t('startKom')}</th>
                <th>{i18n.t('endKom')}</th>
                <th>{i18n.t('startElecHM')}</th>
                <th>{i18n.t('endElecHM')}</th>
                {summarySettings.periodHoursVisible && (
                  <th>{i18n.t('periodHours')}</th>
                )}
                <th>{i18n.t('annualHours')}</th>
                <th>{i18n.t('annualUtilizationRate')}</th>
                <th>{i18n.t('availability')}</th>
                {summarySettings.pmCountVisible && <th>{i18n.t('pmCount')}</th>}
                {summarySettings.avgPmIntervalVisible && (
                  <th>{i18n.t('avgPmInterval')}</th>
                )}
                {summarySettings.mfcCountVisible && (
                  <th>{i18n.t('mfcCount')}</th>
                )}
                {summarySettings.avgMfcIntervalVisible && (
                  <th>{i18n.t('avgMfcInterval')}</th>
                )}
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        summarySettings.sortBy === 'totalCostAsc'
                          ? 'totalCostDesc'
                          : 'totalCostAsc',
                      )
                    }
                  >
                    {i18n.t('totalCost')} &nbsp;
                    <FontAwesome
                      name={
                        summarySettings.sortBy === 'totalCostAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        summarySettings.sortBy === 'pmCostAsc'
                          ? 'pmCostDesc'
                          : 'pmCostAsc',
                      )
                    }
                  >
                    {i18n.t('pm')} &nbsp;
                    <FontAwesome
                      name={
                        summarySettings.sortBy === 'pmCostAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        summarySettings.sortBy === 'repairCostAsc'
                          ? 'repairCostDesc'
                          : 'repairCostAsc',
                      )
                    }
                  >
                    {i18n.t('repair')} &nbsp;
                    <FontAwesome
                      name={
                        summarySettings.sortBy === 'repairCostAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        summarySettings.sortBy === 'tiresCostAsc'
                          ? 'tiresCostDesc'
                          : 'tiresCostAsc',
                      )
                    }
                  >
                    {i18n.t('tires')} &nbsp;
                    <FontAwesome
                      name={
                        summarySettings.sortBy === 'tiresCostAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        summarySettings.sortBy === 'damageCostAsc'
                          ? 'damageCostDesc'
                          : 'damageCostAsc',
                      )
                    }
                  >
                    {i18n.t('damage')} &nbsp;
                    <FontAwesome
                      name={
                        summarySettings.sortBy === 'damageCostAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        summarySettings.sortBy === 'requestCostAsc'
                          ? 'requestCostDesc'
                          : 'requestCostAsc',
                      )
                    }
                  >
                    {i18n.t('request')} &nbsp;
                    <FontAwesome
                      name={
                        summarySettings.sortBy === 'requestCostAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        summarySettings.sortBy === 'attachCostAsc'
                          ? 'attachCostDesc'
                          : 'attachCostAsc',
                      )
                    }
                  >
                    {i18n.t('attachment')} &nbsp;
                    <FontAwesome
                      name={
                        summarySettings.sortBy === 'attachCostAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        summarySettings.sortBy === 'mcCostAsc'
                          ? 'mcCostDesc'
                          : 'mcCostAsc',
                      )
                    }
                  >
                    {i18n.t('maintenanceContracts')} &nbsp;
                    <FontAwesome
                      name={
                        summarySettings.sortBy === 'mcCostAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                {summarySettings.pmCphVisible && (
                  <th>
                    <Link
                      to="#"
                      onClick={() =>
                        this.changeSortBy(
                          summarySettings.sortBy === 'pmCphAsc'
                            ? 'pmCphDesc'
                            : 'pmCphAsc',
                        )
                      }
                    >
                      {i18n.t('pmCPH')} &nbsp;
                      <FontAwesome
                        name={
                          summarySettings.sortBy === 'pmCphAsc'
                            ? 'sort-down'
                            : 'sort-up'
                        }
                      />
                    </Link>
                  </th>
                )}
                {summarySettings.repairCphVisible && (
                  <th>
                    <Link
                      to="#"
                      onClick={() =>
                        this.changeSortBy(
                          summarySettings.sortBy === 'repairCphAsc'
                            ? 'repairCphDesc'
                            : 'repairCphAsc',
                        )
                      }
                    >
                      {i18n.t('repairCPH')} &nbsp;
                      <FontAwesome
                        name={
                          summarySettings.sortBy === 'repairCphAsc'
                            ? 'sort-down'
                            : 'sort-up'
                        }
                      />
                    </Link>
                  </th>
                )}
                {summarySettings.tiresCphVisible && (
                  <th>
                    <Link
                      to="#"
                      onClick={() =>
                        this.changeSortBy(
                          summarySettings.sortBy === 'tiresCphAsc'
                            ? 'tiresCphDesc'
                            : 'tiresCphAsc',
                        )
                      }
                    >
                      {i18n.t('tiresCPH')} &nbsp;
                      <FontAwesome
                        name={
                          summarySettings.sortBy === 'tiresCphAsc'
                            ? 'sort-down'
                            : 'sort-up'
                        }
                      />
                    </Link>
                  </th>
                )}
                {summarySettings.damageCphVisible && (
                  <th>
                    <Link
                      to="#"
                      onClick={() =>
                        this.changeSortBy(
                          summarySettings.sortBy === 'damageCphAsc'
                            ? 'damageCphDesc'
                            : 'damageCphAsc',
                        )
                      }
                    >
                      {i18n.t('damageCPH')} &nbsp;
                      <FontAwesome
                        name={
                          summarySettings.sortBy === 'damageCphAsc'
                            ? 'sort-down'
                            : 'sort-up'
                        }
                      />
                    </Link>
                  </th>
                )}
                {summarySettings.requestCphVisible && (
                  <th>
                    <Link
                      to="#"
                      onClick={() =>
                        this.changeSortBy(
                          summarySettings.sortBy === 'requestCphAsc'
                            ? 'requestCphDesc'
                            : 'requestCphAsc',
                        )
                      }
                    >
                      {i18n.t('requestCPH')} &nbsp;
                      <FontAwesome
                        name={
                          summarySettings.sortBy === 'requestCphAsc'
                            ? 'sort-down'
                            : 'sort-up'
                        }
                      />
                    </Link>
                  </th>
                )}
                {summarySettings.attachCphVisible && (
                  <th>
                    <Link
                      to="#"
                      onClick={() =>
                        this.changeSortBy(
                          summarySettings.sortBy === 'attachCphAsc'
                            ? 'attachCphDesc'
                            : 'attachCphAsc',
                        )
                      }
                    >
                      {i18n.t('attachmentCPH')} &nbsp;
                      <FontAwesome
                        name={
                          summarySettings.sortBy === 'attachCphAsc'
                            ? 'sort-down'
                            : 'sort-up'
                        }
                      />
                    </Link>
                  </th>
                )}
                {summarySettings.mcCphVisible && (
                  <th>
                    <Link
                      to="#"
                      onClick={() =>
                        this.changeSortBy(
                          summarySettings.sortBy === 'mcCphAsc'
                            ? 'mcCphDesc'
                            : 'mcCphAsc',
                        )
                      }
                    >
                      {i18n.t('mcCPH')} &nbsp;
                      <FontAwesome
                        name={
                          summarySettings.sortBy === 'mcCphAsc'
                            ? 'sort-down'
                            : 'sort-up'
                        }
                      />
                    </Link>
                  </th>
                )}
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        summarySettings.sortBy === 'totalCphAsc'
                          ? 'totalCphDesc'
                          : 'totalCphAsc',
                      )
                    }
                  >
                    {i18n.t('totalCPH')} &nbsp;
                    <FontAwesome
                      name={
                        summarySettings.sortBy === 'totalCphAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
              </tr>
            </thead>
            <tbody>
              {payload.equipment.map((equip) => {
                return (
                  <tr key={equip.id}>
                    <td>
                      <Link to={{ pathname: `/equipment/${equip.id}` }}>
                        {equip.vendorEquipId}
                      </Link>
                    </td>
                    {customerIds.length > 1 && (
                      <td>
                        <Link
                          to={{ pathname: `/customer/${equip.customerId}` }}
                        >
                          {equip.customerName}
                        </Link>
                      </td>
                    )}
                    {summarySettings.plannedHoursVisible && (
                      <td>{equip.plannedHours}</td>
                    )}
                    <td>{equip.maintenanceLevel}</td>
                    <td>{equip.make}</td>
                    <td>{equip.model}</td>
                    <td>{equip.serialNumber}</td>
                    <td>{equip.fleetNumber}</td>
                    {summarySettings.firstWoDateVisible && (
                      <td>
                        {equip.firstWorkOrder === null ? (
                          <TimeStamp
                            timeToFormat={equip.lastWorkOrder.invoicedAt}
                          />
                        ) : (
                          <TimeStamp
                            timeToFormat={equip.firstWorkOrder.invoicedAt}
                          />
                        )}
                      </td>
                    )}
                    {summarySettings.lastWoDateVisible && (
                      <td>
                        <TimeStamp
                          timeToFormat={equip.lastWorkOrder.invoicedAt}
                        />
                      </td>
                    )}
                    <td>{equip.startKom}</td>
                    <td>{equip.endKom}</td>
                    { (equip.startHm !== 0 && equip.endHm !== 0) ?
                      <td>{equip.startKom}</td> :
                      <td>-</td>
                    }
                    { (equip.startHm !== 0 && equip.endHm !== 0) ?
                      <td>{equip.endKom}</td> :
                      <td>-</td>
                    }
                    {summarySettings.periodHoursVisible && (
                      <td>{equip.periodHours}</td>
                    )}
                    <td className={equip.annualHours <= 0 ? 'inaccurate-cell' : ''}>
                      {equip.annualHours}
                    </td>
                    <td>{equip.aur}</td>
                    <td>{`${equip.availability.toFixed(1)}%`}</td>
                    {summarySettings.pmCountVisible && <td>{equip.pmCount}</td>}
                    {summarySettings.avgPmIntervalVisible && (
                      <td>
                        {equip.avgPmInterval === 0
                          ? 'N/C'
                          : parseInt(equip.avgPmInterval, 10)}
                      </td>
                    )}
                    {summarySettings.mfcCountVisible && (
                      <td>{equip.mfcCount}</td>
                    )}
                    {summarySettings.avgMfcIntervalVisible && (
                      <td>
                        {equip.avgMfcInterval === 0
                          ? 'N/C'
                          : parseInt(equip.avgMfcInterval, 10)}
                      </td>
                    )}
                    <td className="financial">
                      <Link
                        to="#"
                        onClick={() =>
                          this.linkToFilteredWorkOrders(equip.vendorEquipId, '')
                        }
                      >
                        {this.monetize(equip.totalCost)}
                      </Link>
                    </td>
                    <td className="financial">
                      <Link
                        to="#"
                        onClick={() =>
                          this.linkToFilteredWorkOrders(
                            equip.vendorEquipId,
                            'PM',
                          )
                        }
                      >
                        {this.monetize(equip.pmCost)}
                      </Link>
                    </td>
                    <td className="financial">
                      <Link
                        to="#"
                        onClick={() =>
                          this.linkToFilteredWorkOrders(
                            equip.vendorEquipId,
                            'Repair',
                          )
                        }
                      >
                        {this.monetize(equip.repairCost)}
                      </Link>
                    </td>
                    <td className="financial">
                      <Link
                        to="#"
                        onClick={() =>
                          this.linkToFilteredWorkOrders(
                            equip.vendorEquipId,
                            'Tires',
                          )
                        }
                      >
                        {this.monetize(equip.tiresCost)}
                      </Link>
                    </td>
                    <td className="financial">
                      <Link
                        to="#"
                        onClick={() =>
                          this.linkToFilteredWorkOrders(
                            equip.vendorEquipId,
                            'Damage',
                          )
                        }
                      >
                        {this.monetize(equip.damageCost)}
                      </Link>
                    </td>
                    <td className="financial">
                      <Link
                        to="#"
                        onClick={() =>
                          this.linkToFilteredWorkOrders(
                            equip.vendorEquipId,
                            'Request',
                          )
                        }
                      >
                        {this.monetize(equip.requestCost)}
                      </Link>
                    </td>
                    <td className="financial">
                      <Link
                        to="#"
                        onClick={() =>
                          this.linkToFilteredWorkOrders(
                            equip.vendorEquipId,
                            'Attachment',
                          )
                        }
                      >
                        {this.monetize(equip.attachCost)}
                      </Link>
                    </td>
                    <td className="financial">{this.monetize(equip.mcCost)}</td>
                    {summarySettings.pmCphVisible && (
                      <td className="financial">
                        {this.monetize(equip.pmCph)}
                      </td>
                    )}
                    {summarySettings.repairCphVisible && (
                      <td className="financial">
                        {this.monetize(equip.repairCph)}
                      </td>
                    )}
                    {summarySettings.tiresCphVisible && (
                      <td className="financial">
                        {this.monetize(equip.tiresCph)}
                      </td>
                    )}
                    {summarySettings.damageCphVisible && (
                      <td className="financial">
                        {this.monetize(equip.damageCph)}
                      </td>
                    )}
                    {summarySettings.requestCphVisible && (
                      <td className="financial">
                        {this.monetize(equip.requestCph)}
                      </td>
                    )}
                    {summarySettings.attachCphVisible && (
                      <td className="financial">
                        {this.monetize(equip.attachCph)}
                      </td>
                    )}
                    {summarySettings.mcCphVisible && (
                      <td className="financial">
                        {this.monetize(equip.mcCph)}
                      </td>
                    )}
                    <td className="financial">
                      {this.monetize(equip.totalCph)}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <SummaryCostChart
          pm={payload.totalPm}
          repair={payload.totalRepair}
          tires={payload.totalTires}
          damage={payload.totalDamage}
          request={payload.totalRequest}
          attach={payload.totalAttach}
        />
      </div>
    )
  }
}

BomSummary.defaultProps = {
  customerIds: {},
  summarySettings: {},
  payload: {},
  customers: {},
}

BomSummary.propTypes = {
  customerIds: PropType.array,
  summarySettings: object,
  payload: object,
  customers: PropType.array,
}

export default BomSummary
