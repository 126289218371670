import React, { Component } from 'react'
import PropType from 'prop-types'

class DateRange extends Component {
  constructor(props) {
    super(props)

    this.state = {
      startRange: props.startRange,
      endRange: props.endRange,
    }
  }

  render() {
    const { startRange, endRange } = this.state

    const first = startRange.split(' ')[0].split('-')
    const last = endRange.split(' ')[0].split('-')

    switch (first[1]) {
      case '01':
        first[1] = 'Jan'
        break
      case '02':
        first[1] = 'Feb'
        break
      case '03':
        first[1] = 'Mar'
        break
      case '04':
        first[1] = 'Apr'
        break
      case '05':
        first[1] = 'May'
        break
      case '06':
        first[1] = 'Jun'
        break
      case '07':
        first[1] = 'Jul'
        break
      case '08':
        first[1] = 'Aug'
        break
      case '09':
        first[1] = 'Sep'
        break
      case '10':
        first[1] = 'Oct'
        break
      case '11':
        first[1] = 'Nov'
        break
      case '12':
        first[1] = 'Dec'
        break
      default:
        break
    }

    switch (last[1]) {
      case '01':
        last[1] = 'Jan'
        break
      case '02':
        last[1] = 'Feb'
        break
      case '03':
        last[1] = 'Mar'
        break
      case '04':
        last[1] = 'Apr'
        break
      case '05':
        last[1] = 'May'
        break
      case '06':
        last[1] = 'Jun'
        break
      case '07':
        last[1] = 'Jul'
        break
      case '08':
        last[1] = 'Aug'
        break
      case '09':
        last[1] = 'Sep'
        break
      case '10':
        last[1] = 'Oct'
        break
      case '11':
        last[1] = 'Nov'
        break
      case '12':
        last[1] = 'Dec'
        break
      default:
        break
    }

    let dateRange = ''
    if (first[0] !== last[0]) {
      dateRange = `${first[1]} ${first[2]}, ${first[0]} - ${last[1]} ${last[2]}, ${last[0]}`
    } else if (first[1] !== last[1]) {
      dateRange = `${first[1]} ${first[2]} - ${last[1]} ${last[2]}, ${first[0]}`
    } else {
      dateRange = `${first[1]} ${first[2]} - ${last[2]}, ${first[0]}`
    }

    return <span>{dateRange}</span>
  }
}

DateRange.defaultProps = {
  startRange: {},
  endRange: {},
}

DateRange.propTypes = {
  startRange: PropType.string,
  endRange: PropType.string,
}

export default DateRange
