import React, { Component } from 'react'

import {
  AUTH_TOKEN,
  WO_CUSTOMER_ID,
  WO_CUSTOMER_NAME,
  WO_EQUIP_ID,
  WO_EQUIP_SERIAL_NUM,
  WO_INTERNAL_ID,
} from '../constants'

import {
  CUSTOMER_RELATIONSHIP_SEARCH,
  CREATE_CUSTOMER,
  FETCH_CHILD_RELATIONSHIPS_BY_CUSTOMER,
  FETCH_CURRENT_USER,
} from '../queries'

import { Query, Mutation } from 'react-apollo'

import { Link, Redirect } from 'react-router-dom'
import { object } from 'prop-types'
import i18n from 'i18n-js'

import SignInUser from '../components/SignInUser'
import TextField from '../components/TextField'
import NumberField from '../components/NumberField'
import Button from '../components/Button'

import '../styles/invoice.css'

class NewWorkOrder1 extends Component {
  constructor(props) {
    super(props)

    this.changeLocalSearch = this.changeLocalSearch.bind(this)

    this.state = {
      search: null,
      searchHide: true,
      searchFilter: '',
      customerId: localStorage.getItem(WO_CUSTOMER_ID),
      customerName: localStorage.getItem(WO_CUSTOMER_NAME),
      accordionHide: true,
    }
  }

  changeSearchFilter(event) {
    const searchFilter = event.target.value
    this.setState({ searchFilter })
  }

  changeLocalSearch = async (event) => {
    const search = event.target.value

    const searchHide = search === ''

    this.setState({
      search,
      searchHide,
    })
    return ''
  }

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser />

    if (localStorage.getItem(WO_INTERNAL_ID) !== null) {
      this.props.history.push(`/new-work-order/3`)
    }

    const {
      search,
      searchHide,
      searchFilter,
      customerId,
      customerName,
      accordionHide,
    } = this.state

    const searchClass = searchHide ? 'hidden' : 'visible'

    const accordionClass = accordionHide ? 'hidden' : 'visible'

    return (
      <Query query={FETCH_CURRENT_USER} variables={{ token }}>
        {({ loading, error, data }) => {
          if (loading) return <div>{i18n.t('fetching')}</div>
          if (error) return <div>{error.message}</div>

          const currentUser = data.currentUser

          if (currentUser.role === 'customer') return <Redirect to="/" />

          const vendorId = parseInt(currentUser.vendorId, 10)

          return (
            <div>
              <div>
                {currentUser.role === 'vendor' ? (
                  <div>
                    <h3>{i18n.t('selectOrSearchForCustomerToInvoice')}</h3>
                    <Query
                      query={FETCH_CHILD_RELATIONSHIPS_BY_CUSTOMER}
                      variables={{ token, id: vendorId }}
                    >
                      {({ loading, error, data }) => {
                        if (loading)
                          return (
                            <div className="report">{i18n.t('fetching')}</div>
                          )
                        if (error)
                          return (
                            <div className="report">
                              {i18n.t(error.message)}
                            </div>
                          )

                        const children = data.customer.childRelationships

                        return (
                          <div className="row">
                            <div className="field">
                              <input
                                id="filter-customers"
                                type="text"
                                className="text-field"
                                placeholder={i18n.t('filterCustomer')}
                                onChange={this.changeSearchFilter.bind(this)}
                                value={searchFilter}
                              />
                            </div>
                            {children.map((child) => {
                              let custRegex = new RegExp(searchFilter, 'i')
                              let customer = child.childCustomer

                              if (
                                searchFilter !== '' &&
                                !custRegex.test(child.customerInternalVal) &&
                                !custRegex.test(customer.name) &&
                                !custRegex.test(customer.internalName)
                              ) {
                                return ''
                              }

                              return (
                                <div key={child.id} className="equip-block">
                                  <h3>
                                    <Link
                                      to="#"
                                      onClick={(event) => {
                                        event.preventDefault()
                                        const custName = `${customer.name} (${child.customerInternalVal})`
                                        localStorage.setItem(
                                          WO_CUSTOMER_ID,
                                          customer.id,
                                        )
                                        localStorage.setItem(
                                          WO_CUSTOMER_NAME,
                                          custName,
                                        )
                                        localStorage.setItem(WO_EQUIP_ID, '')
                                        localStorage.setItem(
                                          WO_EQUIP_SERIAL_NUM,
                                          '',
                                        )

                                        this.setState({
                                          customerId: customer.id,
                                          searchFilter: '',
                                          customerName: custName,
                                        })
                                      }}
                                    >
                                      {child.customerInternalVal}
                                    </Link>
                                  </h3>
                                  <div>
                                    {customer.name}
                                    <div>({customer.internalName})</div>
                                  </div>
                                  <div>
                                    {customer.address1}
                                    {customer.address2 !== '' && (
                                      <div>{customer.address2}</div>
                                    )}
                                  </div>
                                  <div>
                                    {customer.city}, {customer.province}{' '}
                                    {customer.postalCode}
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        )
                      }}
                    </Query>
                  </div>
                ) : (
                  <div>
                    <h3>{i18n.t('searchForOrCreateCustomerToInvoice')}</h3>
                    <div id="localSearchContainer">
                      <input
                        id="local-search-field"
                        type="text"
                        className="text-field"
                        placeholder={i18n.t('searchCustomerByNameNumber')}
                        onChange={this.changeLocalSearch.bind(this)}
                      />
                      <span
                        id="local-search-error"
                        className="hidden field-error"
                      >
                        {i18n.t('selectACustomer')}
                      </span>
                    </div>

                    <div id="localSearchResults" className={searchClass}>
                      <Query
                        query={CUSTOMER_RELATIONSHIP_SEARCH}
                        variables={{ token, search, vendorId }}
                      >
                        {({ loading, error, data }) => {
                          if (loading)
                            return (
                              <div className="report">{i18n.t('fetching')}</div>
                            )
                          if (error)
                            return <div className="report">{error.message}</div>

                          const results = data.searchCustomerRelationships

                          if (search.length < 4) {
                            return (
                              <div className="report">
                                {i18n.t('typeFourOrMoreChar')}
                              </div>
                            )
                          }

                          if (results.length === 0) {
                            return (
                              <div className="report">
                                {i18n.t('noResultsFound')}
                              </div>
                            )
                          }

                          return (
                            <div>
                              {results.map((result) => (
                                <div key={result.id} className="searchResult">
                                  <Link
                                    to="#"
                                    onClick={async (event) => {
                                      event.preventDefault()
                                      let custName = result.childCustomer.name

                                      if (
                                        result.childCustomer.internalName !==
                                        null
                                      ) {
                                        custName += ` - ${result.childCustomer.internalName}`
                                      }

                                      custName += ` (${result.customerInternalVal})`
                                      localStorage.setItem(
                                        WO_CUSTOMER_ID,
                                        result.childCustomer.id,
                                      )
                                      localStorage.setItem(
                                        WO_CUSTOMER_NAME,
                                        custName,
                                      )
                                      localStorage.setItem(WO_EQUIP_ID, '')
                                      localStorage.setItem(
                                        WO_EQUIP_SERIAL_NUM,
                                        '',
                                      )
                                      this.setState({
                                        customerId: result.id,
                                        searchHide: true,
                                        search: '',
                                        customerName: custName,
                                      })
                                      document.getElementById(
                                        'local-search-field',
                                      ).value = ''
                                    }}
                                  >
                                    {result.customerInternalVal}
                                    <br />
                                    {result.childCustomer.name} -{' '}
                                    {result.childCustomer.internalName}
                                  </Link>
                                </div>
                              ))}
                            </div>
                          )
                        }}
                      </Query>
                    </div>
                  </div>
                )}
              </div>

              <div>
                {customerId && (
                  <div className="customer-info">
                    <div>
                      <strong>{i18n.t('customerSelected')}: </strong>
                      {customerName} (
                      <Link
                        to="#"
                        onClick={async (event) => {
                          event.preventDefault()
                          localStorage.setItem(WO_CUSTOMER_ID, '')
                          localStorage.setItem(WO_CUSTOMER_NAME, '')
                          localStorage.setItem(WO_EQUIP_ID, '')
                          localStorage.setItem(WO_EQUIP_SERIAL_NUM, '')

                          this.setState({
                            customerId: null,
                            customerName: null,
                          })
                        }}
                      >
                        {i18n.t('clearSelection')}
                      </Link>
                      )
                    </div>
                    <Button
                      id="nextStep"
                      text={i18n.t('nextStep')}
                      clickAction={() => {
                        window.location.href = '/new-work-order/2'
                      }}
                    />
                  </div>
                )}
              </div>

              <div>
                <Link
                  to="#"
                  onClick={() => {
                    this.setState({ accordionHide: !accordionHide })
                  }}
                >
                  <div className="title-toggle">
                    <h4>{i18n.t('createNewCustomer')}</h4>
                  </div>
                </Link>
                <div className={`accordion-form ${accordionClass}`}>
                  <div>
                    <strong>{i18n.t('pleaseNote')}:</strong>{' '}
                    {i18n.t('customerSubmittedWillNeedApproval')}
                  </div>
                  <div>
                    <TextField
                      id="name"
                      label={`${i18n.t('name')} *`}
                      placeholder={i18n.t('name')}
                    />
                    <TextField
                      id="customerInternalVal"
                      label={`${i18n.t('internalValue')} *`}
                      placeholder={i18n.t('internalValue')}
                    />
                    <TextField
                      id="internalName"
                      label={i18n.t('site')}
                      placeholder={i18n.t('site')}
                    />
                    <TextField
                      id="address1"
                      label={i18n.t('address1')}
                      placeholder={i18n.t('address1')}
                    />
                    <TextField
                      id="address2"
                      label={i18n.t('address2')}
                      placeholder={i18n.t('address2')}
                    />
                    <TextField
                      id="city"
                      label={i18n.t('city')}
                      placeholder={i18n.t('city')}
                    />
                    <TextField
                      id="province"
                      label={i18n.t('stateAbbreviation')}
                      placeholder={i18n.t('state')}
                    />
                    <TextField
                      id="postalCode"
                      label={i18n.t('zipCode')}
                      placeholder={i18n.t('zipCode')}
                    />
                    <TextField
                      id="country"
                      label={i18n.t('countryAbbreviation')}
                      placeholder={i18n.t('country')}
                      value={i18n.t('defaultCountry')}
                    />
                    <NumberField
                      id="hpd"
                      label={i18n.t('hoursPerDay')}
                      value="8"
                      step="1"
                    />
                    <NumberField
                      id="dpw"
                      label={i18n.t('daysPerWeek')}
                      value="5"
                      step="1"
                    />
                    <Mutation mutation={CREATE_CUSTOMER}>
                      {(createCustomer, { data, loading, error }) => {
                        return (
                          <div>
                            <Button
                              id="submit-customer"
                              text={i18n.t('submit')}
                              clickAction={() => {
                                const name = document.getElementById('name')
                                  .value
                                const customerInternalVal = document.getElementById(
                                  'customerInternalVal',
                                ).value

                                const nameErr = document.getElementById(
                                  'name-error',
                                )
                                const civErr = document.getElementById(
                                  'customerInternalVal-error',
                                )

                                if (name === '' || customerInternalVal === '') {
                                  if (name === '') {
                                    document
                                      .getElementById('name')
                                      .classList.add('error')
                                    nameErr.innerHTML = i18n.t('nameError')
                                    nameErr.classList.add('show')
                                    nameErr.classList.remove('hidden')
                                  }

                                  if (customerInternalVal === '') {
                                    document
                                      .getElementById('customerInternalVal')
                                      .classList.add('error')
                                    civErr.innerHTML = i18n.t(
                                      'internalValueError',
                                    )
                                    civErr.classList.add('show')
                                    civErr.classList.remove('hidden')
                                  }

                                  window.flash(
                                    i18n.t('validationsNotMet'),
                                    'error',
                                  )
                                  return ''
                                }

                                document
                                  .getElementById('name')
                                  .classList.remove('error')
                                document
                                  .getElementById('customerInternalVal')
                                  .classList.remove('error')
                                civErr.classList.remove('show')
                                civErr.classList.add('hidden')
                                nameErr.classList.remove('show')
                                nameErr.classList.add('hidden')

                                const internalName = document.getElementById(
                                  'internalName',
                                ).value
                                const address1 = document.getElementById(
                                  'address1',
                                ).value
                                const address2 = document.getElementById(
                                  'address2',
                                ).value
                                const city = document.getElementById('city')
                                  .value
                                const province = document.getElementById(
                                  'province',
                                ).value
                                const postalCode = document.getElementById(
                                  'postalCode',
                                ).value
                                const country = document.getElementById(
                                  'country',
                                ).value
                                let hpd = parseInt(
                                  document.getElementById('hpd').value,
                                  10,
                                )
                                let dpw = parseInt(
                                  document.getElementById('dpw').value,
                                  10,
                                )

                                if (isNaN(hpd)) {
                                  hpd = 8
                                }
                                if (isNaN(dpw)) {
                                  dpw = 5
                                }

                                createCustomer({
                                  variables: {
                                    token,
                                    vendorId,
                                    name,
                                    customerInternalVal,
                                    internalName,
                                    address1,
                                    address2,
                                    city,
                                    province,
                                    postalCode,
                                    country,
                                    hpd,
                                    dpw,
                                  },
                                }).then((res) => {
                                  if (data.errors.length > 0) {
                                    console.log('oops')
                                  }

                                  const customer =
                                    res.data.createCustomer.customer
                                  let custName = customer.name

                                  if (customer.internalName !== null) {
                                    custName += ` - ${customer.internalName}`
                                  }

                                  custName += ` (${customer.parentRelationships[0].customerInternalVal})`

                                  localStorage.setItem(
                                    WO_CUSTOMER_ID,
                                    customer.id,
                                  )
                                  localStorage.setItem(
                                    WO_CUSTOMER_NAME,
                                    custName,
                                  )
                                  localStorage.setItem(WO_EQUIP_ID, '')
                                  localStorage.setItem(WO_EQUIP_SERIAL_NUM, '')

                                  this.setState({
                                    customerId: customer.id,
                                    searchHide: true,
                                    accordionHide: true,
                                    search: '',
                                    customerName: custName,
                                  })
                                  window.location.href = '/new-work-order/2'
                                })
                              }}
                            />
                            {loading && <p>{i18n.t('processing')}</p>}
                            {error && <p>{error.message}</p>}
                          </div>
                        )
                      }}
                    </Mutation>
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      </Query>
    )
  }
}

NewWorkOrder1.defaultProps = {
  history: {},
}

NewWorkOrder1.propTypes = {
  history: object,
}

export default NewWorkOrder1
