import React, { Component } from 'react'
import { Query, Mutation } from 'react-apollo'
import i18n from 'i18n-js'

import Button from './Button'
import PasswordField from './PasswordField'
import SignInUser from './SignInUser'
import TextField from './TextField'

import { AUTH_TOKEN } from '../constants'

import {
  FETCH_USER,
  UPDATE_USER,
  UPDATE_USER_PASSWORD
} from '../queries'

class Account extends Component {
  render() {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser />

    return (
      <div>
        <Query query={FETCH_USER} variables={{ token: token }}>
          {({ loading, error, data }) => {
            if (loading) return <div>{i18n.t('processing')}...</div>
            if (error) return <div>{error.message}</div>

            const firstName = data.user.firstName
            const lastName = data.user.lastName
            const email = data.user.email

            return (
              <div>
                <h3>{i18n.t('yourAccount')}</h3>
                <TextField
                  id="firstName"
                  label={i18n.t('firstName')}
                  placeholder={i18n.t('firstName')}
                  value={firstName}
                />
                <TextField
                  id="lastName"
                  label={i18n.t('lastName')}
                  placeholder={i18n.t('lastName')}
                  value={lastName}
                />
                <div>
                  <div className="fieldLabel">
                    <label>{i18n.t('email')}</label>
                  </div>
                  <div>{email}</div>
                </div>
                <Mutation mutation={UPDATE_USER}>
                  {(updateUser, { loading, error }) => {
                    return (
                      <div>
                        <Button
                          id="update-user"
                          text={i18n.t('update')}
                          clickAction={() => {
                            const newFirstName = document.getElementById(
                              'firstName',
                            ).value
                            const newLastName = document.getElementById(
                              'lastName',
                            ).value

                            updateUser({
                              variables: {
                                token,
                                firstName: newFirstName,
                                lastName: newLastName,
                              },
                            }).then(() => {
                              window.flash('Updated your account', 'success')
                            })
                          }}
                        />
                        {loading && <p>{i18n.t('processing')}...</p>}
                        {error && <p>{error.message}</p>}
                      </div>
                    )
                  }}
                </Mutation>
                <h3>{i18n.t('changePassword')}</h3>
                <PasswordField
                  id="oldPassword"
                  label={i18n.t('oldPassword')}
                  placeholder={i18n.t('oldPassword')}
                />
                <PasswordField
                  id="newPassword"
                  label={i18n.t('newPassword')}
                  placeholder={i18n.t('newPassword')}
                />
                <PasswordField
                  id="confirmPassword"
                  label={i18n.t('confirmPassword')}
                  placeholder={i18n.t('confirmPassword')}
                />
                <Mutation mutation={UPDATE_USER_PASSWORD}>
                  {(updateUserPassword, { loading, error }) => {
                    return (
                      <div>
                        <Button
                          id="update-user-password"
                          text={i18n.t('updatePassword')}
                          clickAction={() => {
                            const op = document.getElementById('oldPassword')
                            const opErr = document.getElementById(
                              'oldPassword-error',
                            )
                            op.classList.remove('error')
                            opErr.classList.add('hidden')
                            opErr.classList.remove('show')

                            const np = document.getElementById('newPassword')
                            const npErr = document.getElementById(
                              'newPassword-error',
                            )
                            np.classList.remove('error')
                            npErr.classList.add('hidden')
                            npErr.classList.remove('show')

                            const cp = document.getElementById(
                              'confirmPassword',
                            )
                            const cpErr = document.getElementById(
                              'confirmPassword-error',
                            )
                            cp.classList.remove('error')
                            cpErr.classList.add('hidden')
                            cpErr.classList.remove('show')

                            updateUserPassword({
                              variables: {
                                token,
                                oldPassword: op.value,
                                newPassword: np.value,
                                confirmPassword: cp.value,
                              },
                            }).then((res) => {
                              this._confirm(res.data)
                            })
                          }}
                        />
                        {loading && <p>{i18n.t('processing')}...</p>}
                        {error && <p>{error.message}</p>}
                      </div>
                    )
                  }}
                </Mutation>
              </div>
            )
          }}
        </Query>
      </div>
    )
  }

  async _confirm(data) {
    if (data.updateUserPassword.user === null) {
      window.flash(i18n.t('thereWasAnIssueTryAgain'), 'error')
      var errors = data.updateUserPassword.errors
      errors.map((err) => {
        if (err.fieldName !== 'all') {
          var field = document.getElementById(err.fieldName)
          field.classList.add('error')
          var errField = document.getElementById(err.fieldName + '-error')
          errField.classList.add('show')
          errField.classList.remove('hidden')
          errField.textContent = 'x ' + err.message
        }
        return ''
      })
    } else {
      window.flash(i18n.t('successfullyUpdatedPassword'), 'success')
      document.getElementById('oldPassword').value = ''
      document.getElementById('newPassword').value = ''
      document.getElementById('confirmPassword').value = ''
    }
  }
}

export default Account
