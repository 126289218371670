import React, { Component } from 'react'
import { object } from 'prop-types'

import { AUTH_TOKEN } from '../constants'

import {
  FETCH_CURRENT_USER,
  FETCH_CUSTOMER_FOR_ECHELONS,
} from '../queries'

import { Redirect } from 'react-router-dom'
import { Query } from 'react-apollo'
import i18n from 'i18n-js'

import SignInUser from './SignInUser'

class Echelon extends Component {
  constructor(props) {
    super(props)

    let custId = null
    if (props.match.params.id !== undefined) {
      custId = props.match.params.id
    }

    this.state = {
      custId,
    }
  }

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser />

    const custId = parseInt(this.state.custId, 10)

    return(
      <Query query={FETCH_CURRENT_USER} variables={{token}}>
        {({ loading, error, data }) => {
          if (loading) return <div>{i18n.t('fetching')}</div>
          if (error) return <div>{error.message}</div>

          const currentUser = data.currentUser

          if (currentUser.role !== 'admin') return <Redirect to='/' />

          return(
            <div>
              <h3>{i18n.t("customerEchelons")}</h3>
              { (custId !== null) &&
                <Query query={FETCH_CUSTOMER_FOR_ECHELONS} variables={{ token, id: custId }}>
                  {({ loading, error, data }) => {
                    if (loading) return <div>{i18n.t('fetching')}</div>
                    if (error) return <div>{error.message}</div>

                    const customer = data.customer

                    console.log(customer)

                    return ''
                  } }
                </Query>
              }
            </div>
          )
        } }
      </Query>
    )
  }
}

Echelon.defaultProps = {
  match: {},
}

Echelon.propTypes = {
  match: object,
}

export default Echelon
