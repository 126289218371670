import i18n from 'i18n-js'

import en from './locales/eng.json'
// import spa from './locales/spa.json'

const DEFAULT_LOCALE = 'en'

i18n.translations = {
  en,
  'en-US': en,
  // spa,
}

i18n.defaultLocale = DEFAULT_LOCALE
i18n.locale = DEFAULT_LOCALE

export const setLocale = (locale = DEFAULT_LOCALE) => {
  i18n.locale = locale
}
