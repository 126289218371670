import React, { Component } from 'react'
import { Query, Mutation } from 'react-apollo'
import { Link, Redirect } from 'react-router-dom'
import PropTypes, { object } from 'prop-types'
import i18n from 'i18n-js'

import AllLineItems from './AllLineItems'
import AllNotes from './AllNotes'
import AllReviews from './AllReviews'
import Button from './Button'
import DateTimeField from './DateTimeField'
import DropDown from './DropDown'
import NumberField from './NumberField'
import SignInUser from './SignInUser'
import TextArea from './TextArea'
import TextField from './TextField'
import TimeStamp from './TimeStamp'

import { AUTH_TOKEN } from '../constants'

import {
  INVOICE_TYPES
} from '../dropDowns'

import {
  LIMITED_ROLES
} from '../globals'

import {
  FETCH_CURRENT_USER,
  FETCH_WORK_ORDER,
  UPDATE_WORK_ORDER
} from '../queries'

class WorkOrder extends Component {
  constructor(props) {
    super(props)

    var id = null

    if (typeof props.id !== 'object') {
      id = parseInt(props.id, 10)
    } else if (this.props.match.params.id !== null) {
      id = parseInt(this.props.match.params.id, 10)
    }

    this.state = {
      id: id,
      workOrder: null,
    }
  }

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser />

    const id = parseInt(this.state.id, 10)

    let workOrder = this.state.workOrder

    let totalAmount = 0

    return (
      <Query query={FETCH_CURRENT_USER} variables={{ token }}>
        {({ loading, error, data }) => {
          if (loading) return <div>{i18n.t('fetching')}</div>
          if (error) return <div>{error.message}</div>

          const currentUser = data['currentUser']

          return(
            <Query query={FETCH_WORK_ORDER} variables={{ token, id }}>
              {({ loading, error, data }) => {
                if (loading) return <div>{i18n.t('fetching')}</div>
                if (error) return <div>{error.message}</div>

                if (data.workOrder === null) {
                  return <Redirect to='/' />
                } else if (workOrder === null) {
                  workOrder = data.workOrder
                  this.setState({ workOrder })
                }

                const customer = workOrder.customer
                const equip = workOrder.equipment

                const dateTokens = data.workOrder.invoicedAt.split(' ')
                const dayTokens = dateTokens[0].split('-')

                const invoicedAtDate =
                  dayTokens[1] + '/' + dayTokens[2] + '/' + dayTokens[0]
                const timeTokens = dateTokens[1].split(':')
                const invoicedAtTime = timeTokens[0] + ':' + timeTokens[1]

                const invoicedAt = invoicedAtDate + ' ' + invoicedAtTime

                const lineItems = workOrder.lineItems.sort((a, b) => a.description > b.description)
                const reviews = workOrder.reviews
                const notes = workOrder.notes

                lineItems.map((li) => (totalAmount += li.amount))

                let customerName = customer.name
                if (customer.parentRelationships[0] !== undefined) {
                  customerName =
                    customerName +
                    ' (' +
                    customer.parentRelationships[0].customerInternalVal +
                    ')'
                }

                return (
                  <div>
                    <h3>{i18n.t('workOrderId', { id: workOrder.internalId })}</h3>
                    <div className="fieldContainer">
                      <div className="fieldLabel">
                        <label>{i18n.t('customer')}</label>
                      </div>
                      { (currentUser['role'] === 'customer') ?
                        <span>{customerName}</span> :
                        <Link to={{ pathname: `/customer/${customer.id}` }}>
                          {customerName}
                        </Link>
                      }
                    </div>
                    <div className="fieldContainer">
                      <div className="fieldLabel">
                        <label>{i18n.t('vendor')}</label>
                      </div>
                      {workOrder.vendor.name}
                    </div>
                    { (currentUser['role'] === 'admin') &&
                      <div className="fieldContainer">
                        <div className="fieldLabel">
                          <label>{i18n.t('state')}</label>
                        </div>
                        {workOrder.aasmState[0].toUpperCase() + workOrder.aasmState.substring(1)} &nbsp;
                        <span id='state-toggle'>
                          { ['submitted', 'batched'].includes(workOrder.aasmState) ?
                            <Mutation mutation={UPDATE_WORK_ORDER}>
                              {(updateWorkOrder) => {
                                return(
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      updateWorkOrder({
                                        variables: {
                                          token,
                                          id: workOrder.id,
                                          aasmState: 'accepted'
                                        }
                                      }).then(() => {
                                        workOrder.aasmState = 'accepted'
                                        this.setState({ workOrder })
                                        window.flash(i18n.t('successfullyAcceptedWorkOrder'))
                                      })
                                    } }>
                                    {i18n.t('accept')}
                                  </Link>
                                )
                              } }
                            </Mutation> :
                            <span>
                              { workOrder.aasmState === 'accepted' &&
                                <Mutation mutation={UPDATE_WORK_ORDER}>
                                  {(updateWorkOrder) => {
                                    return(
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          updateWorkOrder({
                                            variables: {
                                              token,
                                              id: workOrder.id,
                                              aasmState: 'submitted'
                                            }
                                          }).then(() => {
                                            workOrder.aasmState = 'submitted'
                                            this.setState({ workOrder })
                                            window.flash(i18n.t('successfullyRolledBackWorkOrder'))
                                          })
                                        } }>
                                        {i18n.t('rollBack')}
                                      </Link>
                                    )
                                  } }
                                </Mutation>
                              }
                            </span>
                          }
                        </span>
                      </div>
                    }
                    <div className="fieldContainer">
                      <div className="fieldLabel">
                        <label>{i18n.t('equipment')}:</label>
                      </div>
                      <Link to={{ pathname: `/equipment/${equip.id}` }}>
                        {equip.vendorEquipment[0].vendorEquipmentId}
                      </Link>
                    </div>
                    <div className="fieldContainer">
                      <div className="fieldLabel">
                        <label>{i18n.t('equipSerialNumber')}:</label>
                      </div>
                      {equip.serialNumber}
                    </div>
                    <div className="fieldContainer">
                      <div className="fieldLabel">
                        <label>{i18n.t('equipModel')}:</label>
                      </div>
                      {equip.model}
                    </div>
                    { !(LIMITED_ROLES.includes(currentUser['role'])) &&
                      <div>
                        <div className="fieldContainer">
                          <div className="fieldLabel">
                            <label>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="countAsPm"
                                checked={workOrder.countAsPm}
                                onChange={() => {
                                  workOrder.countAsPm = !workOrder.countAsPm
                                  this.setState({ workOrder })
                                }}
                              />
                              {i18n.t('countAsPM')}
                            </label>
                          </div>
                        </div>
                        <div className="fieldContainer">
                          <div className="fieldLabel">
                            <label>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="countAsMfc"
                                checked={workOrder.countAsMfc}
                                onChange={() => {
                                  workOrder.countAsMfc = !workOrder.countAsMfc
                                  this.setState({ workOrder })
                                }}
                              />
                              {i18n.t('countAsMFC')}
                            </label>
                          </div>
                        </div>
                      </div>
                    }
                    { (LIMITED_ROLES.includes(currentUser['role'])) ?
                      <div className="fieldContainer">
                        <div className="fieldLabel">
                          <label>
                            {i18n.t('serviceType')}
                          </label>
                        </div>
                        <div>
                          {workOrder.serviceType}
                        </div>
                      </div> :
                      <TextField
                        id="serviceType"
                        label={i18n.t('serviceType')}
                        placeholder={i18n.t('serviceType')}
                        value={workOrder.serviceType}
                      />
                    }
                    { (LIMITED_ROLES.includes(currentUser['role'])) ?
                      <div className="fieldContainer">
                        <div className="fieldLabel">
                          <label>
                            {i18n.t('serviceDescription')}
                          </label>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{ __html: workOrder.serviceDescription.replace(/(?:\r\n|\r|\n)/g, '<br/>') }}
                          style={{width: `75%`}}
                        />
                      </div> :
                      <TextArea
                        id="serviceDescription"
                        label={i18n.t('serviceDescription')}
                        placeholder={i18n.t('descriptionOfWorkOrder')}
                        content={workOrder.serviceDescription}
                      />
                    }
                    { (LIMITED_ROLES.includes(currentUser['role'])) ?
                      <div className="fieldContainer">
                        <div className="fieldLabel">
                          <label>
                            {i18n.t('keyOnMeter')}
                          </label>
                        </div>
                        <div>
                          {workOrder.keyOnMeter}
                        </div>
                      </div> :
                      <NumberField
                        id="keyOnMeter"
                        label={i18n.t('keyOnMeter')}
                        placeholder={i18n.t('keyOnMeter')}
                        value={parseInt(workOrder.keyOnMeter, 10)}
                        min={0}
                        step="1"
                      />
                    }
                    { (LIMITED_ROLES.includes(currentUser['role'])) ?
                      <div className="fieldContainer">
                        <div className="fieldLabel">
                          <label>
                            {i18n.t('driveMeter')}
                          </label>
                        </div>
                        <div>
                          {workOrder.driveMeter}
                        </div>
                      </div> :
                      <NumberField
                        id="driveMeter"
                        label={i18n.t('driveMeter')}
                        placeholder={i18n.t('driveMeter')}
                        value={parseInt(workOrder.driveMeter, 10)}
                        min={0}
                        step="1"
                      />
                    }
                    { (LIMITED_ROLES.includes(currentUser['role'])) ?
                      <div className="fieldContainer">
                        <div className="fieldLabel">
                          <label>
                            {i18n.t('pumpMeter')}
                          </label>
                        </div>
                        <div>
                          {workOrder.pumpMeter}
                        </div>
                      </div> :
                      <NumberField
                        id="pumpMeter"
                        label={i18n.t('pumpMeter')}
                        placeholder={i18n.t('pumpMeter')}
                        value={parseInt(workOrder.pumpMeter, 10)}
                        min={0}
                        step="1"
                      />
                    }
                    { (LIMITED_ROLES.includes(currentUser['role'])) ?
                      <div className="fieldContainer">
                        <div className="fieldLabel">
                          <label>
                            {i18n.t('downtime')}
                          </label>
                        </div>
                        <div>
                          {workOrder.downtime}
                        </div>
                      </div> :
                      <NumberField
                        id="downtime"
                        label={i18n.t('downtime')}
                        placeholder={i18n.t('downtime')}
                        value={parseFloat(workOrder.downtime)}
                        min={0}
                      />
                    }
                    { !(LIMITED_ROLES.includes(currentUser['role'])) &&
                      <div>
                        <DropDown
                          id="invoiceType"
                          label={i18n.t('invoiceTypes')}
                          options={INVOICE_TYPES}
                          selected={workOrder.invoiceType}
                        />
                        <DateTimeField
                          id="startedAt"
                          klass="startedAt"
                          label={i18n.t('startedAt')}
                          value={workOrder.startedAt}
                        />
                        <DateTimeField
                          id="stoppedAt"
                          klass="stoppedAt"
                          label={i18n.t('stoppedAt')}
                          value={workOrder.stoppedAt}
                        />
                      </div>
                    }
                    <div className="fieldContainer">
                      <div className="fieldLabel">
                        <label>{i18n.t('invoicedAt')}</label>
                      </div>
                      <div className="field">
                        <TimeStamp timeToFormat={invoicedAt} />
                      </div>
                    </div>
                    { currentUser['role'] === 'service' &&
                      <div>
                        <h3>{i18n.t('lineItems')}</h3>
                        <table id='service-line-items-table'>
                          <thead>
                            <tr>
                              <th>{i18n.t('productGroup')}</th>
                              <th>{i18n.t('description')}</th>
                              <th>{i18n.t('quantity')}</th>
                              <th>{i18n.t('unitPrice')}</th>
                              <th>{i18n.t('amount')}</th>
                              <th>{i18n.t('workSystem')}</th>
                              <th>{i18n.t('workCategory')}</th>
                            </tr>
                          </thead>
                          <tbody id='allLineItems'>
                            <tr className="table-header">
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td style={{ textAlign: 'center' }}>
                                {i18n.toCurrency(totalAmount)}
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                            {lineItems.map((line) => (
                              <tr key={line.id} id={`line-item-${line.id}`}>
                                <td>
                                  {line.productGroup}
                                </td>
                                <td>
                                  {line.description}
                                </td>
                                <td>
                                  {line.quantity}
                                </td>
                                <td>
                                  {i18n.toCurrency(line.unitPrice)}
                                </td>
                                <td>
                                  {i18n.toCurrency(line.amount)}
                                </td>
                                <td>
                                  { line.workSystem === null ?
                                      '':
                                      line.workSystem.value}
                                </td>
                                <td>
                                  {line.workCategory.value}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    }
                    { !(LIMITED_ROLES.includes(currentUser['role'])) &&
                      <div>
                        <Mutation mutation={UPDATE_WORK_ORDER}>
                          {(updateWorkOrder, { loading, error }) => {
                            return (
                              <div>
                                <Button
                                  id="update-work-order"
                                  text={i18n.t('updateWorkOrder')}
                                  clickAction={() => {
                                    const newServiceType = document.getElementById('serviceType').value
                                    const newServiceDescription =
                                      document.getElementById('serviceDescription').value

                                    var newKeyOnMeter = null
                                    if (document.getElementById('keyOnMeter').value !== null) {
                                      newKeyOnMeter = parseInt(document.getElementById('keyOnMeter').value, 10)
                                    }

                                    var newDriveMeter = null
                                    if (document.getElementById('driveMeter').value !== null) {
                                      newDriveMeter = parseInt(document.getElementById('driveMeter').value, 10)
                                    }

                                    var newPumpMeter = null
                                    if (document.getElementById('pumpMeter').value !== null) {
                                      newPumpMeter = parseInt(document.getElementById('pumpMeter').value, 10)
                                    }

                                    var newDowntime = null
                                    if (document.getElementById('downtime').value !== null) {
                                      newDowntime = parseFloat(document.getElementById('downtime').value, 10)
                                    }

                                    var itSel = document.getElementById('invoiceType')
                                    var newInvoiceType = null
                                    if (itSel.options[itSel.selectedIndex].text !== '') {
                                      newInvoiceType = itSel.options[itSel.selectedIndex]['value']
                                    }

                                    var newStartedAt = null
                                    const startedAtDate = document.getElementById('startedAt-date').value
                                    const startedAtHour = document.getElementById('startedAt-hour').value
                                    const startedAtMin = document.getElementById('startedAt-minute').value

                                    if (
                                      startedAtDate !== '' &&
                                      startedAtHour !== '' &&
                                      startedAtMin !== ''
                                    ) {
                                      newStartedAt =
                                        startedAtDate +
                                        ' ' +
                                        startedAtHour.padStart(2, '0') +
                                        ':' +
                                        startedAtMin.padStart(2, '0')
                                    }

                                    var newStoppedAt = null
                                    const stoppedAtDate = document.getElementById('stoppedAt-date').value
                                    const stoppedAtHour = document.getElementById('stoppedAt-hour').value
                                    const stoppedAtMin = document.getElementById('stoppedAt-minute').value
                                    if (
                                      stoppedAtDate !== '' &&
                                      stoppedAtHour !== '' &&
                                      stoppedAtMin !== ''
                                    ) {
                                      newStoppedAt =
                                        stoppedAtDate +
                                        ' ' +
                                        stoppedAtHour.padStart(2, '0') +
                                        ':' +
                                        stoppedAtMin.padStart(2, '0')
                                    }

                                    updateWorkOrder({
                                      variables: {
                                        token: token,
                                        id: id,
                                        serviceType: newServiceType,
                                        serviceDescription: newServiceDescription,
                                        keyOnMeter: newKeyOnMeter,
                                        driveMeter: newDriveMeter,
                                        pumpMeter: newPumpMeter,
                                        downtime: newDowntime,
                                        invoiceType: newInvoiceType,
                                        startedAt: newStartedAt,
                                        stoppedAt: newStoppedAt,
                                        countAsPm: this.state.countAsPm,
                                        countAsMfc: this.state.countAsMfc,
                                      },
                                    }).then(() => {
                                      window.flash('Updated Work Order', 'success')
                                    })
                                  }}
                                />
                                {loading && <p>{i18n.t('processing')}...</p>}
                                {error && <p>{error.message}</p>}
                              </div>
                            )
                          }}
                        </Mutation>
                        <AllLineItems
                          lineItems={lineItems}
                          totalAmount={totalAmount}
                          id={this.state.id}
                          currentUser={currentUser}
                        />
                        <AllReviews reviews={reviews} />
                        <AllNotes
                          notes={notes}
                          notableType="WorkOrder"
                          notableId={this.state.id}
                        />
                        <div id="modal" className="hidden">
                          <div className="modal-content">
                            <div className="modal-header">
                              <span
                                className="close"
                                onClick={() => {
                                  document
                                    .getElementById('modal')
                                    .classList.add('hidden')
                                }}
                              >
                               &times;
                              </span>
                              {/* This looks unfinished, leaving for now */}
                              <h2>Split Line Item</h2>
                            </div>
                            <div className="modal-body">
                              {"here's where we split the line item"}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

WorkOrder.defaultProps = {
  id: {},
  match: {}
}

WorkOrder.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  match: object,
}

export default WorkOrder
