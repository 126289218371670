import React, { Component } from 'react'
import { object } from 'prop-types'

import { AUTH_TOKEN } from '../constants'

import {
  FETCH_CURRENT_USER,
  FETCH_CUSTOMERS_FOR_EQUIP_REPORT,
  FETCH_SUMMARY_FOR_EQUIP_REPORT,
} from '../queries'

import { Link, Redirect } from 'react-router-dom'
import { Query } from 'react-apollo'
import { createBrowserHistory } from 'history'
import '../styles/report.css'
import i18n from 'i18n-js'

import BomParts from './BomParts'
import BomKpi from './BomKpi'
import BomEquip from './BomEquip'
import BomSettings from './BomSettings'
import BomSummary from './BomSummary'
import BomWorkOrder from './BomWorkOrder'
//import BomReviews from './BomReviews'
import SignInUser from './SignInUser'

class Bom extends Component {
  constructor(props) {
    super(props)

    let today = new Date()

    const todayYear = today.getYear() + 1900
    const prevYear = todayYear - 1
    let month = String(today.getMonth() + 1)
    let day = String(today.getDate())

    if (month.length < 2) {
      month = '0' + month
    }
    if (day.length < 2) {
      day = '0' + day
    }

    let summaryId = null
    let validForm = false
    if (props.match.params.id !== 'new') {
      summaryId = props.match.params.id
      validForm = true
    }

    let history = createBrowserHistory()

    let selectedNav = 'report-settings'
    let woFilterEquipment = ''
    let woFilterServiceType = ''
    let woFilterWorkCategory = ''

    if (history.location.state !== null) {
      if (history.location.state.selectedNav !== undefined) {
        selectedNav = history.location.state.selectedNav
      }

      if (history.location.state.woFilterEquipment !== undefined) {
        woFilterEquipment = history.location.state.woFilterEquipment
      }

      if (history.location.state.woFilterServiceType !== undefined) {
        woFilterServiceType = history.location.state.woFilterServiceType
      }

      if (history.location.state.woFilterWorkCategory !== undefined) {
        woFilterWorkCategory = history.location.state.woFilterWorkCategory
      }
    }

    this.state = {
      summaryId: summaryId,
      customerIds: [],
      summarySettings: {
        startRange: prevYear + '-' + month + '-' + day,
        endRange: todayYear + '-' + month + '-' + day,
        isCustomer: true,
        isTC: true,
        isEC: true,
        isInternal: true,
        includeAllWo: false,
        initialLoad: true,
        title: '',
        reportTitle: '',
        discussion: '',
        deliverPromise: '',
        validForm: validForm,
        executiveSummaryUrl: null,
        sortBy: 'eobjAsc',
        //reviewCount: 0,
        //reviewPopulated: false,
        woFilterEquipment: woFilterEquipment,
        woFilterServiceType: woFilterServiceType,
        woFilterWorkCategory: woFilterWorkCategory,
        selectedNav: selectedNav,
        lastWoDateVisible: true,
        firstWoDateVisible: true,
        periodHoursVisible: true,
        pmCphVisible: true,
        repairCphVisible: true,
        tiresCphVisible: true,
        damageCphVisible: true,
        requestCphVisible: true,
        attachCphVisible: true,
        mcCphVisible: true,
        plannedHoursVisible: true,
        pmCountVisible: true,
        avgPmIntervalVisible: true,
        mfcCountVisible: true,
        avgMfcIntervalVisible: true,
        pdfWoPricesVisible: true,
        kpiPeriod: 'monthly',
      },
      payload: {},
      disallowedEquip: [],
      avoidableWO: [],
      excludedWO: [],
      showcasedWO: [],
      //reviews: [],
    }
  }

  

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)
    const {
      summaryId,
      customerIds,
      summarySettings,
      disallowedEquip,
      avoidableWO,
      excludedWO,
      showcasedWO,
      payload,
      //reviews,
    } = this.state

    if (token === null) return <SignInUser />

    let custIds = ''

    customerIds.map((cust) => {
      if (custIds === '') {
        custIds = `${cust}`
      } else {
        custIds += `,${cust}`
      }
      return ''
    })

    let summaryQuery = FETCH_CUSTOMERS_FOR_EQUIP_REPORT
    let summaryVariables = {
      token,
      customerIds: custIds,
    }
    if (summaryId !== null) {
      summaryQuery = FETCH_SUMMARY_FOR_EQUIP_REPORT
      summaryVariables = {
        token,
        id: parseInt(summaryId, 10),
      }
    }

    return (
      <Query query={FETCH_CURRENT_USER} variables={{ token }}>
        {({ loading, error, data }) => {
          if (loading) return <div>{i18n.t('fetching')}</div>
          if (error) return <div>{error.message}</div>

          const currentUser = data.currentUser

          if (currentUser.role === 'customer') return <Redirect to="/" />

          return (
            <div>
              <Link
                to="#"
                onClick={() => {
                  summarySettings.selectedNav = 'report-settings'
                  this.setState({ summarySettings })
                }}
              >
                <div
                  id="report-settings"
                  className={
                    summarySettings.selectedNav === 'report-settings'
                      ? 'selectedNav localNav'
                      : 'localNav'
                  }
                >
                  {i18n.t('settings').toUpperCase()}
                </div>
              </Link>
              <Link
                to="#"
                onClick={() => {
                  summarySettings.selectedNav = 'report-summary'
                  this.setState({ summarySettings })
                }}
              >
                <div
                  id="report-summary"
                  className={
                    summarySettings.selectedNav === 'report-summary'
                      ? 'selectedNav localNav'
                      : 'localNav'
                  }
                >
                  {i18n.t('summary').toUpperCase()}
                </div>
              </Link>
              <Link
                to="#"
                onClick={() => {
                  summarySettings.selectedNav = 'report-kpi'
                  this.setState({ summarySettings })
                }}
              >
                <div
                  id="report-kpi"
                  className={
                    summarySettings.selectedNav === 'report-kpi'
                      ? 'selectedNav localNav'
                      : 'localNav'
                  }
                >
                  {i18n.t('kpi').toUpperCase()}
                </div>
              </Link>
              <Link
                to="#"
                onClick={() => {
                  summarySettings.selectedNav = 'report-work-order'
                  this.setState({ summarySettings })
                }}
              >
                <div
                  id="report-work-order"
                  className={
                    summarySettings.selectedNav === 'report-work-order'
                      ? 'selectedNav localNav'
                      : 'localNav'
                  }
                >
                  {i18n.t('workOrders').toUpperCase()}
                </div>
              </Link>
              <Link
                to="#"
                onClick={() => {
                  summarySettings.selectedNav = 'report-equipment'
                  this.setState({ summarySettings })
                }}
              >
                <div
                  id="report-equipment"
                  className={
                    summarySettings.selectedNav === 'report-equipment'
                      ? 'selectedNav localNav'
                      : 'localNav'
                  }
                >
                  {i18n.t('equipment').toUpperCase()}
                </div>
              </Link>
              <Link
                to="#"
                onClick={() => {
                  summarySettings.selectedNav = 'report-parts'
                  this.setState({ summarySettings })
                }}
              >
                <div
                  id="report-parts"
                  className={
                    summarySettings.selectedNav === 'report-parts'
                      ? 'selectedNav localNav'
                      : 'localNav'
                  }
                >
                  {i18n.t('parts').toUpperCase()}
                </div>
              </Link>

              <Query query={summaryQuery} variables={summaryVariables}>
                {({ loading, error, data }) => {
                  if (loading) return <div>{i18n.t('processing')}...</div>
                  if (error) return <div>{error.message}</div>

                  let customers = data.customers
                  let summary = null

                  if (summaryId !== null) {
                    customers = data.summary.customers

                    if (summarySettings.initialLoad === true) {
                      summary = data.summary
                      summarySettings.isCustomer = summary.isCustomer
                      summarySettings.isEC = summary.isEc
                      summarySettings.isTC = summary.isTc
                      summarySettings.isInternal = summary.isInternal
                      summarySettings.lastWoDateVisible =
                        summary.lastWoDateVisible
                      summarySettings.firstWoDateVisible =
                        summary.firstWoDateVisible
                      summarySettings.periodHoursVisible =
                        summary.periodHoursVisible
                      summarySettings.pmCphVisible = summary.pmCphVisible
                      summarySettings.repairCphVisible =
                        summary.repairCphVisible
                      summarySettings.tiresCphVisible = summary.tiresCphVisible
                      summarySettings.damageCphVisible =
                        summary.damageCphVisible
                      summarySettings.requestCphVisible =
                        summary.requestCphVisible
                      summarySettings.attachCphVisible =
                        summary.attachCphVisible
                      summarySettings.mcCphVisible = summary.mcCphVisible
                      summarySettings.plannedHoursVisible =
                        summary.plannedHoursVisible
                      summarySettings.pmCountVisible = summary.pmCountVisible
                      summarySettings.avgPmIntervalVisible =
                        summary.avgPmIntervalVisible
                      summarySettings.mfcCountVisible = summary.mfcCountVisible
                      summarySettings.avgMfcIntervalVisible =
                        summary.avgMfcIntervalVisible
                      summarySettings.pdfWoPricesVisible =
                        summary.pdfWoPricesVisible
                      summarySettings.startRange = summary.startRange.split(
                        ' ',
                      )[0]
                      summarySettings.endRange = summary.endRange.split(' ')[0]
                      summarySettings.includeAllWo = summary.includeAllWo
                      summarySettings.title = summary.title
                      summarySettings.reportTitle = summary.reportTitle
                      summarySettings.discussion = summary.discussion
                      summarySettings.deliverPromise = summary.deliverPromise
                      summarySettings.kpiPeriod = summary.kpiPeriod
                      summarySettings.executiveSummaryUrl =
                        summary.executiveSummaryUrl

                      summary.showcasedWorkOrders.map((swo) => {
                        showcasedWO.push(parseInt(swo.workOrder.id, 10))
                        return ''
                      })

                      summary.excludedWorkOrders.map((ewo) => {
                        excludedWO.push(parseInt(ewo.workOrder.id, 10))
                        return ''
                      })

                      summary.workOrders.map((wo) => {
                        avoidableWO.push(parseInt(wo.id, 10))
                        return ''
                      })

                      summary.equipment.map((e) => {
                        disallowedEquip.push(parseInt(e.id, 10))
                        return ''
                      })

                      summary.customers.map((c) => {
                        customerIds.push(parseInt(c.id, 10))
                        return ''
                      })
                    }
                    summarySettings.initialLoad = false
                  }

                  let hpd = 8
                  let dpw = 5
                  let totalAvailability = 0

                  if (customers[0]) {
                    hpd = parseInt(customers[0].hpd, 10)
                    dpw = parseInt(customers[0].dpw, 10)
                  }

                  let deltaYear = 250
                  let deltaMonth = 21

                  if (dpw === 7) {
                    deltaYear = 365
                    deltaMonth = 30
                  } else if (dpw === 6) {
                    deltaMonth = 25
                    deltaYear = 307
                  }

                  payload.totalPm = 0.0
                  payload.totalDamage = 0.0
                  payload.totalTires = 0.0
                  payload.totalRepair = 0.0
                  payload.totalRequest = 0.0
                  payload.totalAttach = 0.0
                  payload.totalMc = 0.0
                  payload.totalCost = 0.0
                  payload.pmCph = 0.0
                  payload.damageCph = 0.0
                  payload.tiresCph = 0.0
                  payload.repairCph = 0.0
                  payload.requestCph = 0.0
                  payload.attachCph = 0.0
                  payload.mcCph = 0.0
                  payload.totalCph = 0.0
                  payload.averageFleetHours = 0.0
                  payload.hpd = hpd
                  payload.dpw = dpw
                  payload.equipment = []
                  payload.totalDowntime = 0.0
                  payload.numDays =
                    (summarySettings.endRange.split('-')[0] -
                      summarySettings.startRange.split('-')[0]) *
                      deltaYear +
                    (summarySettings.endRange.split('-')[1] -
                      summarySettings.startRange.split('-')[1]) *
                      deltaMonth +
                    (summarySettings.endRange.split('-')[2] -
                      summarySettings.startRange.split('-')[2])
                  payload.fleetSize = 0.0
                  payload.hoursInWindow = payload.hpd * payload.numDays
                  payload.actualHours = 0.0
                  payload.totalHours = 0.0
                  payload.uptime = 0.0
                  payload.utilizationHours = 0.0
                  payload.hasFleetNo = false
                  payload.tcCount = 0.0
                  payload.ecCount = 0.0
                  payload.pmCount = 0.0
                  payload.tmCount = 0.0
                  payload.averageFleetAvailability = 0.0
                  payload.accurateEquipCount = 0
                  payload.kpi = []
                  payload.pdfTotalPm = 0.0
                  payload.pdfTotalDamage = 0.0
                  payload.pdfTotalTires = 0.0
                  payload.pdfTotalRepair = 0.0
                  payload.pdfTotalRequest = 0.0
                  payload.pdfTotalAttach = 0.0
                  payload.pdfTotalCost = 0.0
                  payload.pdfPmCph = 0.0
                  payload.pdfDamageCph = 0.0
                  payload.pdfTiresCph = 0.0
                  payload.pdfRepairCph = 0.0
                  payload.pdfRequestCph = 0.0
                  payload.pdfAttachCph = 0.0
                  payload.pdfTotalCph = 0.0

                  let totalPmCph = 0.0
                  let totalDamageCph = 0.0
                  let totalTiresCph = 0.0
                  let totalRepairCph = 0.0
                  let totalRequestCph = 0.0
                  let totalAttachCph = 0.0
                  let totalMcCph = 0.0
                  // eslint-disable-next-line
                  let totalTotalCph = 0.0
                  let totalPdfPmCph = 0.0
                  let totalPdfDamageCph = 0.0
                  let totalPdfTiresCph = 0.0
                  let totalPdfRepairCph = 0.0
                  let totalPdfRequestCph = 0.0
                  let totalPdfAttachCph = 0.0
                  // let totalPdfTotalCph = 0.0
                  let pmCount = 0.0
                  let repairCount = 0.0
                  let tiresCount = 0.0
                  let damageCount = 0.0
                  let requestCount = 0.0
                  let attachCount = 0.0
                  let mcCount = 0.0
                  let totalCount = 0.0
                  let pdfPmCount = 0.0
                  let pdfRepairCount = 0.0
                  let pdfTiresCount = 0.0
                  let pdfDamageCount = 0.0
                  let pdfRequestCount = 0.0
                  let pdfAttachCount = 0.0
                  // let pdfTotalCount = 0.0
                  let totalEndHM = 0.0
                  let equipments = []
                  let includeMC = false

                  if (
                    summarySettings.isCustomer === true &&
                    summarySettings.isTC === false &&
                    summarySettings.isEC === false &&
                    summarySettings.isInternal === false
                  ) {
                    includeMC = true
                  }

                  // const pmRay = ['CPM', 'ECP', 'FM', 'CPM-TM', 'RPM']

                  customers.map(
                    (customer) =>
                      /*
                    if (!summarySettings.reviewPopulated) {
                      customer.reviews.map((review) => {
                        if (review.completedAt === null) {
                          summarySettings.reviewCount += 1
                          reviews.push(review)
                        }
                        return ''
                      })
                    }
*/
                      (equipments = equipments.concat(customer.equipment)),
                  )

                  equipments.map((equip) => {
                    if (disallowedEquip.indexOf(equip.id) !== -1) {
                      return ''
                    }

                    let woInRange = 0

                    /*
                    if (!summarySettings.reviewsPopulated) {
                      equip.reviews.map((review) => {
                        if (review.completedAt === null) {
                          summarySettings.reviewCount += 1
                          reviews.push(review)
                        }
                        return ''
                      })
                    }
*/

                    let workOrders = equip.workOrders
                    workOrders.sort((a, b) =>
                      a.invoicedAt < b.invoicedAt ? 1 : -1,
                    )

                    let equipEntity = {
                      pmCost: 0.0,
                      damageCost: 0.0,
                      tiresCost: 0.0,
                      repairCost: 0.0,
                      requestCost: 0.0,
                      attachCost: 0.0,
                      mcCost: 0.0,
                      totalCost: 0.0,
                      firstWorkOrder: null,
                      lastWorkOrder: null,
                      totalDowntime: 0.0,
                      periodHours: 0.0,
                      annualHours: 0.0,
                      pmCount: 0.0,
                      avgPmInterval: 0.0,
                      mfcCount: 0.0,
                      avgMfcInterval: 0.0,
                      accuracyFlag: false,
                      plannedHours: null,
                      pdfPmCost: 0.0,
                      pdfDamageCost: 0.0,
                      pdfTiresCost: 0.0,
                      pdfRepairCost: 0.0,
                      pdfRequestCost: 0.0,
                      pdfAttachCost: 0.0,
                      pdfTotalCost: 0.0,
                      pdfPmCph: 0.0,
                      pdfDamageCph: 0.0,
                      pdfRepairCph: 0.0,
                      pdfTiresCph: 0.0,
                      pdfRequestCph: 0.0,
                      pdfTotalCph: 0.0,
                    }

                    let previousPmHm = null
                    let previousMfcHm = null
                    let totalPmInterval = 0
                    let totalMfcInterval = 0

                    // TODO: check to see if correct invoiceType

                    if (equipEntity.plannedHours === null) {
                      equipEntity.plannedHours = equip.plannedHours
                    }

                    equip.maintenanceRecords.map((mc) => {
                      if (
                        mc.invoicedAt >= summarySettings.startRange &&
                        mc.invoicedAt <= summarySettings.endRange
                      ) {
                        equipEntity.mcCost += mc.amount

                        return ''
                      }
                      return ''
                    })

                    workOrders.map((wo) => {
                      if (customerIds.indexOf(wo.customer.id) === -1) {
                        return ''
                      }

                      if (excludedWO.indexOf(wo.id) !== -1) {
                        return ''
                      }

                      if (wo.archivedAt !== null) {
                        return ''
                      }

                      equipEntity.firstWorkOrder = wo
                      /*
                      if (!summarySettings.reviewsPopulated) {
                        wo.reviews.map((review) => {
                          if (review.completedAt === null) {
                            summarySettings.reviewCount += 1
                            reviews.push(review)
                          }
                          return ''
                        })
                      }
*/

                      if (
                        wo.startedAt >= summarySettings.startRange &&
                        wo.startedAt <= summarySettings.endRange
                      ) {
                        if (equipEntity.lastWorkOrder === null) {
                          equipEntity.lastWorkOrder = wo
                        }

                        if (wo.downtime !== null) {
                          equipEntity.totalDowntime += parseInt(wo.downtime, 10)
                        }

                        // if (pmRay.indexOf(wo.serviceType) >= 0) {
                        if (wo.countAsPm) {
                          equipEntity.pmCount += 1
                          if (previousPmHm !== null) {
                            totalPmInterval += previousPmHm - wo.keyOnMeter
                          }
                          previousPmHm = wo.keyOnMeter
                        }

                        // if ((wo.serviceType.slice(-1) === 'K') && (['1', '2'].indexOf(wo.serviceType.slice(-2, -1)) >= 0)) {
                        if (wo.countAsMfc) {
                          equipEntity.mfcCount += 1
                          if (previousMfcHm !== null) {
                            totalMfcInterval += previousMfcHm - wo.keyOnMeter
                          }
                          previousMfcHm = wo.keyOnMeter
                        }
                      }

                      if (
                        wo.invoicedAt >= summarySettings.startRange &&
                        wo.invoicedAt <= summarySettings.endRange
                      ) {
                        if (
                          (summarySettings.isCustomer === false &&
                            wo.invoiceType === 'Customer') ||
                          (summarySettings.isTC === false &&
                            wo.invoiceType === 'Total Care') ||
                          (summarySettings.isEC === false &&
                            wo.invoiceType === 'Extra Care') ||
                          (summarySettings.isInternal === false &&
                            wo.invoiceType === 'Internal')
                        ) {
                          return ''
                        }

                        woInRange += 1

                        wo.lineItems.map((li) => {
                          let amount = parseFloat(li.amount)

                          switch (li.workCategory.value) {
                            case 'PM':
                              equipEntity.pmCost += amount
                              if (wo.invoiceType === 'Customer') {
                                equipEntity.pdfPmCost += amount
                                payload.pdfTotalPm += amount
                              }
                              break
                            case 'Damage':
                              equipEntity.damageCost += amount
                              if (wo.invoiceType === 'Customer') {
                                equipEntity.pdfDamageCost += amount
                                payload.pdfTotalDamage += amount
                              }
                              break
                            case 'Tires':
                              equipEntity.tiresCost += amount
                              if (wo.invoiceType === 'Customer') {
                                equipEntity.pdfTiresCost += amount
                                payload.pdfTotalTires += amount
                              }
                              break
                            case 'Repair':
                              equipEntity.repairCost += amount
                              if (wo.invoiceType === 'Customer') {
                                equipEntity.pdfRepairCost += amount
                                payload.pdfTotalRepair += amount
                              }
                              break
                            case 'Request':
                              equipEntity.requestCost += amount
                              if (wo.invoiceType === 'Customer') {
                                equipEntity.pdfRequestCost += amount
                                payload.pdfTotalRequest += amount
                              }
                              break
                            case 'Attachment':
                              equipEntity.attachCost += amount
                              if (wo.invoiceType === 'Customer') {
                                equipEntity.pdfAttachCost += amount
                                payload.pdfTotalAttach += amount
                              }
                              break
                            default:
                              break
                          }
                          return ''
                        })
                      }
                      return ''
                    })

                    if (woInRange === 0 && equipEntity.mcCost === 0) {
                      return ''
                    }

                    let wayback = summarySettings.endRange.split('-')
                    let waybackDay = parseInt(wayback[2], 10)
                    let waybackMonth = 0
                    let waybackYear = 0

                    if (parseInt(wayback[1], 10) - 3 < 1) {
                      waybackMonth = 12 + (parseInt(wayback[1], 10) - 3)
                      waybackYear = parseInt(wayback[0], 10) - 2
                    } else {
                      waybackMonth = parseInt(wayback[1], 10) - 3
                      waybackYear = parseInt(wayback[0], 10) - 1
                    }

                    let oneLastCheck = false

                    if (!equipEntity.lastWorkOrder) {
                      equipEntity.lastWorkOrder = workOrders[0]
                      oneLastCheck = true
                    }

                    if (typeof equipEntity.lastWorkOrder === 'undefined') {
                      return ''
                    }

                    let lastWODate = equipEntity.lastWorkOrder.startedAt.split(
                      ' ',
                    )[0]
                    let lastWOYear = lastWODate.split('-')[0]
                    let lastWOMonth = lastWODate.split('-')[1]
                    let lastWODay = lastWODate.split('-')[2]

                    if (
                      oneLastCheck &&
                      (waybackYear > lastWOYear ||
                        (waybackYear === lastWOYear &&
                          waybackMonth > lastWOMonth) ||
                        (waybackYear === lastWOYear &&
                          waybackMonth === lastWOMonth &&
                          waybackDay > lastWODay))
                    ) {
                      return ''
                    }

                    let woPointer = workOrders.indexOf(
                      equipEntity.lastWorkOrder,
                    )
                    let lateWO = equipEntity.lastWorkOrder

                    if (woPointer !== 0) {
                      lateWO = workOrders[woPointer - 1]
                    }

                    let deltaT = 1

                    let woMonth = lastWOMonth
                    let woYear = lastWOYear
                    let woDay = lastWODay

                    while (
                      deltaT <= payload.numDays * 1.2 &&
                      workOrders[woPointer + 1] !== undefined
                    ) {
                      woPointer += 1
                      let wo = workOrders[woPointer]
                      let woDate = wo.startedAt.split(' ')[0]
                      woYear = woDate.split('-')[0]
                      woMonth = woDate.split('-')[1]
                      woDay = woDate.split('-')[2]

                      deltaT =
                        (lastWOYear - woYear) * deltaYear +
                        (lastWOMonth - woMonth) * deltaMonth +
                        (lastWODay - woDay)
                    }

                    let earlyWO = workOrders[woPointer]

                    let lateKeyOnMeter = 0
                    let earlyKeyOnMeter = 0

                    let lateDrive = 0
                    let latePump = 0
                    let earlyDrive = 0
                    let earlyPump = 0

                    if (lateWO.keyOnMeter !== null) {
                      lateKeyOnMeter = parseInt(lateWO.keyOnMeter, 10)
                    }

                    if (lateWO.pumpMeter !== null) {
                      latePump = parseInt(lateWO.pumpMeter, 10)
                    }

                    if (lateWO.driveMeter !== null) {
                      lateDrive = parseInt(lateWO.driveMeter, 10)
                    }

                    let lateHM = lateDrive + latePump

                    if (earlyWO.keyOnMeter !== null) {
                      earlyKeyOnMeter = parseInt(earlyWO.keyOnMeter, 10)
                    }

                    if (earlyWO.pumpMeter !== null) {
                      earlyPump = parseInt(earlyWO.pumpMeter, 10)
                    }

                    if (earlyWO.driveMeter !== null) {
                      earlyDrive = parseInt(earlyWO.driveMeter, 10)
                    }

                    let earlyHM = earlyDrive + earlyPump

                    if (deltaT === 0) {
                      equipEntity.hoursPerDay = 0
                    } else if (lateHM !== 0) {
                      equipEntity.hoursPerDay = (lateHM - earlyHM) / deltaT
                    } else {
                      equipEntity.hoursPerDay =
                        (lateKeyOnMeter - earlyKeyOnMeter) / deltaT
                    }

                    if (equipEntity.hoursPerDay > 22) {
                      equipEntity.hoursPerDay = 22
                    }

                    let daysAfterNA = 0
                    let daysBeforeNA = 0

                    if (
                      earlyWO === equipEntity.firstWorkOrder &&
                      earlyWO.startedAt >= summarySettings.startRange
                    ) {
                      daysBeforeNA =
                        (woYear - summarySettings.startRange.split('-')[0]) *
                          deltaYear +
                        (woMonth - summarySettings.startRange.split('-')[1]) *
                          deltaMonth +
                        (woDay - summarySettings.startRange.split('-')[2])
                    }

                    let rangeSinceLastWO =
                      (summarySettings.endRange.split('-')[0] - lastWOYear) *
                        deltaYear +
                      (summarySettings.endRange.split('-')[1] - lastWOMonth) *
                        deltaMonth +
                      (summarySettings.endRange.split('-')[2] - lastWODay)

                    if (rangeSinceLastWO > deltaYear) {
                      daysAfterNA = rangeSinceLastWO - 3 * deltaMonth
                    }

                    equipEntity.periodHours = parseInt(
                      equipEntity.hoursPerDay *
                        (payload.numDays - daysBeforeNA - daysAfterNA),
                      10,
                    )

                    if (equipEntity.periodHours > 0) {
                      payload.totalHours += equipEntity.periodHours
                      payload.accurateEquipCount += 1
                      equipEntity.accuracyFlag = true
                    }

                    equipEntity.annualHours = parseInt(
                      equipEntity.hoursPerDay * deltaYear,
                      10,
                    )

                    if (equipEntity.pmCount > 1) {
                      equipEntity.avgPmInterval =
                        parseFloat(totalPmInterval) / equipEntity.pmCount
                    } else {
                      equipEntity.avgPmInterval = 0
                    }

                    if (equipEntity.mfcCount > 1) {
                      equipEntity.avgMfcInterval =
                        parseFloat(totalMfcInterval) / equipEntity.mfcCount
                    } else {
                      equipEntity.avgMfcInterval = 0
                    }

                    // Find the number of hours between earlyWO and beginning of window
                    let deltaHours =
                      ((woYear - summarySettings.startRange.split('-')[0]) *
                        deltaYear +
                        (woMonth - summarySettings.startRange.split('-')[1]) *
                          deltaMonth +
                        (woDay - summarySettings.startRange.split('-')[2])) *
                      equipEntity.hoursPerDay

                    if (
                      equipEntity.firstWorkOrder &&
                      equipEntity.firstWorkOrder.keyOnMeter <= 0
                    ) {
                      equipEntity.startKom = 0
                    } else {
                      equipEntity.startKom = earlyKeyOnMeter - deltaHours

                      if (equipEntity.startKom < 0) {
                        equipEntity.startKom = 0
                      } else {
                        equipEntity.startKom = parseInt(
                          equipEntity.startKom,
                          10,
                        )
                      }
                    }

                    equipEntity.endKom = parseInt(
                      equipEntity.lastWorkOrder.keyOnMeter,
                      10,
                    )

                    totalEndHM += equipEntity.endKom

                    if (earlyHM === 0 && lateHM === 0) {
                      equipEntity.startHm = 0
                      equipEntity.endHm = 0

                      equipEntity.startHM = equipEntity.startKom
                      equipEntity.endHM = equipEntity.endKom
                    } else {
                      equipEntity.startHm = earlyHM - deltaHours

                      if (equipEntity.startHm < 0) {
                        equipEntity.startHm = 0
                      } else {
                        equipEntity.startHm = parseInt(equipEntity.startHm, 10)
                      }

                      equipEntity.endHm = parseInt(
                        equipEntity.startHm + equipEntity.periodHours,
                        10,
                      )

                      equipEntity.startHM = equipEntity.startHm
                      equipEntity.endHM = equipEntity.endHm
                    }

                    let utilization = 0

                    if (deltaT > 45 || payload.numDays < 45) {
                      if (equip.plannedHours) {
                        utilization =
                          equipEntity.annualHours / equip.plannedHours
                        if (equipEntity.accuracyFlag) {
                          payload.actualHours +=
                            equip.plannedHours *
                            (payload.numDays / (payload.dpw * 50))
                        }
                      } else {
                        utilization =
                          equipEntity.annualHours /
                          (payload.hpd * payload.dpw * 50)
                        if (equipEntity.accuracyFlag) {
                          payload.actualHours += payload.hpd * payload.numDays
                        }
                      }
                      equipEntity.aur = (utilization * 100).toFixed(1) + '%'
                    } else {
                      equipEntity.aur = 'N/C'
                      equipEntity.annualHours = 'N/C'
                      equipEntity.periodHours = 'N/C'
                    }

                    equipEntity.availability =
                      (1 - equipEntity.totalDowntime / payload.hoursInWindow) *
                      100

                    if (equipEntity.accuracyFlag) {
                      totalAvailability += equipEntity.availability
                    }

                    equipEntity.totalCost =
                      equipEntity.pmCost +
                      equipEntity.damageCost +
                      equipEntity.tiresCost +
                      equipEntity.repairCost +
                      equipEntity.requestCost +
                      equipEntity.attachCost

                    equipEntity.pdfTotalCost =
                      equipEntity.pdfPmCost +
                      equipEntity.pdfDamageCost +
                      equipEntity.pdfTiresCost +
                      equipEntity.pdfRepairCost +
                      equipEntity.pdfRequestCost +
                      equipEntity.pdfAttachCost

                    if (includeMC) {
                      equipEntity.totalCost += equipEntity.mcCost
                      equipEntity.pdfTotalCost += equipEntity.mcCost
                    }

                    payload.totalPm += equipEntity.pmCost
                    payload.totalDamage += equipEntity.damageCost
                    payload.totalTires += equipEntity.tiresCost
                    payload.totalRepair += equipEntity.repairCost
                    payload.totalRequest += equipEntity.requestCost
                    payload.totalAttach += equipEntity.attachCost
                    payload.totalMc += equipEntity.mcCost

                    equipEntity.pmCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.pmCost / equipEntity.periodHours
                        : null
                    equipEntity.repairCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.repairCost / equipEntity.periodHours
                        : null
                    equipEntity.tiresCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.tiresCost / equipEntity.periodHours
                        : null
                    equipEntity.damageCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.damageCost / equipEntity.periodHours
                        : null
                    equipEntity.requestCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.requestCost / equipEntity.periodHours
                        : null
                    equipEntity.attachCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.attachCost / equipEntity.periodHours
                        : null
                    equipEntity.totalCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.totalCost / equipEntity.periodHours
                        : null
                    equipEntity.mcCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.mcCost / equipEntity.periodHours
                        : null

                    equipEntity.pdfPmCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.pdfPmCost / equipEntity.periodHours
                        : null
                    equipEntity.pdfRepairCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.pdfRepairCost / equipEntity.periodHours
                        : null
                    equipEntity.pdfTiresCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.pdfTiresCost / equipEntity.periodHours
                        : null
                    equipEntity.pdfDamageCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.pdfDamageCost / equipEntity.periodHours
                        : null
                    equipEntity.pdfRequestCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.pdfRequestCost / equipEntity.periodHours
                        : null
                    equipEntity.pdfTotalCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.pdfTotalCost / equipEntity.periodHours
                        : null

                    if (equipEntity.pmCost > 0 && equipEntity.periodHours > 0) {
                      totalPmCph += equipEntity.pmCost
                      pmCount += equipEntity.periodHours
                    }

                    if (
                      equipEntity.repairCph > 0 &&
                      equipEntity.periodHours > 0
                    ) {
                      totalRepairCph += equipEntity.repairCost
                      repairCount += equipEntity.periodHours
                    }

                    if (
                      equipEntity.tiresCph > 0 &&
                      equipEntity.periodHours > 0
                    ) {
                      totalTiresCph += equipEntity.tiresCost
                      tiresCount += equipEntity.periodHours
                    }

                    if (
                      equipEntity.damageCph > 0 &&
                      equipEntity.periodHours > 0
                    ) {
                      totalDamageCph += equipEntity.damageCost
                      damageCount += equipEntity.periodHours
                    }

                    if (
                      equipEntity.requestCph > 0 &&
                      equipEntity.periodHours > 0
                    ) {
                      totalRequestCph += equipEntity.requestCost
                      requestCount += equipEntity.periodHours
                    }

                    if (
                      equipEntity.attachCph > 0 &&
                      equipEntity.periodHours > 0
                    ) {
                      totalAttachCph += equipEntity.attachCost
                      attachCount += equipEntity.periodHours
                    }

                    if (
                      equipEntity.totalCph > 0 &&
                      equipEntity.periodHours > 0
                    ) {
                      totalTotalCph += equipEntity.totalCost
                      totalCount += equipEntity.periodHours
                    }

                    if (equipEntity.mcCph > 0 && equipEntity.periodHours > 0) {
                      totalMcCph += equipEntity.mcCost
                      mcCount += equipEntity.periodHours
                    }

                    if (
                      equipEntity.pdfPmCph > 0 &&
                      equipEntity.periodHours > 0
                    ) {
                      totalPdfPmCph += equipEntity.pdfPmCost
                      pdfPmCount += equipEntity.periodHours
                    }

                    if (
                      equipEntity.pdfRepairCph > 0 &&
                      equipEntity.periodHours > 0
                    ) {
                      totalPdfRepairCph += equipEntity.pdfRepairCost
                      pdfRepairCount += equipEntity.periodHours
                    }

                    if (
                      equipEntity.pdfDamageCph > 0 &&
                      equipEntity.periodHours > 0
                    ) {
                      totalPdfDamageCph += equipEntity.pdfDamageCost
                      pdfDamageCount += equipEntity.periodHours
                    }

                    if (
                      equipEntity.pdfTiresCph > 0 &&
                      equipEntity.periodHours > 0
                    ) {
                      totalPdfTiresCph += equipEntity.pdfTiresCost
                      pdfTiresCount += equipEntity.periodHours
                    }

                    if (
                      equipEntity.pdfAttachCph > 0 &&
                      equipEntity.periodHours > 0
                    ) {
                      totalPdfAttachCph += equipEntity.pdfAttachCost
                      pdfAttachCount += equipEntity.periodHours
                    }

                    if (
                      equipEntity.pdfRequestCph > 0 &&
                      equipEntity.periodHours > 0
                    ) {
                      totalPdfRequestCph += equipEntity.pdfRequestCost
                      pdfRequestCount += equipEntity.periodHours
                    }

                    if (
                      equipEntity.pdfTotalCph > 0 &&
                      equipEntity.periodHours > 0
                    ) {
                      // totalPdfTotalCph += equipEntity.pdfTotalCost
                      // pdfTotalCount += equipEntity.periodHours
                    }

                    payload.fleetSize += 1

                    switch (equip.maintenanceLevel) {
                      case 'TC':
                        payload.tcCount += 1
                        break
                      case 'EC':
                        payload.ecCount += 1
                        break
                      case 'PM':
                        payload.pmCount += 1
                        break
                      default:
                        payload.tmCount += 1
                        break
                    }

                    equipEntity.id = equip.id
                    equipEntity.vendorEquipId =
                      equip.vendorEquipment[0].vendorEquipmentId
                    equipEntity.customerId = equip.customer.id
                    // Is this an error?
                    equipEntity.customerName = equip.customer.internalName
                      ? equip.customer.internalName
                      : equip.customer.name
                    equipEntity.maintenanceLevel = equip.maintenanceLevel
                    equipEntity.make = equip.make
                    equipEntity.model = equip.model
                    equipEntity.serialNumber = equip.serialNumber
                    equipEntity.fleetNumber = equip.fleetNumber

                    if (
                      equip.fleetNumber !== '' &&
                      equip.fleetNumber !== null
                    ) {
                      payload.hasFleetNo = true
                    }

                    payload.totalDowntime += equipEntity.totalDowntime

                    payload.equipment.push(equipEntity)

                    return ''
                  })

                  //summarySettings.reviewsPopulated = true
                  payload.totalCost =
                    payload.totalPm +
                    payload.totalRepair +
                    payload.totalTires +
                    payload.totalDamage +
                    payload.totalRequest +
                    payload.totalAttach

                  // console.log(
                  //   payload.totalCost,
                  //   ' payload.cphWithoutDamageAndRequests',
                  // )

                  payload.pdfTotalCost =
                    payload.pdfTotalPm +
                    payload.pdfTotalRepair +
                    payload.pdfTotalTires +
                    payload.pdfTotalDamage +
                    payload.pdfTotalRequest +
                    payload.pdfTotalAttach

                  if (includeMC) {
                    payload.totalCost += payload.totalMc
                    payload.pdfTotalCost += payload.totalMc
                  }

                  // only add payload.totalMc if invoiceType is ONLY Customer

                  payload.pmCph = pmCount === 0 ? 0.0 : totalPmCph / pmCount
                  payload.repairCph =
                    repairCount === 0 ? 0.0 : totalRepairCph / repairCount
                  payload.tiresCph =
                    tiresCount === 0 ? 0.0 : totalTiresCph / tiresCount
                  payload.damageCph =
                    damageCount === 0 ? 0.0 : totalDamageCph / damageCount
                  payload.requestCph =
                    requestCount === 0 ? 0.0 : totalRequestCph / requestCount
                  payload.attachCph =
                    attachCount === 0 ? 0.0 : totalAttachCph / attachCount
                  payload.mcCph = mcCount === 0 ? 0.0 : totalMcCph / mcCount
                  payload.totalCph =
                    totalCount === 0 ? 0.0 : totalTotalCph / totalCount

                  payload.pdfPmCph =
                    pmCount === 0 ? 0.0 : totalPdfPmCph / pdfPmCount
                  payload.pdfRepairCph =
                    repairCount === 0 ? 0.0 : totalPdfRepairCph / pdfRepairCount
                  payload.pdfTiresCph =
                    tiresCount === 0 ? 0.0 : totalPdfTiresCph / pdfTiresCount
                  payload.pdfDamageCph =
                    damageCount === 0 ? 0.0 : totalPdfDamageCph / pdfDamageCount
                  payload.pdfRequestCph =
                    requestCount === 0
                      ? 0.0
                      : totalPdfRequestCph / pdfRequestCount
                  payload.pdfAttachCph =
                    attachCount === 0 ? 0.0 : totalPdfAttachCph / pdfAttachCount
                  payload.pdfTotalCph =
                    totalCount === 0 ? 0.0 : totalTotalCph / totalCount

                  // Filter equipment items based on period hours not equal to 'N/C' and greater than or equal to 0
                  const filteredEquipment = payload.equipment.filter(equip => {
                    return equip.periodHours !== 'N/C' && equip.periodHours >= 0;
                  });


                  //* Calculate the sum of PM, Repair, Tires, Attachments, and Maintenance Contracts for filtered equipment
                  //* Include mcCost only when 'isCustomer' is the sole true summary setting.
                  const sumCostWithoutDamageAndRequests = filteredEquipment.reduce((acc, equip) => {
                    let mcCost = 0;
                    if (summarySettings.isCustomer && !summarySettings.isTC && !summarySettings.isEC && !summarySettings.isInternal) {
                      mcCost = equip.mcCost || 0;
                    }
                      return acc + (equip.pmCost || 0) +
                                  (equip.repairCost || 0) +
                                  (equip.tiresCost || 0) +
                                  (equip.attachCost || 0) +
                                  mcCost;
                    }, 0);

                  // Calculate the total number of period hours
                  const sumPeriodHours = filteredEquipment.reduce((acc, equip) => acc + parseFloat(equip.periodHours), 0);

                  // Calculate the Cost Per Hour (CPH) without Damage and Requests
                  const cphWithoutDamageAndRequests = sumPeriodHours === 0 ? 0 : sumCostWithoutDamageAndRequests / sumPeriodHours;

                  // Assign the calculated CPH without Damage and Requests to adjustedCph in payload
                  payload.adjustedCph = totalCount === 0 ? 0.0 : cphWithoutDamageAndRequests;

                  payload.averageFleetHours =
                    payload.accurateEquipCount === 0
                      ? 0
                      : parseInt(totalEndHM / payload.accurateEquipCount, 10)
                  payload.actualHours = parseInt(payload.actualHours, 10)
                  payload.uptime =
                    100 *
                    ((payload.actualHours - payload.totalDowntime) /
                      payload.actualHours)
                  payload.utilizationHours =
                    100 * (payload.totalHours / payload.actualHours)

                  payload.averageFleetAvailability =
                    payload.accurateEquipCount === 0
                      ? 0
                      : totalAvailability / payload.accurateEquipCount

                  payload.kpi.push({
                    headline: i18n.t('currentKpi'),
                    startRange: summarySettings.startRange,
                    endRange: summarySettings.endRange,
                    damage: payload.totalDamage,
                    utilization: payload.utilizationHours,
                    availability: payload.averageFleetAvailability,
                    uptime: payload.uptime,
                    totalCph: payload.totalCph,
                  })

                  // console.log(payload.totalCph, ' payload.totalCph')

                  return (
                    <div>
                      {summarySettings.selectedNav === 'report-settings' && (
                        <BomSettings
                          customerIds={customerIds}
                          summarySettings={summarySettings}
                          summaryId={summaryId}
                          avoidableWO={avoidableWO}
                          excludedWO={excludedWO}
                          showcasedWO={showcasedWO}
                          disallowedEquip={disallowedEquip}
                          customers={customers}
                          history={this.props.history}
                          payload={payload}
                        />
                      )}
                      {summarySettings.selectedNav === 'report-summary' && (
                        <BomSummary
                          customerIds={customerIds}
                          summarySettings={summarySettings}
                          payload={payload}
                          customers={customers}
                        />
                      )}
                      {summarySettings.selectedNav === 'report-kpi' && (
                        <BomKpi
                          customerIds={customerIds}
                          settings={summarySettings}
                          summaryId={summaryId}
                          payload={payload}
                        />
                      )}
                      {summarySettings.selectedNav === 'report-work-order' && (
                        <BomWorkOrder
                          customerIds={customerIds}
                          summarySettings={summarySettings}
                          summaryId={summaryId}
                          avoidableWO={avoidableWO}
                          showcasedWO={showcasedWO}
                          excludedWO={excludedWO}
                          disallowedEquip={disallowedEquip}
                        />
                      )}
                      {summarySettings.selectedNav === 'report-equipment' && (
                        <BomEquip
                          customerIds={customerIds}
                          summarySettings={summarySettings}
                          customers={customers}
                          startRange={summarySettings.startRange}
                          endRange={summarySettings.endRange}
                          disallowedEquip={disallowedEquip}
                          summaryId={summaryId}
                          excludedWO={excludedWO}
                        />
                      )}
                      {summarySettings.selectedNav === 'report-parts' && (
                        <BomParts
                          customerIds={customerIds}
                          startRange={summarySettings.startRange}
                          endRange={summarySettings.endRange}
                          summaryId={summaryId}
                          summarySettings={summarySettings}
                          excludedWO={excludedWO}
                        />
                      )}
                      {/*
                        summarySettings.selectedNav === 'report-reviews' &&
                        <BomReviews
                          reviews={reviews}
                          summarySettings={summarySettings}
                        />
                      */}
                      {/* summarySettings.reviewCount > 0 &&
                        <span id="review-notifications">
                          {summarySettings.reviewCount}
                        </span>
                      */}
                    </div>
                  )
                }}
              </Query>
            </div>
          )
        }}
      </Query>
    )
  }
}

Bom.defaultProps = {
  match: {},
  history: {},
}

Bom.propTypes = {
  match: object,
  history: object,
}

export default Bom
