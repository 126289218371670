import React, { Component } from 'react'
import { CSVLink } from 'react-csv'
import FontAwesome from 'react-fontawesome'
import { Query } from 'react-apollo'
import { Link } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { object } from 'prop-types'
import i18n from 'i18n-js'

import SignInUser from './SignInUser'
import TimeStamp from './TimeStamp'

import { AUTH_TOKEN } from '../constants'
import {
  FETCH_CUSTOMERS_FOR_WORK_ORDER_REPORT,
  FETCH_ALL_WORK_CATEGORIES,
} from '../queries'

class ReportWorkOrder extends Component {
  constructor(props) {
    super(props)

    this.woChangeSortBy = this.woChangeSortBy.bind(this)

    this.state = {
      customers: props.customers,
      settings: props.settings,
      woSortBy: 'invoicedAsc',
      excludedWO: props.excludedWO,
      disallowedEquip: props.disallowedEquip,
      selectedCustomers: props.selectedCustomers,
    }

    let history = createBrowserHistory()
    history.replace(history.location.pathname, {
      selectedNav: 'report-work-order',
      woFilterServiceType: props.settings.woFilterServiceType,
      woFilterEquipment: props.settings.woFilterEquipment,
      woFilterWorkCategory: props.settings.woFilterWorkCategory,
    })
  }

  woChangeSortBy(val) {
    this.setState({ woSortBy: val })
  }

  changeFilterServiceType(event) {
    let settings = this.state.settings
    let history = createBrowserHistory()

    settings.woFilterServiceType = event.target.value
    this.setState({ settings })
    history.replace(history.location.pathname, {
      selectedNav: 'report-work-order',
      woFilterServiceType: event.target.value,
      woFilterEquipment: settings.woFilterEquipment,
      woFilterWorkCategory: settings.woFilterWorkCategory,
    })
  }

  changeFilterEquipment(event) {
    let settings = this.state.settings
    let history = createBrowserHistory()

    settings.woFilterEquipment = event.target.value
    this.setState({ settings })
    history.replace(history.location.pathname, {
      selectedNav: 'report-work-order',
      woFilterServiceType: settings.woFilterServiceType,
      woFilterEquipment: event.target.value,
      woFilterWorkCategory: settings.woFilterWorkCategory,
    })
  }

  changeFilterWorkCategory(event) {
    let settings = this.state.settings
    let history = createBrowserHistory()

    settings.woFilterWorkCategory = event.target.value
    this.setState({ settings })
    history.replace(history.location.pathname, {
      selectedNav: 'report-work-order',
      woFilterServiceType: settings.woFilterServiceType,
      woFilterEquipment: settings.woFilterEquipment,
      woFilterWorkCategory: event.target.value,
    })
  }

  render() {
    const authToken = localStorage.getItem(AUTH_TOKEN)

    if (authToken === null) return <SignInUser />

    const {
      customers,
      settings,
      woSortBy,
      excludedWO,
      disallowedEquip,
      selectedCustomers,
    } = this.state

    let customerIds = ''

    const startRange = settings.startRange
    const endRange = settings.endRange

    customers.map((c) => {
      if (customerIds === '') {
        customerIds = `${c.id}`
      } else {
        customerIds += `,${c.id}`
      }   
      return ''
    }) 

    let query = FETCH_CUSTOMERS_FOR_WORK_ORDER_REPORT
    let variables = {
      token: authToken,
      customerIds: customerIds,
    }

    return (
      <div>
        <Query query={query} variables={variables}>
          {({ loading, error, data }) => {
            if (loading) return <div>{i18n.t('processing')}...</div>
            if (error) return <div>{error.message}</div>

            let customers = data.customers

            let allWorkOrders = []
            let allWO = []

            const csvHeaders = [
              { label: 'Started At', key: 'startedAt' },
              { label: 'No.', key: 'no' },
              { label: 'Equipment', key: 'equip' },
              { label: 'Maintenance Level', key: 'maintLevel' },
              { label: 'Serial No.', key: 'serial' },
              { label: 'Service Type', key: 'serviceType' },
              { label: 'Service Description', key: 'serviceDesc' },
              { label: 'Meter', key: 'meter' },
              { label: 'PM', key: 'pm' },
              { label: 'Repair', key: 'repair' },
              { label: 'Tires', key: 'tires' },
              { label: 'Damage', key: 'damage' },
              { label: 'Request', key: 'request' },
              { label: 'Attachment', key: 'attach' },
              { label: 'Downtime', key: 'downtime' },
            ]

            if (selectedCustomers.lenght > 1) {
              csvHeaders.splice( 4, 0, { label: 'Customer', key: 'cust' } )
            }

            const csvData = []

            customers.map((customer) => {
              if (!(selectedCustomers.includes(customer.id))) {
                return ''
              }

              let workOrders = customer.workOrders

              switch (woSortBy) {
                case 'dateDesc':
                  workOrders.sort((a, b) =>
                    a.startedAt > b.startedAt ? 1 : -1,
                  )
                  break
                case 'eobjAsc':
                  workOrders.sort((a, b) =>
                    a.equipment.vendorEquipment[0].vendorEquipmentId <
                    b.equipment.vendorEquipment[0].vendorEquipmentId
                      ? 1
                      : -1,
                  )
                  break
                case 'eobjDesc':
                  workOrders.sort((a, b) =>
                    a.equipment.vendorEquipment[0].vendorEquipmentId >
                    b.equipment.vendorEquipment[0].vendorEquipmentId
                      ? 1
                      : -1,
                  )
                  break
                case 'serviceTypeAsc':
                  workOrders.sort((a, b) =>
                    a.serviceType < b.serviceType ? 1 : -1,
                  )
                  break
                case 'serviceTypeDesc':
                  workOrders.sort((a, b) =>
                    a.serviceType > b.serviceType ? 1 : -1,
                  )
                  break
                case 'careAsc':
                  workOrders.sort((a, b) => { 
                    let aVal = 0
                    let bVal = 0
                    switch (a.equipment.maintenanceLevel) {
                      case 'TC':
                        aVal = 4
                        break
                      case 'EC':
                        aVal = 3
                        break
                      case 'PM':
                        aVal = 2
                        break
                      default:
                        aVal = 1
                        break
                    }

                    switch (b.equipment.maintenanceLevel) {
                      case 'TC':
                        bVal = 4
                        break
                      case 'EC':
                        bVal = 3
                        break
                      case 'PM':
                        bVal = 2
                        break
                      default:
                        bVal = 1
                        break
                    }

                    if (aVal < bVal) {
                      return 1
                    } else {
                      return -1
                    }
                  })
                  break
                case 'careDesc':
                  workOrders.sort((a, b) => {
                    let aVal = 0
                    let bVal = 0
                    switch (a.equipment.maintenanceLevel) {
                      case 'TC':
                        aVal = 4
                        break
                      case 'EC':
                        aVal = 3
                        break
                      case 'PM':
                        aVal = 2
                        break
                      default:
                        aVal = 1
                        break
                    }

                    switch (b.equipment.maintenanceLevel) {
                      case 'TC':
                        bVal = 4
                        break
                      case 'EC':
                        bVal = 3
                        break
                      case 'PM':
                        bVal = 2
                        break
                      default:
                        bVal = 1
                        break
                    }

                    if (aVal > bVal) {
                      return 1
                    } else {
                      return -1
                    }
                  })
                  break
                default:
                  workOrders.sort((a, b) =>
                    a.startedAt < b.startedAt ? 1 : -1,
                  )
                  break
              }
              allWorkOrders = allWorkOrders.concat(workOrders)
              workOrders.map((wo) => {
                if (disallowedEquip.indexOf(wo.equipment.id) === -1) {
                  allWO.push(wo.id)
                }
                return ''
              })
              return ''
            })

            allWorkOrders.map((wo) => {
              if (disallowedEquip.indexOf(wo.equipment.id) !== -1) {
                return ''
              }

              let pmCost = 0
              let damageCost = 0
              let tiresCost = 0
              let repairCost = 0
              let requestCost = 0
              let attachCost = 0

              let filterServiceType =
                settings.woFilterServiceType
              let filterEquipment = settings.woFilterEquipment
              let filterWorkCategory =
                settings.woFilterWorkCategory

              let serviceTypeRegex = new RegExp(filterServiceType, 'i')
              let equipmentRegex = new RegExp(filterEquipment, 'i')

              const vendorEquipId =
                wo.equipment.vendorEquipment[0].vendorEquipmentId

              if (
                filterServiceType !== '' &&
                !serviceTypeRegex.test(wo.serviceType)
              ) { return '' }

              if (
                filterEquipment !== '' &&
                !equipmentRegex.test(vendorEquipId)
              ) { return '' }

              if (
                wo.invoicedAt >= startRange &&
                wo.invoicedAt <= endRange
              ) {

                wo.lineItems.map((li) => {
                  const amount = parseFloat(li.amount)

                  switch (li.workCategory.value) {
                    case 'PM':
                      pmCost += amount
                      break
                    case 'Damage':
                      damageCost += amount
                      break
                    case 'Tires':
                      tiresCost += amount
                      break
                    case 'Repair':
                      repairCost += amount
                      break
                    case 'Request':
                      requestCost += amount
                      break
                    case 'Attachment':
                      attachCost += amount
                      break
                    default:
                      break
                  }
                  return ''
                })

                if (
                  filterWorkCategory !== '' &&
                    ((filterWorkCategory === 'PM' && pmCost <= 0) ||
                    (filterWorkCategory === 'Damage' && damageCost <= 0) ||
                    (filterWorkCategory === 'Tires' && tiresCost <= 0) ||
                    (filterWorkCategory === 'Repair' && repairCost <= 0) ||
                    (filterWorkCategory === 'Request' && requestCost <= 0) ||
                    (filterWorkCategory === 'Attachment' && attachCost <= 0)) 
                ) { return '' }

                let pmCostParts = parseFloat(pmCost).toFixed(2).split(".")
                pmCostParts[0] = pmCostParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                const pmCostFin = `$${pmCostParts.join(".")}`

                let repairCostParts = parseFloat(repairCost).toFixed(2).split(".")
                repairCostParts[0] = repairCostParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                const repairCostFin = `$${repairCostParts.join(".")}`

                let tiresCostParts = parseFloat(tiresCost).toFixed(2).split(".")
                tiresCostParts[0] = tiresCostParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                const tiresCostFin = `$${tiresCostParts.join(".")}`

                let damageCostParts = parseFloat(damageCost).toFixed(2).split(".")
                damageCostParts[0] = damageCostParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                const damageCostFin = `$${damageCostParts.join(".")}`

                let requestCostParts = parseFloat(requestCost).toFixed(2).split(".")
                requestCostParts[0] = requestCostParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                const requestCostFin = `$${requestCostParts.join(".")}`

                let attachCostParts = parseFloat(attachCost).toFixed(2).split(".")
                attachCostParts[0] = attachCostParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                const attachCostFin = `$${attachCostParts.join(".")}`

                let data = {
                  id: wo.id,
                  startedAt: wo.startedAt,
                  no: wo.internalId,
                  equip: vendorEquipId,
                  maintLevel: wo.equipment.maintenanceLevel,
                  serial: wo.equipment.serialNumber,
                  serviceType: wo.serviceType,
                  serviceDesc: wo.serviceDescription.split(/[ ,]+/).join(' '),
                  meter: wo.keyOnMeter,
                  pm: pmCostFin,
                  pmRaw: pmCost,
                  repair: repairCostFin,
                  repairRaw: repairCost,
                  tires: tiresCostFin,
                  tiresRaw: tiresCost,
                  damage: damageCostFin,
                  damageRaw: damageCost,
                  request: requestCostFin,
                  requestRaw: requestCost,
                  attach: attachCostFin,
                  attachRaw: attachCost,
                  downtime: wo.downtime,
                  invoiceType: wo.invoiceType,
                  equipId: wo.equipment.id
                }

                if (selectedCustomers.length > 1) {
                  data.customer = wo.customer.name
                  if (wo.customer.parentRelationships.length > 0) {
                    data.customer += ` ${wo.customer.parentRelationships[0].customerInternalVal}`
                  }
                  data.custId = wo.customer.id
                }

                csvData.push(data)
              }

            return ''
            })

            return (
              <div>
                <div className="fieldContainer">
                  <div className="fieldLabel">
                    <label htmlFor="filter-equipment">
                      {i18n.t('equipmentNo')}
                    </label>
                  </div>
                  <div className="field">
                    <input
                      id="filter-equipment"
                      type="text"
                      className="text-field"
                      placeholder={i18n.t(
                        'typeHereToFilterWorkOrdersByEquipNo',
                      )}
                      onChange={this.changeFilterEquipment.bind(this)}
                      value={settings.woFilterEquipment}
                    />
                  </div>
                </div>
                <div className="fieldContainer">
                  <div className="fieldLabel">
                    <label htmlFor="filter-service-type">
                      {i18n.t('serviceType')}
                    </label>
                  </div>
                  <div className="field">
                    <input
                      id="fiter-service-type"
                      type="text"
                      className="text-field"
                      placeholder={i18n.t(
                        'typeHereToFilterWorkOrdersByServiceType',
                      )}
                      onChange={this.changeFilterServiceType.bind(this)}
                      value={settings.woFilterServiceType}
                    />
                  </div>
                </div>
                <Query
                  query={FETCH_ALL_WORK_CATEGORIES}
                  variables={{ token: authToken }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <span>{i18n.t('processing')}...</span>
                    if (error) return <span>{error.message}</span>

                    let workCategories = data.allWorkCategories

                    return (
                      <div className="fieldContainer">
                        <div className="fieldLabel">
                          <label htmlFor="filter-work-category">
                            {i18n.t('workCategory')}
                          </label>
                        </div>
                        <select
                          value={settings.woFilterWorkCategory}
                          id="filter-work-category"
                          onChange={this.changeFilterWorkCategory.bind(this)}
                        >
                          <option value="" key=""></option>
                          {workCategories.map((category) => (
                            <option value={category.value} key={category.id}>
                              {category.value}
                            </option>
                          ))}
                        </select>
                      </div>
                    )
                  }}
                </Query>
                <CSVLink
                  data={csvData}
                  headers={csvHeaders}
                  filename={`${customers[0].name}-WO.csv`} >
                  Export to CSV
                </CSVLink>
                <table id="wo-table" border="1">
                  <thead>
                    <tr>
                      <th>{i18n.t('exclude')}</th>
                      <th>
                        <Link
                          to="#"
                          onClick={() =>
                            this.woChangeSortBy(
                              woSortBy === 'dateAsc' ? 'dateDesc' : 'dateAsc',
                            )
                          }
                        >
                          {i18n.t('startedAt')} &nbsp;
                          <FontAwesome
                            name={
                              woSortBy === 'dateAsc' ? 'sort-down' : 'sort-up'
                            }
                          />
                        </Link>
                      </th>
                      <th>{i18n.t('noPeriod')}</th>
                      <th>
                        <Link
                          to="#"
                          onClick={() =>
                            this.woChangeSortBy(
                              woSortBy === 'eobjAsc' ? 'eobjDesc' : 'eobjAsc',
                            )
                          }
                        >
                          {i18n.t('equipment')} &nbsp;
                          <FontAwesome
                            name={
                              woSortBy === 'eobjAsc' ? 'sort-down' : 'sort-up'
                            }
                          />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={() =>
                            this.woChangeSortBy(
                              woSortBy === 'careAsc' ? 'careDesc' : 'careAsc',
                            )
                          }
                        >
                          {i18n.t('maintenanceLevel')}
                          <FontAwesome
                            name={
                              woSortBy === 'careAsc' ? 'sort-down' : 'sort-up'
                            }
                          />
                        </Link>
                      </th>
                      {selectedCustomers.length > 1 && <th>{i18n.t('customer')}</th>}
                      <th>{i18n.t('serialNo')}</th>
                      <th>
                        <Link
                          to="#"
                          onClick={() =>
                            this.woChangeSortBy(
                              woSortBy === 'serviceTypeAsc'
                                ? 'serviceTypeDesc'
                                : 'serviceTypeAsc',
                            )
                          }
                        >
                          {i18n.t('serviceType')} &nbsp;
                          <FontAwesome
                            name={
                              woSortBy === 'serviceTypeAsc'
                                ? 'sort-down'
                                : 'sort-up'
                            }
                          />
                        </Link>
                      </th>
                      <th>{i18n.t('serviceDescription')}</th>
                      <th>{i18n.t('meter')}</th>
                      <th>{i18n.t('amount')}</th>
                      <th>{i18n.t('downtime')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {csvData.map((wo) => {
                      const excludeSelectId = 'wo-exclude-' + wo.id
                      let isExcludeChecked = excludedWO.indexOf(wo.id) !== -1

                      return (
                        <tr key={wo.id} className="wo-row">
                          <td>
                            <label>
                              <input
                                id={excludeSelectId}
                                type="checkbox"
                                name={wo.id}
                                className="form-check-input"
                                checked={isExcludeChecked}
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    excludedWO.push(wo.id)
                                  } else {
                                    excludedWO.splice(
                                      excludedWO.indexOf(wo.id),
                                      1,
                                    )
                                  }

                                  this.setState(excludedWO)
                                }}
                              />
                            </label>
                          </td>
                          <td>
                            <TimeStamp timeToFormat={wo.startedAt} />
                          </td>
                          <td>
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  window.open(
                                    `https://ui.transparencyfleet.com//#/token?token=${localStorage.getItem(
                                      'auth-token',
                                    )}&location=workorders&id=${wo.id}`,
                                    '_blank'
                                  )
                                }}
                              >
                              {wo.no}
                            </div>
                          </td>
                          <td>
                            <Link
                              to={{
                                pathname: `/equipment/${wo.equipId}`,
                              }}
                            >
                              {wo.equip}
                            </Link>
                          </td>
                          <td>{wo.maintLevel}</td>
                          {selectedCustomers.length > 1 && (
                            <td>
                              {wo.customer}
                            </td>
                          )}
                          <td>{wo.serial}</td>
                          <td>{wo.serviceType}</td>
                          <td className="wo-desc">{wo.serviceDesc}</td>
                          <td>{wo.meter}</td>
                          <td>
                            {wo.pmRaw > 0 && (
                              <div>
                                {i18n.t('pm')}
                                {wo.invoiceType === 'Customer' &&
                                  <div>
                                    {wo.pm}
                                  </div>
                                }
                              </div>
                            )}
                            {wo.damageRaw > 0 && (
                              <div>
                                {i18n.t('damage')}
                                {wo.invoiceType === 'Customer' &&
                                  <div>
                                    {wo.damage}
                                  </div>
                                }
                              </div>
                            )}
                            {wo.requestRaw > 0 && (
                              <div>
                                {i18n.t('request')}
                                {wo.invoiceType === 'Customer' &&
                                  <div>
                                    {wo.request}
                                  </div>
                                }
                              </div>
                            )}
                            {wo.attachRaw > 0 && (
                              <div>
                                {i18n.t('attachment')}
                                {wo.invoiceType === 'Customer' &&
                                  <div>
                                    {wo.attach}
                                  </div>
                                }
                              </div>
                            )}
                            {wo.tiresRaw > 0 && (
                              <div>
                                {i18n.t('tires')}
                                {wo.invoiceType === 'Customer' &&
                                  <div>
                                    {wo.tires}
                                  </div>
                                }
                              </div>
                            )}
                            {wo.repairRaw > 0 && (
                              <div>
                                {i18n.t('repair')} 
                                {wo.invoiceType === 'Customer' &&
                                  <div>
                                    {wo.repair}
                                  </div>
                                }
                              </div>
                            )}
                          </td>
                          <td>{wo.downtime}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div>
                  <label>
                    <input
                      id="selectEmAll"
                      type="checkbox"
                      checked={allWO.length === excludedWO.length}
                      name="checkAll"
                      className="form-check-input"
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          excludedWO.splice(0, excludedWO.length)
                          excludedWO.push.apply(excludedWO, allWO)
                        } else {
                          excludedWO.splice(0, excludedWO.length)
                        }
                        this.setState(excludedWO)
                      }}
                    />
                    {i18n.t(
                      allWorkOrders.length !== excludedWO.length
                        ? 'selectAll'
                        : 'deselectAll',
                    )}
                  </label>
                </div>
              </div>
            )
          }}
        </Query>
      </div>
    )
  }
}

ReportWorkOrder.defaultProps = {
  customers: {},
  settings: {},
  excludedWO: {},
  disallowedEquip: {},
  selectedCustomers: {},
}

ReportWorkOrder.propTypes = {
  customers: object,
  settings: object,
  excludedWO: object,
  disallowedEquip: object,
  selectedCustomers: object,
}

export default ReportWorkOrder
