import React, { Component } from 'react'
//import FontAwesome from 'react-fontawesome'
import { createBrowserHistory } from 'history'
import { object } from 'prop-types'
import i18n from 'i18n-js'

import SignInUser from './SignInUser'
import TimeStamp from './TimeStamp'

import { AUTH_TOKEN } from '../constants'

class BomKpi extends Component {
  constructor(props) {
    super(props)

    let history = createBrowserHistory()
    history.replace(history.location.pathname, {
      selectedNav: 'report-kpi'
    })

    this.state = {
      payload: props.payload,
      //settings: props.settings,
    }
  }

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser />

    const {
      payload,
      //settings,
    } = this.state

    return (
      <div>
        <h3>{i18n.t('keyPerformanceIndicators')}</h3>
        {payload.kpi.map((period) => {
          return(
            <div key={period.headline}>
              <h4>{period.headline}</h4>
              <div>
                <strong>{i18n.t('dateRange')}: </strong>
                <TimeStamp timeToFormat={period.startRange} /> - <TimeStamp timeToFormat={period.endRange} />
              </div>
              <div>
                <strong>{i18n.t('damage')}: </strong>
                { `$${parseFloat(period.damage).toFixed(2)}` }
              </div>
              <div>
                <strong>{i18n.t('utilizationHours')}: </strong>
                { `${parseFloat(period.utilization).toFixed(2)}%` }
              </div>
              <div>
                <strong>{i18n.t('availability')}: </strong>
                { `${parseFloat(period.availability).toFixed(2)}%` }
              </div>
              <div>
                <strong>{i18n.t('uptime')}: </strong>
                { `${parseFloat(period.uptime).toFixed(2)}%` }
              </div>
              <div>
                <strong>{i18n.t('totalCPH')}: </strong>
                { `$${parseFloat(period.totalCph).toFixed(2)}` }
              </div>
            </div>
          )
        } ) }
      </div>
    )
  }
}

BomKpi.defaultProps = {
  payload: {},
}

BomKpi.propTypes = {
  payload: object,
}

export default BomKpi
