import React, {Component} from 'react'

import {
  AUTH_TOKEN,
} from '../constants'

import {
  CREATE_CUSTOMER,
  FETCH_CURRENT_USER,
} from '../queries'

import {
  Mutation,
  Query,
} from 'react-apollo'

import { Redirect } from 'react-router-dom'
import i18n from 'i18n-js'

import SignInUser from './SignInUser'
import TextField from './TextField'
import NumberField from './NumberField'
import Button from './Button'

import '../styles/invoice.css'

class NewCustomer extends Component {
  render() {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser />

    return(
      <Query query={FETCH_CURRENT_USER} variables={{token}}>
        {({loading, error, data}) => {
          if (loading) return <div>{i18n.t('fetching')}</div>
          if (error) return <div>{error.message}</div>

          const currentUser = data['currentUser']

          if (currentUser['role'] !== 'admin') return <Redirect to='/' />

          return(
            <div>
              <h3>{i18n.t('createNewCustomer')}</h3>
              <div>
                <TextField
                  id='name'
                  label={`${i18n.t('name')} *`}
                  placeholder={i18n.t('name')}
                />
                <TextField
                  id='customerInternalVal'
                  label={`${i18n.t('internalValue')} *`}
                  placeholder={i18n.t('internalValue')}
                />
                <TextField
                  id='internalName'
                  label={i18n.t('site')}
                  placeholder={i18n.t('site')}
                />
                <TextField
                  id='address1'
                  label={i18n.t('address1')}
                  placeholder={i18n.t('address1')}
                />
                <TextField
                  id='address2'
                  label={i18n.t('address2')}
                  placeholder={i18n.t('address2')}
                />
                <TextField
                  id='city'
                  label={i18n.t('city')}
                  placeholder={i18n.t('city')}
                />
                <TextField
                  id='province'
                  label={i18n.t('stateAbbreviation')}
                  placeholder={i18n.t('state')}
                />
                <TextField
                  id='postalCode'
                  label={i18n.t('zipCode')}
                  placeholder={i18n.t('zipCode')}
                />
                <TextField
                  id='country'
                  label={i18n.t('countryAbbreviation')}
                  placeholder={i18n.t('country')}
                  value={i18n.t('defaultCountry')}
                />
                <NumberField
                  id='hpd'
                  label={i18n.t('hoursPerDay')}
                  value='8'
                  step='1'
                />
                <NumberField
                  id='dpw'
                  label={i18n.t('daysPerWeek')}
                  value='5'
                  step='1'
                />
                <Mutation mutation={CREATE_CUSTOMER}>
                  {(createCustomer, { loading, error }) => {
                    return(
                      <div>
                        <Button
                          id='submit-customer'
                          text={i18n.t('submit')}
                          clickAction={() => {
                            const name = document.getElementById('name').value
                            const customerInternalVal = document.getElementById('customerInternalVal').value

                            const nameErr = document.getElementById('name-error')
                            const civErr = document.getElementById('customerInternalVal-error')

                            if ((name === '') || (customerInternalVal === '')) {
                              if (name === '') {
                                document.getElementById('name').classList.add('error')
                                nameErr.innerHTML = i18n.t('nameError')
                                nameErr.classList.remove('hidden')
                              }

                              if (customerInternalVal === '') {
                                document.getElementById('customerInternalVal').classList.add('error')
                                civErr.innerHTML = i18n.t('internalValueError')
                                civErr.classList.remove('hidden')
                              }

                              window.flash(i18n.t('validationsNotMet'), 'error')
                              return ''
                            }

                            document.getElementById('name').classList.remove('error')
                            document.getElementById('customerInternalVal').classList.remove('error')
                            civErr.classList.add('hidden')
                            nameErr.classList.add('hidden')

                            const internalName = document.getElementById('internalName').value
                            const address1 = document.getElementById('address1').value
                            const address2 = document.getElementById('address2').value
                            const city = document.getElementById('city').value
                            const province = document.getElementById('province').value
                            const postalCode = document.getElementById('postalCode').value
                            const country = document.getElementById('country').value
                            let hpd = parseInt(document.getElementById('hpd').value, 10)
                            let dpw = parseInt(document.getElementById('dpw').value, 10)

                            if (isNaN(hpd)) { hpd = 8 }
                            if (isNaN(dpw)) { dpw = 5 }

                            createCustomer({
                              variables: {
                                token,
                                vendorId: currentUser['vendorId'],
                                name,
                                customerInternalVal,
                                internalName,
                                address1,
                                address2,
                                city,
                                province,
                                postalCode,
                                country,
                                hpd,
                                dpw,
                              }
                            }).then(() => {
                              document.getElementById("customer-creation-confirmation").classList.remove("hidden")
                            })
                          } }
                        />
                        { loading && <p>{i18n.t('processing')}</p> }
                        { error && <p>{error.message}</p> }
                      </div>
                    )
                  } }
                </Mutation>
                <div id="customer-creation-confirmation" className="modal hidden">
                  <div className="modal-content delete-modal">
                    <div className="modal-header">
                      <span
                        className="close"
                        onClick={() => {
                          document.getElementById("customer-creation-confirmation").classList.add("hidden")
                        } }
                      >
                        &times;
                      </span>
                      <h2>{i18n.t("successfullyCreatedCustomer")}</h2>
                    </div>
                    <div className="modal-body">
                      <div className="thirdButtons">
                        <Button
                          id="refresh-page"
                          text={i18n.t("enterAnother")}
                          clickAction={() => {
                            window.location.reload(true)
                          } }
                        />
                      </div>
                      <div className="thirdButtons">
                        <Button
                          id="end-creation"
                          text={i18n.t("finish")}
                          clickAction={() => {
                            window.location.href = "/"
                          } }
                        />
                      </div>
                      <div className="thirdButtons">
                        <Button
                          id="echelon"
                          text={i18n.t("echelonize")}
                          clickAction={() => {
                            window.location.href = "/echelons"
                          } }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      </Query>
    )

  }
}

export default NewCustomer
