import React, {Component} from 'react'

import { AUTH_TOKEN } from '../constants'

import {
  AMOUNT_TYPES,
  CONTRACT_TYPES,
  INVOICE_RATES,
  PAYMENT_RATES,
  REPORT_TYPES,
} from '../dropDowns'

import {
  CUSTOMER_SEARCH,
  CREATE_CONTRACT,
  FETCH_CURRENT_USER,
} from '../queries'

import {
  Query,
  Mutation,
} from 'react-apollo'

import i18n from 'i18n-js'
import {Link} from 'react-router-dom'

import SignInUser from '../components/SignInUser'
import Dashboard from '../components/Dashboard'

import Button from '../components/Button'
import DateField from '../components/DateField'
import DropDown from '../components/DropDown'
import NumberField from '../components/NumberField'
import TextField from '../components/TextField'

import '../styles/contract.css'

class NewContract extends Component {
  constructor(props) {
    super(props)

    this.changeCustSearch = this.changeCustSearch.bind(this)

    let today = new Date()

    const year = today.getYear() + 1900
    let month = String(today.getMonth() + 1)
    let day = String(today.getDay() + 1)

    if (month.length < 2) {
      month = `0${month}`
    }

    if (day.length < 2) {
      day = `0${day}`
    }

    this.state = {
      custSearch: null,
      custSearchHide: true,
      customers: [],
      custIds: '',
      startRange: `${year}-${month}-${day}`,
      endRange: `${year}-${month}-${day}`,
    }
  }

  changeCustSearch = async(event) => {
    const custSearch = event.target.value

    const custSearchHide = (custSearch === '')

    this.setState({
      custSearch,
      custSearchHide
    })

    return ''
  }

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser/>

    const {
      custSearch,
      custSearchHide,
      customers,
      custIds,
      startRange,
      endRange,
    } = this.state

    const custSearchClass = custSearchHide ? 'hidden' : 'visible'

    return(
      <Query query={FETCH_CURRENT_USER} variables={{ token }}>
        {({ loading, error, data }) => {
          if (loading) return <div className='report'>{i18n.t('processing')}</div>
          if (error) return <div className='report'>{error.message}</div>

          const currentUser = data['currentUser']

          if (currentUser['role'] !== 'admin') return <Dashboard />
          return(
            <div>
              <h3>{i18n.t('createContract')}</h3>

              <div>
                <div className='localSearchContainer fieldContainer'>
                  <div className='select-dropdown-label'>
                    <label className='fieldLabel'>
                      {i18n.t('selectCustomers')}
                    </label>
                  </div>
                  <input
                    id='cust-search-field'
                    type='text'
                    className='text-field local-search-field'
                    placeholder={i18n.t('searchCustomerByNameNumber')}
                    onChange={this.changeCustSearch.bind(this)}
                  />
                  <span id='cust-search-error' className='hidden field-error local-search-error'>
                    {i18n.t('pleaseSelectACustomer')}
                  </span>
                </div>

                <div id='custSearchResults' className={custSearchClass}>
                  <Query
                    query={CUSTOMER_SEARCH}
                    variables={{
                      token,
                      search: custSearch,
                      vendorId: currentUser['vendorId'],
                      excluding: custIds,
                    }}>
                    {({ loading, error, data}) => {
                      if (loading) return <div className='report'>{i18n.t('processing')}</div>
                      if (error) return <div className='report'>{error.message}</div>

                      const results = data.searchCustomers

                      if (custSearch.length < 4) {
                        return <div className='report'>{i18n.t('typeFourOrMoreChar')}</div>
                      }

                      if (results.length === 0) {
                        return <div className='report'>{i18n.t('noResultsFound')}</div>
                      }

                      return(
                        <div>
                          {results.map( result => {
                            result.custName = result.name
                            if (result.internalName !== null) { result.custName += ` - ${result.internalName}` }

                            return(
                              <div key={result.id} className='searchResult'>
                                <Link to='#' onClick={async (event) => {
                                  event.preventDefault()
                                  customers.push(result)

                                  let tempIds = custIds

                                  if (custIds === '') {
                                    tempIds = `${result.id}`
                                  } else {
                                    tempIds += `,${result.id}`
                                  }

                                  this.setState({
                                    customers,
                                    custIds: tempIds,
                                  })
                                } }>
                                  {result.parentRelationships[0].customerInternalVal}<br/>
                                  {result.custName}
                                </Link>
                              </div>
                            )
                          } ) }
                        </div>
                      )
                    } }
                  </Query>
                </div>

                <div>
                  <ol>
                    {customers.map( cust => {
                      return(
                        <li key={cust.id}>
                          {cust.parentRelationships[0].customerInternalVal}<br/>
                          {cust.custName}
                          <Link to='#' onClick={async (event) => {
                            event.preventDefault()

                            customers.splice(
                              customers.indexOf(cust),
                              1
                            )

                            let tempIds = ''
                            customers.map((c) => {
                              if (tempIds === '') {
                                tempIds = `${c.id}`
                              } else {
                                tempIds += `,${c.id}`
                              }

                              return ''
                            } )

                            this.setState({
                              customers: customers,
                              custIds: tempIds,
                            })
                          } }> X</Link>
                        </li>
                      )
                    } ) }
                  </ol>
                </div>
              </div>

              <div>
                <h4>{i18n.t('contractAttributes')}</h4>
                <div>
                  <TextField
                    id="internalName"
                    label={i18n.t("internalName")}
                    placeholder={i18n.t("internalName")}
                  />

                  <TextField
                    id="billingValue"
                    label={i18n.t("billingValue")}
                    placeholder={i18n.t("billingValue")}
                  />

                  <DropDown
                    id="contractType"
                    label={i18n.t("contractType")}
                    options={CONTRACT_TYPES}
                    allowNull="false"
                  />

                  <div className="fieldContainer">
                    <div className="fieldLabel">
                      <label>
                        <input
                          id="payVendor"
                          type="checkbox"
                          className="form-check-input"
                        />
                        {i18n.t("payVendor")}
                      </label>
                    </div>
                  </div>

                  <div className="fieldContainer">
                    <div className="fieldLabel">
                      <label>
                        <input
                          id="hold"
                          type="checkbox"
                          className="form-check-input"
                        />
                        {i18n.t("hold")}
                      </label>
                    </div>
                  </div>

                  <div className="fieldContainer">
                    <div className="fieldLabel">
                      <label>
                        <input
                          id="poRequired"
                          type="checkbox"
                          className="form-check-input"
                        />
                        {i18n.t("poRequired")}
                      </label>
                    </div>
                  </div>

                  <div>
                    <h5>{i18n.t('dateRange')}</h5>
                    <DateField
                      id="startRange"
                      label={i18n.t("startRange")}
                      value={startRange}
                      onDateChange={(date) => {
                        this.setState({ startRange: date })
                      } }
                    />
                    <DateField
                      id="endRange"
                      label={i18n.t("endRange")}
                      value={endRange}
                      onDateChange={(date) => {
                        this.setState({ endRange: date })
                      } }
                    />
                  </div>

                  <NumberField
                    id="amount"
                    label={i18n.t("amount")}
                    placeholder={i18n.t("amount")}
                  />

                  <DropDown
                    id="amountType"
                    label={i18n.t("amountType")}
                    options={AMOUNT_TYPES}
                    allowNull="false"
                  />

                  <DropDown
                    id="invoiceRate"
                    label={i18n.t("invoiceRate")}
                    options={INVOICE_RATES}
                    allowNull="false"
                  />

                  <DropDown
                    id="paymentRate"
                    label={i18n.t("paymentRate")}
                    options={PAYMENT_RATES}
                    allowNull="false"
                  />

                  <DropDown
                    id="reportType"
                    label={i18n.t("reportType")}
                    options={REPORT_TYPES}
                    allowNull="false"
                  />

                  <TextField
                    id="customerPo"
                    label={i18n.t("customerPo")}
                    placeholder={i18n.t("customerPo")}
                  />

                  <TextField
                    id="paymentTerms"
                    label={i18n.t("paymentTerms")}
                    placeholder={i18n.t("paymentTerms")}
                  />

                </div>
              </div>

              <div>
                <Mutation mutation={CREATE_CONTRACT}>
                  {(createContract, { loading, error }) => {
                    return(
                      <div>
                        <Button
                          id="create-contract"
                          text={i18n.t('create')}
                          clickAction={() => {
                            let errorMsg = []

                            if (custIds === '') {
                              errorMsg.push(i18n.t('noCustomersSelected'))
                              let field = document.getElementById(
                                'cust-search-field'
                              )
                              field.classList.add('error')
                            }

                            if (startRange === '') {
                              errorMsg.push(i18n.t('noStartRangeSet'))
                            }

                            if (endRange === '') {
                              errorMsg.push(i18n.t('noEndRangeSet'))
                            }

                            let internalName = document.getElementById('internalName').value

                            if (internalName === '') {
                              errorMsg.push(i18n.t('internalNameCannotBeBlank'))
                              let field = document.getElementById(
                                'internalName'
                              )
                              field.classList.add('error')
                            }

                            let billingValue = document.getElementById('billingValue').value

                            if (billingValue === '') {
                              errorMsg.push(i18n.t('billingValueCannotBeBlank'))
                              let field = document.getElementById(
                                'billingValue'
                              )
                              field.classList.add('error')
                            }

                            let amount = parseFloat(document.getElementById('amount').value)

                            if (amount === '') {
                              errorMsg.push(i18n.t('amountCannotBeBlank'))
                              let field = document.getElementById(
                                'amount'
                              )
                              field.classList.add('error')
                            }

                            if (errorMsg.length > 0) {
                              window.flash(
                                `${i18n.t(
                                  'theFormHasTheFollowingIssues',
                                )} ${errorMsg.join('. ')}.`,
                                'error',
                              )
                              return ''
                            }

                            let contractType = null
                            let sel = document.getElementById('contractType')

                            if (
                              sel.options[sel.selectedIndex].text !== ''
                            ) {
                              contractType = sel.options[sel.selectedIndex]['value']
                            }

                            const hold = document.getElementById('hold').checked
                            const poRequired = document.getElementById('poRequired').checked
                            const payVendor = document.getElementById('payVendor').checked

                            let amountType = null
                            sel = document.getElementById('amountType')
                            if (
                              sel.options[sel.selectedIndex].text !== ''
                            ) {
                              amountType = sel.options[sel.selectedIndex]['value']
                            }

                            let invoiceRate = null
                            sel = document.getElementById('invoiceRate')
                            if (
                              sel.options[sel.selectedIndex].text !== ''
                            ) {
                              invoiceRate = sel.options[sel.selectedIndex]['value']
                            }

                            let paymentRate = null
                            sel = document.getElementById('paymentRate')
                            if (
                              sel.options[sel.selectedIndex].text !== ''
                            ) {
                              paymentRate = sel.options[sel.selectedIndex]['value']
                            }

                            let reportType = null
                            sel = document.getElementById('reportType')
                            if (
                              sel.options[sel.selectedIndex].text !== ''
                            ) {
                              reportType = sel.options[sel.selectedIndex]['value']
                            }

                            const customerPo = document.getElementById('customerPo').value
                            const paymentTerms = document.getElementById('paymentTerms').value

                            createContract({
                              variables: {
                                token,
                                customers: custIds,
                                internalName,
                                contractType,
                                hold,
                                poRequired,
                                payVendor,
                                startRange,
                                endRange,
                                amount,
                                amountType,
                                invoiceRate,
                                paymentRate,
                                reportType,
                                billingValue,
                                customerPo,
                                paymentTerms,
                              },
                            }).then((res) => {
                              window.location.href = `/contract/${res.data.createContract.contract.id}`
                            })
                          } }
                        />
                        {loading && <p>{i18n.t('processing')}</p>}
                        {error && <p>{error.message}</p>}
                      </div>
                    )
                  } }
                </Mutation>
              </div>
            </div>
          )
        } }
      </Query>
    )
  }

}

export default NewContract
