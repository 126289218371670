import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'

class DropDown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: props.id,
      required: props.required || false,
      options: props.options || [],
      label: props.label || null,
      allowNull: props.allowNull || 'true',
      selected: props.selected || '',
      rawOptions: props.rawOptions || false
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    this.setState({ selected: event.target.value })
  }

  render() {
    const { id, label, required, options, allowNull, rawOptions } = this.state

    return (
      <div className="fieldContainer">
        {label && (
          <div className="fieldLabel">
            <label htmlFor={id}>{label}</label>
          </div>
        )}
        <select
          value={this.state.selected}
          id={id}
          required={required}
          onChange={this.handleChange}
        >
          {/* Why is this boolean a string? */}
          {allowNull === 'true' ? <option></option> : null}
          {options.map((opt) => (
            <option value={opt.value} key={opt.key}>
              {rawOptions ? opt.name : i18n.t(opt.name)}
            </option>
          ))}
        </select>
      </div>
    )
  }
}

DropDown.defaultProps = {
  id: null,
  required: false,
  options: [],
  label: null,
  allowNull: 'true',
  selected: '',
  rawOptions: false,
}

DropDown.propTypes = {
  id: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
  label: PropTypes.string,
  allowNull: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rawOptions: PropTypes.bool,
}

export default DropDown
