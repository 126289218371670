import React, { Component } from 'react'
import i18n from 'i18n-js'

import { Query } from 'react-apollo'

import SignInUser from './SignInUser'
import TimeStamp from './TimeStamp'

import {
  Link,
  Redirect,
} from 'react-router-dom'

import { AUTH_TOKEN } from '../constants'

import {
  FETCH_ALL_INVOICES,
  FETCH_CURRENT_USER,
} from '../queries'

class AllInvoices extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openVisibility: 'show',
      partialVisibility: 'hidden',
      closedVisibility: 'hidden',
    }
  }

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser />

    const {
      openVisibility,
      partialVisibility,
      closedVisibility,
    } = this.state

    return(
      <Query query={FETCH_CURRENT_USER} variables={{token}}>
        {({ loading, error, data }) => {
          if (loading) return <div>{i18n.t('fetching')}</div>
          if (error) return <div>{error.message}</div>

          const currentUser = data['currentUser']

          if (currentUser['role'] !== 'admin') return <Redirect to='/' />

          return(
            <Query query={FETCH_ALL_INVOICES} variables={{token}}>
              {({ loading, error, data }) => {
                if (loading) return <div>{i18n.t('fetching')}</div>
                if (error) return <div>{error.message}</div>

                const invoices = data.allInvoices

                invoices.sort((a, b) =>
                  (a.createdAt < b.createdAt ? 1 : -1)
                )

                const openInvoices = []
                const partialInvoices = []
                const closedInvoices = []

                invoices.map((invoice) => {
                  if (invoice.aasmState === 'open') {
                    openInvoices.push(invoice)
                  } else if (invoice.aasmState === 'partial') {
                    partialInvoices.push(invoice)
                  } else if (invoice.aasmState === 'closed') {
                    closedInvoices.push(invoice)
                  }

                  return ''
                } )

                return(
                  <div>
                    <Link to="#" onClick={() => {
                      let css = openVisibility === 'hidden' ? 'show' : 'hidden'
                      this.setState({openVisibility: css})
                    } }>
                      <div className="title-toggle">
                        <h4>{i18n.t('openInvoices')}</h4>
                      </div>
                    </Link>
                    { openInvoices.length <= 0 ?
                      <div className={openVisibility}>
                        {i18n.t('noOpenInvoices')}
                      </div> :
                      <div className={openVisibility}>
                        {openInvoices.map((openInvoice) => (
                          <div key={openInvoice.id}>
                            <div>
                              <Link to="#">
                                <h3>{openInvoice.internalId}</h3>
                              </Link>
                            </div>
                            <div>
                              <strong>{i18n.t('customer')}: </strong>
                              {openInvoice.customer.name} - {openInvoice.customer.internalName} ({openInvoice.customer.parentRelationships[0].customerInternalVal})
                            </div>
                            <div>
                              <strong>{i18n.t('amount')}: </strong>
                              ${openInvoice.amount}
                            </div>
                            <div>
                              <strong>{i18n.t('createdAt')}: </strong>
                              <TimeStamp timeToFormat={openInvoice.createdAt} />
                            </div>
                          </div>
                        ) ) }
                      </div>
                    }

                    <Link to="#" onClick={() => {
                      let css = partialVisibility === 'hidden' ? 'show' : 'hidden'
                      this.setState({partialVisibility: css})
                    } }>
                      <div className="title-toggle">
                        <h4>{i18n.t('partialInvoices')}</h4>
                      </div>
                    </Link>
                    { partialInvoices.length <= 0 ?
                      <div className={partialVisibility}>
                        {i18n.t('noPartialInvoices')}
                      </div> :
                      <div className={partialVisibility}>
                        {partialInvoices.map((partialInvoice) => (
                          <div key={partialInvoice.id}>
                            <div>
                              <Link to="#">
                                <h3>{partialInvoice.internalId}</h3>
                              </Link>
                            </div>
                            <div>
                              <strong>{i18n.t('vendor')}: </strong>
                              {partialInvoice.vendor.name}
                            </div>
                            <div>
                              <strong>{i18n.t('customer')}: </strong>
                              {partialInvoice.customer.name} - {partialInvoice.customer.internalName} ({partialInvoice.customer.parentRelationships[0].customerInternalVal})
                            </div>
                            <div>
                              <strong>{i18n.t('amount')}: </strong>
                              ${partialInvoice.amount}
                            </div>
                            <div>
                              <strong>{i18n.t('createdAt')}: </strong>
                              <TimeStamp timeToFormat={partialInvoice.createdAt} />
                            </div>

                          </div>
                        ) ) }
                      </div>
                    }

                    <Link to="#" onClick={() => {
                      let css = closedVisibility === 'hidden' ? 'show' : 'hidden'
                      this.setState({closedVisibility: css})
                    } }>
                      <div className="title-toggle">
                        <h4>{i18n.t('closedInvoices')}</h4>
                      </div>
                    </Link>
                    { closedInvoices.length <= 0 ?
                      <div className={closedVisibility}>
                        {i18n.t('noClosedInvoices')}
                      </div> :
                      <div className={closedVisibility}>
                        {closedInvoices.map((closedInvoice) => (
                          <div key={closedInvoice.id}>
                            <div>
                              <Link to="#">
                                <h3>{closedInvoice.internalId}</h3>
                              </Link>
                            </div>
                            <div>
                              <strong>{i18n.t('vendor')}: </strong>
                              {closedInvoice.vendor.name}
                            </div>
                            <div>
                              <strong>{i18n.t('customer')}: </strong>
                              {closedInvoice.customer.name} - {closedInvoice.customer.internalName} ({closedInvoice.customer.parentRelationships[0].customerInternalVal})
                            </div>
                            <div>
                              <strong>{i18n.t('amount')}: </strong>
                              ${closedInvoice.amount}
                            </div>
                            <div>
                              <strong>{i18n.t('createdAt')}: </strong>
                              <TimeStamp timeToFormat={closedInvoice.createdAt} />
                            </div>
                          </div>
                        ) ) }
                      </div>
                    }
                  </div>
                )  
              } }
            </Query>
          )
        } }
      </Query>
    )
  }
}

export default AllInvoices
