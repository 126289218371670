import React, { useCallback, useEffect, useState } from 'react'

import Bus from './../utils/Bus'

export default function Flash() {
  const [visibility, setVisibility] = useState(false)
  const [flashMessage, setFlashMessage] = useState('')
  const [flashType, setFlashType] = useState('')

  useEffect(() => {
    let timeout = null
    Bus.addListener('flash', ({ message, type }) => {
      setVisibility(true)
      setFlashMessage(message)
      setFlashType(type)

      timeout = setTimeout(() => {
        setVisibility(false)
      }, 4000)
    })

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const onCloseClick = useCallback(() => {
    setVisibility(false)
  }, [])

  return (
    visibility && (
      <div className={`alert alert-${flashType}`}>
        <span className="close" onClick={onCloseClick}>
          <strong>X</strong>
        </span>
        <p>{flashMessage}</p>
      </div>
    )
  )
}
