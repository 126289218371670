import React, { Component } from 'react'
import { CSVLink } from 'react-csv'
import { Link } from 'react-router-dom'
import FontAwesome from 'react-fontawesome'
import { createBrowserHistory } from 'history'
import { object } from 'prop-types'
import i18n from 'i18n-js'

import SignInUser from './SignInUser'
import SummaryCostChart from './SummaryCostChart'
import TimeStamp from './TimeStamp'

import { AUTH_TOKEN } from '../constants'
import '../styles/report.css'

class ReportSummary extends Component {
  constructor(props) {
    super(props)

    let history = createBrowserHistory()
    history.replace(history.location.pathname, {
      selectedNav: 'report-summary',
    })

    this.changeSortBy = this.changeSortBy.bind(this)
    this.linkToFilteredWorkOrders = this.linkToFilteredWorkOrders.bind(this)
    this.monetize = this.monetize.bind(this)

    this.state = {
      customers: props.customers,
      settings: props.settings,
      payload: props.payload,
      selectedCustomers: props.selectedCustomers,
    }
  }

  changeSortBy(val) {
    const settings = this.state.settings
    settings.sortBy = val
    this.setState({ settings })
  }

  linkToFilteredWorkOrders(equipId, workCategory) {
    const settings = this.state.settings
    settings.woFilterEquipment = equipId
    settings.woFilterWorkCategory = workCategory
    settings.woFilterServiceType = ''
    settings.selectedNav = 'report-work-order'
    this.setState({ settings })
  }

  monetize(val, defaultVal = 'N/C', includeCents = true) {
    if (isNaN(parseFloat(val))) {
      return defaultVal
    } else {
      let finParts = parseFloat(val).toFixed(2).split('.')
      finParts[0] = finParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      if (includeCents) {
        return `$${finParts.join('.')}`
      } else {
        return `$${finParts[0]}`
      }
    }
  }

  render() {
    const authToken = localStorage.getItem(AUTH_TOKEN)
    const { customers, settings, payload, selectedCustomers } = this.state

    if (authToken === null) return <SignInUser />

    let topHeaderLength = 18

    if (selectedCustomers.length > 1) {
      topHeaderLength += 1
    }

    const csvHeaders = [
      { label: 'EOBJ', key: 'eobj' },
      { label: 'Maintenance Level', key: 'maintLevel' },
      { label: 'Make', key: 'make' },
      { label: 'Model', key: 'model' },
      { label: 'Serial', key: 'serial' },
      { label: 'Fleet No', key: 'fleetNo' },
      { label: 'First WO Date', key: 'firstWoDate' },
      { label: 'Last WO Date', key: 'lastWoDate' },
      { label: 'Start HM', key: 'startHm' },
      { label: 'End HM', key: 'endHm' },
      { label: 'Period Hours', key: 'periodHours' },
      { label: 'Annual Hours', key: 'annualHours' },
      { label: 'Annual Utilization Rate', key: 'aur' },
      { label: 'Availability', key: 'availability' },
      { label: 'PM Count', key: 'pmCount' },
      { label: 'Avg PM Interval', key: 'avgPmInt' },
      { label: 'MFC Count', key: 'mfcCount' },
      { label: 'Avg MFC Interval', key: 'avgMfcInt' },
      { label: 'Total Cost', key: 'totalCost' },
      { label: 'PM', key: 'pmCost' },
      { label: 'Repair', key: 'repairCost' },
      { label: 'Tires', key: 'tiresCost' },
      { label: 'Damage', key: 'damageCost' },
      { label: 'Request', key: 'requestCost' },
      { label: 'Attachment', key: 'attachCost' },
      { label: 'Maintenance Contracts', key: 'mc' },
      { label: 'PM CPH', key: 'pmCph' },
      { label: 'Repair CPH', key: 'repairCph' },
      { label: 'Tires CPH', key: 'tiresCph' },
      { label: 'Damage CPH', key: 'damageCph' },
      { label: 'Request CPH', key: 'requestCph' },
      { label: 'Attachment CPH', key: 'attachCph' },
      { label: 'MC CPH', key: 'mcCph' },
      { label: 'Total CPH', key: 'totalCph' },
    ]

    if (selectedCustomers.length > 1) {
      csvHeaders.splice(1, 0, { label: 'Customer', key: 'customer' })
    }

    let csvFilename = `${customers[0].name}.csv`

    const csvData = []

    let equipment = payload.equipment

    switch (settings.sortBy) {
      case 'eobjDesc':
        equipment.sort((a, b) =>
          a.vendorEquipmentId < b.vendorEquipmentId ? 1 : -1,
        )
        break
      case 'makeAsc':
        equipment.sort((a, b) => (a.make > b.make ? 1 : -1))
        break
      case 'makeDesc':
        equipment.sort((a, b) => (a.make < b.make ? 1 : -1))
        break
      case 'modelAsc':
        equipment.sort((a, b) => (a.model > b.model ? 1 : -1))
        break
      case 'modelDesc':
        equipment.sort((a, b) => (a.model < b.model ? 1 : -1))
        break
      case 'totalCostAsc':
        equipment.sort((a, b) => (a.totalCost < b.totalCost ? 1 : -1))
        break
      case 'totalCostDesc':
        equipment.sort((a, b) => (a.totalCost > b.totalCost ? 1 : -1))
        break
      case 'pmCostAsc':
        equipment.sort((a, b) => (a.pmCost < b.pmCost ? 1 : -1))
        break
      case 'pmCostDesc':
        equipment.sort((a, b) => (a.pmCost > b.pmCost ? 1 : -1))
        break
      case 'repairCostAsc':
        equipment.sort((a, b) => (a.repairCost < b.repairCost ? 1 : -1))
        break
      case 'repairCostDesc':
        equipment.sort((a, b) => (a.repairCost > b.repairCost ? 1 : -1))
        break
      case 'tiresCostAsc':
        equipment.sort((a, b) => (a.tiresCost < b.tiresCost ? 1 : -1))
        break
      case 'tiresCostDesc':
        equipment.sort((a, b) => (a.tiresCost > b.tiresCost ? 1 : -1))
        break
      case 'damageCostAsc':
        equipment.sort((a, b) => (a.damageCost < b.damageCost ? 1 : -1))
        break
      case 'damageCostDesc':
        equipment.sort((a, b) => (a.damageCost > b.damageCost ? 1 : -1))
        break
      case 'requestCostAsc':
        equipment.sort((a, b) => (a.requestCost < b.requestCost ? 1 : -1))
        break
      case 'requestCostDesc':
        equipment.sort((a, b) => (a.requestCost > b.requestCost ? 1 : -1))
        break
      case 'attachCostAsc':
        equipment.sort((a, b) => (a.attachCost < b.attachCost ? 1 : -1))
        break
      case 'attachCostDesc':
        equipment.sort((a, b) => (a.attachCost > b.attachCost ? 1 : -1))
        break
      case 'mcCostAsc':
        equipment.sort((a, b) => (a.mcCost < b.mcCost ? 1 : -1))
        break
      case 'mcCostDesc':
        equipment.sort((a, b) => (a.mcCost > b.mcCost ? 1 : -1))
        break
      case 'pmCphAsc':
        equipment.sort((a, b) => (a.pmCph < b.pmCph ? 1 : -1))
        break
      case 'pmCphDesc':
        equipment.sort((a, b) => (a.pmCph > b.pmCph ? 1 : -1))
        break
      case 'repairCphAsc':
        equipment.sort((a, b) => (a.repairCph < b.repairCph ? 1 : -1))
        break
      case 'repairCphDesc':
        equipment.sort((a, b) => (a.repairCph > b.repairCph ? 1 : -1))
        break
      case 'tiresCphAsc':
        equipment.sort((a, b) => (a.tiresCph < b.tiresCph ? 1 : -1))
        break
      case 'tiresCphDesc':
        equipment.sort((a, b) => (a.tiresCph > b.tiresCph ? 1 : -1))
        break
      case 'damageCphAsc':
        equipment.sort((a, b) => (a.damageCph < b.damageCph ? 1 : -1))
        break
      case 'damageCphDesc':
        equipment.sort((a, b) => (a.damageCph > b.damageCph ? 1 : -1))
        break
      case 'requestCphAsc':
        equipment.sort((a, b) => (a.requestCph < b.requestCph ? 1 : -1))
        break
      case 'requestCphDesc':
        equipment.sort((a, b) => (a.requestCph > b.requestCph ? 1 : -1))
        break
      case 'attachCphAsc':
        equipment.sort((a, b) => (a.attachCph < b.attachCph ? 1 : -1))
        break
      case 'attachCphDesc':
        equipment.sort((a, b) => (a.attachCph > b.attachCph ? 1 : -1))
        break
      case 'mcCphAsc':
        equipment.sort((a, b) => (a.mcCph < b.mcCph ? 1 : -1))
        break
      case 'mcCphDesc':
        equipment.sort((a, b) => (a.mcCph > b.mcCph ? 1 : -1))
        break
      case 'totalCphAsc':
        equipment.sort((a, b) => (a.totalCph < b.totalCph ? 1 : -1))
        break
      case 'totalCphDesc':
        equipment.sort((a, b) => (a.totalCph > b.totalCph ? 1 : -1))
        break
      case 'careAsc':
        equipment.sort((a, b) => {
          let aVal = 0
          let bVal = 0
          switch (a.maintenanceLevel) {
            case 'TC':
              aVal = 4
              break
            case 'EC':
              aVal = 3
              break
            case 'PM':
              aVal = 2
              break
            default:
              aVal = 1
              break
          }

          switch (b.maintenanceLevel) {
            case 'TC':
              bVal = 4
              break
            case 'EC':
              bVal = 3
              break
            case 'PM':
              bVal = 2
              break
            default:
              bVal = 1
              break
          }

          if (aVal < bVal) {
            return 1
          } else {
            return -1
          }
        })
        break
      case 'careDesc':
        equipment.sort((a, b) => {
          let aVal = 0
          let bVal = 0
          switch (a.maintenanceLevel) {
            case 'TC':
              aVal = 4
              break
            case 'EC':
              aVal = 3
              break
            case 'PM':
              aVal = 2
              break
            default:
              aVal = 1
              break
          }

          switch (b.maintenanceLevel) {
            case 'TC':
              bVal = 4
              break
            case 'EC':
              bVal = 3
              break
            case 'PM':
              bVal = 2
              break
            default:
              bVal = 1
              break
          }

          if (aVal > bVal) {
            return 1
          } else {
            return -1
          }
        })
        break
      default:
        equipment.sort((a, b) =>
          a.vendorEquipmentId > b.vendorEquipmentId ? 1 : -1,
        )
        break
    }

    equipment.map((equip) => {
      let data = {
        eobj: equip.vendorEquipId,
        maintLevel: equip.maintenanceLevel,
        make: equip.make,
        model: equip.model,
        serial: equip.serialNumber,
        fleetNo: equip.fleetNumber,
        firstWoDate: equip.firstWorkOrder.invoicedAt,
        lastWoDate: equip.lastWorkOrder.invoicedAt,
        startHm: equip.startHM,
        endHm: equip.endHM,
        periodHours: equip.periodHours,
        annualHours: equip.annualHours,
        aur: equip.aur,
        availability: `${equip.availability.toFixed(1)}%`,
        pmCount: equip.pmCount,
        avgPmInt:
          equip.avgPmInterval === 0 ? 'N/C' : parseInt(equip.avgPmInterval, 10),
        mfcCount: equip.mfcCount,
        avgMfcInt:
          equip.avgMfcInterval === 0
            ? 'N/C'
            : parseInt(equip.avgMfcInterval, 10),
        totalCost: this.monetize(equip.totalCost),
        pmCost: this.monetize(equip.pmCost),
        repairCost: this.monetize(equip.repairCost),
        tiresCost: this.monetize(equip.tiresCost),
        damageCost: this.monetize(equip.damageCost),
        requestCost: this.monetize(equip.requestCost),
        attachCost: this.monetize(equip.attachCost),
        mc: this.monetize(equip.mcCost),
        pmCph: this.monetize(equip.pmCph, '$0.00'),
        repairCph: this.monetize(equip.repairCph, '$0.00'),
        tiresCph: this.monetize(equip.tiresCph, '$0.00'),
        damageCph: this.monetize(equip.damageCph, '$0.00'),
        requestCph: this.monetize(equip.requestCph, '$0.00'),
        attachCph: this.monetize(equip.attachCph, '$0.00'),
        mcCph: this.monetize(equip.mcCph, '$0.00'),
        totalCph: this.monetize(equip.totalCph, '$0.00'),
      }

      if (selectedCustomers.length > 1) {
        data.customer = equip.customerName
      }

      csvData.push(data)

      return ''
    })

    let hasInaccurateHours = false;

    payload.equipment.forEach((equip) => {
      if (equip.periodHours === 'N/C' || equip.periodHours <= 0) {
        hasInaccurateHours = true;
      }
    });

    return (
      <div>
        <div className="summary-header">
          <div>
            <h2>{i18n.t('summary')}</h2>
            <ul>
              {customers.map((customer) => {
                if (!selectedCustomers.includes(customer.id)) {
                  return ''
                }
                return (
                  <li key={customer.id}>
                    {customer.name} - {customer.internalName} (
                    {customer.parentRelationships[0].customerInternalVal})
                  </li>
                )
              })}
            </ul>
            <p>
              <b>{i18n.t('startDate')}</b> {settings.startRange}
            </p>
            <p>
              <b>{i18n.t('endDate')}</b> {settings.endRange}
            </p>
          </div>
          <div>
            <p>
              <b>{i18n.t('workDays')}:</b> {payload.numDays}
            </p>
            <p>
              <b>{i18n.t('daysSlashWeek')}:</b> {payload.dpw}
            </p>
            <p>
              <b>{i18n.t('hoursSlashDay')}:</b> {payload.hpd}
            </p>
            <p>
              <b>{i18n.t('trucks')}:</b> {payload.fleetSize}
            </p>
            <p>
              <b>{i18n.t('overallCPH')}:</b> $
              {parseFloat(payload.totalCph).toFixed(2)}
            </p>
            <p>
              <b>{i18n.t('cphWoDamageAndRequests')}</b> $
              {parseFloat(payload.adjustedCph).toFixed(2)}
            </p>
          </div>
          <div>
            <p>
              <b>{i18n.t('availableHours')}:</b> {payload.actualHours}
            </p>
            <p>
              <b>{i18n.t('downtime')}:</b> {payload.totalDowntime}
            </p>
            <p>
              <b>{i18n.t('uptime')}:</b> {parseFloat(payload.uptime).toFixed(2)}
              %
            </p>
            <p>
              <b>{i18n.t('averageFleetAvailability')}:</b>{' '}
              {parseFloat(payload.averageFleetAvailability).toFixed(2)}%
            </p>
            <p>
            {/* total hours */}
            <b>{i18n.t('totalHours')}: </b>
            {hasInaccurateHours ? (
              <span className="tooltip-container">
                <span className="inaccurate-hours">{payload.totalHours}</span>
                <span className="tooltip">Some equipment have invalid values for Period Hours!</span>
              </span>
            ) : (
              payload.totalHours
            )}
            </p>
            {payload.fleetSize !== payload.accurateEquipCount && (
              <p>
                <b>{i18n.t('equipmentWithProblemHours')}: </b>
                {payload.fleetSize - payload.accurateEquipCount}
              </p>
            )}
            <p>
              <b>{i18n.t('utilizationHours')}:</b>{' '}
              {parseFloat(payload.utilizationHours).toFixed(2)}%
            </p>
            <p>
              <b>{i18n.t('averageFleetHours')}:</b> {payload.averageFleetHours}
            </p>
          </div>
        </div>
        <div className="info-box">
          <ul>
            {hasInaccurateHours ? (
              <li className='important'><b>NOTE:</b> Red Total Hours means that there are equipments with invalid values in the Period Hours.</li>
            ) : null}
            <li><b>Total Hours:</b> Sum of Equipment Period Hours. </li>
            <li><b>Overall CPH:</b> (Total Cost) / (Total Hours) </li>
            <li><b>CPH w/o Damage & Requests:</b> ([Total Cost] - [Damage & Requests]) / (Total Hours)</li>
          </ul>
        </div>
        <div>
          <CSVLink data={csvData} headers={csvHeaders} filename={csvFilename}>
            Export to CSV
          </CSVLink>
          <table border="1" id="summary-table">
            <thead>
              <tr>
                <td
                  colSpan={`${topHeaderLength}`}
                  className="summary-special-header"
                >
                  {i18n.t('totalCost').toUpperCase()}
                </td>
                <td className="financial">
                  {this.monetize(payload.totalCost)}
                </td>
                <td className="financial">
                  <Link
                    to="#"
                    onClick={() => this.linkToFilteredWorkOrders('', 'PM')}
                  >
                    {this.monetize(payload.totalPm)}
                  </Link>
                </td>
                <td className="financial">
                  <Link
                    to="#"
                    onClick={() => this.linkToFilteredWorkOrders('', 'Repair')}
                  >
                    {this.monetize(payload.totalRepair)}
                  </Link>
                </td>
                <td className="financial">
                  <Link
                    to="#"
                    onClick={() => this.linkToFilteredWorkOrders('', 'Tires')}
                  >
                    {this.monetize(payload.totalTires)}
                  </Link>
                </td>
                <td className="financial">
                  <Link
                    to="#"
                    onClick={() => this.linkToFilteredWorkOrders('', 'Damage')}
                  >
                    {this.monetize(payload.totalDamage)}
                  </Link>
                </td>
                <td className="financial">
                  <Link
                    to="#"
                    onClick={() => this.linkToFilteredWorkOrders('', 'Request')}
                  >
                    {this.monetize(payload.totalRequest)}
                  </Link>
                </td>
                <td className="financial">
                  <Link
                    to="#"
                    onClick={() =>
                      this.linkToFilteredWorkOrders('', 'Attachment')
                    }
                  >
                    {this.monetize(payload.totalAttach)}
                  </Link>
                </td>
                <td className="financial">
                  {this.monetize(payload.totalMc, '$0', false)}
                </td>
              </tr>
              <tr>
                <td
                  colSpan={`${topHeaderLength}`}
                  className="summary-special-header"
                >
                  {i18n.t('avgCostPerHour').toUpperCase()}
                </td>
                <td className="financial">{this.monetize(payload.totalCph)}</td>
                <td className="financial">{this.monetize(payload.pmCph)}</td>
                <td className="financial">
                  {this.monetize(payload.repairCph)}
                </td>
                <td className="financial">{this.monetize(payload.tiresCph)}</td>
                <td className="financial">
                  {this.monetize(payload.damageCph)}
                </td>
                <td className="financial">
                  {this.monetize(payload.requestCph)}
                </td>
                <td className="financial">
                  {this.monetize(payload.attachCph)}
                </td>
                <td className="financial">{this.monetize(payload.mcCph)}</td>
              </tr>
              <tr>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        settings.sortBy === 'eobjAsc' ? 'eobjDesc' : 'eobjAsc',
                      )
                    }
                  >
                    {i18n.t('eobj')} &nbsp;
                    <FontAwesome
                      name={
                        settings.sortBy === 'eobjAsc' ? 'sort-down' : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                {selectedCustomers.length > 1 && <th>{i18n.t('customer')}</th>}
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        settings.sortBy === 'careAsc' ? 'careDesc' : 'careAsc',
                      )
                    }
                  >
                    {i18n.t('maintenanceLevel')} &nbsp;
                    <FontAwesome
                      name={
                        settings.sortBy === 'careAsc' ? 'sort-down' : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        settings.sortBy === 'makeAsc' ? 'makeDesc' : 'makeAsc',
                      )
                    }
                  >
                    {i18n.t('make')} &nbsp;
                    <FontAwesome
                      name={
                        settings.sortBy === 'makeAsc' ? 'sort-down' : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        settings.sortBy === 'modelAsc'
                          ? 'modelDesc'
                          : 'modelAsc',
                      )
                    }
                  >
                    {i18n.t('model')} &nbsp;
                    <FontAwesome
                      name={
                        settings.sortBy === 'modelAsc' ? 'sort-down' : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>{i18n.t('serial')}</th>
                <th>{i18n.t('fleetNo')}</th>
                <th>{i18n.t('firstWoDate')}</th>
                <th>{i18n.t('lastWoDate')}</th>
                <th>{i18n.t('startHM')}</th>
                <th>{i18n.t('endHM')}</th>
                <th>{i18n.t('periodHours')}</th>
                <th>{i18n.t('annualHours')}</th>
                <th>{i18n.t('annualUtilizationRate')}</th>
                <th>{i18n.t('availability')}</th>
                <th>{i18n.t('pmCount')}</th>
                <th>{i18n.t('avgPmInterval')}</th>
                <th>{i18n.t('mfcCount')}</th>
                <th>{i18n.t('avgMfcInterval')}</th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        settings.sortBy === 'totalCostAsc'
                          ? 'totalCostDesc'
                          : 'totalCostAsc',
                      )
                    }
                  >
                    {i18n.t('totalCost')} &nbsp;
                    <FontAwesome
                      name={
                        settings.sortBy === 'totalCostAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        settings.sortBy === 'pmCostAsc'
                          ? 'pmCostDesc'
                          : 'pmCostAsc',
                      )
                    }
                  >
                    {i18n.t('pm')} &nbsp;
                    <FontAwesome
                      name={
                        settings.sortBy === 'pmCostAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        settings.sortBy === 'repairCostAsc'
                          ? 'repairCostDesc'
                          : 'repairCostAsc',
                      )
                    }
                  >
                    {i18n.t('repair')} &nbsp;
                    <FontAwesome
                      name={
                        settings.sortBy === 'repairCostAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        settings.sortBy === 'tiresCostAsc'
                          ? 'tiresCostDesc'
                          : 'tiresCostAsc',
                      )
                    }
                  >
                    {i18n.t('tires')} &nbsp;
                    <FontAwesome
                      name={
                        settings.sortBy === 'tiresCostAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        settings.sortBy === 'damageCostAsc'
                          ? 'damageCostDesc'
                          : 'damageCostAsc',
                      )
                    }
                  >
                    {i18n.t('damage')} &nbsp;
                    <FontAwesome
                      name={
                        settings.sortBy === 'damageCostAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        settings.sortBy === 'requestCostAsc'
                          ? 'requestCostDesc'
                          : 'requestCostAsc',
                      )
                    }
                  >
                    {i18n.t('request')} &nbsp;
                    <FontAwesome
                      name={
                        settings.sortBy === 'requestCostAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        settings.sortBy === 'attachCostAsc'
                          ? 'attachCostDesc'
                          : 'attachCostAsc',
                      )
                    }
                  >
                    {i18n.t('attachment')} &nbsp;
                    <FontAwesome
                      name={
                        settings.sortBy === 'attachCostAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        settings.sortBy === 'mcCostAsc'
                          ? 'mcCostDesc'
                          : 'mcCostAsc',
                      )
                    }
                  >
                    {i18n.t('maintenanceContracts')} &nbsp;
                    <FontAwesome
                      name={
                        settings.sortBy === 'mcCostAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        settings.sortBy === 'pmCphAsc'
                          ? 'pmCphDesc'
                          : 'pmCphAsc',
                      )
                    }
                  >
                    {i18n.t('pmCPH')} &nbsp;
                    <FontAwesome
                      name={
                        settings.sortBy === 'pmCphAsc' ? 'sort-down' : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        settings.sortBy === 'repairCphAsc'
                          ? 'repairCphDesc'
                          : 'repairCphAsc',
                      )
                    }
                  >
                    {i18n.t('repairCPH')} &nbsp;
                    <FontAwesome
                      name={
                        settings.sortBy === 'repairCphAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        settings.sortBy === 'tiresCphAsc'
                          ? 'tiresCphDesc'
                          : 'tiresCphAsc',
                      )
                    }
                  >
                    {i18n.t('tiresCPH')} &nbsp;
                    <FontAwesome
                      name={
                        settings.sortBy === 'tiresCphAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        settings.sortBy === 'damageCphAsc'
                          ? 'damageCphDesc'
                          : 'damageCphAsc',
                      )
                    }
                  >
                    {i18n.t('damageCPH')} &nbsp;
                    <FontAwesome
                      name={
                        settings.sortBy === 'damageCphAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        settings.sortBy === 'rquestCphAsc'
                          ? 'requestCphDesc'
                          : 'requestCphAsc',
                      )
                    }
                  >
                    {i18n.t('requestCPH')} &nbsp;
                    <FontAwesome
                      name={
                        settings.sortBy === 'requestCphAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        settings.sortBy === 'attachCphAsc'
                          ? 'attachCphDesc'
                          : 'attachCphASc',
                      )
                    }
                  >
                    {i18n.t('attachmentCPH')} &nbsp;
                    <FontAwesome
                      name={
                        settings.sortBy === 'attachCphASc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        settings.sortBy === 'mcCphAsc'
                          ? 'mcCphDesc'
                          : 'mcCphAsc',
                      )
                    }
                  >
                    {i18n.t('mcCPH')} &nbsp;
                    <FontAwesome
                      name={
                        settings.sortBy === 'mcCphAsc' ? 'sort-down' : 'sort-up'
                      }
                    />
                  </Link>
                </th>
                <th>
                  <Link
                    to="#"
                    onClick={() =>
                      this.changeSortBy(
                        settings.sortBy === 'totalCphAsc'
                          ? 'totalCphDesc'
                          : 'totalCphAsc',
                      )
                    }
                  >
                    {i18n.t('totalCPH')} &nbsp;
                    <FontAwesome
                      name={
                        settings.sortBy === 'totalCphAsc'
                          ? 'sort-down'
                          : 'sort-up'
                      }
                    />
                  </Link>
                </th>
              </tr>
            </thead>
            <tbody>
              {equipment.map((equip) => {
                return (
                  <tr key={equip.id}>
                    <td>
                      <Link to={{ pathname: `/equipment/${equip.id}` }}>
                        {equip.vendorEquipId}
                      </Link>
                    </td>
                    {selectedCustomers.length > 1 && (
                      <td>{equip.customerName}</td>
                    )}
                    <td>{equip.maintenanceLevel}</td>
                    <td>{equip.make}</td>
                    <td>{equip.model}</td>
                    <td>{equip.serialNumber}</td>
                    <td>{equip.fleetNumber}</td>
                    <td>
                      <TimeStamp
                        timeToFormat={equip.firstWorkOrder.invoicedAt}
                      />
                    </td>
                    <td>
                      <TimeStamp
                        timeToFormat={equip.lastWorkOrder.invoicedAt}
                      />
                    </td>
                    <td>{equip.startHM}</td>
                    <td>{equip.endHM}</td>
                    <td>{equip.periodHours}</td>
                    <td className={equip.annualHours <= 0 && 'inaccurate-cell'}>
                      {equip.annualHours}
                    </td>
                    <td>{equip.aur}</td>
                    <td>{equip.availability.toFixed(1) + '%'}</td>
                    <td>{equip.pmCount}</td>
                    <td>
                      {equip.avgPmInterval === 0
                        ? 'N/C'
                        : parseInt(equip.avgPmInterval, 10)}
                    </td>
                    <td>{equip.mfcCount}</td>
                    <td>
                      {equip.avgMfcInterval === 0
                        ? 'N/C'
                        : parseInt(equip.avgMfcInterval, 10)}
                    </td>
                    <td className="financial">
                      <Link
                        to="#"
                        onClick={() =>
                          this.linkToFilteredWorkOrders(equip.vendorEquipId, '')
                        }
                      >
                        {this.monetize(equip.totalCost)}
                      </Link>
                    </td>
                    <td className="financial">
                      <Link
                        to="#"
                        onClick={() =>
                          this.linkToFilteredWorkOrders(
                            equip.vendorEquipId,
                            'PM',
                          )
                        }
                      >
                        {this.monetize(equip.pmCost)}
                      </Link>
                    </td>
                    <td className="financial">
                      <Link
                        to="#"
                        onClick={() =>
                          this.linkToFilteredWorkOrders(
                            equip.vendorEquipId,
                            'Repair',
                          )
                        }
                      >
                        {this.monetize(equip.repairCost)}
                      </Link>
                    </td>
                    <td className="financial">
                      <Link
                        to="#"
                        onClick={() =>
                          this.linkToFilteredWorkOrders(
                            equip.vendorEquipId,
                            'Tires',
                          )
                        }
                      >
                        {this.monetize(equip.tiresCost)}
                      </Link>
                    </td>
                    <td className="financial">
                      <Link
                        to="#"
                        onClick={() =>
                          this.linkToFilteredWorkOrders(
                            equip.vendorEquipId,
                            'Damage',
                          )
                        }
                      >
                        {this.monetize(equip.damageCost)}
                      </Link>
                    </td>
                    <td className="financial">
                      <Link
                        to="#"
                        onClick={() =>
                          this.linkToFilteredWorkOrders(
                            equip.vendorEquipId,
                            'Request',
                          )
                        }
                      >
                        {this.monetize(equip.requestCost)}
                      </Link>
                    </td>
                    <td className="financial">
                      <Link
                        to="#"
                        onClick={() =>
                          this.linkToFilteredWorkOrders(
                            equip.vendorEquipId,
                            'Attachment',
                          )
                        }
                      >
                        {this.monetize(equip.attachCost)}
                      </Link>
                    </td>
                    <td className="financial">{this.monetize(equip.mcCost)}</td>
                    <td className="financial">{this.monetize(equip.pmCph)}</td>
                    <td className="financial">
                      {this.monetize(equip.repairCph)}
                    </td>
                    <td className="financial">
                      {this.monetize(equip.tiresCph)}
                    </td>
                    <td className="financial">
                      {this.monetize(equip.damageCph)}
                    </td>
                    <td className="financial">
                      {this.monetize(equip.requestCph)}
                    </td>
                    <td className="financial">
                      {this.monetize(equip.attachCph)}
                    </td>
                    <td className="financial">{this.monetize(equip.mcCph)}</td>
                    <td className="financial">
                      {this.monetize(equip.totalCph)}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <SummaryCostChart
          pm={payload.totalPm}
          repair={payload.totalRepair}
          tires={payload.totalTires}
          damage={payload.totalDamage}
          request={payload.totalRequest}
          attach={payload.totalAttach}
        />
      </div>
    )
  }
}

ReportSummary.defaultProps = {
  customers: {},
  settings: {},
  payload: {},
  selectedCustomers: {},
}

ReportSummary.propTypes = {
  customers: object,
  settings: object,
  payload: object,
  selectedCustomers: object,
}

export default ReportSummary
