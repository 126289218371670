import React, {Component} from 'react'
import { Mutation } from 'react-apollo'
import i18n from 'i18n-js'

import Button from './Button'
import PasswordField from './PasswordField'

import { Redirect } from 'react-router-dom'

import {
  AUTH_TOKEN,
  CURRENT_EMAIL,
  RESET_TOKEN,
} from '../constants'

import {
  RESET_PASSWORD,
} from '../queries'

class ResetPassword extends Component {
  render() {
    const token = localStorage.getItem(AUTH_TOKEN)
    if (token !== null) return <Redirect to='/' />

    const email = localStorage.getItem(CURRENT_EMAIL)
    if (email === null) return <Redirect to='/' />

    const resetToken = localStorage.getItem(RESET_TOKEN)
    if (resetToken === null) return <Redirect to='/' />

    return(
      <div id='sign-in-box'>
        <h4>{i18n.t('resetPassword')}</h4>
        <div>
          <PasswordField
            id='password'
            placeholder={i18n.t('newPassword')}
            label={i18n.t('newPassword')}
          />
        </div>
        <div>
          <PasswordField
            id='confirmPassword'
            placeholder={i18n.t('confirmPassword')}
            label={i18n.t('confirmPassword')}
          />
        </div>
        <div>
          <Mutation mutation={RESET_PASSWORD}>
            {(resetPassword) => {
              return(
                <Button
                  id='reset-password'
                  text={i18n.t('resetPassword')}
                  klass='sign-in'
                  clickAction={() => {
                    let password = document.getElementById('password').value

                    let confirmPassword = document.getElementById('confirmPassword').value

                    if (password !== confirmPassword) {
                      window.flash(i18n.t('passwordsDontMatch'), 'error')
                      return ''
                    }

                    resetPassword({
                      variables: {
                        email,
                        password,
                      }
                    }).then((res) => {
                      if (res.data.resetPassword.response === 'true') {
                        localStorage.removeItem(RESET_TOKEN)
                        localStorage.removeItem(CURRENT_EMAIL)

                        window.location.href = '/'
                      } else {
                        window.flash(i18n.t('somethingWentWrongTryAgain'), 'error')
                      }
                    })
                  } }
                />
              )
            } }
          </Mutation>
        </div>
      </div>
    )
  }
}

export default ResetPassword
