import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Query } from 'react-apollo'
import i18n from 'i18n-js'

import {
  FETCH_CURRENT_USER,
  FETCH_WORK_ORDERS_FOR_ALL_INVOICES_REPORT,
} from '../queries'

import SignInUser from './SignInUser'

import { AUTH_TOKEN } from '../constants'

import '../styles/report.css'
import SERVER_REDIRECTION_URL from '../config/config'

class InvoicedWorkOrders extends Component {
  constructor(props) {
    super(props)

    this.monetize = this.monetize.bind(this)

    this.state = {
      submittedAccord: 'hidden',
      batchedAccord: 'hidden',
      acceptedAccord: 'hidden',
      assignedAccord: 'hidden',
      uploadedAccord: 'hidden',
      invoicedAccord: 'hidden',
      disputedAccord: 'hidden',
      internalAccord: 'hidden',
      creditedAccord: 'hidden',
      openAccord: 'hidden',
      paidAccord: 'hidden',
    }
  }

  monetize(val, defaultVal = 'N/C', includeCents = true) {
    if (isNaN(parseFloat(val))) {
      return defaultVal
    } else {
      let finParts = parseFloat(val).toFixed(2).split('.')
      finParts[0] = finParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      if (includeCents) {
        return `$${finParts.join('.')}`
      } else {
        return `$${finParts[0]}`
      }
    }
  }

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser />

    const {
      submittedAccord,
      batchedAccord,
      acceptedAccord,
      assignedAccord,
      uploadedAccord,
      invoicedAccord,
      disputedAccord,
      internalAccord,
      creditedAccord,
      openAccord,
      paidAccord,
    } = this.state

    return (
      <Query query={FETCH_CURRENT_USER} variables={{ token }}>
        {({ loading, error, data }) => {
          if (loading) return <div>{i18n.t('processing')}</div>
          if (error) return <div>{error.message}</div>

          const currentUser = data['currentUser']

          return (
            <div>
              <Query
                query={FETCH_WORK_ORDERS_FOR_ALL_INVOICES_REPORT}
                variables={{ token, id: currentUser['vendorId'] }}
              >
                {({ loading, error, data }) => {
                  if (loading) return <div>{i18n.t('processing')}</div>
                  if (error) return <div>{error.message}</div>

                  let customer = data.customer

                  let submitted = []
                  let batched = []
                  let accepted = []
                  let assigned = []
                  let uploaded = []
                  let invoiced = []
                  let disputed = []
                  let internal = []
                  let credited = []
                  let open = []
                  let paid = []

                  customer.workOrders.map((wo) => {
                    wo.amount = 0
                    wo.categories = []

                    wo.lineItems.map((li) => {
                      wo.amount += li.amount

                      if (!wo.categories.includes(li.workCategory.value)) {
                        wo.categories.push(li.workCategory.value)
                      }

                      return ''
                    })

                    switch (wo.aasmState) {
                      case 'paid':
                        paid.push(wo)
                        break
                      case 'open':
                        open.push(wo)
                        break
                      case 'credited':
                        credited.push(wo)
                        break
                      case 'internal':
                        internal.push(wo)
                        break
                      case 'disputed':
                        disputed.push(wo)
                        break
                      case 'invoiced':
                        invoiced.push(wo)
                        break
                      case 'uploaded':
                        uploaded.push(wo)
                        break
                      case 'assigned':
                        assigned.push(wo)
                        break
                      case 'accepted':
                        accepted.push(wo)
                        break
                      case 'batched':
                        batched.push(wo)
                        break
                      default:
                        submitted.push(wo)
                        break
                    }

                    return ''
                  })

                  return (
                    <div>
                      <div id="submitted">
                        <Link
                          to="#"
                          onClick={() => {
                            let css =
                              this.state.submittedAccord === 'hidden'
                                ? 'visible'
                                : 'hidden'
                            this.setState({ submittedAccord: css })
                          }}
                        >
                          <div className="title-toggle">
                            <h4>{i18n.t('submitted')}</h4>
                          </div>
                        </Link>
                        <div className={submittedAccord}>
                          {submitted.length <= 0 ? (
                            <div>{i18n.t('noWorkOrdersFound')}</div>
                          ) : (
                            <table border="1" className="workOrderTable">
                              <thead>
                                <tr>
                                  <th>{i18n.t('invoiceId')}</th>
                                  <th>{i18n.t('category')}</th>
                                  <th>{i18n.t('total')}</th>
                                  <th>{i18n.t('exportToCsv')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {submitted.map((wo) => {
                                  return (
                                    <tr key={wo.id}>
                                      <td>
                                        <Link
                                          to={{
                                            pathname: `/work-order/${wo.id}`,
                                          }}
                                          onClick={() => {
                                            window.location.replace(
                                              `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
                                                'auth-token',
                                              )}&location=workorders&id=${
                                                wo.id
                                              }`,
                                            )
                                          }}
                                        >
                                          {wo.internalId}
                                        </Link>
                                      </td>
                                      <td>{wo.categories.join(', ')}</td>
                                      <td>{this.monetize(wo.amount)}</td>
                                      <td>{i18n.t('csv')}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>

                      <div id="batched">
                        <Link
                          to="#"
                          onClick={() => {
                            let css =
                              this.state.batchedAccord === 'hidden'
                                ? 'visible'
                                : 'hidden'
                            this.setState({ batchedAccord: css })
                          }}
                        >
                          <div className="title-toggle">
                            <h4>{i18n.t('batched')}</h4>
                          </div>
                        </Link>
                        <div className={batchedAccord}>
                          {batched.length <= 0 ? (
                            <div>{i18n.t('noWorkOrdersFound')}</div>
                          ) : (
                            <table border="1" className="workOrderTable">
                              <thead>
                                <tr>
                                  <th>{i18n.t('invoiceId')}</th>
                                  <th>{i18n.t('category')}</th>
                                  <th>{i18n.t('total')}</th>
                                  <th>{i18n.t('exportToCsv')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {batched.map((wo) => {
                                  return (
                                    <tr key={wo.id}>
                                      <td>
                                        <Link
                                          to={{
                                            pathname: `/work-order/${wo.id}`,
                                          }}
                                          onClick={() => {
                                            window.location.replace(
                                              `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
                                                'auth-token',
                                              )}&location=workorders&id=${
                                                wo.id
                                              }`,
                                            )
                                          }}
                                        >
                                          {wo.internalId}
                                        </Link>
                                      </td>
                                      <td>{wo.categories.join(', ')}</td>
                                      <td>{this.monetize(wo.amount)}</td>
                                      <td>{i18n.t('csv')}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>

                      <div id="accepted">
                        <Link
                          to="#"
                          onClick={() => {
                            let css =
                              this.state.acceptedAccord === 'hidden'
                                ? 'visible'
                                : 'hidden'
                            this.setState({ acceptedAccord: css })
                          }}
                        >
                          <div className="title-toggle">
                            <h4>{i18n.t('accepted')}</h4>
                          </div>
                        </Link>
                        <div className={acceptedAccord}>
                          {accepted.length <= 0 ? (
                            <div>{i18n.t('noWorkOrdersFound')}</div>
                          ) : (
                            <table border="1" className="workOrderTable">
                              <thead>
                                <tr>
                                  <th>{i18n.t('invoiceId')}</th>
                                  <th>{i18n.t('category')}</th>
                                  <th>{i18n.t('total')}</th>
                                  <th>{i18n.t('exportToCsv')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {accepted.map((wo) => {
                                  return (
                                    <tr key={wo.id}>
                                      <td>
                                        <Link
                                          to={{
                                            pathname: `/work-order/${wo.id}`,
                                          }}
                                          onClick={() => {
                                            window.location.replace(
                                              `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
                                                'auth-token',
                                              )}&location=workorders&id=${
                                                wo.id
                                              }`,
                                            )
                                          }}
                                        >
                                          {wo.internalId}
                                        </Link>
                                      </td>
                                      <td>{wo.categories.join(', ')}</td>
                                      <td>{this.monetize(wo.amount)}</td>
                                      <td>{i18n.t('csv')}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>

                      <div id="assigned">
                        <Link
                          to="#"
                          onClick={() => {
                            let css =
                              this.state.assignedAccord === 'hidden'
                                ? 'visible'
                                : 'hidden'
                            this.setState({ assignedAccord: css })
                          }}
                        >
                          <div className="title-toggle">
                            <h4>{i18n.t('assigned')}</h4>
                          </div>
                        </Link>
                        <div className={assignedAccord}>
                          {assigned.length <= 0 ? (
                            <div>{i18n.t('noWorkOrdersFound')}</div>
                          ) : (
                            <table border="1" className="workOrderTable">
                              <thead>
                                <tr>
                                  <th>{i18n.t('invoiceId')}</th>
                                  <th>{i18n.t('category')}</th>
                                  <th>{i18n.t('total')}</th>
                                  <th>{i18n.t('exportToCsv')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {batched.map((wo) => {
                                  return (
                                    <tr key={wo.id}>
                                      <td>
                                        <Link
                                          to={{
                                            pathname: `/work-order/${wo.id}`,
                                          }}
                                          onClick={() => {
                                            window.location.replace(
                                              `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
                                                'auth-token',
                                              )}&location=workorders&id=${
                                                wo.id
                                              }`,
                                            )
                                          }}
                                        >
                                          {wo.internalId}
                                        </Link>
                                      </td>
                                      <td>{wo.categories.join(', ')}</td>
                                      <td>{this.monetize(wo.amount)}</td>
                                      <td>{i18n.t('csv')}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>

                      <div id="uploaded">
                        <Link
                          to="#"
                          onClick={() => {
                            let css =
                              this.state.uploadedAccord === 'hidden'
                                ? 'visible'
                                : 'hidden'
                            this.setState({ uploadedAccord: css })
                          }}
                        >
                          <div className="title-toggle">
                            <h4>{i18n.t('uploaded')}</h4>
                          </div>
                        </Link>
                        <div className={uploadedAccord}>
                          {uploaded.length <= 0 ? (
                            <div>{i18n.t('noWorkOrdersFound')}</div>
                          ) : (
                            <table border="1" className="workOrderTable">
                              <thead>
                                <tr>
                                  <th>{i18n.t('invoiceId')}</th>
                                  <th>{i18n.t('category')}</th>
                                  <th>{i18n.t('total')}</th>
                                  <th>{i18n.t('exportToCsv')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {uploaded.map((wo) => {
                                  return (
                                    <tr key={wo.id}>
                                      <td>
                                        <Link
                                          to={{
                                            pathname: `/work-order/${wo.id}`,
                                          }}
                                          onClick={() => {
                                            window.location.replace(
                                              `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
                                                'auth-token',
                                              )}&location=workorders&id=${
                                                wo.id
                                              }`,
                                            )
                                          }}
                                        >
                                          {wo.internalId}
                                        </Link>
                                      </td>
                                      <td>{wo.categories.join(', ')}</td>
                                      <td>{this.monetize(wo.amount)}</td>
                                      <td>{i18n.t('csv')}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>

                      <div id="invoiced">
                        <Link
                          to="#"
                          onClick={() => {
                            let css =
                              this.state.invoicedAccord === 'hidden'
                                ? 'visible'
                                : 'hidden'
                            this.setState({ invoicedAccord: css })
                          }}
                        >
                          <div className="title-toggle">
                            <h4>{i18n.t('invoiced')}</h4>
                          </div>
                        </Link>
                        <div className={invoicedAccord}>
                          {invoiced.length <= 0 ? (
                            <div>{i18n.t('noWorkOrdersFound')}</div>
                          ) : (
                            <table border="1" className="workOrderTable">
                              <thead>
                                <tr>
                                  <th>{i18n.t('invoiceId')}</th>
                                  <th>{i18n.t('category')}</th>
                                  <th>{i18n.t('total')}</th>
                                  <th>{i18n.t('exportToCsv')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {invoiced.map((wo) => {
                                  return (
                                    <tr key={wo.id}>
                                      <td>
                                        <Link
                                          to={{
                                            pathname: `/work-order/${wo.id}`,
                                          }}
                                          onClick={() => {
                                            window.location.replace(
                                              `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
                                                'auth-token',
                                              )}&location=workorders&id=${
                                                wo.id
                                              }`,
                                            )
                                          }}
                                        >
                                          {wo.internalId}
                                        </Link>
                                      </td>
                                      <td>{wo.categories.join(', ')}</td>
                                      <td>{this.monetize(wo.amount)}</td>
                                      <td>{i18n.t('csv')}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>

                      <div id="disputed">
                        <Link
                          to="#"
                          onClick={() => {
                            let css =
                              this.state.disputedAccord === 'hidden'
                                ? 'visible'
                                : 'hidden'
                            this.setState({ disputedAccord: css })
                          }}
                        >
                          <div className="title-toggle">
                            <h4>{i18n.t('disputed')}</h4>
                          </div>
                        </Link>
                        <div className={disputedAccord}>
                          {disputed.length <= 0 ? (
                            <div>{i18n.t('noWorkOrdersFound')}</div>
                          ) : (
                            <table border="1" className="workOrderTable">
                              <thead>
                                <tr>
                                  <th>{i18n.t('invoiceId')}</th>
                                  <th>{i18n.t('category')}</th>
                                  <th>{i18n.t('total')}</th>
                                  <th>{i18n.t('exportToCsv')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {disputed.map((wo) => {
                                  return (
                                    <tr key={wo.id}>
                                      <td>
                                        <Link
                                          to={{
                                            pathname: `/work-order/${wo.id}`,
                                          }}
                                          onClick={() => {
                                            window.location.replace(
                                              `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
                                                'auth-token',
                                              )}&location=workorders&id=${
                                                wo.id
                                              }`,
                                            )
                                          }}
                                        >
                                          {wo.internalId}
                                        </Link>
                                      </td>
                                      <td>{wo.categories.join(', ')}</td>
                                      <td>{this.monetize(wo.amount)}</td>
                                      <td>{i18n.t('csv')}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>

                      <div id="internal">
                        <Link
                          to="#"
                          onClick={() => {
                            let css =
                              this.state.internalAccord === 'hidden'
                                ? 'visible'
                                : 'hidden'
                            this.setState({ internalAccord: css })
                          }}
                        >
                          <div className="title-toggle">
                            <h4>{i18n.t('internal')}</h4>
                          </div>
                        </Link>
                        <div className={internalAccord}>
                          {internal.length <= 0 ? (
                            <div>{i18n.t('noWorkOrdersFound')}</div>
                          ) : (
                            <table border="1" className="workOrderTable">
                              <thead>
                                <tr>
                                  <th>{i18n.t('invoiceId')}</th>
                                  <th>{i18n.t('category')}</th>
                                  <th>{i18n.t('total')}</th>
                                  <th>{i18n.t('exportToCsv')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {internal.map((wo) => {
                                  return (
                                    <tr key={wo.id}>
                                      <td>
                                        <Link
                                          to={{
                                            pathname: `/work-order/${wo.id}`,
                                          }}
                                          onClick={() => {
                                            window.location.replace(
                                              `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
                                                'auth-token',
                                              )}&location=workorders&id=${
                                                wo.id
                                              }`,
                                            )
                                          }}
                                        >
                                          {wo.internalId}
                                        </Link>
                                      </td>
                                      <td>{wo.categories.join(', ')}</td>
                                      <td>{this.monetize(wo.amount)}</td>
                                      <td>{i18n.t('csv')}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>

                      <div id="open">
                        <Link
                          to="#"
                          onClick={() => {
                            let css =
                              this.state.openAccord === 'hidden'
                                ? 'visible'
                                : 'hidden'
                            this.setState({ openAccord: css })
                          }}
                        >
                          <div className="title-toggle">
                            <h4>{i18n.t('open')}</h4>
                          </div>
                        </Link>
                        <div className={openAccord}>
                          {open.length <= 0 ? (
                            <div>{i18n.t('noWorkOrdersFound')}</div>
                          ) : (
                            <table border="1" className="workOrderTable">
                              <thead>
                                <tr>
                                  <th>{i18n.t('invoiceId')}</th>
                                  <th>{i18n.t('category')}</th>
                                  <th>{i18n.t('total')}</th>
                                  <th>{i18n.t('exportToCsv')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {open.map((wo) => {
                                  return (
                                    <tr key={wo.id}>
                                      <td>
                                        <Link
                                          to={{
                                            pathname: `/work-order/${wo.id}`,
                                          }}
                                          onClick={() => {
                                            window.location.replace(
                                              `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
                                                'auth-token',
                                              )}&location=workorders&id=${
                                                wo.id
                                              }`,
                                            )
                                          }}
                                        >
                                          {wo.internalId}
                                        </Link>
                                      </td>
                                      <td>{wo.categories.join(', ')}</td>
                                      <td>{this.monetize(wo.amount)}</td>
                                      <td>{i18n.t('csv')}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>

                      <div id="credited">
                        <Link
                          to="#"
                          onClick={() => {
                            let css =
                              this.state.creditedAccord === 'hidden'
                                ? 'visible'
                                : 'hidden'
                            this.setState({ creditedAccord: css })
                          }}
                        >
                          <div className="title-toggle">
                            <h4>{i18n.t('credited')}</h4>
                          </div>
                        </Link>
                        <div className={creditedAccord}>
                          {credited.length <= 0 ? (
                            <div>{i18n.t('noWorkOrdersFound')}</div>
                          ) : (
                            <table border="1" className="workOrderTable">
                              <thead>
                                <tr>
                                  <th>{i18n.t('invoiceId')}</th>
                                  <th>{i18n.t('category')}</th>
                                  <th>{i18n.t('total')}</th>
                                  <th>{i18n.t('exportToCsv')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {credited.map((wo) => {
                                  return (
                                    <tr key={wo.id}>
                                      <td>
                                        <Link
                                          to={{
                                            pathname: `/work-order/${wo.id}`,
                                          }}
                                          onClick={() => {
                                            window.location.replace(
                                              `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
                                                'auth-token',
                                              )}&location=workorders&id=${
                                                wo.id
                                              }`,
                                            )
                                          }}
                                        >
                                          {wo.internalId}
                                        </Link>
                                      </td>
                                      <td>{wo.categories.join(', ')}</td>
                                      <td>{this.monetize(wo.amount)}</td>
                                      <td>{i18n.t('csv')}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>

                      <div id="paid">
                        <Link
                          to="#"
                          onClick={() => {
                            let css =
                              this.state.paidAccord === 'hidden'
                                ? 'visible'
                                : 'hidden'
                            this.setState({ paidAccord: css })
                          }}
                        >
                          <div className="title-toggle">
                            <h4>{i18n.t('paid')}</h4>
                          </div>
                        </Link>
                        <div className={paidAccord}>
                          {paid.length <= 0 ? (
                            <div>{i18n.t('noWorkOrdersFound')}</div>
                          ) : (
                            <table border="1" className="workOrderTable">
                              <thead>
                                <th>{i18n.t('invoiceId')}</th>
                                <th>{i18n.t('category')}</th>
                                <th>{i18n.t('total')}</th>
                                <th>{i18n.t('exportToCsv')}</th>
                              </thead>
                              <tbody>
                                {paid.map((wo) => {
                                  return (
                                    <tr key={wo.id}>
                                      <td>
                                        <Link
                                          to={{
                                            pathname: `/work-order/${wo.id}`,
                                          }}
                                          onClick={() => {
                                            window.location.replace(
                                              `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
                                                'auth-token',
                                              )}&location=workorders&id=${
                                                wo.id
                                              }`,
                                            )
                                          }}
                                        >
                                          {wo.internalId}
                                        </Link>
                                      </td>
                                      <td>{wo.categories.join(', ')}</td>
                                      <td>{this.monetize(wo.amount)}</td>
                                      <td>{i18n.t('csv')}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                }}
              </Query>
            </div>
          )
        }}
      </Query>
    )
  }
}

InvoicedWorkOrders.defaultProps = {
  submittedAccord: 'hidden',
  batchedAccord: 'hidden',
  acceptedAccord: 'hidden',
  assignedAccord: 'hidden',
  uploadedAccord: 'hidden',
  invoicedAccord: 'hidden',
  disputedAccord: 'hidden',
  internalAccord: 'hidden',
  creditedAccord: 'hidden',
  openAccord: 'hidden',
  paidAccord: 'hidden',
}

InvoicedWorkOrders.propTypes = {
  submittedAccord: PropTypes.string,
  batchedAccord: PropTypes.string,
  acceptedAccord: PropTypes.string,
  assignedAccord: PropTypes.string,
  uploadedAccord: PropTypes.string,
  invoicedAccord: PropTypes.string,
  disputedAccord: PropTypes.string,
  internalAccord: PropTypes.string,
  creditedAccord: PropTypes.string,
  openAccord: PropTypes.string,
  paidAccord: PropTypes.string,
}

export default InvoicedWorkOrders
