import React, { Component } from 'react'
import { Query, Mutation } from 'react-apollo'
import { Redirect } from 'react-router-dom'
import { object } from 'prop-types'
import i18n from 'i18n-js'

import Button from './Button'
import DateField from './DateField'
import DropDown from './DropDown'
import NumberField from './NumberField'
import SignInUser from './SignInUser'
import TextArea from './TextArea'
import TextField from './TextField'

import { AUTH_TOKEN } from '../constants'

import {
  USER_TYPES,
} from '../dropDowns'

import {
  FETCH_CURRENT_USER,
  FETCH_DOCUMENT,
  UPDATE_DOCUMENT,
} from '../queries'

class Document extends Component {
  constructor(props) {
    super(props)

    const id = this.props.match.params.id

    this.state = {
      id: id,
      doc: null,
      startRange: null,
      endRange: null,
    }
  }

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser />

    const id = parseInt(this.state.id, 10)

    let doc = this.state.doc
    let startRange = this.state.startRange
    let endRange = this.state.endRange

    return(
      <Query query={FETCH_CURRENT_USER} variables={{token}}>
        {({loading, error, data}) => {
          if (loading) return <div>{i18n.t('fetching')}</div>
          if (error) return <div>{error.message}</div>

          const currentUser = data.currentUser

          if (currentUser.role !== 'admin') return <Redirect to='/' />

          return(
            <Query query={FETCH_DOCUMENT} variables={{ token, id }}>
              {({ loading, error, data }) => {
                if (loading) return <div>{i18n.t('fetching')}</div>
                if (error) return <div>{error.message}</div>

                if (doc === null) {
                  doc = data.document

                  startRange = doc.startRange.split(' ')[0]

                  if (doc.endRange !== null) {
                    endRange = doc.endRange.split(' ')[0]
                  }

                  this.setState({
                    doc,
                    startRange,
                    endRange,
                  })
                }

                return(
                  <div>
                    <h3>{i18n.t('editDocument')}</h3>
                    <TextField
                      id='name'
                      label={`${i18n.t('name')} *`}
                      placeholder={i18n.t('name')}
                      value={doc.name}
                    />
                    <div>
                      {i18n.t('documentWildcardExplanation')}
                    </div>
                    <TextArea
                      id='content'
                      label={`${i18n.t('content')} *`}
                      placeholder={i18n.t('content')}
                      content={doc.content}
                    />
                    <DateField
                      id='start-range'
                      label={`${i18n.t('startRange')} *`}
                      value={startRange}
                      onDateChange={(date) => {
                        startRange = date
                        doc.startRange = startRange
                        this.setState({ startRange, doc })
                      } }
                    />
                    <DateField
                      id='end-range'
                      label={i18n.t('endRange')}
                      value={endRange}
                      onDateChange={(date) => {
                        endRange = date
                        doc.endRange = endRange
                        this.setState({ endRange, doc })
                      } }
                    />
                    <DropDown
                      id='user-type'
                      options={USER_TYPES}
                      label={`${i18n.t('userType')} *`}
                      selected={doc.userType}
                      rawOptions={true}
                      allowNull={'false'}
                    />
                    <NumberField
                      id='length'
                      label={i18n.t('length')}
                      placeholder={i18n.t('length')}
                      value={doc.length}
                      min={1}
                    />
                    <Mutation mutation={UPDATE_DOCUMENT}>
                      {(updateDocument) => {
                        return(
                          <div>
                            <Button
                              id='update-document'
                              text={i18n.t('update')}
                              clickAction={() => {
                                let errorMsg = []

                                const nameField = document.getElementById('name')
                                const name = nameField.value

                                if (name === '') {
                                  errorMsg.push(i18n.t('nameError'))
                                  nameField.classList.add('error')
                                }

                                const contentField = document.getElementById('content')
                                const content = contentField.value

                                if (content === '') {
                                  errorMsg.push(i18n.t('contentError'))
                                  contentField.classList.add('error')
                                }

                                const startField = document.getElementById('start-range')

                                if (startRange === '') {
                                  errorMsg.push(i18n.t('startRangeError'))
                                  startField.classList.add('error')
                                }

                                if (errorMsg.length > 0) {
                                  window.flash(
                                    `${i18n.t('theFormHasTheFollowingIssues',
                                    )} ${errorMsg.join(', ')}.`,
                                    'error'
                                  )
                                  return ''
                                }

                                nameField.classList.remove('error')
                                contentField.classList.remove('error')
                                startField.classList.remove('error')

                                let sel = document.getElementById('user-type')

                                const lengthVal = document.getElementById('length').value

                                let length = null

                                if (lengthVal !== '') {
                                  length = parseInt(lengthVal, 10)
                                }

                                const userType = sel.options[sel.selectedIndex]['value'] 

                                updateDocument({
                                  variables: {
                                    token,
                                    id,
                                    name,
                                    content,
                                    startRange,
                                    endRange,
                                    userType,
                                    length,
                                  }
                                }).then(() => {
                                  window.flash(i18n.t('successfullyUpdatedDocument'), 'success')
                                })
                              } }
                            />
                          </div>
                        )
                      } }
                    </Mutation>
                  </div>
                )
              } }
            </Query>
          )
        } }
      </Query>
    )
  }
}

Document.defaultProps = {
  match: {},
}

Document.propTypes = {
  match: object,
}

export default Document
