import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Query } from 'react-apollo'
import i18n from 'i18n-js'

import SignInUser from './SignInUser'

import { FETCH_CURRENT_USER, FETCH_WORK_ORDERS_BY_STATE } from '../queries'

import { AUTH_TOKEN } from '../constants'
import SERVER_REDIRECTION_URL from '../config/config'

class ReviewWorkOrders extends Component {
  invoicePortal = (id) => {
    window.location.replace(
      `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
        'auth-token',
      )}&location=workorders&id=${id}`,
    )
  }
  render() {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser />

    return (
      <Query query={FETCH_CURRENT_USER} variables={{ token }}>
        {({ loading, error, data }) => {
          if (loading) return <div>{i18n.t('fetching')}</div>
          if (error) return <div>{error.message}</div>

          const currentUser = data['currentUser']

          if (currentUser['role'] !== 'admin') return <Redirect to="/" />

          return (
            <div>
              <h3>{i18n.t('submittedWorkOrders')}</h3>
              <Query
                query={FETCH_WORK_ORDERS_BY_STATE}
                variables={{ token, state: 'submitted' }}
              >
                {({ loading, error, data }) => {
                  if (loading) return <div>{i18n.t('fetching')}</div>
                  if (error) return <div>{error.message}</div>

                  let workOrders = data.allWorkOrdersByState

                  console.log(workOrders)

                  return (
                    <div>
                      {workOrders.map((wo) => {
                        return (
                          <div key={wo.id} className="work-order-block">
                            <div onClick={() => this.invoicePortal(wo.id)}>
                              <Link
                                onClick={() => {
                                  window.location.replace(
                                    `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
                                      'auth-token',
                                    )}&location=workorders&id=${wo.id}`,
                                  )
                                }}
                                to={`/work-order/${wo.id}`}
                              >
                                <strong>{wo.internalId}</strong>
                              </Link>
                            </div>
                            <div>
                              <strong>{i18n.t('vendor')}: </strong>
                              <Link to={`/customer/${wo.vendor.id}`}>
                                {wo.vendor.name}
                              </Link>
                            </div>
                            <hr />
                          </div>
                        )
                      })}
                    </div>
                  )
                }}
              </Query>
            </div>
          )
        }}
      </Query>
    )
  }
}

export default ReviewWorkOrders
