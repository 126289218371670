import React, { Component } from 'react'

import {
  AUTH_TOKEN,
} from '../constants'

import {
  FETCH_CURRENT_USER,
  FETCH_CUSTOMERS_FOR_CUSTOMER_REPORT,
  FETCH_CUSTOMERS_FROM_USER_GROUP,
} from '../queries'

import { Link, Redirect } from 'react-router-dom'
import { Query } from 'react-apollo'
import { createBrowserHistory } from 'history'
import '../styles/report.css'
import { object } from 'prop-types'
import i18n from 'i18n-js'

import ReportEquip from './ReportEquip'
import ReportSettings from './ReportSettings'
import ReportSummary from './ReportSummary'
import ReportWorkOrder from './ReportWorkOrder'
import SignInUser from './SignInUser'

class Report extends Component {
  constructor(props) {
    super(props)

    let today = new Date()

    const todayYear = today.getYear() + 1900
    const prevYear = todayYear - 1
    let month = String(today.getMonth() + 1)
    let day = String(today.getDate())

    if (month.length < 2) {
      month = '0' + month
    }
    if (day.length < 2) {
      day = '0' + day
    }

    const customerIds = []

    const selectable = props.match.params.id === undefined

    if (!selectable) {
      customerIds.push(parseInt(props.match.params.id, 10))
    }

    let history = createBrowserHistory()

    let selectedNav = 'report-settings'
    let woFilterEquipment = ''
    let woFilterServiceType = ''
    let woFilterWorkCategory = ''

    if (history.location.state !== null) {
      if (history.location.state.selectedNav !== undefined) {
        selectedNav = history.location.state.selectedNav
      }

      if (history.location.state.woFilterEquipment !== undefined) {
        woFilterEquipment = history.location.state.woFilterEquipment
      }

      if (history.location.state.woFilterServiceType !== undefined) {
        woFilterServiceType = history.location.state.woFilterServiceType
      }

      if (history.location.state.woFilterWorkCategory !== undefined) {
        woFilterWorkCategory = history.location.state.woFilterWorkCategory
      }
    }

    this.state = {
      customerIds: customerIds,
      settings: {
        startRange: prevYear + '-' + month + '-' + day,
        endRange: todayYear + '-' + month + '-' + day,
        sortBy: 'eobjAsc',
        woFilterEquipment: woFilterEquipment,
        woFilterServiceType: woFilterServiceType,
        woFilterWorkCategory: woFilterWorkCategory,
        selectedNav: selectedNav,
        selectable: selectable,
        initial: true,
      },
      payload: {},
      disallowedEquip: [],
      selectedCustomers: [],
      excludedWO: [],
    }
  }

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)
    const {
      customerIds,
      settings,
      disallowedEquip,
      selectedCustomers,
      excludedWO,
      payload,
    } = this.state

    if (token === null) return <SignInUser />

    let custIds = ''

    if (customerIds.length === 0) {
      customerIds.push(4810)
    }

    customerIds.map((cust) => { 
      if (custIds === '') {
        custIds = `${cust}`
      } else {
        custIds += `,${cust}`
      }    
      return ''
    })

    return (
      <Query query={FETCH_CURRENT_USER} variables={{token}}>
        {({loading, error, data}) => {
          if (loading) return <div>{i18n.t('fetching')}</div>
          if (error) return <div>{error.message}</div>

          const currentUser = data['currentUser']

          let query = FETCH_CUSTOMERS_FOR_CUSTOMER_REPORT
          let variables = {
            token,
            customerIds: custIds,
          }

          if (settings.selectable) {
            query = FETCH_CUSTOMERS_FROM_USER_GROUP
            variables = {
              token,
              vendorId: parseInt(currentUser['vendorId'], 10),
            }
          }

          return(
            <div>
              <Link
                to="#"
                onClick={() => {
                  settings.selectedNav = 'report-settings'
                  this.setState({ settings })
                }}
              >
                <div
                  id="cust-report-settings"
                  className={
                    settings.selectedNav === 'report-settings'
                      ? 'selectedNav localNav'
                      : 'localNav'
                  }
                >
                  {i18n.t('settings')}
                </div>
              </Link>
              <Link
                to="#"
                onClick={() => {
                  settings.selectedNav = 'report-summary'
                  this.setState({ settings })
                }}
              >
                <div
                  id="cust-report-summary"
                  className={
                    settings.selectedNav === 'report-summary'
                      ? 'selectedNav localNav'
                      : 'localNav'
                  }
                >
                  {i18n.t('summary')}
                </div>
              </Link>
              <Link
                to="#"
                onClick={() => {
                  settings.selectedNav = 'report-work-order'
                  this.setState({ settings })
                }}
              >
                <div
                  id="cust-report-work-order"
                   className={
                    settings.selectedNav === 'report-work-order'
                      ? 'selectedNav localNav'
                      : 'localNav'
                  }
                >
                  {i18n.t('workOrders')}
                </div>
              </Link>
              <Link
                to="#"
                onClick={() => {
                  settings.selectedNav = 'report-equipment'
                  this.setState({ settings })
                }}
              >
                <div
                  id="cust-report-equipment"
                  className={
                    settings.selectedNav === 'report-equipment'
                      ? 'selectedNav localNav'
                      : 'localNav'
                  }
                >
                  {i18n.t('equipment')}
                </div>
              </Link>

              <Query query={query} variables={variables}>
                {({ loading, error, data }) => {
                  if (loading) return <div>{i18n.t('processing')}</div>
                  if (error) return <div>{error.message}</div>

                  const customers = []
                  if (settings.selectable) {
                    data.customer.childRelationships.map((cr) => {
                      customers.push(cr.childCustomer)
                      if (settings.initial) {
                        selectedCustomers.push(cr.childCustomer.id)
                      }

                      return ''
                    })
                  } else {
                    data.customers.map((customer) => {
                      customers.push(customer)
                      if (settings.initial) {
                        selectedCustomers.push(customer.id)
                      }

                      return ''
                    })
                  }

                  if (settings.initial) {
                    settings.initial = false
                  }

                  if (customers.length === 0) {
                    return <Redirect to="/" />
                  }

                  let hpd = 8
                  let dpw = 5
                  let totalAvailability = 0

                  if (customers[0]) {
                    hpd = parseInt(customers[0].hpd, 10)
                    dpw = parseInt(customers[0].dpw, 10)
                  }

                  let deltaYear = 250
                  let deltaMonth = 21

                  if (dpw === 7) {
                    deltaYear = 365
                    deltaMonth = 30
                  } else if (dpw === 6) {
                    deltaYear = 307
                    deltaMonth = 25
                  }

                  payload.totalPm = 0.0
                  payload.totalDamage = 0.0
                  payload.totalTires = 0.0
                  payload.totalRepair = 0.0
                  payload.totalRequest = 0.0
                  payload.totalAttach = 0.0
                  payload.totalMc = 0.0
                  payload.totalCost = 0.0
                  payload.pmCph = 0.0
                  payload.damageCph = 0.0
                  payload.tiresCph = 0.0
                  payload.repairCph = 0.0
                  payload.requestCph = 0.0
                  payload.attachCph = 0.0
                  payload.mcCph = 0.0
                  payload.totalCph = 0.0
                  payload.averageFleetHours = 0.0
                  payload.hpd = hpd
                  payload.dpw = dpw
                  payload.equipment = []
                  payload.totalDowntime = 0.0
                  payload.numDays =
                    (settings.endRange.split('-')[0] -
                      settings.startRange.split('-')[0]) *
                      deltaYear +
                    (settings.endRange.split('-')[1] -
                      settings.startRange.split('-')[1]) *
                      deltaMonth +
                    (settings.endRange.split('-')[2] -
                      settings.startRange.split('-')[2])
                      payload.fleetSize = 0.0
                  payload.hoursInWindow = payload.hpd * payload.numDays
                  payload.actualHours = 0.0
                  payload.totalHours = 0.0
                  payload.uptime = 0.0
                  payload.utilizationHours = 0.0
                  payload.hasFleetNo = false
                  payload.tcCount = 0.0
                  payload.ecCount = 0.0
                  payload.pmCount = 0.0
                  payload.tmCount = 0.0
                  payload.averageFleetAvailability = 0.0
                  payload.accurateEquipCount = 0

                  let totalPmCph = 0.0
                  let totalDamageCph = 0.0
                  let totalTiresCph = 0.0
                  let totalRepairCph = 0.0
                  let totalRequestCph = 0.0
                  let totalAttachCph = 0.0
                  let totalTotalCph = 0.0
                  let totalMcCph = 0.0
                  let pmCount = 0.0
                  let repairCount = 0.0
                  let tiresCount = 0.0
                  let damageCount = 0.0
                  let requestCount = 0.0
                  let attachCount = 0.0
                  let mcCount = 0.0
                  let totalCount = 0.0
                  let totalEndHM = 0.0
                  let equipments = []

                  // const pmRay = ['CPM', 'ECP', 'FM', 'CPM-TM', 'RPM']

                  customers.map((customer) => {
                    if (!selectedCustomers.includes(customer.id)) {
                      return ''
                    }

                    let customerEquip = customer.equipment

                    switch (settings.sortBy) {
                      case 'eobjDesc':
                        customerEquip.sort((a, b) =>
                          a.vendorEquipment[0].vendorEquipmentId <
                          b.vendorEquipment[0].vendorEquipmentId
                            ? 1
                            : -1,
                        )
                        break
                      case 'makeAsc':
                        customerEquip.sort((a, b) => (a.make > b.make ? 1 : -1))
                        break
                      case 'makeDesc':
                        customerEquip.sort((a, b) => (a.make < b.make ? 1 : -1))
                        break
                      case 'modelAsc':
                        customerEquip.sort((a, b) => (a.model > b.model ? 1 : -1))
                        break
                      case 'modelDesc':
                        customerEquip.sort((a, b) => (a.model < b.model ? 1 : -1))
                        break
                      case 'careAsc':
                        customerEquip.sort((a, b) => {
                          let aVal = 0
                          let bVal = 0
                          switch (a.maintenanceLevel) {
                            case 'TC':
                              aVal = 4
                              break
                            case 'EC':
                              aVal = 3
                              break
                            case 'PM':
                              aVal = 2
                              break
                            default:
                              aVal = 1
                              break
                          }

                          switch (b.maintenanceLevel) {
                            case 'TC':
                              bVal = 4
                              break
                            case 'EC':
                              bVal = 3
                              break
                            case 'PM':
                              bVal = 2
                              break
                            default:
                              bVal = 1
                              break
                          }

                          if (aVal < bVal) {
                            return 1
                          } else {
                            return -1
                          }
                        })
                        break
                      case 'careDesc':
                        customerEquip.sort((a, b) => {
                          let aVal = 0
                          let bVal = 0
                          switch (a.maintenanceLevel) {
                            case 'TC':
                              aVal = 4
                              break
                            case 'EC':
                              aVal = 3
                              break
                            case 'PM':
                              aVal = 2
                              break
                            default:
                              aVal = 1
                              break
                          }

                          switch (b.maintenanceLevel) {
                            case 'TC':
                              bVal = 4
                              break
                            case 'EC':
                              bVal = 3
                              break
                            case 'PM':
                              bVal = 2
                              break
                            default:
                              bVal = 1
                              break
                          }

                          if (aVal > bVal) {
                            return 1
                          } else {
                            return -1
                          }
                        })
                        break
                      default:
                        customerEquip.sort((a, b) =>
                          a.vendorEquipment[0].vendorEquipmentId >
                          b.vendorEquipment[0].vendorEquipmentId
                            ? 1
                            : -1,
                        )
                        break
                    }

                    equipments = equipments.concat(customerEquip)

                    return ''
                  })

                  equipments.map((equip) => {
                    if (disallowedEquip.indexOf(equip.id) !== -1) {
                      return ''
                    }

                    let woInRange = 0

                    let workOrders = equip.workOrders
                    workOrders.sort((a, b) => (a.invoicedAt < b.invoicedAt ? 1 : -1))

                    let equipEntity = {
                      pmCost: 0.0,
                      damageCost: 0.0,
                      tiresCost: 0.0,
                      repairCost: 0.0,
                      requestCost: 0.0,
                      attachCost: 0.0,
                      totalCost: 0.0,
                      firstWorkOrder: workOrders[workOrders.length - 1],
                      lastWorkOrder: null,
                      totalDowntime: 0.0,
                      periodHours: 0.0,
                      annualHours: 0.0,
                      pmCount: 0.0,
                      avgPmInterval: 0.0,
                      mfcCount: 0.0,
                      avgMfcInterval: 0.0,
                      accuracyFlag: false,
                      mcCost: 0.0,
                    }

                    let previousPmHm = null
                    let previousMfcHm = null
                    let totalPmInterval = 0
                    let totalMfcInterval = 0

                    equip.maintenanceRecords.map((mc) => {
                      if (
                        mc.invoicedAt >= settings.startRange &&
                          mc.invoicedAt <= settings.endRange
                      ) {
                        equipEntity.mcCost += mc.amount
                      }

                      return ''
                    } )

                    workOrders.map((wo) => {
                      if (wo.customer.id !== equip.customer.id) {
                        return ''
                      }

                      if (excludedWO.indexOf(wo.id) !== -1) {
                        return ''
                      }

                      if (wo.archivedAt !== null) {
                        return ''
                      }

                      if (
                        wo.startedAt >= settings.startRange &&
                        wo.startedAt <= settings.endRange
                      ) {
                        if (equipEntity.lastWorkOrder === null) {
                          equipEntity.lastWorkOrder = wo
                        }

                        if (wo.downtime !== null) {
                          equipEntity.totalDowntime += parseInt(wo.downtime, 10)
                        }

                        if (wo.countAsPm) {
                          equipEntity.pmCount += 1
                          if (previousPmHm !== null) {
                            totalPmInterval += previousPmHm - wo.keyOnMeter
                          }
                          previousPmHm = wo.keyOnMeter
                        }

                        if (wo.countAsMfc) {
                          equipEntity.mfcCount += 1
                          if (previousMfcHm !== null) {
                            totalMfcInterval += previousMfcHm - wo.keyOnMeter
                          }
                          previousMfcHm = wo.keyOnMeter
                        }
                      }

                      if (
                        wo.invoicedAt >= settings.startRange &&
                        wo.invoicedAt <= settings.endRange
                      ) {

                        woInRange += 1
                  
                        if (
                          (wo.invoiceType === 'Internal') ||
                          (wo.invoiceType === 'Extra Care') ||
                          (wo.invoiceType === 'Total Care')
                        ) {
                          return ''
                        }

                        wo.lineItems.map((li) => {
                          let amount = parseFloat(li.amount)

                          switch (li.workCategory.value) {
                            case 'PM':
                              equipEntity.pmCost += amount
                              break
                            case 'Damage':
                              equipEntity.damageCost += amount
                              break
                            case 'Tires':
                              equipEntity.tiresCost += amount
                              break
                            case 'Repair':
                              equipEntity.repairCost += amount
                              break
                            case 'Request':
                              equipEntity.requestCost += amount
                              break
                            case 'Attachment':
                              equipEntity.attachCost += amount
                              break
                            default:
                              break
                          }
                          return ''
                        })
                      }

                      return ''
                    })

                    if ((woInRange === 0) && (equipEntity.mcCost === 0)) {
                      return ''
                    }

                    let wayback = settings.endRange.split('-')
                    let waybackDay = parseInt(wayback[2], 10)
                    let waybackMonth = 0
                    let waybackYear = 0

                    if (parseInt(wayback[1], 10) - 3 < 1) {
                      waybackMonth = 12 + (parseInt(wayback[1], 10) - 3)
                      waybackYear = parseInt(wayback[0], 10) - 2
                    } else {
                      waybackMonth = parseInt(wayback[1], 10) - 3
                      waybackYear = parseInt(wayback[0], 10) - 1
                    }

                    let oneLastCheck = false

                    if (!equipEntity.lastWorkOrder) {
                      equipEntity.lastWorkOrder = workOrders[0]
                      oneLastCheck = true
                    }

                    if (typeof equipEntity.lastWorkOrder === 'undefined') {
                      return ''
                    }

                    let lastWODate = equipEntity.lastWorkOrder.startedAt.split(' ')[0]
                    let lastWOYear = lastWODate.split('-')[0]
                    let lastWOMonth = lastWODate.split('-')[1]
                    let lastWODay = lastWODate.split('-')[2]

                    if (
                      oneLastCheck &&
                      (waybackYear > lastWOYear ||
                        (waybackYear === lastWOYear && waybackMonth > lastWOMonth) ||
                        (waybackYear === lastWOYear &&
                          waybackMonth === lastWOMonth &&
                          waybackDay > lastWODay))
                    ) {
                      return ''
                    }

                    let woPointer = workOrders.indexOf(equipEntity.lastWorkOrder)
                    let lateWO = equipEntity.lastWorkOrder

                    if (woPointer !== 0) {
                      lateWO = workOrders[woPointer - 1]
                    }

                    let deltaT = 1

                    let woMonth = 0
                    let woYear = 0
                    let woDay = 0

                    while (
                      deltaT <= payload.numDays * 1.2 &&
                      workOrders[woPointer + 1] !== undefined
                    ) {
                      woPointer += 1
                      let wo = workOrders[woPointer]
                      let woDate = wo.startedAt.split(' ')[0]
                      woYear = woDate.split('-')[0]
                      woMonth = woDate.split('-')[1]
                      woDay = woDate.split('-')[2]

                      deltaT =
                        (lastWOYear - woYear) * deltaYear +
                        (lastWOMonth - woMonth) * deltaMonth +
                        (lastWODay - woDay)
                    }

                    let earlyWO = workOrders[woPointer]

                    let lateKeyOnMeter = 0
                    let earlyKeyOnMeter = 0

                    if (lateWO.keyOnMeter !== null) {
                      lateKeyOnMeter = parseInt(lateWO.keyOnMeter, 10)
                    }

                    if (earlyWO.keyOnMeter !== null) {
                      earlyKeyOnMeter = parseInt(earlyWO.keyOnMeter, 10)
                    }

                    if (deltaT === 0) {
                      equipEntity.hoursPerDay = 0
                    } else {
                      equipEntity.hoursPerDay =
                        (lateKeyOnMeter - earlyKeyOnMeter) / deltaT
                    }

                    let daysAfterNA = 0
                    let daysBeforeNA = 0

                    if (
                      earlyWO === equipEntity.firstWorkOrder &&
                      earlyWO.startedAt >= settings.startRange
                    ) {
                      daysBeforeNA =
                        (woYear - settings.startRange.split('-')[0]) * deltaYear +
                        (woMonth - settings.startRange.split('-')[1]) * deltaMonth +
                        (woDay - settings.startRange.split('-')[2])
                    }

                    let rangeSinceLastWO =
                      (settings.endRange.split('-')[0] - lastWOYear) * deltaYear +
                      (settings.endRange.split('-')[1] - lastWOMonth) * deltaMonth +
                      (settings.endRange.split('-')[2] - lastWODay)

                    if (rangeSinceLastWO > deltaYear) {
                      daysAfterNA = rangeSinceLastWO - (3 * deltaMonth)
                    }

                    equipEntity.periodHours = parseInt(
                      (equipEntity.hoursPerDay *
                        (payload.numDays - daysBeforeNA - daysAfterNA)),
                      10,
                    )

                    if (equipEntity.periodHours > 0) {
                      payload.totalHours += equipEntity.periodHours
                      payload.accurateEquipCount += 1
                      equipEntity.accuracyFlag = true
                    }

                    equipEntity.annualHours = parseInt((equipEntity.hoursPerDay * deltaYear), 10)

                    if (equipEntity.pmCount > 1) {
                      equipEntity.avgPmInterval =
                        parseFloat(totalPmInterval) / equipEntity.pmCount
                    } else {
                      equipEntity.avgPmInterval = 0
                    }

                    if (equipEntity.mfcCount > 1) {
                      equipEntity.avgMfcInterval =
                        parseFloat(totalMfcInterval) / equipEntity.mfcCount
                    } else {
                      equipEntity.avgMfcInterval = 0
                    }

                    // Find the number of hours between earlyWO and beginning of window
                    let deltaHours =
                      ((woYear - settings.startRange.split('-')[0]) * deltaYear +
                        (woMonth - settings.startRange.split('-')[1]) * deltaMonth +
                        (woDay - settings.startRange.split('-')[2])) *
                      equipEntity.hoursPerDay

                    equipEntity.startHM = earlyKeyOnMeter - deltaHours

                    if (equipEntity.startHM < 0) {
                      equipEntity.startHM = 0
                    } else {
                      equipEntity.startHM = parseInt(equipEntity.startHM, 10)
                    }

                    equipEntity.endHM = parseInt(
                      (equipEntity.startHM + equipEntity.periodHours),
                      10,
                    )

                    totalEndHM += equipEntity.endHM

                    let utilization = 0

                    if (equip.plannedHours) {
                      utilization = equipEntity.annualHours / equip.plannedHours
                      if (equipEntity.accuracyFlag) {
                        payload.actualHours +=
                          (equip.plannedHours / (payload.hpd * payload.dpw * 50)) *
                          payload.numDays
                      }
                    } else {
                      utilization =
                        equipEntity.annualHours / (payload.hpd * payload.dpw * 50)

                      if (equipEntity.accuracyFlag) {
                        payload.actualHours += payload.hpd * payload.numDays
                      }
                    }

                    equipEntity.aur = (utilization * 100).toFixed(1) + '%'
                    equipEntity.availability =
                      (1 - equipEntity.totalDowntime / payload.hoursInWindow) * 100

                    if (equipEntity.accuracyFlag) {
                      totalAvailability += equipEntity.availability
                    }

                    equipEntity.totalCost =
                      equipEntity.pmCost +
                      equipEntity.damageCost +
                      equipEntity.tiresCost +
                      equipEntity.repairCost +
                      equipEntity.requestCost +
                      equipEntity.attachCost +
                      equipEntity.mcCost

                    payload.totalPm += equipEntity.pmCost
                    payload.totalDamage += equipEntity.damageCost
                    payload.totalTires += equipEntity.tiresCost
                    payload.totalRepair += equipEntity.repairCost
                    payload.totalRequest += equipEntity.requestCost
                    payload.totalAttach += equipEntity.attachCost
                    payload.totalMc += equipEntity.mcCost

                    equipEntity.pmCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.pmCost / equipEntity.periodHours
                        : null
                    equipEntity.repairCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.repairCost / equipEntity.periodHours
                        : null
                    equipEntity.tiresCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.tiresCost / equipEntity.periodHours
                        : null
                    equipEntity.damageCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.damageCost / equipEntity.periodHours
                        : null
                    equipEntity.requestCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.requestCost / equipEntity.periodHours
                        : null
                    equipEntity.attachCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.attachCost / equipEntity.periodHours
                        : null
                    equipEntity.totalCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.totalCost / equipEntity.periodHours
                        : null
                    equipEntity.mcCph =
                      equipEntity.periodHours > 0
                        ? equipEntity.mcCost / equipEntity.periodHours
                        : null

                    if (equipEntity.pmCost > 0 && equipEntity.periodHours > 0) {
                      totalPmCph += equipEntity.pmCost
                      pmCount += equipEntity.periodHours
                    }

                    if (equipEntity.repairCph > 0 && equipEntity.periodHours > 0) {
                      totalRepairCph += equipEntity.repairCost
                      repairCount += equipEntity.periodHours
                    }

                    if (equipEntity.tiresCph > 0 && equipEntity.periodHours > 0) {
                      totalTiresCph += equipEntity.tiresCost
                      tiresCount += equipEntity.periodHours
                    }

                    if (equipEntity.damageCph > 0 && equipEntity.periodHours > 0) {
                      totalDamageCph += equipEntity.damageCost
                      damageCount += equipEntity.periodHours
                    }

                    if (equipEntity.requestCph > 0 && equipEntity.periodHours > 0) {
                      totalRequestCph += equipEntity.requestCost
                      requestCount += equipEntity.periodHours
                    }

                    if (equipEntity.attachCph > 0 && equipEntity.periodHours > 0) {
                      totalAttachCph += equipEntity.attachCost
                      attachCount += equipEntity.periodHours
                    }

                    if (equipEntity.totalCph > 0 && equipEntity.periodHours > 0) {
                      totalTotalCph += equipEntity.totalCost
                      totalCount += equipEntity.periodHours
                    }

                    if (equipEntity.mcCph > 0 && equipEntity.periodHours > 0) {
                      totalMcCph += equipEntity.mcCost
                      mcCount += equipEntity.periodHours
                    }

                    payload.fleetSize += 1

                    switch (equip.maintenanceLevel) {
                      case 'TC':
                        payload.tcCount += 1
                        break
                      case 'EC':
                        payload.ecCount += 1
                        break
                      case 'PM':
                        payload.pmCount += 1
                        break
                      default:
                        payload.tmCount += 1
                        break
                    }

                    equipEntity.id = equip.id
                    equipEntity.vendorEquipId =
                      equip.vendorEquipment[0].vendorEquipmentId
                    equipEntity.customerId = equip.customer.id
                    equipEntity.customerName = equip.customer.internalName
                      ? equip.customer.internalName
                      : equip.customer.name
                    equipEntity.maintenanceLevel = equip.maintenanceLevel
                    equipEntity.make = equip.make
                    equipEntity.model = equip.model
                    equipEntity.serialNumber = equip.serialNumber
                    equipEntity.fleetNumber = equip.fleetNumber

                    if (equip.fleetNumber !== '' && equip.fleetNumber !== null) {
                      payload.hasFleetNo = true
                    }

                    equipEntity.hpy = 2000

                    payload.totalDowntime += equipEntity.totalDowntime

                    payload.equipment.push(equipEntity)

                    return ''
                  })

                  payload.totalCost =
                    payload.totalPm +
                    payload.totalRepair +
                    payload.totalTires +
                    payload.totalDamage +
                    payload.totalRequest +
                    payload.totalAttach +
                    payload.totalMc

                  payload.pmCph = pmCount === 0 ? 0.0 : totalPmCph / pmCount
                  payload.repairCph =
                    repairCount === 0 ? 0.0 : totalRepairCph / repairCount
                  payload.tiresCph =
                    tiresCount === 0 ? 0.0 : totalTiresCph / tiresCount
                  payload.damageCph =
                    damageCount === 0 ? 0.0 : totalDamageCph / damageCount
                  payload.requestCph =
                    requestCount === 0 ? 0.0 : totalRequestCph / requestCount
                  payload.attachCph =
                    attachCount === 0 ? 0.0 : totalAttachCph / attachCount
                  payload.mcCph =
                    mcCount === 0 ? 0.0 : totalMcCph / mcCount
                  payload.totalCph =
                    totalCount === 0 ? 0.0 : totalTotalCph / totalCount
                  // Filter equipment items based on period hours not equal to 'N/C' and greater than or equal to 0
                  const filteredEquipment = payload.equipment.filter(equip => {
                    return equip.periodHours !== 'N/C' && equip.periodHours >= 0;
                  });

                  // Calculate the sum of PM, Repair, Tires, Attachments, and Maintenance Contracts for filtered equipment
                  const sumCostWithoutDamageAndRequests = filteredEquipment.reduce((acc, equip) => {
                    return acc + (equip.pmCost || 0) +
                                (equip.repairCost || 0) +
                                (equip.tiresCost || 0) +
                                (equip.attachCost || 0) +
                                (equip.mcCost || 0);
                  }, 0);

                  // Calculate the total number of period hours
                  const sumPeriodHours = filteredEquipment.reduce((acc, equip) => acc + parseFloat(equip.periodHours), 0);

                  // Calculate the Cost Per Hour (CPH) without Damage and Requests
                  const cphWithoutDamageAndRequests = sumPeriodHours === 0 ? 0 : sumCostWithoutDamageAndRequests / sumPeriodHours;

                  // Assign the calculated CPH without Damage and Requests to adjustedCph in payload
                  payload.adjustedCph = totalCount === 0 ? 0.0 : cphWithoutDamageAndRequests;
                  
                  payload.averageFleetHours =
                    payload.accurateEquipCount === 0
                      ? 0
                      : parseInt((totalEndHM / payload.accurateEquipCount), 10)
                  payload.uptime =
                    100 *
                    ((payload.actualHours - payload.totalDowntime) /
                      payload.actualHours)
                  payload.utilizationHours =
                    100 * (payload.totalHours / payload.actualHours)
                  payload.averageFleetAvailability =
                    payload.accurateEquipCount === 0
                      ? 0
                      : totalAvailability / payload.accurateEquipCount

                  return (
                    <div>
                      {settings.selectedNav === 'report-settings' && (
                        <ReportSettings
                          customers={customers}
                          settings={settings}
                          excludedWO={excludedWO}
                          disallowedEquip={disallowedEquip}
                          history={this.props.history}
                          payload={payload}
                          selectedCustomers={selectedCustomers}
                        />
                      )}
                      {settings.selectedNav === 'report-summary' && (
                        <ReportSummary
                          customers={customers}
                          settings={settings}
                          payload={payload}
                          selectedCustomers={selectedCustomers}
                        />
                      )}
                      {settings.selectedNav === 'report-work-order' && (
                        <ReportWorkOrder
                          customers={customers}
                          settings={settings}
                          excludedWO={excludedWO}
                          disallowedEquip={disallowedEquip}
                          selectedCustomers={selectedCustomers}
                        />
                      )}
                      {settings.selectedNav === 'report-equipment' && (
                        <ReportEquip
                          customers={customers}
                          settings={settings}
                          disallowedEquip={disallowedEquip}
                          excludedWO={excludedWO}
                          selectedCustomers={selectedCustomers}
                        />
                      )}
                    </div>
                  )
                }}
              </Query>
            </div>
          )
        }}
      </Query>
    )
  }
}

Report.defaultProps = {
  match: {},
  history: {},
}

Report.propTypes = {
  match: object,
  history: object,
}

export default Report
