import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'

import TimeStamp from './TimeStamp'
import DateRange from './DateRange'

class AllSummaries extends Component {
  constructor(props) {
    super(props)
    this.state = {
      summaries: props.summaries,
      showHide: 'hidden',
    }
  }

  render() {
    const { summaries, showHide } = this.state

    summaries.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))

    return (
      <div>
        <Link to="#" onClick={this.toggleDisplay.bind(this)}>
          <div className="title-toggle">
            <h4>{i18n.t('summaries')}</h4>
          </div>
        </Link>
        {summaries.length <= 0 ? (
          <div className={showHide}>{i18n.t('noSummariesFound')}</div>
        ) : (
          <div className={showHide}>
            {summaries.map((summary) => {
              let customers = ''
              summary.customers.map((customer) => {
                if (customers === '') {
                  customers = `${customer.name}`
                } else {
                  customers += `, ${customer.name}`
                }
                return ''
              })

              return (
                <div key={summary.id} className="review-search-result">
                  <h3>
                    <Link to={{ pathname: `/summary/${summary.id}` }}>
                      {summary.title}
                    </Link>
                  </h3>
                  <ol>
                    <li>
                      <strong>{i18n.t('users').toUpperCase()}</strong>: &nbsp;
                      {`${summary.user.firstName} ${summary.user.lastName}`}
                    </li>
                    <li>
                      <strong>{i18n.t('customers').toUpperCase()}</strong>:
                      &nbsp;{customers}
                    </li>
                    <li>
                      <strong>{i18n.t('dateRange').toUpperCase()}</strong>:
                      &nbsp;
                      <DateRange
                        startRange={summary.startRange}
                        endRange={summary.endRange}
                      />
                    </li>
                    <li>
                      <strong>{i18n.t('created').toUpperCase()}</strong>: &nbsp;
                      <TimeStamp timeToFormat={summary.createdAt} />
                    </li>
                  </ol>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }

  toggleDisplay() {
    var css = this.state.showHide === 'hidden' ? 'visible' : 'hidden'
    this.setState({ showHide: css })
  }
}

AllSummaries.defaultProps = {
  summaries: {},
}

AllSummaries.propTypes = {
  summaries: PropTypes.array,
}

export default AllSummaries
