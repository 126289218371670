import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropType from 'prop-types'
import i18n from 'i18n-js'

class Pagination extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pageCount: props.pageCount || 1,
      currentPage: props.currentPage || 1,
    }
  }

  render() {
    const { pageCount, currentPage } = this.state

    const onFirst = currentPage === 1
    const onLast = currentPage === pageCount
    var pagination = []
    var minPage = currentPage - 4 < 1 ? 1 : currentPage - 4
    var maxPage = currentPage + 5 > pageCount ? pageCount : currentPage + 5
    var i

    pagination.push(
      <Link
        key="p"
        to={{
          pathname: `/reviews/p/${currentPage === 1 ? 1 : currentPage - 1}`,
        }}
      >
        <li id="prev-page" className={onFirst ? 'active' : ''}>
          {i18n.t('prev').toUpperCase()}
        </li>
      </Link>,
    )

    for (i = minPage; i <= maxPage; i++) {
      pagination.push(
        <Link key={i} to={{ pathname: `/reviews/p/${i}` }}>
          <li className={currentPage === i ? 'active' : ''}>{i}</li>
        </Link>,
      )
    }

    pagination.push(
      <Link key="n" to={{ pathname: `/reviews/p/${currentPage + 1}` }}>
        <li id="next-page" className={onLast ? 'active' : ''}>
          {i18n.t('next').toUpperCase()}
        </li>
      </Link>,
    )

    return <ul id="pagination">{pageCount > 1 ? pagination : ''}</ul>
  }
}

Pagination.defaultProps = {
  pageCount: 1,
  currentPage: 1,
}

Pagination.propTypes = {
  pageCount: PropType.number,
  currentPage: PropType.number,
}

export default Pagination
