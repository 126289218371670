const currentUrl = window.location.href
let SERVER_REDIRECTION_URL

if (currentUrl.includes('sandbox')) {
  SERVER_REDIRECTION_URL = 'https://uat.transparencyfleet.com/#/token'
} else if (!currentUrl.includes('sandbox')) {
  SERVER_REDIRECTION_URL = 'https://ui.transparencyfleet.com/#/token'
} else {
  SERVER_REDIRECTION_URL = 'https://uat.transparencyfleet.com/#/token'
}

export default SERVER_REDIRECTION_URL
