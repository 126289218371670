export const SERVICE_TYPES = [
  { key: 1, value: 'Attachment', name: 'attachment' },
  { key: 2, value: 'Damage', name: 'damage' },
  { key: 3, value: 'MFC', name: 'majorFluidChange' },
  { key: 4, value: 'PM', name: 'plannedMaintenance' },
  { key: 5, value: 'Repair', name: 'repair' },
  { key: 6, value: 'Request', name: 'request' },
  { key: 7, value: 'Tires', name: 'tires' },
]

export const INVOICE_TYPES = [
  { key: 1, value: 'Customer', name: 'customer' },
  { key: 2, value: 'Total Care', name: 'totalCare' },
  { key: 3, value: 'Extra Care', name: 'extraCare' },
  { key: 4, value: 'Internal', name: 'internal' },
]

export const PRODUCT_GROUPS = [
  { key: 1, value: 'PARTS', name: 'parts' },
  { key: 2, value: 'LABOR', name: 'labor' },
  { key: 3, value: 'TAXES', name: 'taxes' },
  { key: 4, value: 'OTHER', name: 'other' },
]

export const CATEGORIES = [
  { key: 1, value: 'I', name: 'I' },
  { key: 2, value: 'II', name: 'II' },
  { key: 3, value: 'III', name: 'III' },
  { key: 4, value: 'IV', name: 'IV' },
  { key: 5, value: 'V', name: 'V' },
]

export const EQUIPMENT_GROUPS = [
  { key: 1, value: 'Forklift', name: 'forklift' },
  { key: 2, value: 'Spotter', name: 'spotter' },
  { key: 3, value: 'Aerial Equipment', name: 'aerialEquipment' },
  { key: 4, value: 'Other', name: 'other' },
]

export const MAINTENANCE_LEVELS = [
  { key: 1, value: 'TM', name: 'timeAndMaterial' },
  { key: 2, value: 'PM', name: 'plannedMaintenance' },
  { key: 3, value: 'EC', name: 'extraCare' },
  { key: 4, value: 'TC', name: 'totalCare' },
]

export const CONTRACT_TYPES = [
  { key: 1, value: 'Cost Plus', name: 'costPlus' },
  { key: 2, value: 'Cost Plus - Repair', name: 'costPlusRepair' },
  { key: 3, value: 'Monthly', name: 'monthly' },
]

export const AMOUNT_TYPES = [
  { key: 1, value: 'Percentage', name: 'percentage' },
  { key: 2, value: 'Flat', name: 'flat' },
  { key: 3, value: 'Flat Per Invoice', name: 'flatPerInvoice' },
  { key: 4, value: 'Flat Per Equipment', name: 'flatPerEquipment' },
]

export const INVOICE_RATES = [
  { key: 1, value: 'Weekly', name: 'weekly' },
  { key: 2, value: 'Bimonthly', name: 'bimonthly' },
  { key: 3, value: 'Monthly', name: 'monthly' },
]

export const PAYMENT_RATES = [
  { key: 1, value: 'Invoice', name: 'invoice' },
  { key: 2, value: 'Monthly', name: 'monthly' },
  { key: 3, value: 'Quarterly', name: 'quarterly' },
  { key: 4, value: 'Yearly', name: 'yearly' },
  { key: 5, value: 'MultiYearly', name: 'multiyearly' },
]

export const REPORT_TYPES = [
  { key: 1, value: 'pdf', name: 'pdf' },
  { key: 2, value: 'csv', name: 'csv' },
]

export const USER_TYPES = [
  { key: 1, value: 'admin', name: 'Admin' },
  { key: 2, value: 'customer', name: 'External Customer' },
  { key: 3, value: 'user', name: 'Fleet Director' },
  { key: 4, value: 'service', name: 'Service Manager' },
  { key: 5, value: 'vendor', name: 'Vendor' },
]
