import React, { Component } from 'react'
import { Query, Mutation } from 'react-apollo'
import PropType, { object } from 'prop-types'
import i18n from 'i18n-js'

import Button from './Button'
import DropDown from './DropDown'
import CurrencyField from './CurrencyField'
import NumberField from './NumberField'
import TextField from './TextField'

import { AUTH_TOKEN } from '../constants'
import { PRODUCT_GROUPS } from '../dropDowns'

import {
  DESTROY_LINE_ITEM,
  UPDATE_LINE_ITEM,
  FETCH_ALL_WORK_SYSTEMS,
  FETCH_ALL_WORK_CATEGORIES,
  SPLIT_LINE_ITEM,
} from '../queries'

class LineItem extends Component {
  constructor(props) {
    super(props)

    var attachValue = 0
    var damageValue = 0
    var pmValue = 0
    var repairValue = 0
    var requestValue = 0
    var tireValue = 0

    switch (props.workCategory) {
      case 1:
        attachValue = props.amount
        break
      case 2:
        damageValue = props.amount
        break
      case 3:
        pmValue = props.amount
        break
      case 4:
        repairValue = props.amount
        break
      case 5:
        requestValue = props.amount
        break
      case 6:
        tireValue = props.amount
        break
      default:
    }

    this.state = {
      id: props.id,
      currentUser: props.currentUser,
      productGroup: props.productGroup,
      quantity: props.quantity,
      description: props.description,
      unitPrice: props.unitPrice,
      amount: parseFloat(props.amount).toFixed(2),
      workSystem: props.workSystem,
      workCategory: props.workCategory,
      tax: props.tax,
      internalId: props.internalId,
      remainingAvailable: 0,
      attachValue: attachValue,
      damageValue: damageValue,
      pmValue: pmValue,
      repairValue: repairValue,
      requestValue: requestValue,
      tireValue: tireValue,
    }
  }

  prevent(event) {
    event.preventDefault()
  }

  preventDelete(event) {
    var keyId = event.keyCode
    if (keyId === 8) {
      event.preventDefault()
    }
  }

  changeValue(event) {
    const categoryToChange = event.target.id.split('-')[1]
    let value = 0.0
    if (event.target.value !== '') {
      value = parseFloat(event.target.value).toFixed(2)
    }

    var previousValue = 0

    switch (categoryToChange) {
      case 'attachment':
        previousValue = parseFloat(this.state.attachValue)

        this.setState({ attachValue: value })
        break
      case 'damage':
        previousValue = parseFloat(this.state.damageValue)

        this.setState({ damageValue: value })
        break
      case 'pm':
        previousValue = parseFloat(this.state.pmValue)
        this.setState({ pmValue: value })
        break
      case 'repair':
        previousValue = parseFloat(this.state.repairValue)
        this.setState({ repairValue: value })
        break
      case 'request':
        previousValue = parseFloat(this.state.requestValue)
        this.setState({ requestValue: value })
        break
      case 'tires':
        previousValue = parseFloat(this.state.tireValue)
        this.setState({ tireValue: value })
        break
      default:
    }

    const delta = parseFloat(
      parseFloat(this.state.remainingAvailable) + (previousValue - value),
    ).toFixed(2)

    this.setState({ remainingAvailable: delta })

    return ''
  }

  render() {
    const {
      id,
      currentUser,
      productGroup,
      quantity,
      remainingAvailable,
      description,
      unitPrice,
      amount,
      tax,
      internalId,
      workSystem,
      workCategory,
      attachValue,
      damageValue,
      pmValue,
      repairValue,
      requestValue,
      tireValue,
    } = this.state

    const token = localStorage.getItem(AUTH_TOKEN)

    return (
      <tr key={id} id={`line-item-${id}`}>
        <td>
          <DropDown
            id={'product-group-' + id}
            options={PRODUCT_GROUPS}
            selected={productGroup}
            allowNull='false'
          />
        </td>
        {
          currentUser['role'] === 'admin' &&
          <td>
            <TextField
              id={'internal-id-' + id}
              placeholder={i18n.t('internalId')}
              value={internalId}
            />
          </td>
        }
        {
          currentUser['role'] === 'admin' ?
          <td>
            <TextField
              id={'description-' + id}
              placeholder={i18n.t('description')}
              value={description}
            />
          </td> :
          <td>{description}</td>
        }
        <td>
          <NumberField
            id={'quantity-' + id}
            placeholder={i18n.t('quantity')}
            value={quantity}
            min={0}
          />
        </td>
        <td>
          <CurrencyField
            id={'unit-price-' + id}
            placeholder={i18n.t('unitPrice')}
            value={unitPrice}
          />
        </td>
        <td>
          <CurrencyField
            id={'tax-' + id}
            placeholder={i18n.t('tax')}
            value={tax}
          />
        </td>
        <td>
          <CurrencyField
            id={'amount-' + id}
            placeholder={i18n.t('amount')}
            value={amount}
          />
        </td>
        <td>
          <Query query={FETCH_ALL_WORK_SYSTEMS} variables={{ token }}>
            {({ loading, error, data }) => {
              if (loading) return <span>{i18n.t('processing')}</span>
              if (error) return <span>{error.message}</span>

              let workSystems = []
              for (
                let i = 0, length = data.allWorkSystems.length;
                i < length;
                i++
              ) {
                workSystems.push({
                  key: data.allWorkSystems[i].id,
                  value: data.allWorkSystems[i].id,
                  name: data.allWorkSystems[i].value,
                })
              }
              return (
                <DropDown
                  id={'work-system-' + id}
                  options={workSystems}
                  selected={workSystem}
                />
              )
            }}
          </Query>
        </td>
        <td>
          <Query query={FETCH_ALL_WORK_CATEGORIES} variables={{ token }}>
            {({ loading, error, data }) => {
              if (loading) return <span>{i18n.t('processing')}</span>
              if (error) return <span>{error.message}</span>

              let workCategories = []
              for (
                let i = 0, length = data.allWorkCategories.length;
                i < length;
                i++
              ) {
                workCategories.push({
                  key: data.allWorkCategories[i].id,
                  value: data.allWorkCategories[i].id,
                  name: data.allWorkCategories[i].value.toLowerCase(),
                })
              }
              return (
                <DropDown
                  id={'work-category-' + id}
                  options={workCategories}
                  selected={workCategory}
                  allowNull="false"
                />
              )
            }}
          </Query>
        </td>
        <td>
          {productGroup === 'LABOR' ? (
            <Button
              id={'split-line-item-' + this.state.id}
              text={i18n.t('split')}
              clickAction={() => {
                document
                  .getElementById('modal-' + id)
                  .classList.remove('hidden')
              }}
            />
          ) : (
            ''
          )}
        </td>
        <td>
          <Mutation mutation={UPDATE_LINE_ITEM}>
            {(updateLineItem, { loading, error }) => {

              return (
                <div>
                  <Button
                    id={`update-line-item-${id}`}
                    text={i18n.t('update')}
                    clickAction={() => {
                      const token = localStorage.getItem(AUTH_TOKEN)

                      const liId = parseInt(this.state.id, 10)
                      let pgSel = document.getElementById(
                        'product-group-' + liId,
                      )
                      let newProductGroup = productGroup
                      if (pgSel.options[pgSel.selectedIndex].text !== '') {
                        newProductGroup =
                          pgSel.options[pgSel.selectedIndex]['value']
                      }
                      let newInternalId = internalId
                      if (
                        (currentUser['role'] === 'admin') &&
                        (document.getElementById('internal-id-' + liId).value !== '')
                      ) {
                        newInternalId = document.getElementById('internal-id-' + liId).value
                      }
                      let newQuantity = quantity
                      if (
                        document.getElementById('quantity-' + liId).value !==
                        null
                      ) {
                        newQuantity = parseFloat(
                          document.getElementById('quantity-' + liId).value,
                        )
                      }
                      let newUnitPrice = unitPrice
                      if (
                        document.getElementById('unit-price-' + liId).value !==
                        null
                      ) {
                        newUnitPrice = parseFloat(
                          document.getElementById('unit-price-' + liId).value,
                        )
                      }
                      let newTax = tax
                      if ( 
                          (currentUser['role'] === 'admin') &&
                          ( document.getElementById('tax-' + liId).value !== null )
                      ) {
                        newTax = parseFloat(
                          document.getElementById('tax-' + liId).value,
                        )
                      }
                      let newAmount = amount
                      if (
                        document.getElementById('amount-' + liId).value !== null
                      ) {
                        newAmount = parseFloat(
                          document.getElementById('amount-' + liId).value,
                        )
                      }
                      let wsSel = document.getElementById('work-system-' + liId)
                      let newWorkSystem = null
                      if (wsSel.options[wsSel.selectedIndex].text !== '') {
                        newWorkSystem = parseInt(
                          wsSel.options[wsSel.selectedIndex]['value'],
                          10,
                        )
                      }
                      let wcSel = document.getElementById(
                        'work-category-' + liId,
                      )
                      let newWorkCategory = null
                      if (wcSel.options[wcSel.selectedIndex].text !== '') {
                        newWorkCategory = parseInt(
                          wcSel.options[wcSel.selectedIndex]['value'],
                          10,
                        )
                      }
                      let newDescription = description
                      if (
                           (currentUser['role'] === 'admin') &&
                           (document.getElementById('description-' + liId).value !== null)
                      ) {
                        newDescription = document.getElementById('description-' + liId).value
                      }

                      updateLineItem({
                        variables: {
                          token: token,
                          id: liId,
                          productGroup: newProductGroup,
                          quantity: newQuantity,
                          unitPrice: newUnitPrice,
                          amount: newAmount,
                          tax: newTax,
                          internalId: newInternalId,
                          workSystemId: newWorkSystem,
                          workCategoryId: newWorkCategory,
                          description: newDescription,
                        },
                      }).then(() => {
                        window.flash(i18n.t('updatedLineItem'), 'success')
                      })
                    }}
                  />
                  {loading && <p>{i18n.t('processing')}</p>}
                  {error && <p>{error.message}</p>}
                </div>
              )
            }}
          </Mutation>
        </td>
        { currentUser['role'] === 'admin' &&
          <td>
            <Button
              id={`destroy-line-item-${id}`}
              text={i18n.t('delete')}
              klass="destroy"
              clickAction={() => {
                document.getElementById(`delete-modal-${id}`).classList.remove('hidden')
              } }
            />
          </td>
        }
        { currentUser['role'] === 'admin' &&
          <td>
            <div id={`delete-modal-${id}`} className="modal hidden">
              <div className="modal-content delete-modal">
                <div className="modal-header">
                  <span
                    className="close"
                    onClick={() => {
                      document.getElementById(`delete-modal-${id}`).classList.add('hidden')
                    } }
                  >
                    &times;
                  </span>
                  <h2>{i18n.t('areYouSure')}</h2>
                </div>
                <div className="modal-body">
                  <div>
                    {i18n.t('confirmDeleteLineItem', {description: description})}
                    <div>
                      <div className='splitButtons'>
                        <Mutation mutation={DESTROY_LINE_ITEM}>
                          {(destroyLineItem) => {
                            return(
                              <div>
                                <Button
                                  id={`confirm-delete-${id}`}
                                  text={i18n.t('confirm')}
                                  klass='destroy'
                                  clickAction={() => {
                                    const token = localStorage.getItem(AUTH_TOKEN)
                                    destroyLineItem({
                                      variables: {
                                        token,
                                        id,
                                      }
                                    }).then(() => {
                                      window.flash(i18n.t('deletedLineItem'), 'success')
                                      document.getElementById(`line-item-${id}`).classList.add('hidden')
                                      document.getElementById(`delete-modal-${id}`).classList.add('hidden')
                                    })
                                  } }
                                />
                              </div>
                            )
                          } }
                        </Mutation>
                      </div>
                      <div className='splitButtons'>
                        <Button
                          id={`cancel-delete-${id}`}
                          text={i18n.t('cancel')}
                          clickAction={() => {
                            document
                              .getElementById('delete-modal-' + id)
                              .classList.add('hidden')
                          } }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        }
        <td>
          <div id={'modal-' + id} className="modal hidden">
            <div className="modal-content">
              <div className="modal-header">
                <span
                  className="close"
                  onClick={() => {
                    document
                      .getElementById('modal-' + id)
                      .classList.add('hidden')
                  }}
                >
                  &times;
                </span>
                <h2>{i18n.t('splitLineItem')}</h2>
              </div>
              <div className="modal-body">
                <table>
                  <thead>
                    <tr>
                      <th>{i18n.t('workCategory')}</th>
                      <th>{i18n.t('amount')}</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{i18n.t('total').toUpperCase()}</td>
                      <td>${amount}</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>{i18n.t('attachment')}</td>
                      <td>
                        <div className="field">
                          <input
                            id={'split-attachment-' + id}
                            min={0}
                            max={
                              parseFloat(attachValue) +
                              parseFloat(remainingAvailable)
                            }
                            value={attachValue}
                            className="text-field"
                            type="number"
                            onChange={this.changeValue.bind(this)}
                          />
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>{i18n.t('damage')}</td>
                      <td>
                        <div className="field">
                          <input
                            id={'split-damage-' + id}
                            min={0}
                            max={
                              parseFloat(damageValue) +
                              parseFloat(remainingAvailable)
                            }
                            value={damageValue}
                            className="text-field"
                            type="number"
                            onChange={this.changeValue.bind(this)}
                          />
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>{i18n.t('pm')}</td>
                      <td>
                        <div className="field">
                          <input
                            id={'split-pm-' + id}
                            min={0}
                            max={
                              parseFloat(pmValue) +
                              parseFloat(remainingAvailable)
                            }
                            value={pmValue}
                            className="text-field"
                            type="number"
                            onChange={this.changeValue.bind(this)}
                          />
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>{i18n.t('repair')}</td>
                      <td>
                        <div className="field">
                          <input
                            id={'split-repair-' + id}
                            min={0}
                            max={
                              parseFloat(repairValue) +
                              parseFloat(remainingAvailable)
                            }
                            value={repairValue}
                            className="text-field"
                            type="number"
                            onChange={this.changeValue.bind(this)}
                          />
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>{i18n.t('request')}</td>
                      <td>
                        <div className="field">
                          <input
                            id={'split-request-' + id}
                            min={0}
                            max={
                              parseFloat(requestValue) +
                              parseFloat(remainingAvailable)
                            }
                            value={requestValue}
                            className="text-field"
                            type="number"
                            onChange={this.changeValue.bind(this)}
                          />
                        </div>
                      </td>
                      <td></td>
                      <td>
                        <label>{i18n.t('amountAvailable')}</label>
                      </td>
                    </tr>
                    <tr>
                      <td>{i18n.t('tires')}</td>
                      <td>
                        <div className="field">
                          <input
                            id={'split-tires-' + id}
                            min={0}
                            max={
                              parseFloat(tireValue) +
                              parseFloat(remainingAvailable)
                            }
                            value={tireValue}
                            className="text-field"
                            type="number"
                            onChange={this.changeValue.bind(this)}
                          />
                        </div>
                      </td>
                      <td></td>
                      <td>{remainingAvailable}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Mutation mutation={SPLIT_LINE_ITEM}>
                {(splitLineItem, { loading, error }) => {
                  return (
                    <div className="modal-footer">
                      <Button
                        id="submit-split"
                        text={i18n.t('submit')}
                        clickAction={() => {
                          const remainingAvailable = this.state
                            .remainingAvailable
                          // prevent submit action if quantity is still available
                          if (remainingAvailable !== '0.00') {
                            window.flash(
                              i18n.t('cannotSaveWhileRemainingAmount'),
                              'error',
                            )
                            return ''
                          }

                          const token = localStorage.getItem(AUTH_TOKEN)
                          const id = parseInt(this.state.id, 10)

                          const attachment = parseFloat(
                            document.getElementById('split-attachment-' + id)
                              .value,
                          )
                          const damage = parseFloat(
                            document.getElementById('split-damage-' + id).value,
                          )
                          const pm = parseFloat(
                            document.getElementById('split-pm-' + id).value,
                          )
                          const repair = parseFloat(
                            document.getElementById('split-repair-' + id).value,
                          )
                          const request = parseFloat(
                            document.getElementById('split-request-' + id)
                              .value,
                          )
                          const tires = parseFloat(
                            document.getElementById('split-tires-' + id).value,
                          )
                          splitLineItem({
                            variables: {
                              token: token,
                              id: id,
                              attachment: attachment,
                              damage: damage,
                              pm: pm,
                              repair: repair,
                              request: request,
                              tires: tires,
                            },
                          }).then(() => {
                            document.location.reload()
                          })
                        }}
                      />
                      {loading && <p>processing...</p>}
                      {error && <p>{error.message}</p>}
                    </div>
                  )
                }}
              </Mutation>
            </div>
          </div>
        </td>
      </tr>
    )
  }
}

LineItem.defaultProps = {
  workCategory: {},
  amount: {},
  id: {},
  productGroup: {},
  quantity: {},
  description: {},
  unitPrice: {},
  workSystem: {},
  tax: {},
  internalId: {},
  currentUser: null,
}

LineItem.propTypes = {
  workCategory: PropType.number,
  amount: PropType.number,
  id: PropType.number,
  productGroup: PropType.string,
  quantity: PropType.number,
  description: PropType.string,
  unitPrice: PropType.number,
  workSystem: object,
  tax: PropType.number,
  internalId: PropType.string,
  currentUser: object,
}

export default LineItem
