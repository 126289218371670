import React from 'react'
import PropTypes, { object } from 'prop-types'

const TimeStamp = ({ timeToFormat } = {}) => {
  const oldDate = new Date(timeToFormat)

  const newDate = new Date(oldDate.getTime() + oldDate.getTimezoneOffset() * 60 * 1000)

  const daysAgo = `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`

  /*
		const oneDay = 24 * 60 * 60 * 1000
		const today = new Date()
		const diffDays = Math.floor(Math.abs((oldDate - today) / oneDay))

		let daysAgo = ''
		if (diffDays < 30) {
			if (diffDays === 1) {
				daysAgo = "1 Day Ago"
			} else if (diffDays === 0) {
				daysAgo = "Today"
			} else {
				daysAgo = diffDays + " Days Ago"
			}
		} else {
			daysAgo = String(oldDate.getMonth() + 1) + "/" + oldDate.getDate() + "/" + oldDate.getFullYear()
		}
	*/

  return <span>{daysAgo}</span>
}

TimeStamp.propTypes = {
  timeToFormat: PropTypes.oneOfType([PropTypes.string, object])
}

export default TimeStamp
