import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'

import TimeStamp from './TimeStamp'

class AllLogs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      logs: props.logs,
      showHide: 'hidden',
    }
  }

  render() {
    const { logs, showHide } = this.state

    return (
      <div>
        <Link to="#" onClick={this.toggleDisplay.bind(this)}>
          <div className="title-toggle">
            <h4>{i18n.t('logs')}</h4>
          </div>
        </Link>
        {logs.length <= 0 ? (
          <div className={showHide}>{i18n.t('noLogsFound')}</div>
        ) : (
          <div className={showHide}>
            {logs.map((log) => (
              <div key={log.id} className="log-item">
                {log.oldValue === null || log.oldValue === '' ? (
                  <div>
                    <h4>{i18n.t('instantiatedValue')}</h4>
                    <div className="fieldContainer">
                      <div className="fieldLabel">
                        <label>{i18n.t('fieldName')}</label>
                      </div>
                      {log.fieldName}
                    </div>
                    <div className="fieldContainer">
                      <div className="fieldLabel">
                        <label>{i18n.t('value')}</label>
                      </div>
                      {log.newValue}
                    </div>
                    <div className="fieldContainer">
                      <div className="fieldLabel">
                        <label>{i18n.t('instantiated')}</label>
                      </div>
                      <TimeStamp timeToFormat={log.endedAt} />
                    </div>
                  </div>
                ) : (
                  <div>
                    <h4>{i18n.t('changedValue')}</h4>
                    <div className="fieldContainer">
                      <div className="fieldLabel">
                        <label>{i18n.t('fieldName')}</label>
                      </div>
                      {log.fieldName}
                    </div>
                    <div className="fieldContainer">
                      <div className="fieldLabel">
                        <label>{i18n.t('oldValue')}</label>
                      </div>
                      {log.oldValue}
                    </div>
                    <div className="fieldContainer">
                      <div className="fieldLabel">
                        <label>{i18n.t('newValue')}</label>
                      </div>
                      {log.newValue}
                    </div>
                    <div className="fieldContainer">
                      <div className="fieldLabel">
                        <label>{i18n.t('oldValueStarted')}</label>
                      </div>
                      <TimeStamp timeToFormat={log.startedAt} />
                    </div>
                    <div className="fieldContainer">
                      <div className="fieldLabel">
                        <label>{i18n.t('changedToNewValue')}</label>
                      </div>
                      <TimeStamp timeToFormat={log.endedAt} />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }

  toggleDisplay() {
    var css = this.state.showHide === 'hidden' ? 'visible' : 'hidden'
    this.setState({ showHide: css })
  }
}

AllLogs.defaultProps = {
  logs: [],
}

AllLogs.propTypes = {
  logs: PropTypes.array,
}

export default AllLogs
