import React, { Component } from 'react'
import { Query, Mutation } from 'react-apollo'
import { object } from 'prop-types'
import i18n from 'i18n-js'
import { Redirect } from 'react-router-dom'

import AllNotes from './AllNotes'
import Button from './Button'
import DropDown from './DropDown'
import SignInUser from './SignInUser'

import {
  AUTH_TOKEN
} from '../constants'

import {
  ASSIGN_BUG_REPORT_TO_USER,
  CLOSE_BUG_REPORT,
  FETCH_BUG_REPORT,
  FETCH_CURRENT_USER,
  FETCH_USER_GROUP,
} from '../queries'

class BugReport extends Component {
  constructor(props) {
    super(props)

    const id = props.match.params.id

    this.state = {
      id
    }
  }

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser />

    const id = parseInt(this.state.id, 10)

    return(
      <Query query={FETCH_CURRENT_USER} variables={{token}}>
        {({ loading, error, data }) => {
          if (loading) return <div>{i18n.t('processing')}</div>
          if (error) return <div>{error.message}</div>

          const currentUser = data['currentUser']

          return (
            <Query query={FETCH_BUG_REPORT} variables={{token, id}}>
              {({ loading, error, data }) => {
                if (loading) return <div>{i18n.t('processing')}</div>
                if (error) return <div>{error.message}</div>

                const bugReport = data.bugReport

                if ( (currentUser['role'] !== 'admin') && (parseInt(bugReport.reporter.id, 10) !== currentUser['id']) ) {
                  return <Redirect to="/" />
                }

                return(
                  <div>
                    <h3>{i18n.t('bugReportId', {id: bugReport.id })}</h3>
                    <div>
                      <strong>{i18n.t('description')}: </strong>
                      {bugReport.description}
                    </div>
                    <div>
                      <strong>{i18n.t('status')}: </strong>
                      <span id="state">{bugReport.aasmState}</span>
                    </div>
                    <div>
                      <strong>{i18n.t('reporter')}</strong>
                      {`${bugReport.reporter.firstName} ${bugReport.reporter.lastName}`}
                    </div>
                    <div>
                      { currentUser['role'] === 'admin' ?
                        <div>
                          <Query query={FETCH_USER_GROUP} variables={{ token, id: currentUser['vendorId'] }}>
                            {({ loading, error, data }) => {
                              if (loading) return <div>{i18n.t('processing')}</div>
                              if (error) return <div>{error.message}</div>

                              const users = data.userGroup.users
                              const usersForSelect = []

                              users.map((user) =>
                                usersForSelect.push({
                                  key: user.id,
                                  value: user.id,
                                  name: `${user.firstName} ${user.lastName}`,
                                })
                              )

                              let responderId = null

                              if (bugReport.responder !== null) {
                                responderId = parseInt(bugReport.responder.id, 10)
                              }

                              return(
                                <DropDown
                                  id='responder-select'
                                  options={usersForSelect}
                                  selected={responderId}
                                  label={i18n.t('responder')}
                                  rawOptions={true}
                                />
                              )
                            } }
                          </Query> 
                          <Mutation mutation={ASSIGN_BUG_REPORT_TO_USER}>
                            {(assignBugReportToUser) => {
                              return(
                                <span>
                                  <Button
                                    id="assign-bug-report-to-user"
                                    text={i18n.t("updateResponder")}
                                    clickAction={() => {
                                      let sel = document.getElementById("responder-select")
                                      let newUser = null

                                      if (sel.options[sel.selectedIndex].text !== '') {
                                        newUser = parseInt(sel.options[sel.selectedIndex].value, 10)
                                      }

                                      assignBugReportToUser({
                                        variables: {
                                          token,
                                          id,
                                          responderId: newUser,
                                        }
                                      }).then((res) => {
                                        window.flash(i18n.t('successfullyAssignedBugReport'), 'success')
                                        document.getElementById("state").value = res.aasmState
                                      })
                                    } }
                                  />
                                </span>
                              )
                            } } 
                          </Mutation>
                        </div> :
                        <div>
                          { bugReport.aasmState === 'submitted' ?
                            <div>
                              <strong>{i18n.t('responder')}</strong>
                              {i18n.t('noResponderYet')}
                            </div> :
                            <div>
                              <strong>{i18n.t('responder')}</strong>
                              {`${bugReport.responder.firstName} ${bugReport.responder.lastName}`}
                            </div>
                          }
                        </div>
                      }
                    </div>
                    { bugReport.aasmState !== 'completed' &&
                      <Mutation mutation={CLOSE_BUG_REPORT}>
                        {(closeBugReport) => {
                          return(
                            <div className="close-bug-report">
                              <Button
                               id="complete-button"
                               text={i18n.t('closeBugReport')}
                               clickAction={() => {
                                 closeBugReport({
                                   variables: {
                                     token,
                                     id
                                   }
                                 }).then(() => {
                                   bugReport.aasmState = 'completed'
                                   window.flash(
                                     i18n.t('successfullyClosedBugReport'),
                                     'success'
                                   )
                                 })
                               } }
                              />
                            </div>
                          )
                        } }
                      </Mutation>
                    }
                    <AllNotes
                      notes={bugReport.notes}
                      notableType="BugReport"
                      notableId={bugReport.id}
                    />
                  </div>
                )
              } }
            </Query>
          )
        } }
      </Query>
    )
  }

}

BugReport.defaultProps = {
  match: {},
}

BugReport.propTypes = {
  match: object,
}

export default BugReport
