import React, { Component } from 'react'
import { Mutation, Query } from 'react-apollo'
import { Link, Redirect } from 'react-router-dom'
import { object } from 'prop-types'
import i18n from 'i18n-js'
import FontAwesome from 'react-fontawesome'

import AllEquipment from '../components/AllEquipment'
import AllNotes from '../components/AllNotes'
import AllReviews from '../components/AllReviews'
import AllSummaries from '../components/AllSummaries'
import AllWorkOrders from '../components/AllWorkOrders'
import Button from '../components/Button'
import NumberField from '../components/NumberField'
import SignInUser from '../components/SignInUser'
import TextField from '../components/TextField'
import TimeStamp from '../components/TimeStamp'

import { AUTH_TOKEN } from '../constants'

import {
  FETCH_CURRENT_USER,
  FETCH_CUSTOMER,
  UPDATE_CUSTOMER,
  UPDATE_WORK_ORDER,
} from '../queries'
import SERVER_REDIRECTION_URL from '../config/config'

class Customer extends Component {
  constructor(props) {
    super(props)

    this.woChangeSortBy = this.woChangeSortBy.bind(this)

    let id = null

    if (typeof props.id !== 'object') {
      id = props.id
    } else {
      id = props.match.params.id
    }

    this.state = {
      id: parseInt(id, 10),
      pendingWO: 'hidden',
      initialLoad: true,
      woSortBy: 'statusAsc',
      allWorkOrders: [],
      workOrders: [],
      manyCustomers: false,
      selected: {
        submitted: true,
        batched: true,
        accepted: true,
        assigned: true,
        uploaded: true,
        invoiced: true,
        disputed: true,
        internal: false,
        paid: false,
        credited: false,
      },
    }
  }

  woChangeSortBy(val) {
    this.setState({ woSortBy: val })
  }

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser />

    const { id, woSortBy, workOrders, selected, pendingWO } = this.state

    let manyCustomers = this.state.manyCustomers

    return (
      <Query query={FETCH_CURRENT_USER} variables={{ token }}>
        {({ loading, error, data }) => {
          if (loading) return <div>{i18n.t('fetching')}</div>
          if (error) return <div>{error.message}</div>

          const currentUser = data['currentUser']
          if (currentUser['role'] === 'customer') return <Redirect to="/" />

          return (
            <Query query={FETCH_CUSTOMER} variables={{ token, id }}>
              {({ loading, error, data }) => {
                if (loading) return <div>{i18n.t('fetching')}</div>
                if (error) return <div>{error.message}</div>

                if (data.customer === null) return <Redirect to="/" />

                const customer = data.customer

                if (customer.parentRelationships[0] !== undefined) {
                  customer.customerInternalVal =
                    customer.parentRelationships[0].customerInternalVal
                }

                workOrders.length = 0
                customer.workOrders.map((wo) => {
                  if (
                    (!selected.submitted && wo.aasmState === 'submitted') ||
                    (!selected.accepted && wo.aasmState === 'accepted') ||
                    (!selected.batched && wo.aasmState === 'batched') ||
                    (!selected.assigned && wo.aasmState === 'assigned') ||
                    (!selected.uploaded && wo.aasmState === 'uploaded') ||
                    (!selected.invoiced && wo.aasmState === 'invoiced') ||
                    (!selected.disputed && wo.aasmState === 'disputed') ||
                    (!selected.internal && wo.aasmState === 'internal') ||
                    (!selected.paid && wo.aasmState === 'paid') ||
                    (!selected.paid && wo.aasmState === 'credited')
                  ) {
                    return ''
                  }

                  workOrders.push(wo)
                  return ''
                })

                customer.childRelationships.map((cr) => {
                  cr.childCustomer.workOrders.map((wo) => {
                    if (
                      (!selected.submitted && wo.aasmState === 'submitted') ||
                      (!selected.accepted && wo.aasmState === 'accepted') ||
                      (!selected.batched && wo.aasmState === 'batched') ||
                      (!selected.assigned && wo.aasmState === 'assigned') ||
                      (!selected.uploaded && wo.aasmState === 'uploaded') ||
                      (!selected.invoiced && wo.aasmState === 'invoiced') ||
                      (!selected.disputed && wo.aasmState === 'disputed') ||
                      (!selected.internal && wo.aasmState === 'internal') ||
                      (!selected.paid && wo.aasmState === 'paid') ||
                      (!selected.paid && wo.aasmState === 'credited')
                    ) {
                      return ''
                    }

                    if (!manyCustomers) {
                      manyCustomers = true
                      this.setState({ manyCustomers })
                    }

                    workOrders.push(wo)
                    return ''
                  })
                  return ''
                })

                switch (woSortBy) {
                  case 'dateAsc':
                    workOrders.sort((a, b) =>
                      a.createdAt > b.createdAt ? 1 : -1,
                    )
                    break
                  case 'vendorAsc':
                    workOrders.sort((a, b) =>
                      a.vendor.name > b.vendor.name ? 1 : -1,
                    )
                    break
                  case 'vendorDesc':
                    workOrders.sort((a, b) =>
                      a.vendor.name < b.vendor.name ? 1 : -1,
                    )
                    break
                  case 'siteAsc':
                    workOrders.sort((a, b) =>
                      a.customer.internalName > b.customer.internalName
                        ? 1
                        : -1,
                    )
                    break
                  case 'siteDesc':
                    workOrders.sort((a, b) =>
                      a.customer.internalName < b.customer.internalName
                        ? 1
                        : -1,
                    )
                    break
                  case 'serviceTypeAsc':
                    workOrders.sort((a, b) =>
                      a.serviceType > b.serviceType ? 1 : -1,
                    )
                    break
                  case 'serviceTypeDesc':
                    workOrders.sort((a, b) =>
                      a.serviceType < b.serviceType ? 1 : -1,
                    )
                    break
                  case 'statusAsc':
                    workOrders.sort((a, b) => {
                      let aVal = 0
                      let bVal = 0
                      switch (a.aasmState) {
                        case 'paid':
                          aVal = 10
                          break
                        case 'credited':
                          aVal = 9
                          break
                        case 'internal':
                          aVal = 8
                          break
                        case 'disputed':
                          aVal = 7
                          break
                        case 'invoiced':
                          aVal = 6
                          break
                        case 'uploaded':
                          aVal = 5
                          break
                        case 'assigned':
                          aVal = 4
                          break
                        case 'accepted':
                          aVal = 3
                          break
                        case 'batched':
                          aVal = 2
                          break
                        default:
                          aVal = 1
                          break
                      }

                      switch (b.aasmState) {
                        case 'paid':
                          bVal = 10
                          break
                        case 'credited':
                          bVal = 9
                          break
                        case 'internal':
                          bVal = 8
                          break
                        case 'disputed':
                          bVal = 7
                          break
                        case 'invoiced':
                          bVal = 6
                          break
                        case 'uploaded':
                          bVal = 5
                          break
                        case 'assigned':
                          bVal = 4
                          break
                        case 'accepted':
                          bVal = 3
                          break
                        case 'batched':
                          bVal = 2
                          break
                        default:
                          bVal = 1
                          break
                      }

                      if (aVal > bVal) {
                        return 1
                      } else {
                        return -1
                      }
                    })
                    break
                  case 'statusDesc':
                    workOrders.sort((a, b) => {
                      let aVal = 0
                      let bVal = 0
                      switch (a.aasmState) {
                        case 'paid':
                          aVal = 10
                          break
                        case 'credited':
                          aVal = 9
                          break
                        case 'internal':
                          aVal = 8
                          break
                        case 'disputed':
                          aVal = 7
                          break
                        case 'invoiced':
                          aVal = 6
                          break
                        case 'uploaded':
                          aVal = 5
                          break
                        case 'assigned':
                          aVal = 4
                          break
                        case 'accepted':
                          aVal = 3
                          break
                        case 'batched':
                          aVal = 2
                          break
                        default:
                          aVal = 1
                          break
                      }

                      switch (b.aasmState) {
                        case 'paid':
                          bVal = 10
                          break
                        case 'credited':
                          bVal = 9
                          break
                        case 'internal':
                          bVal = 8
                          break
                        case 'disputed':
                          bVal = 7
                          break
                        case 'invoiced':
                          bVal = 6
                          break
                        case 'uploaded':
                          bVal = 5
                          break
                        case 'assigned':
                          bVal = 4
                          break
                        case 'accepted':
                          bVal = 3
                          break
                        case 'batched':
                          bVal = 2
                          break
                        default:
                          bVal = 1
                          break
                      }

                      if (aVal < bVal) {
                        return 1
                      } else {
                        return -1
                      }
                    })
                    break
                  default:
                    workOrders.sort((a, b) =>
                      a.createdAt < b.createdAt ? 1 : -1,
                    )
                    break
                }

                return (
                  <div>
                    <h3>
                      {i18n.t('customerValue', {
                        value: customer.customerInternalVal,
                      })}
                    </h3>
                    {currentUser['role'] === 'service' ? (
                      <div>
                        <div className="fieldContainer">
                          <div className="fieldLabel">
                            <label>{i18n.t('name')}</label>
                          </div>
                          <div>{customer.name}</div>
                        </div>
                        <div className="fieldContainer">
                          <div className="fieldLabel">
                            <label>{i18n.t('site')}</label>
                          </div>
                          <div>{customer.internalName}</div>
                        </div>
                        <div className="fieldContainer">
                          <div className="fieldLabel">
                            <label>{i18n.t('address')}</label>
                          </div>
                          <div>
                            <p>{customer.address1}</p>
                            {typeof str === 'string' &&
                              customer.address2.trim() !== '' && (
                                <p>{customer.address2}</p>
                              )}
                            <p>
                              {customer.city}, {customer.province}{' '}
                              {customer.postalCode}
                            </p>
                            <p> {customer.country}</p>
                          </div>
                        </div>
                        <div className="fieldContainer">
                          <div className="fieldLabel">
                            <label>{i18n.t('hoursPerDay')}</label>
                          </div>
                          <div>{customer.hpd}</div>
                        </div>
                        <div className="fieldContainer">
                          <div className="fieldLabel">
                            <label>{i18n.t('daysPerWeek')}</label>
                          </div>
                          <div>{customer.dpw}</div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <TextField
                          id="name"
                          label={i18n.t('name')}
                          placeholder={i18n.t('name')}
                          value={customer.name}
                        />
                        <TextField
                          id="internalName"
                          label={i18n.t('site')}
                          placeholder={i18n.t('site')}
                          value={customer.internalName}
                        />
                        <TextField
                          id="address1"
                          label={i18n.t('address1')}
                          placeholder={i18n.t('address1')}
                          value={customer.address1}
                        />
                        <TextField
                          id="address2"
                          label={i18n.t('address2')}
                          placeholder={i18n.t('address2')}
                          value={customer.address2}
                        />
                        <TextField
                          id="city"
                          label={i18n.t('city')}
                          placeholder={i18n.t('city')}
                          value={customer.city}
                        />
                        <TextField
                          id="province"
                          label={i18n.t('state')}
                          placeholder={i18n.t('state')}
                          value={customer.province}
                        />
                        <TextField
                          id="postalCode"
                          label={i18n.t('zipCode')}
                          placeholder={i18n.t('zipCode')}
                          value={customer.postalCode}
                        />
                        <TextField
                          id="country"
                          label={i18n.t('country')}
                          placeholder={i18n.t('country')}
                          value={customer.country}
                        />
                        <NumberField
                          id="hpd"
                          label={i18n.t('hoursPerDay')}
                          placeholder={i18n.t('hoursPerDay')}
                          value={customer.hpd}
                          step="1"
                        />
                        <NumberField
                          id="dpw"
                          label={i18n.t('daysPerWeek')}
                          placeholder={i18n.t('daysPerWeek')}
                          value={customer.dpw}
                          step="1"
                        />
                        <Mutation mutation={UPDATE_CUSTOMER}>
                          {(updateCustomer) => {
                            return (
                              <div>
                                <Button
                                  id="update-customer"
                                  text={i18n.t('updateCustomer')}
                                  clickAction={() => {
                                    updateCustomer({
                                      variables: {
                                        token,
                                        id,
                                        name: document.getElementById('name')
                                          .value,
                                        internalName: document.getElementById(
                                          'internalName',
                                        ).value,
                                        address1: document.getElementById(
                                          'address1',
                                        ).value,
                                        address2: document.getElementById(
                                          'address2',
                                        ).value,
                                        city: document.getElementById('city')
                                          .value,
                                        province: document.getElementById(
                                          'province',
                                        ).value,
                                        postalCode: document.getElementById(
                                          'postalCode',
                                        ).value,
                                        country: document.getElementById(
                                          'country',
                                        ).value,
                                        hpd: parseInt(
                                          document.getElementById('hpd').value,
                                          10,
                                        ),
                                        dpw: parseInt(
                                          document.getElementById('dpw').value,
                                          10,
                                        ),
                                      },
                                    }).then(() => {
                                      window.flash(
                                        i18n.t('updatedCustomer'),
                                        'success',
                                      )
                                    })
                                  }}
                                />
                              </div>
                            )
                          }}
                        </Mutation>
                      </div>
                    )}

                    <div>
                      <AllSummaries summaries={customer.summaries} />
                      <AllWorkOrders workOrders={customer.workOrders} />
                      {currentUser['role'] === 'admin' && (
                        <div>
                          <div>
                            <Link
                              to="#"
                              onClick={() => {
                                let css =
                                  pendingWO === 'hidden' ? 'visible' : 'hidden'
                                this.setState({ pendingWO: css })
                              }}
                            >
                              <div className="title-toggle">
                                <h4>{i18n.t('pendingWorkOrders')}</h4>
                              </div>
                            </Link>
                            <div className={pendingWO}>
                              <div>
                                <div className="fieldContainer">
                                  <div className="fieldLabel inline">
                                    <label>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={selected.submitted}
                                        onChange={() => {
                                          selected.submitted = !selected.submitted
                                          this.setState({ selected })
                                        }}
                                      />
                                      {i18n.t('submitted')}
                                    </label>
                                  </div>
                                  <div className="fieldLabel inline">
                                    <label>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={selected.batched}
                                        onChange={() => {
                                          selected.batched = !selected.batched
                                          this.setState({ selected })
                                        }}
                                      />
                                      {i18n.t('batched')}
                                    </label>
                                  </div>
                                  <div className="fieldLabel inline">
                                    <label>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={selected.accepted}
                                        onChange={() => {
                                          selected.accepted = !selected.accepted
                                          this.setState({ selected })
                                        }}
                                      />
                                      {i18n.t('accepted')}
                                    </label>
                                  </div>
                                  <div className="fieldLabel inline">
                                    <label>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={selected.assigned}
                                        onChange={() => {
                                          selected.assigned = !selected.assigned
                                          this.setState({ selected })
                                        }}
                                      />
                                      {i18n.t('assigned')}
                                    </label>
                                  </div>
                                  <div className="fieldLabel inline">
                                    <label>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={selected.uploaded}
                                        onChange={() => {
                                          selected.uploaded = !selected.uploaded
                                          this.setState({ selected })
                                        }}
                                      />
                                      {i18n.t('uploaded')}
                                    </label>
                                  </div>
                                  <div className="fieldLabel inline">
                                    <label>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={selected.invoiced}
                                        onChange={() => {
                                          selected.invoiced = !selected.invoiced
                                          this.setState({ selected })
                                        }}
                                      />
                                      {i18n.t('invoiced')}
                                    </label>
                                  </div>
                                  <div className="fieldLabel inline">
                                    <label>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={selected.disputed}
                                        onChange={() => {
                                          selected.disputed = !selected.disputed
                                          this.setState({ selected })
                                        }}
                                      />
                                      {i18n.t('disputed')}
                                    </label>
                                  </div>
                                  <div className="fieldLabel inline">
                                    <label>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={selected.internal}
                                        onChange={() => {
                                          selected.internal = !selected.internal
                                          this.setState({ selected })
                                        }}
                                      />
                                      {i18n.t('internal')}
                                    </label>
                                  </div>
                                  <div className="fieldLabel inline">
                                    <label>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={selected.paid}
                                        onChange={() => {
                                          selected.paid = !selected.paid
                                          this.setState({ selected })
                                        }}
                                      />
                                      {i18n.t('paid')}
                                    </label>
                                  </div>
                                  <div className="fieldLabel inline">
                                    <label>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={selected.credited}
                                        onChange={() => {
                                          selected.credited = !selected.credited
                                          this.setState({ selected })
                                        }}
                                      />
                                      {i18n.t('credited')}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="review-search-results">
                                <table className="wo-table" border="1">
                                  <thead>
                                    <tr>
                                      <th>
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            this.woChangeSortBy(
                                              woSortBy === 'dateAsc'
                                                ? 'dateDesc'
                                                : 'dateAsc',
                                            )
                                          }}
                                        >
                                          {i18n.t('createdAt')} &nbsp;
                                          <FontAwesome
                                            name={
                                              woSortBy === 'dateAsc'
                                                ? 'sort-down'
                                                : 'sort-up'
                                            }
                                          />
                                        </Link>
                                      </th>
                                      <th>{i18n.t('workOrderNo')}</th>
                                      {manyCustomers && (
                                        <th>
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              this.woChangeSortBy(
                                                woSortBy === 'siteAsc'
                                                  ? 'siteDesc'
                                                  : 'siteAsc',
                                              )
                                            }}
                                          >
                                            {i18n.t('site')}
                                            <FontAwesome
                                              name={
                                                woSortBy === 'siteAsc'
                                                  ? 'sort-down'
                                                  : 'sort-up'
                                              }
                                            />
                                          </Link>
                                        </th>
                                      )}
                                      <th>
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            this.woChangeSortBy(
                                              woSortBy === 'vendorAsc'
                                                ? 'vendorDesc'
                                                : 'vendorAsc',
                                            )
                                          }}
                                        >
                                          {i18n.t('vendor')} &nbsp;
                                          <FontAwesome
                                            name={
                                              woSortBy === 'vendorAsc'
                                                ? 'sort-down'
                                                : 'sort-up'
                                            }
                                          />
                                        </Link>
                                      </th>
                                      <th>{i18n.t('amount')}</th>
                                      <th>
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            this.woChangeSortBy(
                                              woSortBy === 'serviceTypeAsc'
                                                ? 'serviceTypeDesc'
                                                : 'serviceTypeAsc',
                                            )
                                          }}
                                        >
                                          {i18n.t('serviceType')} &nbsp;
                                          <FontAwesome
                                            name={
                                              woSortBy === 'serviceTypeAsc'
                                                ? 'sort-down'
                                                : 'sort-up'
                                            }
                                          />
                                        </Link>
                                      </th>
                                      <th>
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            this.woChangeSortBy(
                                              woSortBy === 'statusAsc'
                                                ? 'statusDesc'
                                                : 'statusAsc',
                                            )
                                          }}
                                        >
                                          {i18n.t('status')} &nbsp;
                                          <FontAwesome
                                            name={
                                              woSortBy === 'statusAsc'
                                                ? 'sort-down'
                                                : 'sort-up'
                                            }
                                          />
                                        </Link>
                                      </th>
                                      <th>{i18n.t('acceptWorkOrder')}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {workOrders.map((wo) => {
                                      let pmCost = 0.0
                                      let damageCost = 0.0
                                      let tiresCost = 0.0
                                      let repairCost = 0.0
                                      let requestCost = 0.0
                                      let attachCost = 0.0

                                      wo.lineItems.map((li) => {
                                        const amount = parseFloat(li.amount)

                                        switch (li.workCategory.value) {
                                          case 'PM':
                                            pmCost += amount
                                            break
                                          case 'Damage':
                                            damageCost += amount
                                            break
                                          case 'Tires':
                                            tiresCost += amount
                                            break
                                          case 'Repairs':
                                            repairCost += amount
                                            break
                                          case 'Request':
                                            requestCost += amount
                                            break
                                          case 'Attachment':
                                            attachCost += amount
                                            break
                                          default:
                                            break
                                        }

                                        return ''
                                      })

                                      let pmCostParts = parseFloat(pmCost)
                                        .toFixed(2)
                                        .split('.')
                                      pmCostParts[0] = pmCostParts[0].replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ',',
                                      )
                                      const pmCostFin = `$${pmCostParts.join(
                                        '.',
                                      )}`

                                      let damageCostParts = parseFloat(
                                        damageCost,
                                      )
                                        .toFixed(2)
                                        .split('.')
                                      damageCostParts[0] = damageCostParts[0].replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ',',
                                      )
                                      const damageCostFin = `$${damageCostParts.join(
                                        '.',
                                      )}`

                                      let tiresCostParts = parseFloat(tiresCost)
                                        .toFixed(2)
                                        .split('.')
                                      tiresCostParts[0] = tiresCostParts[0].replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ',',
                                      )
                                      const tiresCostFin = `$${tiresCostParts.join(
                                        '.',
                                      )}`

                                      let requestCostParts = parseFloat(
                                        requestCost,
                                      )
                                        .toFixed(2)
                                        .split('.')
                                      requestCostParts[0] = requestCostParts[0].replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ',',
                                      )
                                      const requestCostFin = `$${requestCostParts.join(
                                        '.',
                                      )}`

                                      let repairCostParts = parseFloat(
                                        repairCost,
                                      )
                                        .toFixed(2)
                                        .split('.')
                                      repairCostParts[0] = repairCostParts[0].replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ',',
                                      )
                                      const repairCostFin = `$${repairCostParts.join(
                                        '.',
                                      )}`

                                      let attachCostParts = parseFloat(
                                        attachCost,
                                      )
                                        .toFixed(2)
                                        .split('.')
                                      attachCostParts[0] = attachCostParts[0].replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ',',
                                      )
                                      const attachCostFin = `$${attachCostParts.join(
                                        '.',
                                      )}`

                                      return (
                                        <tr key={wo.id} className="wo-row">
                                          <td>
                                            <TimeStamp
                                              timeToFormat={wo.createdAt}
                                            />
                                          </td>
                                          <td>
                                            <Link
                                              to={{
                                                pathname: `/work-order/${wo.id}`,
                                              }}
                                              onClick={() => {
                                                window.location.replace(
                                                  `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
                                                    'auth-token',
                                                  )}&location=workorders&id=${
                                                    wo.id
                                                  }`,
                                                )
                                              }}
                                            >
                                              {wo.internalId}
                                            </Link>
                                          </td>
                                          {manyCustomers && (
                                            <td>{wo.customer.internalName}</td>
                                          )}
                                          <td>
                                            <Link
                                              to={{
                                                pathname: `/work-order/${wo.vendor.id}`,
                                              }}
                                              onClick={() => {
                                                window.location.replace(
                                                  `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
                                                    'auth-token',
                                                  )}&location=workorders&id=${
                                                    wo.vendor.id
                                                  }`,
                                                )
                                              }}
                                            >
                                              {wo.vendor.name}
                                            </Link>
                                          </td>
                                          <td>
                                            {parseFloat(pmCost) > 0 && (
                                              <div>
                                                {`${i18n.t(
                                                  'pm',
                                                )}: ${pmCostFin}`}
                                              </div>
                                            )}
                                            {parseFloat(damageCost) > 0 && (
                                              <div>
                                                {`${i18n.t(
                                                  'damage',
                                                )}: ${damageCostFin}`}
                                              </div>
                                            )}
                                            {parseFloat(tiresCost) > 0 && (
                                              <div>
                                                {`${i18n.t(
                                                  'tires',
                                                )}: ${tiresCostFin}`}
                                              </div>
                                            )}
                                            {parseFloat(requestCost) > 0 && (
                                              <div>
                                                {`${i18n.t(
                                                  'request',
                                                )}: ${requestCostFin}`}
                                              </div>
                                            )}
                                            {parseFloat(repairCost) > 0 && (
                                              <div>
                                                {`${i18n.t(
                                                  'repair',
                                                )}: ${repairCostFin}`}
                                              </div>
                                            )}
                                            {parseFloat(attachCost) > 0 && (
                                              <div>
                                                {`${i18n.t(
                                                  'attachment',
                                                )}: ${attachCostFin}`}
                                              </div>
                                            )}
                                          </td>
                                          <td>{wo.serviceType}</td>
                                          <td>
                                            {wo.aasmState[0].toUpperCase() +
                                              wo.aasmState.substring(1)}
                                          </td>
                                          <td>
                                            {['submitted', 'batched'].includes(
                                              wo.aasmState,
                                            ) ? (
                                              <Mutation
                                                mutation={UPDATE_WORK_ORDER}
                                              >
                                                {(updateWorkOrder) => {
                                                  return (
                                                    <Link
                                                      to="#"
                                                      onClick={() => {
                                                        updateWorkOrder({
                                                          variables: {
                                                            token,
                                                            id: wo.id,
                                                            aasmState:
                                                              'accepted',
                                                          },
                                                        }).then(() => {
                                                          wo.aasmState =
                                                            'accepted'
                                                          this.setState({
                                                            workOrders,
                                                          })
                                                          window.flash(
                                                            i18n.t(
                                                              'successfullyAcceptedWorkOrder',
                                                            ),
                                                          )
                                                        })
                                                      }}
                                                    >
                                                      {i18n.t('accept')}
                                                    </Link>
                                                  )
                                                }}
                                              </Mutation>
                                            ) : (
                                              <span>
                                                {wo.aasmState ===
                                                  'accepted' && (
                                                  <Mutation
                                                    mutation={UPDATE_WORK_ORDER}
                                                  >
                                                    {(updateWorkOrder) => {
                                                      return (
                                                        <Link
                                                          to="#"
                                                          onClick={() => {
                                                            updateWorkOrder({
                                                              variables: {
                                                                token,
                                                                id: wo.id,
                                                                aasmState:
                                                                  'submitted',
                                                              },
                                                            }).then(() => {
                                                              wo.aasmState =
                                                                'submitted'
                                                              this.setState({
                                                                workOrders,
                                                              })
                                                              window.flash(
                                                                i18n.t(
                                                                  'successfullyRolledBackWorkOrder',
                                                                ),
                                                              )
                                                            })
                                                          }}
                                                        >
                                                          {i18n.t('rollBack')}
                                                        </Link>
                                                      )
                                                    }}
                                                  </Mutation>
                                                )}
                                              </span>
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <AllEquipment equipment={customer.equipment} />
                      {currentUser['role'] !== 'service' && (
                        <AllReviews reviews={customer.reviews} />
                      )}
                      <AllNotes
                        notes={customer.notes}
                        notableType="Customer"
                        notableId={id}
                      />
                    </div>
                  </div>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

Customer.defaultProps = {
  id: {},
  match: {},
}

Customer.propTypes = {
  id: object,
  match: object,
}

export default Customer
