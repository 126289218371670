import React, {Component} from 'react'
import { Mutation } from 'react-apollo'
import i18n from 'i18n-js'

import Button from './Button'
import EmailField from './EmailField'

import {
  CURRENT_EMAIL,
} from '../constants'

import {
  REQUEST_PASSWORD_RESET,
} from '../queries'

class ForgotPassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
    }
  }

  render() {
    const {
      email,
    } = this.state

    return(
      <div id='sign-in-box'>
        <h4>{i18n.t('resetPassword')}</h4>
        <div>
          <EmailField
            id='email'
            value={email}
            placeholder={i18n.t('forgotPasswordInstructions')}
            label={i18n.t('email')}
          />
        </div>
        <div>
          <Mutation mutation={REQUEST_PASSWORD_RESET}>
            {(requestPasswordReset) => {
              return(
                <Button
                  id='send-reset'
                  text={i18n.t('sendReset')}
                  klass='sign-in'
                  clickAction={() => {

                    let emailVal = document.getElementById('email').value

                    if (emailVal.length < 1) {
                      window.flash(i18n.t('notAValidEmail'), 'error')
                      return ''
                    }

                    console.log(emailVal)

                    requestPasswordReset({
                      variables: {
                        email: emailVal,
                      }
                    }).then((res) => {
                      if (res.data.requestPasswordReset.response === 'false') {
                        window.flash(i18n.t('couldNotFindEmail'), 'error')
                      } else {
                        localStorage.setItem(CURRENT_EMAIL, emailVal)

                        window.location.href = '/check-code'
                      }
                    })
                  } }
                />
              )
            } }
          </Mutation>
        </div>
      </div>
    )
  }
}

export default ForgotPassword
