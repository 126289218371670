import gql from 'graphql-tag'

export const FETCH_ALL_DOCUMENTS = gql`
  query Documents($token: String!) {
    allDocuments(token: $token) {
      id
      name
      userType
      createdAt
      archivedAt
    }
  }
`

export const FETCH_DOCUMENT = gql`
  query Document($token: String!, $id: Int!) {
    document(id: $id, token: $token) {
      id
      name
      content
      startRange
      endRange
      userType
      length
    }
  }
`

export const FETCH_ALL_INVOICES = gql`
  query Invoices($token: String!) {
    allInvoices(token: $token) {
      id
      internalId
      amount
      tax
      aasmState
      createdAt
      customer {
        id
        name
        internalName
        parentRelationships {
          id
          customerInternalVal
        }
      }
    }
  }
`

export const FETCH_ALL_CONTRACTS = gql`
  query Contracts($token: String!) {
    allContracts(token: $token) {
      id
      internalName
      startRange
      endRange
      billingValue
      customers {
        id
        name
        internalName
        parentRelationships {
          id
          customerInternalVal
        }
      }
    }
  }
`

export const FETCH_CONTRACT = gql`
  query Contract($token: String!, $id: Int!) {
    contract(id: $id, token: $token) {
      id
      internalName
      startRange
      endRange
      amount
      hold
      payVendor
      amountType
      contractType
      invoiceRate
      paymentRate
      reportType
      billingValue
      customerPo
      paymentTerms
      customers {
        id
        name
        internalName
        parentRelationships {
          id
          customerInternalVal
        }
      }
      contractEquipments {
        id
        startRange
        endRange
        equipment {
          id
          vendorEquipment {
            id
            vendorEquipmentId
          }
        }
      }
    }
  }
`

// Customers
export const FETCH_CUSTOMER_FOR_REPORT = gql`
  query Customer($token: String!, $id: Int!) {
    customer(id: $id, token: $token) {
      id
      name
      parentRelationships {
        id
        customerInternalVal
      }
    }
  }
`

export const FETCH_CHILD_RELATIONSHIPS_BY_CUSTOMER = gql`
  query Customer($token: String!, $id: Int!) {
    customer(id: $id, token: $token) {
      id
      childRelationships {
        id
        customerInternalVal
        childCustomer {
          id
          name
          internalName
          address1
          address2
          city
          province
          postalCode
        }
      }
    }
  }
`

export const FETCH_EQUIPMENT_BY_VENDOR = gql`
  query Customer($token: String!, $id: Int!) {
    customer(id: $id, token: $token) {
      id
      childRelationships {
        id
        childCustomer {
          id
          equipment {
            id
            make
            model
            serialNumber
            fleetNumber
            vendorEquipment {
              id
              vendorEquipmentId
              customer {
                id
              }
            }
          }
        }
      }
    }
  }
`

export const FETCH_EQUIPMENT_BY_CUSTOMER = gql`
  query Customer($token: String!, $id: Int!) {
    customer(id: $id, token: $token) {
      id
      equipment {
        id
        make
        model
        serialNumber
        fleetNumber
        vendorEquipment {
          id
          vendorEquipmentId
          customer {
            id
          }
        }
      }
    }
  }
`

export const CUSTOMER_SEARCH = gql`
  query SearchCustomers($token: String!, $search: String!, $excluding: String) {
    searchCustomers(token: $token, search: $search, excluding: $excluding) {
      id
      name
      internalName
      parentRelationships {
        id
        customerInternalVal
      }
    }
  }
`

export const CUSTOMER_RELATIONSHIP_SEARCH = gql`
  query SearchCustomerRelationships(
    $token: String!
    $search: String!
    $vendorId: Int!
  ) {
    searchCustomerRelationships(
      token: $token
      search: $search
      vendorId: $vendorId
    ) {
      id
      customerInternalVal
      childCustomer {
        id
        name
        internalName
      }
    }
  }
`

export const EQUIPMENT_SEARCH = gql`
  query SearchEquipment($token: String!, $search: String!) {
    searchEquipment(token: $token, search: $search) {
      id
      serialNumber
      vendorEquipment {
        id
        vendorEquipmentId
      }
    }
  }
`

export const WORK_ORDER_SEARCH = gql`
  query SearchWorkOrders($token: String!, $search: String!) {
    searchWorkOrders(token: $token, search: $search) {
      id
      internalId
    }
  }
`

export const GENERATE_SUMMARY_PDF = gql`
  mutation GenerateSummaryPdf(
    $token: String!
    $id: Int!
    $summaryPayload: String!
  ) {
    generateSummaryPdf(
      input: { token: $token, id: $id, summaryPayload: $summaryPayload }
    ) {
      summary {
        id
        executiveSummaryUrl
      }
    }
  }
`

export const UPDATE_SUMMARY = gql`
  mutation UpdateSummary(
    $token: String!
    $id: Int!
    $title: String
    $reportTitle: String
    $discussion: String
    $deliverPromise: String
    $isCustomer: Boolean
    $isEc: Boolean
    $isTc: Boolean
    $isInternal: Boolean
    $startRange: String
    $endRange: String
    $includeAllWo: Boolean
    $lastWoDateVisible: Boolean
    $firstWoDateVisible: Boolean
    $periodHoursVisible: Boolean
    $pmCphVisible: Boolean
    $repairCphVisible: Boolean
    $tiresCphVisible: Boolean
    $damageCphVisible: Boolean
    $requestCphVisible: Boolean
    $attachCphVisible: Boolean
    $avgFleetHours: Int
    $avgFleetAvailability: Int
    $pmCountVisible: Boolean
    $avgPmIntervalVisible: Boolean
    $mfcCountVisible: Boolean
    $avgMfcIntervalVisible: Boolean
    $pdfWoPricesVisible: Boolean
    $mcCphVisible: Boolean
    $plannedHoursVisible: Boolean
    $kpiPeriod: String
  ) {
    updateSummary(
      input: {
        token: $token
        id: $id
        title: $title
        reportTitle: $reportTitle
        discussion: $discussion
        deliverPromise: $deliverPromise
        isCustomer: $isCustomer
        isEc: $isEc
        isTc: $isTc
        isInternal: $isInternal
        startRange: $startRange
        endRange: $endRange
        includeAllWo: $includeAllWo
        lastWoDateVisible: $lastWoDateVisible
        firstWoDateVisible: $firstWoDateVisible
        periodHoursVisible: $periodHoursVisible
        pmCphVisible: $pmCphVisible
        repairCphVisible: $repairCphVisible
        tiresCphVisible: $tiresCphVisible
        damageCphVisible: $damageCphVisible
        requestCphVisible: $requestCphVisible
        attachCphVisible: $attachCphVisible
        avgFleetHours: $avgFleetHours
        avgFleetAvailability: $avgFleetAvailability
        pmCountVisible: $pmCountVisible
        avgPmIntervalVisible: $avgPmIntervalVisible
        mfcCountVisible: $mfcCountVisible
        avgMfcIntervalVisible: $avgMfcIntervalVisible
        pdfWoPricesVisible: $pdfWoPricesVisible
        mcCphVisible: $mcCphVisible
        plannedHoursVisible: $plannedHoursVisible
        kpiPeriod: $kpiPeriod
      }
    ) {
      summary {
        id
      }
    }
  }
`

export const FETCH_CUSTOMERS_FROM_USER_GROUP = gql`
  query Customer($token: String!, $vendorId: Int!) {
    customer(id: $vendorId, token: $token) {
      id
      childRelationships {
        id
        childCustomer {
          id
          name
          internalName
          hpd
          dpw
          parentRelationships {
            id
            customerInternalVal
          }
          equipment {
            id
            year
            model
            make
            serialNumber
            fleetNumber
            acquisitionCost
            plannedHours
            maintenanceRecords {
              id
              amount
              invoicedAt
            }
            maintenanceLevel
            customer {
              id
              name
              internalName
              parentRelationships {
                id
                customerInternalVal
              }
            }
            vendorEquipment {
              id
              vendorEquipmentId
            }
            workOrders {
              id
              invoicedAt
              startedAt
              keyOnMeter
              downtime
              invoiceType
              serviceType
              countAsPm
              countAsMfc
              archivedAt
              customer {
                id
              }
              lineItems {
                id
                amount
                workCategory {
                  id
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`

export const FETCH_CUSTOMERS_FOR_CUSTOMER_REPORT = gql`
  query Customers($token: String!, $customerIds: String!) {
    customers(customerIds: $customerIds, token: $token) {
      id
      name
      internalName
      hpd
      dpw
      parentRelationships {
        id
        customerInternalVal
      }
      equipment {
        id
        year
        model
        make
        serialNumber
        fleetNumber
        acquisitionCost
        plannedHours
        maintenanceLevel
        maintenanceRecords {
          id
          amount
          invoicedAt
        }
        customer {
          id
          name
          internalName
          parentRelationships {
            id
            customerInternalVal
          }
        }
        vendorEquipment {
          id
          vendorEquipmentId
        }
        workOrders {
          id
          invoicedAt
          startedAt
          keyOnMeter
          downtime
          invoiceType
          serviceType
          countAsPm
          countAsMfc
          archivedAt
          customer {
            id
          }
          lineItems {
            id
            amount
            workCategory {
              id
              value
            }
          }
        }
      }
    }
  }
`

/*
  Removed from: equipment, work orders

  reviews(unassigned: true) {
    id
    message
    createdAt
    completedAt
    reviewableType
    user {
      id
      firstName
      lastName
    }
  }

*/

export const FETCH_SUMMARY_FOR_EQUIP_REPORT = gql`
  query Summary($token: String!, $id: Int!) {
    summary(id: $id, token: $token) {
      id
      title
      reportTitle
      discussion
      deliverPromise
      isCustomer
      isEc
      isTc
      isInternal
      startRange
      endRange
      includeAllWo
      lastWoDateVisible
      firstWoDateVisible
      periodHoursVisible
      pmCountVisible
      avgPmIntervalVisible
      mfcCountVisible
      avgMfcIntervalVisible
      pmCphVisible
      repairCphVisible
      tiresCphVisible
      damageCphVisible
      requestCphVisible
      attachCphVisible
      mcCphVisible
      plannedHoursVisible
      avgFleetHours
      avgFleetAvailability
      executiveSummaryUrl
      kpiPeriod
      workOrders {
        id
      }
      excludedWorkOrders {
        id
        workOrder {
          id
        }
      }
      showcasedWorkOrders {
        id
        workOrder {
          id
        }
      }
      equipment {
        id
      }
      customers {
        id
        name
        internalName
        hpd
        dpw
        reviews {
          id
          message
          createdAt
          completedAt
          reviewableType
          user {
            id
            firstName
            lastName
          }
        }
        parentRelationships {
          id
          customerInternalVal
        }
        equipment {
          id
          year
          model
          make
          serialNumber
          fleetNumber
          acquisitionCost
          plannedHours
          maintenanceLevel
          maintenanceRecords {
            id
            amount
            invoicedAt
          }
          vendorEquipment {
            id
            vendorEquipmentId
          }
          customer {
            id
            name
            internalName
            parentRelationships {
              id
              customerInternalVal
            }
          }
          workOrders {
            id
            invoicedAt
            startedAt
            keyOnMeter
            driveMeter
            pumpMeter
            downtime
            invoiceType
            serviceType
            countAsPm
            countAsMfc
            archivedAt
            customer {
              id
            }
            lineItems {
              id
              amount
              workCategory {
                id
                value
              }
            }
          }
        }
      }
    }
  }
`

/*
  Removed from: equipment, work orders

  reviews(unassigned: true) {
    id
    message
    createdAt
    completedAt
    reviewableType
    user {
      id
      firstName
      lastName
    }
  }

*/

export const FETCH_CUSTOMERS_FOR_EQUIP_REPORT = gql`
  query Customers($token: String!, $customerIds: String!) {
    customers(customerIds: $customerIds, token: $token) {
      id
      name
      internalName
      hpd
      dpw
      reviews(unassigned: true) {
        id
        message
        createdAt
        completedAt
        reviewableType
        user {
          id
          firstName
          lastName
        }
      }
      parentRelationships {
        id
        customerInternalVal
      }
      equipment {
        id
        year
        model
        make
        serialNumber
        fleetNumber
        acquisitionCost
        plannedHours
        maintenanceLevel
        maintenanceRecords {
          id
          amount
          invoicedAt
        }
        customer {
          id
          name
          internalName
          parentRelationships {
            id
            customerInternalVal
          }
        }
        vendorEquipment {
          id
          vendorEquipmentId
        }
        workOrders {
          id
          invoicedAt
          startedAt
          keyOnMeter
          driveMeter
          pumpMeter
          downtime
          invoiceType
          serviceType
          countAsPm
          countAsMfc
          archivedAt
          customer {
            id
          }
          lineItems {
            id
            amount
            workCategory {
              id
              value
            }
          }
        }
      }
    }
  }
`

export const FETCH_SUMMARY_FOR_WORK_ORDER_REPORT = gql`
  query Summary($token: String!, $id: Int!) {
    summary(id: $id, token: $token) {
      id
      customers {
        id
        name
        workOrders {
          id
          internalId
          serviceType
          serviceDescription
          invoicedAt
          startedAt
          keyOnMeter
          downtime
          invoiceType
          archivedAt
          customer {
            id
            name
            internalName
            parentRelationships {
              id
              customerInternalVal
            }
          }
          equipment {
            id
            serialNumber
            maintenanceLevel
            maintenanceRecords {
              id
              amount
              invoicedAt
            }
            vendorEquipment {
              id
              vendorEquipmentId
            }
          }
          lineItems {
            id
            amount
            workCategory {
              id
              value
            }
          }
        }
      }
    }
  }
`

export const FETCH_CUSTOMERS_FOR_WORK_ORDER_REPORT = gql`
  query Customers($token: String!, $customerIds: String!) {
    customers(customerIds: $customerIds, token: $token) {
      id
      name
      internalName
      workOrders {
        id
        internalId
        serviceType
        serviceDescription
        invoicedAt
        startedAt
        keyOnMeter
        downtime
        invoiceType
        archivedAt
        customer {
          id
          name
          internalName
          parentRelationships {
            id
            customerInternalVal
          }
        }
        equipment {
          id
          serialNumber
          maintenanceLevel
          vendorEquipment {
            id
            vendorEquipmentId
          }
        }
        lineItems {
          id
          amount
          workCategory {
            id
            value
          }
        }
      }
    }
  }
`

export const FETCH_WORK_ORDERS_BY_STATE = gql`
  query AllWorkOrdersByState($token: String!, $state: String!) {
    allWorkOrdersByState(token: $token, state: $state) {
      id
      internalId
      vendor {
        id
        name
      }
    }
  }
`

export const FETCH_WORK_ORDERS_FOR_ALL_INVOICES_REPORT = gql`
  query Customer($token: String!, $id: Int!) {
    customer(id: $id, token: $token) {
      id
      workOrders {
        id
        internalId
        aasmState
        lineItems {
          id
          amount
          workCategory {
            id
            value
          }
        }
      }
    }
  }
`

export const FETCH_SUMMARY_FOR_PART_REPORT = gql`
  query Summary($token: String!, $id: Int!) {
    summary(id: $id, token: $token) {
      id
      customers {
        id
        name
        internalName
        workOrders {
          id
          invoicedAt
          keyOnMeter
          lineItems {
            id
            amount
            description
            unitPrice
            quantity
            productGroup
            workCategory {
              id
              value
            }
          }
          equipment {
            id
            vendorEquipment {
              id
              vendorEquipmentId
            }
          }
        }
      }
    }
  }
`

export const FETCH_CUSTOMERS_FOR_PART_REPORT = gql`
  query Customers($token: String!, $customerIds: String!) {
    customers(customerIds: $customerIds, token: $token) {
      id
      name
      internalName
      workOrders {
        id
        invoicedAt
        keyOnMeter
        lineItems {
          id
          amount
          description
          unitPrice
          quantity
          productGroup
          workCategory {
            id
            value
          }
        }
        equipment {
          id
          vendorEquipment {
            id
            vendorEquipmentId
          }
        }
      }
    }
  }
`

export const CREATE_USER_DOCUMENT_AGREEMENT = gql`
  mutation CreateUserDocumentAgreement(
    $token: String!
    $documentId: Int!
    $customers: String!
  ) {
    createUserDocumentAgreement(
      input: { token: $token, documentId: $documentId, customers: $customers }
    ) {
      userDocumentAgreements {
        id
      }
    }
  }
`

export const CREATE_BUG_REPORT = gql`
  mutation CreateBugReport(
    $token: String!
    $description: String!
    $reporterId: Int
  ) {
    createBugReport(
      input: {
        token: $token
        description: $description
        reporterId: $reporterId
      }
    ) {
      bugReport {
        id
      }
    }
  }
`

export const FETCH_BUG_REPORT = gql`
  query BugReport($token: String!, $id: Int!) {
    bugReport(token: $token, id: $id) {
      id
      description
      aasmState
      createdAt
      reporter {
        id
        firstName
        lastName
      }
      responder {
        id
        firstName
        lastName
      }
      transitionLogs {
        id
      }
      notes {
        id
        content
        createdAt
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const FETCH_BUG_REPORTS = gql`
  query AllBugReports($token: String!) {
    allBugReports(token: $token) {
      id
      description
      aasmState
      createdAt
      reporter {
        id
        firstName
        lastName
      }
      responder {
        id
        firstName
        lastName
      }
    }
  }
`

export const CREATE_CONTRACT = gql`
  mutation CreateContract(
    $token: String!
    $customers: String!
    $contractType: String!
    $internalName: String!
    $hold: Boolean!
    $payVendor: Boolean!
    $startRange: String!
    $endRange: String
    $amount: Float!
    $amountType: String!
    $invoiceRate: String!
    $paymentRate: String!
    $reportType: String!
    $billingValue: String!
    $poRequired: Boolean!
    $customerPo: String
    $paymentTerms: String
  ) {
    createContract(
      input: {
        token: $token
        customers: $customers
        contractType: $contractType
        internalName: $internalName
        hold: $hold
        payVendor: $payVendor
        startRange: $startRange
        endRange: $endRange
        amount: $amount
        amountType: $amountType
        invoiceRate: $invoiceRate
        paymentRate: $paymentRate
        reportType: $reportType
        billingValue: $billingValue
        poRequired: $poRequired
        customerPo: $customerPo
        paymentTerms: $paymentTerms
      }
    ) {
      contract {
        id
      }
    }
  }
`

export const CREATE_CONTRACT_EQUIPMENT = gql`
  mutation CreateContractEquipment(
    $token: String!
    $contractId: Int!
    $equipmentId: Int!
    $startRange: String!
    $endRange: String
  ) {
    createContractEquipment(
      input: {
        token: $token
        contractId: $contractId
        equipmentId: $equipmentId
        startRange: $startRange
        endRange: $endRange
      }
    ) {
      contractEquipment {
        id
        startRange
        endRange
        equipment {
          id
          vendorEquipment {
            id
            vendorEquipmentId
          }
        }
      }
    }
  }
`

export const UPDATE_CONTRACT_EQUIPMENT = gql`
  mutation UpdateContractEquipment(
    $token: String!
    $id: Int!
    $startRange: String
    $endRange: String
  ) {
    updateContractEquipment(
      input: {
        token: $token
        id: $id
        startRange: $startRange
        endRange: $endRange
      }
    ) {
      contractEquipment {
        id
      }
    }
  }
`

export const CREATE_DOCUMENT = gql`
  mutation CreateDocument(
    $token: String!
    $name: String!
    $content: String!
    $startRange: String!
    $endRange: String
    $userType: String
    $length: Int!
  ) {
    createDocument(
      input: {
        token: $token
        name: $name
        content: $content
        startRange: $startRange
        endRange: $endRange
        userType: $userType
        length: $length
      }
    ) {
      document {
        id
      }
    }
  }
`

export const UPDATE_DOCUMENT = gql`
  mutation UpdateDcoument(
    $token: String!
    $id: Int!
    $name: String
    $content: String
    $startRange: String
    $endRange: String
    $userType: String
    $length: Int
    $archivedAt: String
  ) {
    updateDocument(
      input: {
        token: $token
        id: $id
        name: $name
        content: $content
        startRange: $startRange
        endRange: $endRange
        userType: $userType
        length: $length
        archivedAt: $archivedAt
      }
    ) {
      document {
        id
      }
    }
  }
`

export const UPDATE_CONTRACT = gql`
  mutation UpdateContract(
    $token: String!
    $id: Int!
    $customers: String
    $contractType: String
    $internalName: String
    $hold: Boolean
    $payVendor: Boolean
    $startRange: String
    $endRange: String
    $amount: Float
    $amountType: String
    $invoiceRate: String
    $paymentRate: String
    $reportType: String
    $billingValue: String
    $customerPo: String
    $paymentTerms: String
  ) {
    updateContract(
      input: {
        token: $token
        id: $id
        customers: $customers
        contractType: $contractType
        internalName: $internalName
        hold: $hold
        payVendor: $payVendor
        startRange: $startRange
        endRange: $endRange
        amount: $amount
        amountType: $amountType
        invoiceRate: $invoiceRate
        paymentRate: $paymentRate
        reportType: $reportType
        billingValue: $billingValue
        customerPo: $customerPo
        paymentTerms: $paymentTerms
      }
    ) {
      contract {
        id
        contractType
      }
    }
  }
`

export const CREATE_WORK_ORDER = gql`
  mutation CreateWorkOrder(
    $token: String!
    $customerId: Int!
    $equipmentId: Int!
    $vendorId: Int!
    $internalId: String!
    $invoicedAt: String!
    $serviceType: String!
    $invoiceType: String!
    $serviceDescription: String
    $keyOnMeter: Int
    $driveMeter: Int
    $pumpMeter: Int
    $downtime: Float
    $startedAt: String
    $stoppedAt: String
    $po: String
    $countAsPm: Boolean
    $countAsMfc: Boolean
  ) {
    createWorkOrder(
      input: {
        token: $token
        customerId: $customerId
        equipmentId: $equipmentId
        vendorId: $vendorId
        internalId: $internalId
        invoicedAt: $invoicedAt
        serviceType: $serviceType
        invoiceType: $invoiceType
        serviceDescription: $serviceDescription
        keyOnMeter: $keyOnMeter
        driveMeter: $driveMeter
        pumpMeter: $pumpMeter
        downtime: $downtime
        startedAt: $startedAt
        stoppedAt: $stoppedAt
        po: $po
        countAsPm: $countAsPm
        countAsMfc: $countAsMfc
      }
    ) {
      workOrder {
        id
      }
    }
  }
`

export const CREATE_EQUIPMENT = gql`
  mutation CreateEquipment(
    $token: String!
    $vendorEquipmentId: String!
    $ownerId: Int!
    $customerId: Int!
    $maintenanceLevel: String!
    $fleetNumber: String
    $year: Int
    $model: String
    $make: String
    $serialNumber: String
    $acquisitionCost: Float
    $plannedHours: Int
    $description: String
    $group: String
  ) {
    createEquipment(
      input: {
        token: $token
        vendorEquipmentId: $vendorEquipmentId
        ownerId: $ownerId
        customerId: $customerId
        maintenanceLevel: $maintenanceLevel
        fleetNumber: $fleetNumber
        year: $year
        model: $model
        make: $make
        serialNumber: $serialNumber
        acquisitionCost: $acquisitionCost
        plannedHours: $plannedHours
        description: $description
        group: $group
      }
    ) {
      equipment {
        id
        vendorEquipment {
          id
          vendorEquipmentId
        }
      }
    }
  }
`

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer(
    $token: String!
    $name: String!
    $vendorId: Int!
    $customerInternalVal: String!
    $internalName: String
    $address1: String
    $address2: String
    $city: String
    $province: String
    $postalCode: String
    $country: String
    $hpd: Int
    $dpw: Int
  ) {
    createCustomer(
      input: {
        token: $token
        name: $name
        vendorId: $vendorId
        customerInternalVal: $customerInternalVal
        internalName: $internalName
        address1: $address1
        address2: $address2
        city: $city
        province: $province
        postalCode: $postalCode
        country: $country
        hpd: $hpd
        dpw: $dpw
      }
    ) {
      customer {
        id
        name
        internalName
        parentRelationships {
          id
          customerInternalVal
        }
      }
    }
  }
`

export const FETCH_CUSTOMER_FOR_ECHELONS = gql`
  query Customer($token: String!, $id: Int!) {
    customer(id: $id, token: $token) {
      id
      name
      internalName
      parentRelationships {
        id
        customerInternalVal
        parentCustomer {
          id
          name
          internalName
        }
      }
      childRelationships {
        id
        customerInternalVal
        childCustomer {
          id
          name
          internalName
        }
      }
    }
  }
`

export const FETCH_CUSTOMER = gql`
  query Customer($token: String!, $id: Int!) {
    customer(id: $id, token: $token) {
      id
      name
      address1
      address2
      city
      province
      postalCode
      country
      hpd
      dpw
      internalName
      parentRelationships {
        id
        customerInternalVal
      }
      childRelationships {
        id
        customerInternalVal
        childCustomer {
          id
          internalName
          workOrders {
            id
            serviceType
            internalId
            invoicedAt
            aasmState
            createdAt
            customer {
              id
              name
              internalName
            }
            vendor {
              id
              name
            }
            keyOnMeter
            lineItems {
              id
              amount
              workCategory {
                id
                value
              }
            }
          }
        }
      }
      equipment {
        id
        vendorEquipment {
          id
          vendorEquipmentId
        }
        model
        serialNumber
      }
      workOrders {
        id
        serviceType
        internalId
        invoicedAt
        aasmState
        createdAt
        customer {
          id
          name
          internalName
        }
        vendor {
          id
          name
        }
        keyOnMeter
        lineItems {
          id
          amount
          workCategory {
            id
            value
          }
        }
      }
      reviews {
        id
        reviewableType
        message
        createdAt
        completedAt
        user {
          id
          firstName
          lastName
        }
      }
      notes {
        id
        content
        createdAt
        user {
          id
          firstName
          lastName
        }
      }
      summaries {
        id
        title
        startRange
        endRange
        createdAt
        customers {
          id
          name
        }
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer(
    $token: String!
    $id: Int!
    $name: String
    $internalName: String
    $address1: String
    $address2: String
    $city: String
    $province: String
    $postalCode: String
    $country: String
    $hpd: Int
    $dpw: Int
  ) {
    updateCustomer(
      input: {
        token: $token
        id: $id
        name: $name
        internalName: $internalName
        address1: $address1
        address2: $address2
        city: $city
        province: $province
        postalCode: $postalCode
        country: $country
        hpd: $hpd
        dpw: $dpw
      }
    ) {
      customer {
        id
      }
    }
  }
`

// Equipment
export const FETCH_EQUIPMENT = gql`
  query Equipment($token: String!, $id: Int!) {
    equipment(token: $token, id: $id) {
      id
      year
      model
      make
      fleetNumber
      serialNumber
      acquisitionCost
      maintenanceLevel
      plannedHours
      description
      group
      vendorEquipment {
        id
        vendorEquipmentId
      }
      customer {
        id
        name
        internalName
        parentRelationships {
          id
          customerInternalVal
        }
      }
      owner {
        id
        name
      }
      notes {
        id
        user {
          id
          firstName
          lastName
        }
        content
        createdAt
      }
      logs {
        id
        fieldName
        oldValue
        newValue
        startedAt
        endedAt
      }
      reviews(unassigned: false) {
        id
        reviewableType
        message
        createdAt
        completedAt
        user {
          id
          firstName
          lastName
        }
      }
      workOrders {
        id
        serviceType
        internalId
        invoicedAt
        startedAt
        keyOnMeter
        driveMeter
        pumpMeter
        serviceType
        downtime
        invoiceType
        serviceDescription
        customer {
          id
          name
          internalName
        }
        lineItems {
          id
          amount
          workCategory {
            id
            value
          }
        }
      }
    }
  }
`

export const UPDATE_EQUIPMENT = gql`
  mutation UpdateEquipment(
    $token: String!
    $id: Int!
    $year: Int
    $fleetNumber: String
    $serialNumber: String
    $make: String
    $model: String
    $group: String
    $description: String
    $acquisitionCost: Float
    $plannedHours: Int
    $maintenanceLevel: String
  ) {
    updateEquipment(
      input: {
        token: $token
        id: $id
        year: $year
        fleetNumber: $fleetNumber
        serialNumber: $serialNumber
        make: $make
        model: $model
        group: $group
        description: $description
        acquisitionCost: $acquisitionCost
        plannedHours: $plannedHours
        maintenanceLevel: $maintenanceLevel
      }
    ) {
      equipment {
        id
      }
    }
  }
`

// Notes
export const CREATE_NOTE = gql`
  mutation CreateNote(
    $token: String!
    $notableType: String!
    $notableId: Int!
    $content: String!
  ) {
    createNote(
      input: {
        token: $token
        notableType: $notableType
        notableId: $notableId
        content: $content
      }
    ) {
      note {
        id
        content
        createdAt
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`

// Reviews

export const FETCH_REVIEW = gql`
  query Review($token: String!, $id: Int!) {
    review(token: $token, id: $id) {
      id
      message
      completedAt
      createdAt
      comment
      reviewableType
      reviewableId
      user {
        id
        firstName
        lastName
      }
    }
  }
`
export const ASSIGN_REVIEW_TO_USER = gql`
  mutation UpdateReview($token: String!, $userId: Int!, $reviewId: Int!) {
    updateReview(input: { token: $token, id: $reviewId, userId: $userId }) {
      review {
        id
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const CLOSE_BUG_REPORT = gql`
  mutation CloseBugReport($token: String!, $id: Int!) {
    closeBugReport(input: { token: $token, id: $id }) {
      bugReport {
        id
        aasmState
      }
    }
  }
`

export const ASSIGN_BUG_REPORT_TO_USER = gql`
  mutation UpdateBugReport($token: String!, $id: Int!, $responderId: Int!) {
    updateBugReport(
      input: { token: $token, id: $id, responderId: $responderId }
    ) {
      bugReport {
        id
        aasmState
      }
    }
  }
`

export const ADD_COMMENT_TO_REVIEW = gql`
  mutation UpdateReview($token: String!, $reviewId: Int!, $comment: String!) {
    updateReview(input: { token: $token, id: $reviewId, comment: $comment }) {
      review {
        id
        comment
      }
    }
  }
`

export const MARK_REVIEW_AS_COMPLETE = gql`
  mutation UpdateReview(
    $token: String!
    $reviewId: Int!
    $completedAt: String!
  ) {
    updateReview(
      input: { token: $token, id: $reviewId, completedAt: $completedAt }
    ) {
      review {
        id
        completedAt
      }
    }
  }
`

export const SEARCH_REVIEWS = gql`
  query SearchReviews($token: String!, $page: Int!, $limited: String) {
    searchReviews(
      token: $token
      orderBy: { createdAt: DESC }
      page: $page
      limited: $limited
    ) {
      pageCount
      results {
        id
        message
        createdAt
        completedAt
        reviewableType
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`

// Summaries
export const CREATE_SUMMARY = gql`
  mutation CreateSummary(
    $token: String!
    $customers: String!
    $workOrders: String!
    $workOrdersExcluded: String!
    $workOrdersShowcased: String!
    $equipment: String!
    $title: String!
    $reportTitle: String
    $discussion: String
    $deliverPromise: String
    $isCustomer: Boolean!
    $isEc: Boolean!
    $isTc: Boolean!
    $isInternal: Boolean!
    $startRange: String!
    $endRange: String!
    $includeAllWo: Boolean!
    $firstWoDateVisible: Boolean!
    $lastWoDateVisible: Boolean!
    $periodHoursVisible: Boolean!
    $pmCountVisible: Boolean!
    $avgPmIntervalVisible: Boolean!
    $mfcCountVisible: Boolean!
    $avgMfcIntervalVisible: Boolean!
    $pdfWoPricesVisible: Boolean!
    $pmCphVisible: Boolean!
    $repairCphVisible: Boolean!
    $tiresCphVisible: Boolean!
    $damageCphVisible: Boolean!
    $requestCphVisible: Boolean!
    $attachCphVisible: Boolean!
    $mcCphVisible: Boolean!
    $plannedHoursVisible: Boolean!
    $avgFleetHours: Int
    $avgFleetAvailability: Int
    $kpiPeriod: String!
  ) {
    createSummary(
      input: {
        token: $token
        customers: $customers
        workOrders: $workOrders
        workOrdersExcluded: $workOrdersExcluded
        workOrdersShowcased: $workOrdersShowcased
        equipment: $equipment
        title: $title
        reportTitle: $reportTitle
        discussion: $discussion
        deliverPromise: $deliverPromise
        isCustomer: $isCustomer
        isEc: $isEc
        isTc: $isTc
        isInternal: $isInternal
        startRange: $startRange
        endRange: $endRange
        includeAllWo: $includeAllWo
        firstWoDateVisible: $firstWoDateVisible
        lastWoDateVisible: $lastWoDateVisible
        periodHoursVisible: $periodHoursVisible
        pmCountVisible: $pmCountVisible
        avgPmIntervalVisible: $avgPmIntervalVisible
        pdfWoPricesVisible: $pdfWoPricesVisible
        mfcCountVisible: $mfcCountVisible
        avgMfcIntervalVisible: $avgMfcIntervalVisible
        pmCphVisible: $pmCphVisible
        repairCphVisible: $repairCphVisible
        tiresCphVisible: $tiresCphVisible
        damageCphVisible: $damageCphVisible
        requestCphVisible: $requestCphVisible
        attachCphVisible: $attachCphVisible
        mcCphVisible: $mcCphVisible
        plannedHoursVisible: $plannedHoursVisible
        avgFleetHours: $avgFleetHours
        avgFleetAvailability: $avgFleetAvailability
        kpiPeriod: $kpiPeriod
      }
    ) {
      summary {
        id
      }
      errors
    }
  }
`

export const VALIDATE_SUMMARY_TITLE = gql`
  query ValidateSummaryTitle($token: String!, $id: Int, $title: String!) {
    validateSummaryTitle(token: $token, id: $id, title: $title)
  }
`

export const ADJUST_SUMMARY_WORK_ORDERS = gql`
  mutation AdjustSummaryWorkOrders(
    $token: String!
    $id: Int!
    $workOrders: String!
  ) {
    adjustSummaryWorkOrders(
      input: { token: $token, id: $id, workOrders: $workOrders }
    ) {
      summary {
        id
      }
    }
  }
`

export const ADJUST_SUMMARY_EXCLUDED_WORK_ORDERS = gql`
  mutation AdjustSummaryExcludedWorkOrders(
    $token: String!
    $id: Int!
    $workOrders: String!
  ) {
    adjustSummaryExcludedWorkOrders(
      input: { token: $token, id: $id, workOrders: $workOrders }
    ) {
      summary {
        id
      }
    }
  }
`

export const ADJUST_SUMMARY_SHOWCASED_WORK_ORDERS = gql`
  mutation AdjustSummaryShowcasedWorkOrders(
    $token: String!
    $id: Int!
    $workOrders: String!
  ) {
    adjustSummaryShowcasedWorkOrders(
      input: { token: $token, id: $id, workOrders: $workOrders }
    ) {
      summary {
        id
      }
    }
  }
`

export const ADJUST_SUMMARY_CUSTOMERS = gql`
  mutation AdjustSummaryCustomers(
    $token: String!
    $id: Int!
    $customers: String!
  ) {
    adjustSummaryCustomers(
      input: { token: $token, id: $id, customers: $customers }
    ) {
      summary {
        id
      }
    }
  }
`

export const ADJUST_SUMMARY_EQUIPMENT = gql`
  mutation AdjustSummaryEquipment(
    $token: String!
    $id: Int!
    $equipment: String!
  ) {
    adjustSummaryEquipment(
      input: { token: $token, id: $id, equipment: $equipment }
    ) {
      summary {
        id
      }
    }
  }
`

// Line Items
export const CREATE_LINE_ITEM = gql`
  mutation CreateLineItem(
    $token: String!
    $workOrderId: Int!
    $productGroup: String!
    $description: String!
    $quantity: Float!
    $unitPrice: Float!
    $tax: Float
    $amount: Float!
    $workSystemId: Int
    $workCategoryId: Int!
    $internalId: String
  ) {
    createLineItem(
      input: {
        token: $token
        workOrderId: $workOrderId
        productGroup: $productGroup
        description: $description
        quantity: $quantity
        unitPrice: $unitPrice
        tax: $tax
        amount: $amount
        workSystemId: $workSystemId
        workCategoryId: $workCategoryId
        internalId: $internalId
      }
    ) {
      lineItem {
        id
        description
        quantity
        unitPrice
        amount
        tax
        internalId
        productGroup
        workSystem {
          id
          value
        }
        workCategory {
          id
          value
        }
      }
      errors
    }
  }
`

export const DESTROY_CONTRACT_EQUIPMENT = gql`
  mutation DestroyContractEquipment($token: String!, $id: Int!) {
    destroyContractEquipment(input: { token: $token, id: $id }) {
      id
    }
  }
`

export const DESTROY_LINE_ITEM = gql`
  mutation DestroyLineItem($token: String!, $id: Int!) {
    destroyLineItem(input: { token: $token, id: $id }) {
      id
    }
  }
`

export const UPDATE_LINE_ITEM = gql`
  mutation UpdateLineItem(
    $token: String!
    $id: Int!
    $productGroup: String
    $description: String
    $quantity: Float
    $unitPrice: Float
    $tax: Float
    $amount: Float
    $workSystemId: Int
    $workCategoryId: Int
    $internalId: String
  ) {
    updateLineItem(
      input: {
        token: $token
        id: $id
        productGroup: $productGroup
        description: $description
        quantity: $quantity
        unitPrice: $unitPrice
        tax: $tax
        amount: $amount
        workSystemId: $workSystemId
        workCategoryId: $workCategoryId
        internalId: $internalId
      }
    ) {
      lineItem {
        id
      }
    }
  }
`

export const SPLIT_LINE_ITEM = gql`
  mutation SplitLineItem(
    $token: String!
    $id: Int!
    $attachment: Float!
    $damage: Float!
    $pm: Float!
    $repair: Float!
    $request: Float!
    $tires: Float!
  ) {
    splitLineItem(
      input: {
        token: $token
        id: $id
        attachment: $attachment
        damage: $damage
        pm: $pm
        repair: $repair
        request: $request
        tires: $tires
      }
    ) {
      lineItems {
        id
      }
    }
  }
`

// Work Orders

export const FETCH_WORK_ORDER = gql`
  query WorkOrder($token: String!, $id: Int!) {
    workOrder(token: $token, id: $id) {
      id
      serviceType
      serviceDescription
      keyOnMeter
      pumpMeter
      driveMeter
      downtime
      invoiceType
      internalId
      startedAt
      stoppedAt
      invoicedAt
      countAsPm
      countAsMfc
      po
      aasmState
      customer {
        id
        name
        internalName
        parentRelationships {
          id
          customerInternalVal
        }
      }
      equipment {
        id
        serialNumber
        model
        vendorEquipment {
          id
          vendorEquipmentId
        }
      }
      vendor {
        id
        name
      }
      lineItems {
        id
        productGroup
        description
        quantity
        unitPrice
        amount
        tax
        internalId
        workSystem {
          id
          value
        }
        workCategory {
          id
          value
        }
      }
      reviews {
        id
        reviewableType
        message
        createdAt
        completedAt
        user {
          id
          firstName
          lastName
        }
      }
      notes {
        id
        user {
          id
          firstName
          lastName
        }
        content
        createdAt
      }
    }
  }
`
export const UPDATE_WORK_ORDER = gql`
  mutation UpdateWorkOrder(
    $token: String!
    $id: Int!
    $serviceType: String
    $serviceDescription: String
    $keyOnMeter: Int
    $driveMeter: Int
    $pumpMeter: Int
    $downtime: Float
    $invoiceType: String
    $startedAt: String
    $stoppedAt: String
    $countAsPm: Boolean
    $countAsMfc: Boolean
    $aasmState: String
    $invoicedAt: String
  ) {
    updateWorkOrder(
      input: {
        token: $token
        id: $id
        serviceType: $serviceType
        serviceDescription: $serviceDescription
        keyOnMeter: $keyOnMeter
        driveMeter: $driveMeter
        pumpMeter: $pumpMeter
        downtime: $downtime
        invoiceType: $invoiceType
        startedAt: $startedAt
        stoppedAt: $stoppedAt
        countAsPm: $countAsPm
        countAsMfc: $countAsMfc
        aasmState: $aasmState
        invoicedAt: $invoicedAt
      }
    ) {
      workOrder {
        id
      }
    }
  }
`

export const RESET_WORK_CATEGORIES = gql`
  mutation ResetWorkCategories($token: String!, $id: Int!, $workCategory: Int) {
    resetWorkCategories(
      input: { token: $token, id: $id, workCategory: $workCategory }
    ) {
      workOrder {
        id
      }
    }
  }
`

// Users

export const CREATE_USER = gql`
  mutation CreateUser(
    $token: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $userGroupId: Int!
    $role: String!
  ) {
    createUser(
      input: {
        token: $token
        email: $email
        firstName: $firstName
        lastName: $lastName
        userGroupId: $userGroupId
        role: $role
      }
    ) {
      user {
        id
      }
    }
  }
`

export const FETCH_CURRENT_USER = gql`
  query CurrentUser($token: String!) {
    currentUser(token: $token) {
      id
      customers
      documentId
      documentContent
      documentName
      name
      role
      vendorId
    }
  }
`

export const SIGN_IN_USER = gql`
  mutation SignInUser($email: String!, $password: String!) {
    signInUser(input: { credentials: { email: $email, password: $password } }) {
      token
      user {
        id
        email
        firstName
        lastName
        role
        userGroup {
          id
          customer {
            id
          }
        }
      }
      errors {
        fieldName
        message
      }
    }
  }
`

export const SIGN_OUT_USER = gql`
  mutation SignOutUser($token: String!) {
    signOutUser(input: { token: $token }) {
      response
    }
  }
`

export const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(input: { email: $email }) {
      response
    }
  }
`

export const CHECK_PASSCODE = gql`
  mutation CheckPasscode($email: String!, $passcode: String!) {
    checkPasscode(input: { email: $email, passcode: $passcode }) {
      response
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!, $password: String!) {
    resetPassword(input: { email: $email, password: $password }) {
      response
    }
  }
`

export const FETCH_USER = gql`
  query User($token: String!, $id: Int) {
    user(token: $token, id: $id) {
      id
      firstName
      lastName
      role
      email
    }
  }
`

export const FETCH_CUSTOMER_DASHBOARD = gql`
  query User($token: String!) {
    user(token: $token) {
      id
      userGroup {
        id
        customer {
          id
          name
          internalName
          childRelationships {
            id
            customerInternalVal
            childCustomer {
              id
              name
              internalName
            }
          }
        }
      }
    }
  }
`

export const FETCH_USER_DASHBOARD = gql`
  query User($token: String!) {
    user(token: $token) {
      id
      summaries {
        id
        title
        startRange
        endRange
        createdAt
        customers {
          id
          name
        }
        user {
          id
          firstName
          lastName
        }
      }
      reviews(limit: 10) {
        id
        message
        createdAt
        completedAt
        reviewableType
        user {
          id
          firstName
          lastName
        }
      }
      reportedBugs {
        id
        aasmState
        description
        responder {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $token: String!
    $id: Int
    $firstName: String
    $lastName: String
    $email: String
  ) {
    updateUser(
      input: {
        token: $token
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
      }
    ) {
      user {
        id
      }
    }
  }
`

export const UPDATE_USER_PASSWORD = gql`
  mutation UpdateUserPassword(
    $token: String!
    $id: Int
    $oldPassword: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    updateUserPassword(
      input: {
        token: $token
        id: $id
        oldPassword: $oldPassword
        newPassword: $newPassword
        confirmPassword: $confirmPassword
      }
    ) {
      user {
        id
      }
      errors {
        fieldName
        message
      }
    }
  }
`

// User Groups

export const FETCH_USER_GROUP = gql`
  query UserGroup($token: String!, $id: Int!) {
    userGroup(token: $token, id: $id) {
      id
      users {
        id
        firstName
        lastName
      }
    }
  }
`

export const FETCH_ALL_USER_GROUPS = gql`
  query UserGroups($token: String!) {
    allUserGroups(token: $token) {
      id
      name
    }
  }
`

export const FETCH_ALL_WORK_SYSTEMS = gql`
  query WorkSystems($token: String!) {
    allWorkSystems(token: $token) {
      id
      value
    }
  }
`

export const FETCH_ALL_WORK_CATEGORIES = gql`
  query WorkCategories($token: String!) {
    allWorkCategories(token: $token) {
      id
      value
    }
  }
`
