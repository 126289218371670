import React, { Component } from 'react'
import { Query, Mutation } from 'react-apollo'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'

import Button from './Button'
import DropDown from './DropDown'

import { AUTH_TOKEN } from '../constants'

import {
  ASSIGN_REVIEW_TO_USER,
  FETCH_CURRENT_USER,
  FETCH_USER_GROUP,
} from '../queries'

class ReviewUserSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reviewId: props.reviewId,
      reviewerName: props.reviewerName,
      reviewerId: props.reviewerId,
    }
  }

  render() {
    const { reviewerName, reviewerId, reviewId } = this.state

    const token = localStorage.getItem(AUTH_TOKEN)

    return (
      <Query query={FETCH_CURRENT_USER} variables={{ token }}>
        {({ loading, error, data }) => {
          if (loading) return <span>{i18n.t('processing')}...</span>
          if (error) return <span>{error.message}</span>

          const currentUser = data['currentUser']

          return(
            <div>
              { (currentUser['role'] === 'admin') ? (
                <div id="admin" className="fieldContainer">
                  <Query query={FETCH_USER_GROUP} variables={{ token: token, id: currentUser['vendorId'] }}>
                    {({ loading, error, data }) => {
                      if (loading) return <span>{i18n.t('processing')}...</span>
                      if (error) return <span>{error.message}</span>

                      const users = data.userGroup.users

                      let usersForSelect = []

                      users.map((user) =>
                        usersForSelect.push({
                          key: user.id,
                          value: user.id,
                          name: `${user.firstName} ${user.lastName}`,
                        })
                      )

                      return (
                        <div>
                          <DropDown
                            id="reviewUserSelect"
                            options={usersForSelect}
                            selected={reviewerId}
                            label={i18n.t('assignedTo')}
                            rawOptions={true}
                          />
                          <Mutation mutation={ASSIGN_REVIEW_TO_USER}>
                            {(assignUserToReview, { loading, error }) => {
                              return (
                                <span>
                                  <Button
                                    id="update-review-user"
                                    text={i18n.t('updateReviewUser')}
                                    clickAction={() => {
                                      let sel = document.getElementById(
                                        'reviewUserSelect',
                                      )
                                      let userId = null
                                      if (sel.options[sel.selectedIndex].text !== '') {
                                        userId = parseInt(
                                          sel.options[sel.selectedIndex]['value'],
                                          10,
                                        )
                                      }

                                      assignUserToReview({
                                        variables: {
                                          token,
                                          userId,
                                          reviewId,
                                        },
                                      }).then(() => {
                                        window.flash(
                                          i18n.t('successfullyAssignedReview'),
                                          'success',
                                        )
                                      })
                                    }}
                                  />
                                  {loading && <p>{i18n.t('processing')}...</p>}
                                  {error && <p>{error.message}</p>}
                                </span>
                              )
                            }}
                          </Mutation>
                        </div>
                      )
                    }}
                  </Query>
                </div>
              ) : (
                <div>
                  {reviewerName === null ? (
                    <span id="review-assignee">
                      {i18n.t('noOwner')}
                      <Mutation mutation={ASSIGN_REVIEW_TO_USER}>
                        {(assignSelfToReview, { loading, error }) => {
                          return (
                            <span>
                              <Link
                                to="#"
                                onClick={() => {
                                  assignSelfToReview({
                                    variables: { token, userId: currentUser['id'], reviewId },
                                  }).then(() => {

                                    window.flash(
                                      i18n.t('successfullyAssignedReview'),
                                      'success',
                                    )
                                    document.getElementById(
                                      'review-assignee',
                                    ).textContent = i18n.t('assignedToName', {
                                      name: currentUser['name']
                                    })
                                  })
                                }}
                              >
                                {i18n.t('assignToYourself')}
                              </Link>
                              {loading && <p>{i18n.t('processing')}...</p>}
                              {error && <p>{error.message}</p>}
                            </span>
                           )
                        }}
                      </Mutation>
                    </span>
                  ) : (
                    <span>
                      {i18n.t('assignedToName', {
                        name: reviewerName,
                      })}
                    </span>
                  )}
                </div>
              )}
            </div>
          )
        }}
      </Query>
    )
  }
}

ReviewUserSelector.defaultProps = {
  reviewId: {},
  reviewerName: '',
  reviewerId: {},
}

ReviewUserSelector.propTypes = {
  reviewId: PropTypes.number,
  reviewerName: PropTypes.string,
  reviewerId: PropTypes.number,
}

export default ReviewUserSelector
