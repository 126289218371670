import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import i18n from 'i18n-js'

import TimeStamp from './TimeStamp'

const ReviewSearchResults = ({
  reviewId,
  reviewableType,
  createdAt,
  message,
  assignee,
}) => {
  const sanitizedMessage = useMemo(() => {
    const tokens = message.split(' ')

    if (tokens.length > 10) {
      return `${tokens.slice(0, 10).join(' ')} ...`
    }

    return message
  }, [message])

  return (
    <div className="review-search-result">
      <h3>
        <Link to={{ pathname: `/reviews/${reviewId}` }}>Review {reviewId}</Link>
      </h3>
      <ol>
        <li>
          <strong>{i18n.t('user').toUpperCase()}:</strong> {assignee || 'None'}
        </li>
        <li>
          <strong>{i18n.t('reviewable').toUpperCase()}:</strong>{' '}
          {reviewableType}
        </li>
        <li>
          <strong>{i18n.t('created').toUpperCase()}:</strong>{' '}
          <TimeStamp timeToFormat={createdAt} />
        </li>
        <li>
          <strong>{i18n.t('message').toUpperCase()}:</strong> {sanitizedMessage}
        </li>
      </ol>
    </div>
  )
}

ReviewSearchResults.propTypes = {
  reviewId: PropTypes.number,
  reviewableType: PropTypes.string,
  createdAt: PropTypes.string,
  message: PropTypes.string,
  assignee: PropTypes.string,
}

ReviewSearchResults.defaultProps = {
  reviewId: null,
  reviewableType: null,
  createdAt: null,
  message: null,
  assignee: null,
}

export default ReviewSearchResults
