import React, {Component} from 'react'

import {
  AUTH_TOKEN,
  WO_CUSTOMER_ID,
  WO_CUSTOMER_NAME,
  WO_EQUIP_ID,
  WO_EQUIP_SERIAL_NUM,
  WO_INTERNAL_ID,
} from '../constants'

import {
  CREATE_LINE_ITEM,
  CREATE_WORK_ORDER,
  CUSTOMER_RELATIONSHIP_SEARCH,
  FETCH_ALL_WORK_CATEGORIES,
  FETCH_ALL_WORK_SYSTEMS,
  FETCH_CURRENT_USER,
  FETCH_WORK_ORDER,
  UPDATE_LINE_ITEM,
  UPDATE_WORK_ORDER,
} from '../queries'

import {
  INVOICE_TYPES,
  PRODUCT_GROUPS,
  SERVICE_TYPES,
} from '../dropDowns'

import {
  Query,
  Mutation,
} from 'react-apollo'

import { Link, Redirect } from 'react-router-dom'
import { object } from 'prop-types'
import i18n from 'i18n-js'

import Button from '../components/Button'
import CurrencyField from '../components/CurrencyField'
import DateTimeField from '../components/DateTimeField'
import DropDown from '../components/DropDown'
import NumberField from '../components/NumberField'
import SignInUser from '../components/SignInUser'
import TextArea from '../components/TextArea'
import TextField from '../components/TextField'

import '../styles/invoice.css'

class NewWorkOrder3 extends Component {
  constructor(props) {
    super(props)

    let now = new Date()
    let year = now.getFullYear()
    let month = now.getMonth() + 1
    let day = now.getDate()
    let hours = now.getHours()
    let min = now.getMinutes()

    if (month.toString().length === 1) {
      month = `0${month}`
    }

    if (day.toString().length === 1) {
      day = `0${day}`
    }

    if (hours.toString().length === 1) {
      hours = `0${hours}`
    }

    if (min.toString().length === 1) {
      min = `0${min}`
    }

    const nowString = `${year}-${month}-${day} ${hours}:${min}` 

    this.changeLocalSearch = this.changeLocalSearch.bind(this)

    this.state = {
      workOrderId: localStorage.getItem(WO_INTERNAL_ID),
      now: nowString,
      countAsPm: false,
      countAsMfc: false,
      workOrder: null,
      search: null,
      searchHide: true,
      vendor: null,
      description: '',
      quantity: '',
      unitPrice: '',
      tax: '',
      amount: '',
    }
  }

  changeLocalSearch = async (event) => {
    const search = event.target.value
    const searchHide = (search === '')

    this.setState({
      search,
      searchHide,
    })
    return ''
  }

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser/>

    const {
      workOrderId,
      now,
      countAsPm,
      countAsMfc,
      search,
      searchHide,
      description,
      quantity,
      unitPrice,
      tax,
      amount,
    } = this.state

    let workOrder = this.state.workOrder
    let vendor = this.state.vendor

    const customerId = parseInt(localStorage.getItem(WO_CUSTOMER_ID), 10)
    const customerName = localStorage.getItem(WO_CUSTOMER_NAME)

    const equipmentId = parseInt(localStorage.getItem(WO_EQUIP_ID), 10)
    const equipSerialNum = localStorage.getItem(WO_EQUIP_SERIAL_NUM)

    const searchClass = searchHide ? 'hidden' : 'visible'

    return(
      <Query query={FETCH_CURRENT_USER} variables={{token}}>
        {({loading, error, data}) => {
          if (loading) return <div>{i18n.t('fetching')}</div>
          if (error) return <div>{error.message}</div>

           const currentUser = data.currentUser

           if (currentUser.role === 'customer') return <Redirect to='/' />

           const vendorId = parseInt(currentUser.vendorId, 10)

          return(
            <div>
              <h3>{i18n.t('createNewWorkOrder')}</h3>
              <div>
                <strong>{i18n.t('customerSelected')}: </strong>
                {customerName} { (workOrderId === null) &&
                  <Link to='/new-work-order/1'>{i18n.t('edit')}</Link>
                }
              </div>
              <div>
                <strong>{i18n.t('equipmentSelected')}: </strong>
                {equipSerialNum} { (workOrderId === null) &&
                  <Link to='/new-work-order/2'>{i18n.t('edit')}</Link>
                }
              </div>
              { currentUser.role === 'admin' &&
                <div>
                  <h3>{i18n.t('vendor')}</h3>
                  { vendor === null ?
                    <div>
                      <div id='localSearchContainer'>
                        <input
                          id='local-search-field'
                          type='text'
                          className='text-field'
                          placeholder={i18n.t('searchCustomerByNameNumber')}
                          onChange={this.changeLocalSearch.bind(this)}
                        />
                        <span id='local-search-error' className='hidden field-error'>
                          {i18n.t('selectACustomer')}
                        </span>
                      </div>

                      <div id='localSearchResults' className={searchClass}>
                        <Query query={CUSTOMER_RELATIONSHIP_SEARCH} variables={{token, search, vendorId}}>
                          {({ loading, error, data }) => {
                            if (loading) return <div className='report'>{i18n.t('fetching')}</div>
                            if (error) return <div className='report'>{error.message}</div>

                            const results = data.searchCustomerRelationships

                            if (search.length < 4) {
                              return <div className='report'>{i18n.t('typeFourOrMoreChar')}</div>
                            }

                            if (results.length === 0) {
                              return <div className='report'>{i18n.t('noResultsFound')}</div>
                            }

                            return(
                              <div>
                                { results.map( result =>
                                  <div key={result.id} className='searchResult'>
                                    <Link to='#' onClick={async () => {
                                      this.setState({
                                        vendor: result
                                      })
                                      vendor = result
                                    } }>
                                      {result.customerInternalVal}<br />
                                      {result.childCustomer.name} - {result.childCustomer.internalName}
                                    </Link>
                                  </div>
                                ) }
                              </div>
                            )
                          } }
                        </Query>
                      </div>
                    </div> :
                    <div>
                      {vendor.customerInternalVal}<br />
                      {vendor.childCustomer.name} - {vendor.childCustomer.internalName}
                      <Link to='#' onClick={async () => {
                        this.setState({ vendor: null })
                        vendor = null
                      } }>
                        &nbsp;{i18n.t('edit')}
                      </Link>
                    </div>
                  }
                </div>
              }
              { (workOrderId === null) ?
                <div>
                  <TextField
                    id='workOrderId'
                    label={`${i18n.t('actualWorkOrderId')} *`}
                    placeholder={i18n.t('actualWorkOrderId')}
                  />
                  <TextField
                    id='po'
                    label={i18n.t('po')}
                    placeholder={i18n.t('po')}
                  />
                  <DropDown
                    id='invoiceType'
                    label={i18n.t('invoiceType')}
                    options={INVOICE_TYPES}
                    selected={i18n.t('customer')}
                    allowNull='false'
                  />
                  <DateTimeField
                    id='invoicedAt'
                    label={`${i18n.t('invoicedAt')} *`}
                    value={now}
                  />
                  <DropDown
                    id='serviceType'
                    label={`${i18n.t('serviceType')} *`}
                    options={SERVICE_TYPES}
                    selected='PM'
                    allowNull='false'
                  />
                  <TextArea
                    id='serviceDescription'
                    label={i18n.t('serviceDescription')}
                    placeholder={i18n.t('pertinentInformationHere')}
                  />
                  <NumberField
                    id='keyOnMeter'
                    label={`${i18n.t('keyOnMeter')} *`}
                    placeholder={i18n.t('keyOnMeter')}
                    min='0'
                    step='1'
                  />
                  <NumberField
                    id='driveMeter'
                    label={i18n.t('driveMeter')}
                    placeholder={i18n.t('driveMeter')}
                    min='0'
                    step='1'
                  />
                  <NumberField
                    id='pumpMeter'
                    label={i18n.t('pumpMeter')}
                    placeholder={i18n.t('pumpMeter')}
                    min='0'
                    step='1'
                  />
                  <DateTimeField
                    id='startedAt'
                    label={i18n.t('startedAt')}
                    value={now}
                  />
                  <DateTimeField
                    id='stoppedAt'
                    label={i18n.t('stoppedAt')}
                    value={now}
                  />
                  <NumberField
                    id='downtime'
                    label={i18n.t('downtime')}
                    placeholder={i18n.t('downtime')}
                    min='0'
                  />
                  <div className='fieldContainer'>
                    <div className='fieldLabel'>
                      <label>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          id='countAsPm'
                          checked={this.state.countAsPm}
                          onChange={() => {
                            this.setState({ countAsPm: !this.state.countAsPm })
                          } }
                        />
                        {i18n.t('expandedCountAsPm')}
                      </label>
                    </div>
                  </div>
                  <div className='fieldContainer'>
                    <div className='fieldLabel'>
                      <label>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          id='countAsMfc'
                          checked={this.state.countAsMfc}
                          onChange={() => {
                            this.setState({ countAsMfc: !this.state.countAsMfc })
                          } }
                        />
                        {i18n.t('expandedCountAsMfc')}
                      </label>
                    </div>
                  </div>
                  <Mutation mutation={CREATE_WORK_ORDER}>
                    {(createWorkOrder, { loading, error }) => {
                      return(
                        <div>
                          <Button
                            id='submit-work-order'
                            text={i18n.t('submit')}
                            clickAction={() => {
                              const internalId = document.getElementById('workOrderId').value
                              const invoicedAt = `${document.getElementById('invoicedAt-date').value} ${document.getElementById('invoicedAt-hour').value}:${document.getElementById('invoicedAt-minute').value}`
                              const invoiceType = document.getElementById('invoiceType').value
                              const serviceType = document.getElementById('serviceType').value

                              const invoiceErr = document.getElementById('workOrderId-error')

                              if (internalId === '') {
                                document.getElementById('workOrderId').classList.add('error')
                                invoiceErr.innerHTML = i18n.t('workOrderIdError')
                                invoiceErr.classList.add('show')
                                invoiceErr.classList.remove('hidden')
             
                                window.flash(i18n.t('validationsNotMet'), 'error')
                                return ''
                              }

                              document.getElementById('workOrderId').classList.remove('error')
                              invoiceErr.classList.remove('show')
                              invoiceErr.classList.add('hidden')

                              const serviceDescription = document.getElementById('serviceDescription').value
                              const startedAt = `${document.getElementById('startedAt-date').value} ${document.getElementById('startedAt-hour').value}:${document.getElementById('startedAt-minute').value}`
                              const stoppedAt = `${document.getElementById('stoppedAt-date').value} ${document.getElementById('stoppedAt-hour').value}:${document.getElementById('stoppedAt-minute').value}`

                              let keyOnMeter =  0
                              let driveMeter = null
                              let pumpMeter = null
                              let downtime = 0

                              if (document.getElementById('keyOnMeter').value !== '') {
                                keyOnMeter = parseInt(document.getElementById('keyOnMeter').value, 10)
                              }

                              if (document.getElementById('driveMeter').value !== '') {
                                driveMeter = parseInt(document.getElementById('driveMeter').value, 10)
                              }

                              if (document.getElementById('pumpMeter').value !== '') {
                                pumpMeter = parseInt(document.getElementById('pumpMeter').value, 10)
                              }

                              if (document.getElementById('downtime').value !== '') {
                                downtime = parseFloat(document.getElementById('downtime').value)
                              }

                              let woVendorId = vendorId
                              if ((vendor !== null) && (currentUser.role !== 'vendor')) {
                                woVendorId = vendor.childCustomer.id
                              }

                              createWorkOrder({
                                variables: {
                                  token,
                                  customerId,
                                  equipmentId,
                                  vendorId: woVendorId,
                                  internalId,
                                  invoicedAt,
                                  invoiceType,
                                  serviceType,
                                  serviceDescription,
                                  keyOnMeter,
                                  driveMeter,
                                  pumpMeter,
                                  downtime,
                                  startedAt,
                                  stoppedAt,
                                  countAsPm,
                                  countAsMfc,
                                }
                              }).then( res => {
                                const workOrder = res.data.createWorkOrder.workOrder
                                const workOrderId = parseInt(workOrder.id, 10)

                                localStorage.setItem(WO_INTERNAL_ID, workOrderId)
                                this.setState({workOrderId})
                                window.flash(i18n.t('createdNewWorkOrder'), 'success')
                              })
                            } }
                          />
                          { loading && <p>{i18n.t('processing')}</p> }
                          { error && <p>{error.message}</p> }
                        </div>
                      )
                    } }
                  </Mutation> 
                </div> :
                <div>
                  <Query query={FETCH_WORK_ORDER} variables={{ token, id: parseInt(workOrderId, 10) }}>
                    {({loading, error, data}) => {
                      if (loading) return <div>{i18n.t('fetching')}</div>
                      if (error) return <div>{error.message}</div>

                      if (workOrder === null) {
                        workOrder = data.workOrder
                        this.setState({workOrder})
                      }

                      let keyOnMeter = 0
                      let driveMeter = null
                      let pumpMeter = null

                      if (workOrder.keyOnMeter !== null) {
                        keyOnMeter = parseFloat(workOrder.keyOnMeter)
                      }

                      if (workOrder.driveMeter !== null) {
                        driveMeter = parseFloat(workOrder.driveMeter)
                      }

                      if (workOrder.pumpMeter !== null) {
                        pumpMeter = parseFloat(workOrder.pumpMeter)
                      }

                      return (
                        <div>
                          <div>
                            <DropDown
                              id='invoiceType'
                              label={i18n.t('invoiceType')}
                              options={INVOICE_TYPES}
                              selected={workOrder.invoiceType}
                              allowNull='false'
                            />
                            <DateTimeField
                              id='invoicedAt'
                              label={`${i18n.t('invoicedAt')} *`}
                              value={workOrder.invoicedAt}
                            />
                            <DropDown
                              id='serviceType'
                              label={`${i18n.t('serviceType')} *`}
                              options={SERVICE_TYPES}
                              selected={workOrder.serviceType}
                              allowNull='false'
                            />
                            <TextArea
                              id='serviceDescription'
                              label={i18n.t('serviceDescription')}
                              placeholder={i18n.t('pertinentInformationHere')}
                              content={workOrder.serviceDescription}
                            />
                            <NumberField
                              id='keyOnMeter'
                              label={`${i18n.t('keyOnMeter')} *`}
                              placeholder={i18n.t('keyOnMeter')}
                              value={keyOnMeter}
                              min='0'
                              step='1'
                            />
                            <NumberField
                              id='driveMeter'
                              label={i18n.t('driveMeter')}
                              placeholder={i18n.t('driveMeter')}
                              value={driveMeter}
                              min='0'
                              step='1'
                            />
                            <NumberField
                              id='pumpMeter'
                              label={i18n.t('pumpMeter')}
                              placeholder={i18n.t('pumpMeter')}
                              value={pumpMeter}
                              min='0'
                              step='1'
                            />
                            <DateTimeField
                              id='startedAt'
                              label={i18n.t('startedAt')}
                              value={workOrder.startedAt}
                            />
                            <DateTimeField
                              id='stoppedAt'
                              label={i18n.t('stoppedAt')}
                              value={workOrder.stoppedAt}
                            />
                            <NumberField
                              id='downtime'
                              label={i18n.t('downtime')}
                              placeholder={i18n.t('downtime')}
                              value={workOrder.downtime}
                              min='0'
                            />
                            <Mutation mutation={UPDATE_WORK_ORDER}>
                              { (updateWorkOrder, {data, loading, error}) => {
                                return(
                                  <div>
                                    <Button
                                      id='update-work-order'
                                      text={i18n.t('update')}
                                      clickAction={() => {
                                        const id = parseInt(workOrderId, 10)
                                        const invoicedAt = `${document.getElementById('invoicedAt-date').value} ${document.getElementById('invoicedAt-hour').value}:${document.getElementById('invoicedAt-minute').value}`
                                        const invoiceType = document.getElementById('invoiceType').value
                                        const serviceType = document.getElementById('serviceType').value
                                        const serviceDescription =
                                          document.getElementById('serviceDescription').value
                                        const startedAt = `${document.getElementById('startedAt-date').value} ${document.getElementById('startedAt-hour').value}:${document.getElementById('startedAt-minute').value}`
                                        const stoppedAt = `${document.getElementById('stoppedAt-date').value} ${document.getElementById('stoppedAt-hour').value}:${document.getElementById('stoppedAt-minute').value}`

                                        let keyOnMeter =  0
                                        let driveMeter = null
                                        let pumpMeter = null
                                        let downtime = 0 

                                        if (document.getElementById('keyOnMeter').value !== '') {
                                          keyOnMeter = parseInt(document.getElementById('keyOnMeter').value, 10)
                                        }

                                        if (document.getElementById('driveMeter').value !== '') {
                                          driveMeter = parseInt(document.getElementById('driveMeter').value, 10)
                                        }

                                        if (document.getElementById('pumpMeter').value !== '') {
                                          pumpMeter = parseInt(document.getElementById('pumpMeter').value, 10)
                                        }

                                        if (document.getElementById('downtime').value !== '') {
                                          downtime = parseFloat(document.getElementById('downtime').value)
                                        }

                                        updateWorkOrder({
                                          variables: {
                                            token,
                                            id,
                                            invoicedAt,
                                            invoiceType,
                                            serviceType,
                                            serviceDescription,
                                            keyOnMeter,
                                            driveMeter,
                                            pumpMeter,
                                            downtime,
                                            startedAt,
                                            stoppedAt,
                                          }
                                        }).then( () => {
                                          if (data.errors.length > 0) {
                                            console.log('oops')
                                          }
                                          window.flash(i18n.t('updatedWorkOrder'), 'success')
                                        })
                                      } }
                                    />
                                    { loading && <p>{i18n.t('processing')}</p> }
                                    { error && <p>{error.message}</p> }
                                  </div>
                                )
                              } }
                            </Mutation>
                            <div>
                              <table>
                                <thead>
                                  <tr>
                                    <th>{`${i18n.t('productGroup')} *`}</th>
                                    <th>{`${i18n.t('description')} *`}</th>
                                    <th>{`${i18n.t('quantity')} *`}</th>
                                    <th>{`${i18n.t('unitPrice')} *`}</th>
                                    <th>{i18n.t('tax')}</th>
                                    <th>{`${i18n.t('amount')} *`}</th>
                                    <th>{i18n.t('workSystem')}</th>
                                    <th>{`${i18n.t('workCategory')} *`}</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <DropDown
                                        id='new-li-product-group'
                                        options={PRODUCT_GROUPS}
                                        allowNull='false'
                                      />
                                    </td>
                                    <td>
                                      <div className='fieldContainer'>
                                        <div className='field'>
                                          <input
                                            id='new-li-description'
                                            placeholder={i18n.t('description')}
                                            value={description}
                                            className='text-field'
                                            onChange={(event) => {
                                              let value = event.target.value
                                              this.setState({description: value})
                                            } }
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className='fieldContainer'>
                                        <div className='field'>
                                          <input
                                            id='new-li-quantity'
                                            placeholder={i18n.t('quantity')}
                                            value={quantity}
                                            type='number'
                                            min={0}
                                            className='text-field'
                                            onChange={(event) => {
                                              let value = parseFloat(event.target.value)
                                              this.setState({quantity: value})
                                            } }
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className='fieldContainer'>
                                        <div className='field currency'>
                                          <span className='currency-sign'>$</span>
                                          <input
                                            id='new-li-unit-price'
                                            placeholder={i18n.t('unitPrice')}
                                            className='currency'
                                            value={unitPrice}
                                            type='number'
                                            min='0.00'
                                            step='0.01'
                                            onChange={(event) => {
                                              let value = parseFloat(event.target.value)
                                              this.setState({unitPrice: value})
                                            } }
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className='fieldContainer'>
                                        <div className='field currency'>
                                          <span className='currency-sign'>$</span>
                                          <input
                                            id='new-li-tax'
                                            placeholder={i18n.t('tax')}
                                            className='currency'
                                            value={tax}
                                            type='number'
                                            min='0.00'
                                            step='0.01'
                                            onChange={(event) => {
                                              let value = parseFloat(event.target.value)
                                              this.setState({tax: value})
                                            } }
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className='fieldContainer'>
                                        <div className='field currency'>
                                          <span className='currency-sign'>$</span>
                                          <input
                                            id='new-li-amount'
                                            placeholder={i18n.t('amount')}
                                            className='currency'
                                            value={amount}
                                            type='number'
                                            min='0.00'
                                            step='0.01'
                                            onChange={(event) => {
                                              let value = parseFloat(event.target.value)
                                              this.setState({amount: value})
                                            } }
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <Query query={FETCH_ALL_WORK_SYSTEMS} variables={{token}}>
                                        {({ loading, error, data }) => {
                                          if (loading) return <span>{i18n.t('fetching')}</span>
                                          if (error) return <span>{error.message}</span>

                                          let workSystems = []
                                          data.allWorkSystems.map((ws) =>
                                            workSystems.push({
                                              key: ws.id,
                                              value: ws.id,
                                              name: ws.value,
                                            })
                                          )
                                          return (
                                            <DropDown
                                              id='new-li-work-system'
                                              options={workSystems}
                                            />
                                          )
                                        } }
                                      </Query>
                                    </td>
                                    <td>
                                      <Query query={FETCH_ALL_WORK_CATEGORIES} variables={{token}}>
                                        {({ loading, error, data }) => {
                                          if (loading) return <span>{i18n.t('fetching')}</span>
                                          if (error) return <span>{error.message}</span>

                                          let workCategories = []
                                          data.allWorkCategories.map((wc) =>
                                            workCategories.push({
                                              key: wc.id,
                                              value: wc.id,
                                              name: wc.value.toLowerCase(),
                                            })
                                          )
                                          return (
                                            <DropDown
                                              id='new-li-work-category'
                                              options={workCategories}
                                              allowNull='false'
                                            />
                                          )
                                        } }
                                      </Query>
                                    </td>
                                    <td>
                                      <Mutation mutation={CREATE_LINE_ITEM}>
                                        {(createLineItem, { loading, error }) => {
                                          return(
                                            <div>
                                              <Button
                                                id='new-li-button'
                                                text={i18n.t('submit')}
                                                clickAction={() => {
                                                  if (
                                                    (description === '') ||
                                                    (quantity === '') ||
                                                    (unitPrice === '') ||
                                                    (amount === '')
                                                  ) {
                                                    if (description === '') {
                                                      document.getElementById('new-li-description')
                                                        .classList.add('error')
                                                    }

                                                    if (quantity === '') {
                                                      document.getElementById('new-li-quantity')
                                                        .classList.add('error')
                                                    }

                                                    if (unitPrice === '') {
                                                      document.getElementById('new-li-unit-price')
                                                        .classList.add('error')
                                                    }

                                                    if (amount === '') {
                                                      document.getElementById('new-li-amount')
                                                        .classList.add('error')
                                                    }
                                  
                                                    window.flash(i18n.t('validationsNotMet'), 'error')
                                                    return ''
                                                  }

                                                  document.getElementById('new-li-description')
                                                    .classList.remove('error')
                                                  document.getElementById('new-li-quantity')
                                                    .classList.remove('error')
                                                  document.getElementById('new-li-unit-price')
                                                    .classList.remove('error')
                                                  document.getElementById('new-li-amount')
                                                    .classList.remove('error')

                                                  const productGroup =
                                                    document.getElementById('new-li-product-group').value

                                                  let workSystemId = null

                                                  if (
                                                    document.getElementById('new-li-work-system').value !== ''
                                                  ) {
                                                    workSystemId =
                                                      parseInt(
                                                        document.getElementById('new-li-work-system').value,
                                                        10,
                                                      )
                                                  }

                                                  const workCategoryId =
                                                    parseInt(
                                                      document.getElementById('new-li-work-category').value,
                                                      10,
                                                    )

                                                  let taxVal = 0

                                                  if (tax !== '') {
                                                    taxVal = tax
                                                  }

                                                  createLineItem({
                                                    variables: {
                                                      token,
                                                      workOrderId: parseInt(workOrderId, 10),
                                                      description,
                                                      productGroup,
                                                      quantity,
                                                      unitPrice,
                                                      tax: taxVal,
                                                      amount,
                                                      workSystemId,
                                                      workCategoryId,
                                                    }
                                                  }).then( res => {
                                                    let liData = res.data.createLineItem

                                                    if (liData.errors.length > 0) {
                                                      console.log('oops')
                                                    }

                                                    window.flash(i18n.t('createdNewLineItem'), 'success')

                                                    let newLineItem = {
                                                      id: parseInt(liData.lineItem.id, 10),
                                                      description,
                                                      quantity,
                                                      productGroup,
                                                      amount,
                                                      tax: taxVal,
                                                      unitPrice,
                                                      workSystem: {
                                                        id: workSystemId,
                                                      },
                                                      workCategory: {
                                                        id: workCategoryId,
                                                      },
                                                    }
                                                    workOrder.lineItems.unshift(newLineItem)

                                                    this.setState({
                                                      workOrder,
                                                      description: '',
                                                      quantity: '',
                                                      unitPrice: '',
                                                      tax: '',
                                                      amount: ''
                                                    })

                                                    document.getElementById('new-li-product-group').value =
                                                      'PARTS'
                                                    document.getElementById('new-li-work-system').value = ''
                                                  })
                                                } }
                                              />
                                              {loading && <p>{i18n.t('processing')}</p>}
                                              {error && <p>{error.message}</p>}
                                            </div>
                                          )
                                        } }
                                      </Mutation>
                                    </td>
                                  </tr>
                                  {workOrder.lineItems.map((lineItem) => (
                                    <tr key={lineItem.id}>
                                      <td>
                                        <DropDown
                                          id={`product-group-${lineItem.id}`}
                                          options={PRODUCT_GROUPS}
                                          selected={lineItem.productGroup}
                                          allowNull='false'
                                        />
                                      </td>
                                      <td>
                                        <TextField
                                          id={`description-${lineItem.id}`}
                                          placeholder={i18n.t('description')}
                                          value={lineItem.description}
                                        />
                                      </td>
                                      <td>
                                        <NumberField
                                          id={`quantity-${lineItem.id}`}
                                          placeholder={i18n.t('quantity')}
                                          value={lineItem.quantity}
                                          min={0}
                                        />
                                      </td>
                                      <td>
                                        <CurrencyField
                                          id={`unit-price-${lineItem.id}`}
                                          placeholder={i18n.t('unitPrice')}
                                          value={lineItem.unitPrice}
                                        />
                                      </td>
                                      <td>
                                        <CurrencyField
                                          id={`tax-${lineItem.id}`}
                                          placeholder={i18n.t('tax')}
                                          value={lineItem.tax}
                                        />
                                      </td>
                                      <td>
                                        <CurrencyField
                                          id={`amount-${lineItem.id}`}
                                          placeholder={i18n.t('amount')}
                                          value={lineItem.amount}
                                        />
                                      </td>
                                      <td>
                                        <Query query={FETCH_ALL_WORK_SYSTEMS} variables={{token}}>
                                          {({ loading, error, data }) => {
                                            if (loading) return <span>{i18n.t('fetching')}</span>
                                            if (error) return <span>{error.message}</span>

                                            let workSystems = []
                                            data.allWorkSystems.map((ws) =>
                                              workSystems.push({
                                                key: ws.id,
                                                value: ws.id,
                                                name: ws.value,
                                              })
                                            )

                                            return (
                                              <DropDown
                                                id={`work-system-${lineItem.id}`}
                                                options={workSystems}
                                                selected={lineItem.workSystem === null ? null : lineItem.workSystem.id}
                                              />
                                            )
                                          } }
                                        </Query>
                                      </td>
                                      <td>
                                        <Query query={FETCH_ALL_WORK_CATEGORIES} variables={{token}}>
                                          {({ loading, error, data }) => {
                                            if (loading) return <span>{i18n.t('fetching')}</span>
                                            if (error) return <span>{error.message}</span>

                                            let workCategories = []
                                            data.allWorkCategories.map((wc) =>
                                              workCategories.push({
                                                key: wc.id,
                                                value: wc.id,
                                                name: wc.value.toLowerCase(),
                                              })
                                            )
                                            return (
                                              <DropDown
                                                id={`work-category-${lineItem.id}`}
                                                options={workCategories}
                                                allowNull='false'
                                                selected={lineItem.workCategory.id}
                                              />
                                            )
                                          } }
                                        </Query>
                                      </td>
                                      <td>
                                        <Mutation mutation={UPDATE_LINE_ITEM}>
                                          {(updateLineItem, { loading, error }) => {
                                            return(
                                              <div>
                                                <Button
                                                  id={`update-line-item-${lineItem.id}`}
                                                  text={i18n.t('update')}
                                                  clickAction={() => {
                                                    const description =
                                                      document.getElementById(`description-${lineItem.id}`).value
                                                    let quantity =
                                                      document.getElementById(`quantity-${lineItem.id}`).value
                                                    let unitPrice =
                                                      document.getElementById(`unit-price-${lineItem.id}`).value
                                                    let amount =
                                                      document.getElementById(`amount-${lineItem.id}`).value
                                                    let tax =
                                                      document.getElementById(`tax-${lineItem.id}`).value

                                                    if (
                                                      (description === '') ||
                                                      (quantity === '') ||
                                                      (unitPrice === '') ||
                                                      (amount === '')
                                                    ) {
                                                      if (description === '') {
                                                        document.getElementById(`description-${lineItem.id}`)
                                                          .classList.add('error')
                                                      }

                                                      if (quantity === '') {
                                                        document.getElementById(`quantity-${lineItem.id}`)
                                                          .classList.add('error')
                                                      }

                                                      if (unitPrice === '') {
                                                        document.getElementById(`unit-price-${lineItem.id}`)
                                                          .classList.add('error')
                                                      }

                                                      if (amount === '') {
                                                        document.getElementById(`amount-${lineItem.id}`)
                                                          .classList.add('error')
                                                      }

                                                      window.flash(i18n.t('validationsNotMet'), 'error')
                                                      return ''
                                                    }

                                                    quantity = parseFloat(quantity)
                                                    unitPrice = parseFloat(unitPrice)
                                                    amount = parseFloat(amount)

                                                    if (tax !== '') {
                                                      tax = parseFloat(tax)
                                                    } else {
                                                      tax = null
                                                    }

                                                    document.getElementById(`description-${lineItem.id}`)
                                                      .classList.remove('error')
                                                    document.getElementById(`quantity-${lineItem.id}`)
                                                      .classList.remove('error')
                                                    document.getElementById(`unit-price-${lineItem.id}`)
                                                      .classList.remove('error')
                                                    document.getElementById(`amount-${lineItem.id}`)
                                                      .classList.remove('error')

                                                    const productGroup =
                                                      document.getElementById(`product-group-${lineItem.id}`)
                                                        .value

                                                    let workSystemId = null
                                                    if (
                                                      document.getElementById(`work-system-${lineItem.id}`)
                                                        .value !== ''
                                                    ) {
                                                      workSystemId =
                                                        parseInt(
                                                          document.getElementById(`work-system-${lineItem.id}`)
                                                            .value,
                                                          10,
                                                        )
                                                    }

                                                    const workCategoryId =
                                                      parseInt(
                                                        document.getElementById(`work-category-${lineItem.id}`)
                                                          .value,
                                                        10,
                                                      )

                                                    updateLineItem({
                                                      variables: {
                                                        token,
                                                        id: parseInt(lineItem.id, 10),
                                                        description,
                                                        productGroup,
                                                        quantity,
                                                        unitPrice,
                                                        tax,
                                                        amount,
                                                        workSystemId,
                                                        workCategoryId,
                                                      }
                                                    }).then( () => {
                                                      window.flash(i18n.t('updatedLineItem'), 'success')
                                                    })
                                                  } }
                                                />
                                                {loading && <p>{i18n.t('processing')}</p>}
                                                {error && <p>{error.message}</p>}
                                              </div>
                                            )
                                          } }
                                        </Mutation>
                                      </td>
                                    </tr>

                                  ))}
                                </tbody>
                              </table>
                              <Button
                                id='finish-work-order'
                                text={i18n.t('finishWorkOrder')}
                                clickAction={() => {
                                  localStorage.removeItem(WO_CUSTOMER_ID)
                                  localStorage.removeItem(WO_CUSTOMER_NAME)
                                  localStorage.removeItem(WO_EQUIP_ID)
                                  localStorage.removeItem(WO_EQUIP_SERIAL_NUM)
                                  localStorage.removeItem(WO_INTERNAL_ID)

                                  this.props.history.push(`/`)
                                } }
                              />
                            </div>
                          </div>
                        </div>
                       )
                     } }
                  </Query>
                </div>
              }
            </div>
          )
        } }
      </Query>
    )
  }
}

NewWorkOrder3.defaultProps = {
  history: {},
}

NewWorkOrder3.propTypes = {
  history:object,
}

export default NewWorkOrder3
