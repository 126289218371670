import React, {Component} from 'react'
import { Mutation } from 'react-apollo'
import i18n from 'i18n-js'

import Button from './Button'
import TextField from './TextField'

import { Redirect } from 'react-router-dom'

import {
  AUTH_TOKEN,
  CURRENT_EMAIL,
  RESET_TOKEN,
} from '../constants'

import {
  CHECK_PASSCODE,
} from '../queries'

class CheckCode extends Component {
  constructor(props) {
    super(props)

    this.state = {
      passcode: '',
    }
  }

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)
    if (token !== null) return <Redirect to='/' />

    const email = localStorage.getItem(CURRENT_EMAIL)
    if (email === null) return <Redirect to='/forgot-password' />

    const resetToken = localStorage.getItem(RESET_TOKEN)
    if (resetToken !== null) return <Redirect to='/' />

    const {
      passcode,
    } = this.state

    return(
      <div id='sign-in-box'>
        <h4>{i18n.t('resetPassword')}</h4>
        <div>
          {i18n.t('resetPasswordInstructions')}
        </div>
        <div>
          <TextField
            id='passcode'
            value={passcode}
            placeholder={i18n.t('enterPasscode')}
            label={i18n.t('passcode')}
          />
        </div>
        <div>
          <Mutation mutation={CHECK_PASSCODE}>
            {(checkPasscode) => {
              return(
                <Button
                  id='check-code'
                  text={i18n.t('checkCode')}
                  klass='sign-in'
                  clickAction={() => {
                    let passcodeVal = document.getElementById('passcode').value

                    checkPasscode({
                      variables: {
                        email,
                        passcode: passcodeVal,
                      }
                    }).then((res) => {
                      if (res.data.checkPasscode.response === 'true') {
                        localStorage.setItem(RESET_TOKEN, true)
                        window.location.href = '/reset-password'
                      } else {
                        window.flash(res.data.checkPasscode.response, 'error')
                      }
                    })
                  } }
                />
              )
            } }
          </Mutation>
        </div>
      </div>
    )
  }
}

export default CheckCode
