import React, { Component } from 'react'
import { Query } from 'react-apollo'
import {
  Link,
  Redirect,
} from 'react-router-dom'

import i18n from 'i18n-js'

import SignInUser from './SignInUser'
import TimeStamp from './TimeStamp'

import { AUTH_TOKEN } from '../constants'

import {
  FETCH_ALL_CONTRACTS,
  FETCH_CURRENT_USER,
} from '../queries'

class AllContracts extends Component {

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser />

    return(
      <Query query={FETCH_CURRENT_USER} variables={{ token }}>
        {({ loading, error, data }) => {
          if (loading) return <div>{i18n.t('fetching')}</div>
          if (error) return <div>{error.message}</div>

          const currentUser = data['currentUser']

          if (currentUser['role'] !== 'admin') return <Redirect to='/' />

          return(
            <Query query={FETCH_ALL_CONTRACTS} variables={{ token }}>
              {({ loading, error, data }) => {
                if (loading) return <div>{i18n.t('fetching')}</div>
                if (error) return <div>{error.message}</div>

                const contracts = data.allContracts

                return (
                  <div>
                    <h3>{i18n.t('allContracts')}</h3>

                    {contracts.map((contract) => {

                      return(
                        <div key={contract.id}>
                          <h4>
                            <Link to={`contract/${contract.id}`}>
                              {contract.internalName}
                            </Link>
                          </h4>
                          <div>
                            <strong>{contract.billingValue}</strong>
                          </div>
                          <div>
                            <strong>{i18n.t('customers')}: </strong>
                            <ul>
                              {contract.customers.map((customer) => {
                                return(
                                  <li key={customer.id}>
                                    {customer.parentRelationships[0].customerInternalVal} - {customer.name}
                                  </li>
                                )
                              } ) }
                            </ul>
                          </div>
                          <div>
                            <strong>{i18n.t('startRange')}: </strong>
                            <TimeStamp timeToFormat={contract.startRange} />
                          </div>
                          <div>
                            <strong>{i18n.t('endRange')}: </strong>
                            <TimeStamp timeToFormat={contract.endRange} />
                          </div>
                          <hr />
                        </div>
                      )
                    } ) }
                  </div>
                )
              } }
            </Query>
          )
        } }
      </Query>
    )
  }
}

export default AllContracts
