import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'

import ReviewSearchResults from './ReviewSearchResults'

class AllReviews extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reviews: props.reviews,
      showHide: 'hidden',
    }
  }

  render() {
    const { reviews, showHide } = this.state

    return (
      <div>
        <Link to="#" onClick={this.toggleDisplay.bind(this)}>
          <div className="title-toggle">
            <h4>{i18n.t('reviews')}</h4>
          </div>
        </Link>
        {reviews.length <= 0 ? (
          <div className={showHide}>{i18n.t('noReviewsFound')}</div>
        ) : (
          <div className={showHide}>
            {reviews.map((review) => {
              if (review.completedAt !== null) {
                return ''
              }

              return (
                <div key={review.id}>
                  <ReviewSearchResults
                    reviewId={review.id}
                    reviewableType={review.reviewableType}
                    message={review.message}
                    createdAt={review.createdAt}
                    completed={review.completedAt !== null}
                    assignee={
                      review.user === null
                        ? null
                        : `${review.user.firstName} ${review.user.lastName}`
                    }
                  />
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }

  toggleDisplay() {
    var css = this.state.showHide === 'hidden' ? 'visible' : 'hidden'
    this.setState({ showHide: css })
  }
}

AllReviews.defaultProps = {
  reviews: [],
}

AllReviews.propTypes = {
  reviews: PropTypes.array,
}

export default AllReviews
