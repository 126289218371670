import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { Link } from 'react-router-dom'
import FontAwesome from 'react-fontawesome'
import { createBrowserHistory } from 'history'
import PropType from 'prop-types'
import i18n from 'i18n-js'

import SignInUser from './SignInUser'

import { AUTH_TOKEN } from '../constants'
import {
  FETCH_CUSTOMERS_FOR_PART_REPORT,
  FETCH_SUMMARY_FOR_PART_REPORT,
} from '../queries'

class BomParts extends Component {
  constructor(props) {
    super(props)

    let history = createBrowserHistory()
    history.replace(history.location.pathname, { selectedNav: 'report-parts' })

    this.state = {
      customerIds: props.customerIds,
      activePart: null,
      startRange: props.startRange,
      endRange: props.endRange,
      summaryId: props.summaryId,
    }
  }

  render() {
    const authToken = localStorage.getItem(AUTH_TOKEN)

    if (authToken === null) return <SignInUser />

    const {
      customerIds,
      activePart,
      startRange,
      endRange,
      summaryId,
    } = this.state

    let customers = ''

    customerIds.map((c) => {
      if (customers === '') {
        customers = `${c}`
      } else {
        customers = `,${c}`
      }
      return ''
    })

    let summaryQuery = FETCH_CUSTOMERS_FOR_PART_REPORT
    let summaryVariables = {
      token: authToken,
      customerIds: customers,
    }

    if (summaryId !== null) {
      summaryQuery = FETCH_SUMMARY_FOR_PART_REPORT
      summaryVariables = {
        token: authToken,
        id: parseInt(summaryId, 10),
      }
    }

    return (
      <div>
        <Query query={summaryQuery} variables={summaryVariables}>
          {({ loading, error, data }) => {
            if (loading) return <div>{i18n.t('processing')}...</div>
            if (error) return <div>{error.message}</div>

            let customers = data.customers
            let summary = null

            if (summaryId !== null) {
              summary = data.summary
              customers = summary.customers
            }

            let allWorkOrders = []

            customers.map((customer) => {
              allWorkOrders = allWorkOrders.concat(customer.workOrders)
              return ''
            })

            let partsList = {}

            allWorkOrders.map((wo) => {
              if (wo.invoicedAt >= startRange && wo.invoicedAt <= endRange) {
                wo.lineItems.map((li) => {
                  if (
                    li.productGroup === 'PARTS' &&
                    li.workCategory.value !== 'Tires' &&
                    li.workCategory.value !== 'PM'
                  ) {
                    if (partsList[li.id] === undefined) {
                      partsList[li.id] = {
                        id: li.id,
                        name: li.description,
                        quantity: li.quantity,
                        amount: li.amount,
                        unitPrice: li.unitPrice,
                        equipment: [
                          {
                            id: wo.equipment.id,
                            eobj:
                              wo.equipment.vendorEquipment[0].vendorEquipmentId,
                            meter: wo.keyOnMeter,
                            date: wo.invoicedAt,
                          },
                        ],
                      }
                    } else {
                      partsList[li.id]['quantity'] += li.quantity
                      partsList[li.id]['amount'] += li.amount
                      partsList[li.id]['equipment'].push({
                        id: wo.equipment.id,
                        eobj: wo.equipment.vendorEquipment[0].vendorEquipmentId,
                        meter: wo.keyOnMeter,
                        date: wo.invoicedAt,
                      })
                    }
                  }
                  return ''
                })
              }
              return ''
            })

            let qtySort = []
            let costSort = []
            let totalSort = []

/*
            for (let key in partsList) {
              let part = partsList[key]
              let toSortQty = true
              let toSortCost = true
              let toSortTotal = true

              if (qtySort.length === 0) {
                qtySort.push(part)
              } else {
                qtySort.map((item, i) => {
                  if (i < 5 && toSortQty) {
                    if (part.quantity >= item.quantity) {
                      qtySort.splice(i, 0, part)
                      toSortQty = false
                    } else if (i === qtySort.length - 1) {
                      qtySort.push(part)
                      toSortQty = false
                    }
                  }
                  return ''
                })
              }

              if (costSort.length === 0) {
                costSort.push(part)
              } else {
                costSort.map((item, i) => {
                  if (i < 5 && toSortCost) {
                    if (part.unitPrice >= item.unitPrice) {
                      costSort.splice(i, 0, part)
                      toSortCost = false
                    } else if (i === costSort.length - 1) {
                      costSort.push(part)
                      toSortCost = false
                    }
                  }
                  return ''
                })
              }

              if (totalSort.length === 0) {
                totalSort.push(part)
              } else {
                totalSort.map((item, i) => {
                  if (i < 5 && toSortTotal) {
                    if (part.amount >= item.amount) {
                      totalSort.splice(i, 0, part)
                      toSortTotal = false
                    } else if (i === totalSort.length - 1) {
                      totalSort.push(part)
                      toSortTotal = false
                    }
                  }
                  return ''
                })
              }
            }
*/

            totalSort = totalSort.slice(0, 5)
            qtySort = qtySort.slice(0, 5)
            costSort = costSort.slice(0, 5)

            return (
              <div>
                <div>
                  <h3>{i18n.t('quantity')}</h3>
                  <table border="1">
                    <thead>
                      <tr>
                        <th>{i18n.t('name')}</th>
                        <th>{i18n.t('quantity')}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {qtySort.map((part) => {
                        return (
                          <tr key={part.id}>
                            <td>{part.name}</td>
                            <td>{part.quantity}</td>
                            <td>
                              <Link
                                to="#"
                                onClick={() => {
                                  this.setState({ activePart: part })
                                }}
                              >
                                <FontAwesome name="search" />
                              </Link>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <div>
                  <h3>Cost</h3>
                  <table border="1">
                    <thead>
                      <tr>
                        <th>{i18n.t('name')}</th>
                        <th>{i18n.t('unitPrice')}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {costSort.map((part) => {
                        return (
                          <tr key={part.id}>
                            <td>{part.name}</td>
                            <td>${parseFloat(part.unitPrice).toFixed(2)}</td>
                            <td>
                              <Link
                                to="#"
                                onClick={() => {
                                  this.setState({ activePart: part })
                                }}
                              >
                                <FontAwesome name="search" />
                              </Link>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <div>
                  <h3>{i18n.t('total')}</h3>
                  <table border="1">
                    <thead>
                      <tr>
                        <th>{i18n.t('name')}</th>
                        <th>{i18n.t('totalAmount')}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {totalSort.map((part) => {
                        return (
                          <tr key={part.id}>
                            <td>{part.name}</td>
                            <td>${parseFloat(part.amount).toFixed(2)}</td>
                            <td>
                              <Link
                                to="#"
                                onClick={() => {
                                  this.setState({ activePart: part })
                                }}
                              >
                                <FontAwesome name="search" />
                              </Link>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <div>
                  {activePart && (
                    <div>
                      <h3>Examine</h3>
                      <table border="1">
                        <thead>
                          <tr>
                            <th>{i18n.t('name')}</th>
                            <th>{i18n.t('quantity')}</th>
                            <th>{i18n.t('cost')}</th>
                            <th>{i18n.t('total')}</th>
                            <th>{i18n.t('equipment')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{activePart.name}</td>
                            <td>{activePart.quantity}</td>
                            <td>
                              ${parseFloat(activePart.unitPrice).toFixed(2)}
                            </td>
                            <td>${parseFloat(activePart.amount).toFixed(2)}</td>
                            <td>
                              {activePart.equipment.map((equipment) => {
                                return (
                                  <div key={equipment.id}>
                                    <Link
                                      to={{
                                        pathname: `/equipment/${equipment.id}`,
                                      }}
                                    >
                                      {equipment.eobj}
                                    </Link>{' '}
                                    :: {equipment.meter} hr :: {equipment.date}
                                  </div>
                                )
                              })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            )
          }}
        </Query>
      </div>
    )
  }
}

BomParts.defaultProps = {
  customerIds: {},
  startRange: {},
  endRange: {},
  summaryId: {},
}

BomParts.propTypes = {
  customerIds: PropType.array,
  startRange: PropType.string,
  endRange: PropType.string,
  summaryId: PropType.string,
}

export default BomParts
