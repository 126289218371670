import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'
import CanvasJSReact from '../assets/canvasjs.react'

const { CanvasJSChart } = CanvasJSReact

class LineChart extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hmData: props.hmData,
      driveData: props.driveData,
      pumpData: props.pumpData,
    }
   }

  render() {
    const { hmData, driveData, pumpData } = this.state

    const dataPoints = [
      {
        type: 'line',
        markerColor: '#87a3c1',
        lineColor: '#112e4e',
        dataPoints: hmData,
        name: i18n.t('keyOnMeter'),
        showInLegend: true,
        markerSize: 0,
      },
    ]

    if (driveData.length > 0) {
      dataPoints.push({
        type: 'line',
        dataPoints: driveData,
        name: i18n.t('driveMeter'),
        showInLegend: true,
        markerSize: 0,
      })
    }

    if (pumpData.length > 0) {
      dataPoints.push({
        type: 'line',
        dataPoints: pumpData,
        name: i18n.t('pumpMeter'),
        showInLegend: true,
        markerSize: 0,
      })
    }

    const options = {
      exportEnabled: true,
      theme: 'light2',
      zoomEnabled: true,
      zoomType: 'x',
      axisY: {
        title: i18n.t('hours'),
        titleFontSize: 15,
        titleFontWeight: 'bold',
        titleFontFamily: 'Arial',
        titleFontColor: '#434A54',
        labelFontFamily: 'Arial',
        labelFontColor: '#656D78',
      },
      axisX: {
        title: i18n.t('dateOfWorkOrders'),
        valueFormatString: 'M/D/YY',
        titleFontSize: 15,
        titleFontWeight: 'bold',
        titleFontFamily: 'Arial',
        titleFontColor: '#434A54',
        labelFontFamily: 'Arial',
        labelFontColor: '#656D78',
      },
      toolTip: {
        fontFamily: 'Arial',
        fontColor: '#112e4e',
        backgroundColor: '#CCd1d9',
        cornerRadius: 4,
        shared: true,
      },
      legend: {
        cursor: 'pointer',
        verticalAlign: 'top',
        dockInsidePlotArea: true,
      },
      data: dataPoints,
    }

    return (
      <div className="lineChart">
        <div className="fieldLabel">
          <label>{i18n.t('hourMeterByDate')}</label>
        </div>
        <CanvasJSChart options={options} />
      </div>
    )
  }
}

LineChart.defaultProps = {
  hmData: [],
  driveData: [],
  pumpData: [],
}

LineChart.propTypes = {
  hmData: PropTypes.array,
  driveData: PropTypes.array,
  pumpData: PropTypes.array,
}

export default LineChart
