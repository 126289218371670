import React from 'react'
import PropTypes from 'prop-types'

const Button = ({ id, klass, clickAction, text, type, disabled } = {}) => {
  const calculatedKlass = klass === 'sign-in' ? 'centered' : null

  const disabledText = disabled === 'true' ? 'disabled' : ''

  return (
    <div className={calculatedKlass}>
      <button id={id} className={klass} type={type} onClick={clickAction} disabled={disabledText === 'disabled'}>
        {text}
      </button>
    </div>
  )
}

Button.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  klass: PropTypes.string,
  clickAction: PropTypes.func,
  text: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.string,
}

Button.defaultProps = {
  klass: 'submit',
  clickAction: null,
  text: 'Submit',
  type: 'submit',
  disabled: 'false',
}

export default Button
