import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'

class AllReportedBugs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: props.reports,
      showHide: 'hidden'
    }
  }

  render() {
    const { reports, showHide } = this.state

    return (
      <div>
        <Link to="#" onClick={ () => {
          let css = this.state.showHide === 'hidden' ? 'visible' : 'hidden'
          this.setState({ showHide: css })
        } }>
          <div className="title-toggle">
            <h4>{i18n.t('reportedBugs')}</h4>
          </div>
        </Link>
        { (reports.length <= 0) ?
          <div className={showHide}>{i18n.t('noBugsHaveBeenReported')}</div> :
          <div className={showHide}>
            {reports.map((report) => {
              return(
                <div key={report.id}>
                  <h3>
                    <Link to={`/bug-report/${report.id}`}>Bug Report #{report.id}</Link>
                  </h3>
                  <div>
                    <strong>{i18n.t('description')}: </strong>
                    {report.description}
                  </div>
                  <div>
                    <strong>{i18n.t('status')}:</strong> {report.aasmState}
                    { report.aasmState !== 'submitted' &&
                      <div>
                        <strong>{i18n.t('responder')} </strong>
                        {report.responder.firstName} {report.responder.lastName}
                      </div> 
                    }
                  </div>
                </div>
              )
            } ) }
          </div>
        }
      </div>
    )
  }
}

AllReportedBugs.defaultProps = {
  reports: {},
}

AllReportedBugs.propTypes = {
  reports: PropTypes.array,
}

export default AllReportedBugs
