import React, { Component } from 'react'
import { Query, Mutation } from 'react-apollo'
import PropTypes, {object} from 'prop-types'
import i18n from 'i18n-js'

import Button from './Button'
import DropDown from './DropDown'
import LineItem from './LineItem'

import {
  PRODUCT_GROUPS,
} from '../dropDowns'

import { AUTH_TOKEN } from '../constants'

import {
  FETCH_ALL_WORK_CATEGORIES,
  FETCH_ALL_WORK_SYSTEMS,
  RESET_WORK_CATEGORIES,
  CREATE_LINE_ITEM,
} from '../queries'

class AllLineItems extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lineItems: props.lineItems,
      totalAmount: props.totalAmount,
      id: props.id,
      currentUser: props.currentUser,
      internalId: '',
      description: '',
      quantity: '',
      unitPrice: '',
      tax: '',
      amount: '',
    }
  }

  render() {
    const {
      lineItems,
      totalAmount,
      internalId,
      description,
      quantity,
      unitPrice,
      tax,
      amount,
      currentUser,
    } = this.state

    const token = localStorage.getItem(AUTH_TOKEN)

    return (
      <div>
        <h3>{i18n.t('lineItems')}</h3>
        <table>
          <thead>
            <tr>
              <th>{i18n.t('productGroup')}</th>
              { currentUser['role'] === 'admin' &&
                <th>{i18n.t('internalId')}</th>
              }
              <th>{i18n.t('description')}</th>
              <th>{i18n.t('quantity')}</th>
              <th>{i18n.t('unitPrice')}</th>
              <th>{i18n.t('tax')}</th>
              <th>{i18n.t('amount')}</th>
              <th>{i18n.t('workSystem')}</th>
              <th>{i18n.t('workCategory')}</th>
              <th></th>
              <th></th>
              { currentUser['role'] === 'admin' && <th></th> }
            </tr>
          </thead>
          <tbody id='allLineItems'>
            <tr className="table-header">
              <td></td>
              { currentUser['role'] === 'admin' &&
                <td></td>
              }
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style={{ textAlign: 'center' }}>
                {i18n.toCurrency(totalAmount)}
              </td>
              <td></td>
              <td>
                <Query query={FETCH_ALL_WORK_CATEGORIES} variables={{ token }}>
                  {({ loading, error, data }) => {
                    if (loading) return <span>{i18n.t('processing')}...</span>
                    if (error) return <span>{error.message}</span>

                    var workCategories = []
                    for (
                      var i = 0, length = data.allWorkCategories.length;
                      i < length;
                      i++
                    ) {
                      workCategories.push({
                        key: data.allWorkCategories[i].id,
                        value: data.allWorkCategories[i].id,
                        name: data.allWorkCategories[i].value.toLowerCase(),
                      })
                    }
                    return (
                      <DropDown
                        id="work-category-all"
                        options={workCategories}
                        allowNull="false"
                      />
                    )
                  }}
                </Query>
              </td>
              <td></td>
              <Mutation mutation={RESET_WORK_CATEGORIES}>
                {(resetWorkCategories, { loading, error }) => {
                  return (
                    <td>
                      <Button
                        id="set-work-category"
                        text={i18n.t('setAll')}
                        clickAction={() => {
                          const token = localStorage.getItem(AUTH_TOKEN)

                          const id = parseInt(this.state.id, 10)

                          var wcSel = document.getElementById(
                            'work-category-all',
                          )
                          var workCategory = null
                          if (wcSel.options[wcSel.selectedIndex].text !== '') {
                            workCategory = parseInt(
                              wcSel.options[wcSel.selectedIndex]['value'],
                              10,
                            )
                          }

                          resetWorkCategories({
                            variables: { token, id, workCategory },
                          }).then(() => {
                            document.location.reload()
                          })
                        }}
                      />
                      {loading && <p>{i18n.t('processing')}...</p>}
                      {error && <p>{error.message}</p>}
                    </td>
                  )
                }}
              </Mutation>
              { currentUser['role'] === 'admin' && <td></td> }
            </tr>
            {lineItems.map((lineItem) => (
              <LineItem
                key={parseInt(lineItem.id, 10)}
                id={parseInt(lineItem.id, 10)}
                currentUser={currentUser}
                productGroup={lineItem.productGroup}
                description={lineItem.description}
                quantity={lineItem.quantity}
                unitPrice={lineItem.unitPrice}
                amount={lineItem.amount}
                tax={lineItem.tax}
                internalId={lineItem.internalId}
                workSystem={
                  lineItem.workSystem === null ? null : lineItem.workSystem.id
                }
                workCategory={
                  lineItem.workCategory === null
                    ? null
                    : lineItem.workCategory.id
                }
              />
            ))}
            { currentUser['role'] === 'admin' &&
              <tr className=''>
                <td>
                  <DropDown
                    id='product-group-new'
                    options={PRODUCT_GROUPS}
                    allowNull='false'
                  />
                </td>
                <td>
                  <div className='fieldContainer'>
                    <div className='field'>
                      <input
                        id='new-li-internal-id'
                        placeholder={i18n.t('internalId')}
                        value={internalId}
                        className='text-field'
                        onChange={(event) => {
                          let value = event.target.value
                          this.setState({ internalId: value })
                        } }
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div className='fieldContainer'>
                    <div className='field'>
                      <input
                        id='new-li-description'
                        placeholder={i18n.t('description')}
                        value={description}
                        className='text-field'
                        onChange={(event) => {
                          let value = event.target.value
                          this.setState({ description: value })
                        } }
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div className='fieldContainer'>
                    <div className='field'>
                      <input
                        id='new-li-quantity'
                        placeholder={i18n.t('quantity')}
                        value={quantity}
                        type='number'
                        min={0}
                        className='text-field'
                        onChange={(event) => {
                          let value = event.target.value
                          this.setState({ quantity: value })
                        } }
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div className='fieldContainer'>
                    <div className='field currency'>
                      <span className='currency-sign'>$</span>
                      <input
                        id='new-li-unit-price'
                        placeholder={i18n.t('unitPrice')}
                        value={unitPrice}
                        type='number'
                        min='0.00'
                        step='0.01'
                        className='currency'
                        onChange={(event) => {
                          let value = event.target.value
                          this.setState({ unitPrice: value })
                        } }
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div className='fieldContainer'>
                    <div className='field currency'>
                      <span className='currency-sign'>$</span>
                      <input
                        id='new-li-tax'
                        placeholder={i18n.t('tax')}
                        value={tax}
                        type='number'
                        min='0.00'
                        step='0.01'
                        className='currency'
                        onChange={(event) => {
                          let value = event.target.value
                          this.setState({ tax: value })
                        } }
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div className='fieldContainer'>
                    <div className='field currency'>
                      <span className='currency-sign'>$</span>
                      <input
                        id='new-li-amount'
                        placeholder={i18n.t('amount')}
                        value={amount}
                        type='number'
                        min='0.00'
                        step='0.01'
                        className='currency'
                        onChange={(event) => {
                          let value = event.target.value
                          this.setState({ amount: value })
                        } }
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <Query query={FETCH_ALL_WORK_SYSTEMS} variables={{token}}>
                    {({ loading, error, data }) => {
                      if (loading) return <span>{i18n.t('processing')}</span>
                      if (error) return <span>{error.message}</span>

                      let workSystems = []
                      for (
                        let i = 0, length = data.allWorkSystems.length;
                        i < length;
                        i++
                      ) {
                        workSystems.push({
                          key: data.allWorkSystems[i].id,
                          value: data.allWorkSystems[i].id,
                          name: data.allWorkSystems[i].value,
                        })
                      }
                      return (
                        <DropDown
                          id='work-system-new'
                          options={workSystems}
                        />
                      )
                    } }
                  </Query>
                </td>
                <td>
                  <Query query={FETCH_ALL_WORK_CATEGORIES} variables={{token}}>
                    {({ loading, error, data }) => {
                      if (loading) return <span>{i18n.t('processing')}</span>
                      if (error) return <span>{error.message}</span>

                      let workCategories = []
                      for (
                        let i = 0, length = data.allWorkCategories.length;
                        i < length;
                        i++
                      ) {
                        workCategories.push({
                          key: data.allWorkCategories[i].id,
                          value: data.allWorkCategories[i].id,
                          name: data.allWorkCategories[i].value.toLowerCase(),
                        })
                      }
                      return (
                        <DropDown
                          id='work-category-new'
                          options={workCategories}
                          allowNull='false'
                        />
                      )
                    } }
                  </Query>
                </td>
                <td></td>
                <td>
                  <Mutation mutation={CREATE_LINE_ITEM}>
                    {(createLineItem, {loading, error}) => {
                      return(
                        <div>
                          <Button
                            id='create-line-item'
                            text={i18n.t('create')}
                            clickAction={() => {
                              let errorMsg = []

                              let field = null

                              if (internalId === '') {
                                errorMsg.push(i18n.t('internalIdCannotBeBlank'))
                                field = document.getElementById('new-li-internal-id')
                                field.classList.add('error')
                              }

                              if (description === '') {
                                errorMsg.push(i18n.t('descriptionCannotBeEmpty'))
                                field = document.getElementById('new-li-description')
                                field.classList.add('error')
                              }

                              if (quantity === '') {
                                errorMsg.push(i18n.t('quantityCannotBeBlank'))
                                field = document.getElementById('new-li-quantity')
                                field.classList.add('error')
                              }

                              if (unitPrice === '') {
                                errorMsg.push(i18n.t('unitPriceCannotBeBlank'))
                                field = document.getElementById('new-li-unit-price')
                                field.classList.add('error')
                              }

                              if (amount === '') {
                                errorMsg.push(i18n.t('amountCannotBeBlank'))
                                field = document.getElementById('new-li-amount')
                                field.classList.add('error')
                              }

                              if (errorMsg.length > 0) {
                                window.flash(
                                  `${i18n.t(
                                    'theFormHasTheFollowingIssues',
                                  )} ${errorMsg.join('. ')}.`,
                                  'error'
                                )
                                return ''
                              }

                              document.getElementById('new-li-internal-id').classList.remove('error')
                              document.getElementById('new-li-description').classList.remove('error')
                              document.getElementById('new-li-quantity').classList.remove('error')
                              document.getElementById('new-li-unit-price').classList.remove('error')
                              document.getElementById('new-li-amount').classList.remove('error')

                              let pgSel = document.getElementById('product-group-new')
                              let productGroup = null
                              if (pgSel.options[pgSel.selectedIndex].text !== '') {
                                productGroup = pgSel.options[pgSel.selectedIndex]['value']
                              }

                              let wsSel = document.getElementById('work-system-new')
                              let workSystemId = null
                              if (wsSel.options[wsSel.selectedIndex].text !== '') {
                                workSystemId = wsSel.options[wsSel.selectedIndex]['value']
                              }

                              let wcSel = document.getElementById('work-category-new')
                              let workCategoryId = null
                              if (wcSel.options[wcSel.selectedIndex].text !== '') {
                                workCategoryId = wcSel.options[wcSel.selectedIndex]['value']
                              }

                              let taxVal = 0

                              if (tax !== '') {
                                taxVal = parseFloat(tax)
                              }

                              const workOrderId = parseInt(this.state.id, 10)

                              createLineItem( {
                                variables: {
                                  token,
                                  workOrderId,
                                  productGroup,
                                  quantity: parseFloat(quantity),
                                  unitPrice: parseFloat(unitPrice),
                                  amount: parseFloat(amount),
                                  tax: taxVal,
                                  internalId,
                                  workSystemId: parseInt(workSystemId, 10),
                                  workCategoryId: parseInt(workCategoryId, 10),
                                  description
                                }
                              } ).then((res) => {
                                window.flash(i18n.t('createdNewLineItem'), 'success')
                                lineItems.push(res.data.createLineItem.lineItem)

                                this.setState({
                                  lineItems,
                                  internalId: '',
                                  description: '',
                                  quantity: '',
                                  unitPrice: '',
                                  tax: '',
                                  amount: '',
                                })
                              })
                            } }
                          />
                          {loading && <p>{i18n.t('processing')}</p>}
                          {error && <p>{error.message}</p>}
                        </div>
                      )
                    } }
                  </Mutation>
                </td>
              </tr>
            }
          </tbody>
        </table>
        { currentUser['role'] === 'admin' &&
          <Button
            id='add-new-line-item'
            text={i18n.t('addNewLineItem')}
            klass='submit hidden'
            clickAction={() => {

            } }
          />
        }
      </div>
    )
  }
}

AllLineItems.defaultProps = {
  id: null,
  totalAmount: '',
  lineItems: [],
  currentUser: null,
}

AllLineItems.propTypes = {
  id: PropTypes.number,
  totalAmount: PropTypes.number,
  lineItems: PropTypes.array,
  currentUser: object,
}

export default AllLineItems
