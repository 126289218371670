import React, { Component } from 'react'
import i18n from 'i18n-js'

import { Query, Mutation } from 'react-apollo'

import SignInUser from './SignInUser'
import TimeStamp from './TimeStamp'

import {
  Link,
  Redirect,
} from 'react-router-dom'

import { AUTH_TOKEN } from '../constants'

import {
  FETCH_ALL_DOCUMENTS,
  FETCH_CURRENT_USER,
  UPDATE_DOCUMENT,
} from '../queries'

class AllDocuments extends Component {

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser />

    return(
      <Query query={FETCH_CURRENT_USER} variables={{token}}>
        {({ loading, error, data }) => {
          if (loading) return <div>{i18n.t('fetching')}</div>
          if (error) return <div>{error.message}</div>

          const currentUser = data.currentUser

          if (currentUser.role !== 'admin') return <Redirect to='/' />

          return(
            <Query query={FETCH_ALL_DOCUMENTS} variables={{token}}>
              {({ loading, error, data }) => {
                if (loading) return <div>{i18n.t('fetching')}</div>
                if (error) return <div>{error.message}</div>

                const documents = data.allDocuments

                documents.sort((a, b) =>
                  (a.createdAt < b.createdAt ? 1 : -1)
                )

                return(
                  <div>
                    <h3>{i18n.t('allDocuments')}</h3>
                    <div>
                      <Link to='documents/new'>
                        {i18n.t('createNewDocument')}
                      </Link>
                    </div>
                    {documents.map((doc) => (
                      <div key={doc.id} className='listed-document'>
                        <div>
                          <Link to={`/documents/${doc.id}`}>
                            <h3>
                              {doc.archivedAt === null ?
                                doc.name :
                                `${doc.name} - INACTIVE`
                              }
                            </h3>
                          </Link>
                          <div>
                            <strong>{i18n.t('userType')}: </strong>
                            {doc.userType}
                          </div>
                          <div>
                            <strong>{i18n.t('createdAt')}: </strong>
                            <TimeStamp timeToFormat={doc.createdAt} />
                          </div>
                          {doc.archivedAt === null ?
                            <Mutation mutation={UPDATE_DOCUMENT}>
                              {(updateDocument) => {
                                return(
                                  <div>
                                    <Link to='#'
                                      onClick={() => {
                                        const date = new Date()

                                        let dateYear = date.getFullYear()
                                        let dateMonth = (date.getMonth() + 1).toString()

                                        if (dateMonth.length === 1) {
                                          dateMonth = `0${dateMonth.toString()}`
                                        }

                                        let dateDay = date.getDate().toString()

                                        if (dateDay.length === 1) {
                                          dateDay = `0${dateDay}`
                                        }

                                        updateDocument({
                                          variables: {
                                            id: doc.id,
                                            token,
                                            archivedAt: `${dateYear}-${dateMonth}-${dateDay}`
                                          }
                                        }).then(() => {
                                          window.location.reload(true)
                                        } )
                                      } }
                                    >
                                      {i18n.t('deactivateDocument')}
                                    </Link>
                                  </div>
                                )
                              } }
                            </Mutation> :
                            <Mutation mutation={UPDATE_DOCUMENT}>
                              {(updateDocument) => {
                                return(
                                  <div>
                                    <Link to='#'
                                      onClick={() => {
                                        updateDocument({
                                          variables: {
                                            id: doc.id,
                                            token,
                                            archivedAt: '',
                                          }
                                        }).then(() => {
                                          window.location.reload(true)
                                        } )
                                      } }
                                    >
                                      {i18n.t('reactivateDocument')}
                                    </Link>
                                  </div>
                                )
                              } }
                            </Mutation>
                          }
                        </div>
                      </div>
                    ) ) }
                  </div>
                )
              } }
            </Query>
          )
        } }
      </Query>
    )
  }
}

export default AllDocuments
