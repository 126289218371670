import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import i18n from 'i18n-js'

const AllEquipment = ({ equipment }) => {
  const [isHidden, setHidden] = useState(true)
  const hiddenClassName = useMemo(() => (isHidden ? 'hidden' : 'visible'), [
    isHidden,
  ])

  const onToggleDisplayClick = useCallback(() => setHidden((prev) => !prev), [])

  return (
    <div>
      <Link to="#" onClick={onToggleDisplayClick}>
        <div className="title-toggle">
          <h4>{i18n.t('equipment')}</h4>
        </div>
      </Link>
      {equipment.length === 0 ? (
        <div className={hiddenClassName}>{i18n.t('noEquipmentFound')}</div>
      ) : (
        <div className={hiddenClassName}>
          {equipment.map((equip) => (
            <div key={equip.id}>
              <h3>
                <Link to={{ pathname: `/equipment/${parseInt(equip.id, 10)}` }}>
                  {equip.vendorEquipment[0].vendorEquipmentId} | {equip.model} |{' '}
                  {equip.serialNumber}
                </Link>
              </h3>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

AllEquipment.propTypes = {
  equipment: PropTypes.arrayOf(PropTypes.shape({})),
}

AllEquipment.defaultProps = {
  equipment: [],
}

export default AllEquipment
