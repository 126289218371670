import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'

import TimeStamp from './TimeStamp'

class AllWorkOrders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      workOrders: props.workOrders,
      showHide: 'hidden',
    }
  }

  render() {
    const { workOrders, showHide } = this.state

    workOrders.sort((a, b) => (a.invoicedAt < b.invoicedAt ? 1 : -1))

    let workCategories = []
    let wc = []
    let totals = []
    let totalAmount = 0

    workOrders.map((wo) => {
      wc = []
      totalAmount = 0
      wo.lineItems.map((li) => {
        totalAmount += li.amount
        if (!wc.includes(li.workCategory.value)) {
          wc.push(li.workCategory.value)
        }
        return ''
      })
      workCategories[wo.id] = wc
      totals[wo.id] = totalAmount
      return ''
    })

    return (
      <div>
        <Link to="#" onClick={this.toggleDisplay.bind(this)}>
          <div className="title-toggle">
            <h4>{i18n.t('workOrders')}</h4>
          </div>
        </Link>
        {workOrders.length <= 0 ? (
          <div className={showHide}>{i18n.t('noWorkOrdersFound')}</div>
        ) : (
          <div className={showHide}>
            {workOrders.map((wo) => {
              let customerName = wo.customer.name

              if (wo.customer.internalName !== null) {
                customerName += ` - ${wo.customer.internalName}`
              }

              const oldDate = new Date(wo.invoicedAt)

              const newDate = new Date(oldDate.getTime() + oldDate.getTimezoneOffset() * 60 * 1000)

              return (
                <div key={wo.id}>
                  <div className="review-search-result">
                    <h3>
                      <Link to={{ pathname: `/work-order/${parseInt(wo.id, 10)}` }}>
                        {wo.internalId}
                      </Link>
                    </h3>
                    <ol>
                      <li>
                        <strong>{i18n.t('customer').toUpperCase()}: </strong>
                        <Link
                          to={{
                            pathname: `/customer/${parseInt(wo.customer.id, 10)}`,
                          }}
                        >
                          {customerName}
                        </Link>
                      </li>
                      <li>
                        <strong>{i18n.t('serviceType').toUpperCase()}: </strong>
                        {wo.serviceType}
                      </li>
                      <li>
                        <strong>{i18n.t('invoicedAt').toUpperCase()}: </strong>
                        <TimeStamp timeToFormat={newDate} />
                      </li>
                      <li>
                        <strong>{i18n.t('amount').toUpperCase()}: </strong>$
                        {parseFloat(totals[wo.id]).toFixed(2)}
                      </li>
                      <li>
                        <strong>
                          {i18n.t('workCategories').toUpperCase()}:{' '}
                        </strong>
                        {workCategories[wo.id].map((wc) => (
                          <span key={wc}>{wc}</span>
                        ))}
                      </li>
                      <li>
                        <strong>{i18n.t('keyOnMeter').toUpperCase()}: </strong>
                        {wo.keyOnMeter}
                      </li>
                    </ol>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }

  toggleDisplay() {
    var css = this.state.showHide === 'hidden' ? 'visible' : 'hidden'
    this.setState({ showHide: css })
  }
}

AllWorkOrders.defaultProps = {
  workOrders: [],
}

AllWorkOrders.propTypes = {
  workOrders: PropTypes.array,
}

export default AllWorkOrders
