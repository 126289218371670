import React, { Component } from 'react'
import PropTypes from 'prop-types'

class DateTimeField extends Component {
  constructor(props) {
    super(props)

    let date = ''
    let hour = ''
    let minute = ''

    if (props.value !== null) {
      const oldDate = new Date(props.value)

      const newDate = new Date(oldDate.getTime() + oldDate.getTimezoneOffset() * 60 * 1000)

      let month = newDate.getMonth() + 1
      let day = newDate.getDate()

      if (month.toString().length === 1) { month = `0${month}` }
      if (day.toString().length === 1) { day = `0${day}` }

      date = `${newDate.getFullYear()}-${month}-${day}`

      hour = newDate.getHours()
      minute = newDate.getMinutes()

      if (hour.toString().length === 1) { hour = `0${hour}` }
      if (minute.toString().length === 1) { minute = `0${minute}` }
    }

    this.state = {
      id: props.id || 'dateTime',
      label: props.label || 'Date Time',
      klass: props.klass || null,
      date: date,
      hour: hour,
      minute: minute,
    }
  }

  changeDateValue(event) {
    const value = event.target.value
    this.setState({ date: value, error: '' })
  }

  changeHourValue(event) {
    const value = event.target.value
    this.setState({ hour: value, error: '' })
  }

  changeMinValue(event) {
    const value = event.target.value
    this.setState({ minute: value, error: '' })
  }

  render() {
    const { id, label, klass, date, hour, minute } = this.state

    var dateId = id + '-date'
    var hourId = id + '-hour'
    var minId = id + '-minute'

    return (
      <div className="fieldContainer">
        <div className="fieldLabel">
          <label>{label}</label>
        </div>
        <div className="field">
          <input
            type="date"
            id={dateId}
            value={date}
            onChange={this.changeDateValue.bind(this)}
            className={`date-time-date ${klass}`}
          />
          <div className="timeContainer">
            <input
              type="number"
              id={hourId}
              value={hour}
              max="24"
              min="0"
              onChange={this.changeHourValue.bind(this)}
              className={klass}
            />
            :
            <input
              type="number"
              id={minId}
              value={minute}
              min="0"
              max="60"
              onChange={this.changeMinValue.bind(this)}
              className={klass}
            />
          </div>
        </div>
      </div>
    )
  }
}

DateTimeField.defaultProps = {
  id: 'dateTime',
  klass: null,
  label: 'Date Time',
  value: '',
}

DateTimeField.propTypes = {
  id: PropTypes.string,
  klass: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
}

export default DateTimeField
