import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import i18n from 'i18n-js'

import Customer from '../pages/Customer'
import Equipment from '../pages/Equipment'
import WorkOrder from './WorkOrder'

import {
  REVIEWABLE_TYPE_CUSTOMER,
  REVIEWABLE_TYPE_EQUIPMENT,
  REVIEWABLE_TYPE_WORKORDER,
} from '../constants'
import SERVER_REDIRECTION_URL from '../config/config'

const Reviewable = ({ reviewableId, reviewableType }) => {
  const reviewableBlock = useMemo(() => {
    switch (reviewableType) {
      case REVIEWABLE_TYPE_EQUIPMENT:
        return (
          <div className="drilldown">
            <Link to={{ pathname: `/equipment/${parseInt(reviewableId, 10)}` }}>
              {i18n.t('drillDown')}
            </Link>
            <Equipment id={reviewableId} />
          </div>
        )

      case REVIEWABLE_TYPE_WORKORDER:
        return (
          <div className="drilldown">
            <Link
              onClick={() => {
                window.location.replace(
                  `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
                    'auth-token',
                  )}&location=workorders&id=${reviewableId}`,
                )
              }}
              to={{ pathname: `/work-order/${parseInt(reviewableId, 10)}` }}
            >
              {i18n.t('drillDown')}
            </Link>
            <WorkOrder id={reviewableId} />
          </div>
        )

      case REVIEWABLE_TYPE_CUSTOMER:
        return (
          <div className="drilldown">
            <Link to={{ pathname: `/customer/${parseInt(reviewableId, 10)}` }}>
              {i18n.t('user')}
            </Link>
            <Customer custId={reviewableId} />
          </div>
        )

      default:
        return null
    }
  }, [reviewableId, reviewableType])

  return (
    <div>
      <hr />
      {reviewableBlock}
    </div>
  )
}

Reviewable.propTypes = {
  reviewableId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reviewableType: PropTypes.string,
}

Reviewable.defaultProps = {
  reviewableId: null,
  reviewableType: null,
}

export default Reviewable
