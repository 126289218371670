import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'
import CanvasJSReact from '../assets/canvasjs.react'

const { CanvasJSChart } = CanvasJSReact

const SummaryCostChart = ({ pm, repair, tires, damage, request, attach }) => {
  const options = useMemo(
    () => ({
      exportEnabled: true,
      theme: 'light2',
      title: {
        text: 'Total Costs',
      },
      axisY: {
        prefix: '$',
      },
      data: [
        {
          type: 'column',
          dataPoints: [
            { label: i18n.t('pm'), y: pm },
            { label: i18n.t('repair'), y: repair },
            { label: i18n.t('tires'), y: tires },
            { label: i18n.t('damage'), y: damage },
            { label: i18n.t('request'), y: request },
            { label: i18n.t('attachments'), y: attach },
          ],
        },
      ],
    }),
    [pm, repair, tires, damage, request, attach],
  )

  return (
    <div className="summaryCostChart">
      <CanvasJSChart options={options} />
    </div>
  )
}

SummaryCostChart.propTypes = {
  pm: PropTypes.number,
  repair: PropTypes.number,
  tires: PropTypes.number,
  damage: PropTypes.number,
  request: PropTypes.number,
  attach: PropTypes.number,
}

SummaryCostChart.defaultProps = {
  pm: null,
  repair: null,
  tires: null,
  damage: null,
  request: null,
  attach: null,
}

export default SummaryCostChart
