import React, { Component } from 'react'
import { createBrowserHistory } from 'history'
import { Link } from 'react-router-dom'
import { object } from 'prop-types'
import i18n from 'i18n-js'

import DateField from './DateField'
import SignInUser from './SignInUser'

import { AUTH_TOKEN } from '../constants'
import '../styles/report.css'

class ReportSettings extends Component {
  constructor(props) {
    super(props)

    let history = createBrowserHistory()

    history.replace(history.location.pathname, {
      selectedNav: 'report-settings',
    })

    this.changeLocalSearch = this.changeLocalSearch.bind(this)

    this.state = {
      customers: props.customers,
      settings: props.settings,
      selectedCustomers: props.selectedCustomers,
    }
  }

  changeLocalSearch = async (event) => {
    const value = event.target.value

    const hideVal = value === ''

    this.setState({
      localSearch: value,
      localSearchHide: hideVal,
    })
    return ''
  }

  render() {
    const authToken = localStorage.getItem(AUTH_TOKEN)
    const {
      customers,
      settings,
      selectedCustomers,
    } = this.state

    if (authToken === null) return <SignInUser />

    return (
      <div>
        <div>
          <h4>{i18n.t('selectedCustomers')}</h4>
          <div>
            { (customers.length > 1) &&
              <div>
                { (selectedCustomers.length === customers.length) ?
                  <Link to='#'
                    onClick={() => {
                      selectedCustomers.splice(0, selectedCustomers.length)
                      this.setState({selectedCustomers})
                    } }
                  >
                    {i18n.t('deselectAll')}
                  </Link> :
                  <Link to='#'
                    onClick={() => {
                      customers.map((customer) => {
                        if (!(selectedCustomers.includes(customer.id))) {
                          selectedCustomers.push(customer.id)
                        }

                        return ''
                      })
                      this.setState({selectedCustomers})
                    } }
                  >
                    {i18n.t('selectAll')}
                  </Link>
                }
              </div>
            }

            {customers.map((customer) => {
              if (customers.length === 1) {
                return(
                  <ul>
                    <li>
                      {customer.name} - {customer.internalName} (
                      {customer.parentRelationships.length > 0 &&
                        customer.parentRelationships[0].customerInternalVal})
                    </li>
                  </ul>
                )
              }

              return(
                <div className='fieldContainer' key={customer.id}>
                  <div className='fieldLabel'>
                    <label>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        checked={selectedCustomers.includes(customer.id)}
                        onChange={() => {
                          if (selectedCustomers.includes(customer.id)) {
                            delete selectedCustomers[selectedCustomers.indexOf(customer.id)]
                          } else {
                            selectedCustomers.push(customer.id)
                          }
                          this.setState({selectedCustomers})
                        } }
                      />
                      {customer.name} - {customer.internalName} (
                      {customer.parentRelationships.length > 0 &&
                        customer.parentRelationships[0].customerInternalVal})
                    </label>
                  </div>
                </div>
              )
            }) }
          </div>
        </div>

        <div>
          <h4>{i18n.t('dateRange')}</h4>
          <DateField
            id="startRange"
            label={i18n.t('startRange')}
            value={settings.startRange}
            onDateChange={(date) => {
              settings.startRange = date
              this.setState({ settings })
            }}
          />
          <DateField
            id="endRange"
            label={i18n.t('endRange')}
            value={settings.endRange}
            onDateChange={(date) => {
              settings.endRange = date
              this.setState({ settings })
            }}
          />
        </div>

      </div>
    )
  }
}

ReportSettings.defaultProps = {
  customers: {},
  settings: {},
  selectedCustomers: {},
}

ReportSettings.propTypes = {
  customers: object,
  settings: object,
  selectedCustomers: object,
}

export default ReportSettings
