import React, { Component } from 'react'
import {
  Mutation,
  Query 
} from 'react-apollo'

import {
  Link,
  Redirect,
} from 'react-router-dom'
import i18n from 'i18n-js'

import Button from './Button'
import DropDown from './DropDown'
import SignInUser from './SignInUser'
import TimeStamp from './TimeStamp'

import {
  AUTH_TOKEN,
} from '../constants'

import {
  ASSIGN_BUG_REPORT_TO_USER,
  FETCH_BUG_REPORTS,
  FETCH_CURRENT_USER,
  FETCH_USER_GROUP,
} from '../queries'

class AllBugReports extends Component {
  constructor(props) {
    super(props)
    this.state = {
      usersForSelect: []
    }
  }

  render() {
    const { usersForSelect } = this.state

    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser />

    return (
      <Query query={FETCH_CURRENT_USER} variables={{token}}>
        {({ loading, error, data }) => {
          if (loading) return <div>{i18n.t('processing')}</div>
          if (error) return <div>{error.message}</div>

          const currentUser = data['currentUser']

          if (currentUser['role'] !== 'admin') return <Redirect to="/" />

          return(
            <div>
              <Query query={FETCH_BUG_REPORTS} variables={{token}}>
                {({ loading, error, data }) => {
                  if (loading) return <div>{i18n.t('processing')}</div>
                  if (error) return <div>{error.message}</div>

                  const reports = data.allBugReports

                  reports.sort((a, b) =>
                    (a.createdAt < b.createdAt ? 1 : -1)
                  )

                  return(
                    <div>
                      <h3>{i18n.t('allBugReports')}</h3>

                      <Query query={FETCH_USER_GROUP} variables={{ token, id: currentUser['vendorId'] }}>
                        {({ loading, error, data }) => {
                          if (loading) return <span>{i18n.t('processing')}</span>
                          if (error) return <span>{error.message}</span>

                          const users = data.userGroup.users

                          if (usersForSelect.length === 0) {
                            users.map((user) => {
                              usersForSelect.push({
                                key: user.id,
                                value: user.id,
                                name: `${user.firstName} ${user.lastName}`,
                              })

                              this.setState({usersForSelect})
                              return ''
                            })
                          }

                          return ''
                        } }
                      </Query>

                      {reports.map((report) => {

                        let responderId = null

                        if (report.responder !== null) {
                          responderId = parseInt(report.responder.id, 10)
                        }

                        return(
                          <div key={report.id} className="bug-report">
                            <div>
                              <h3>
                                <Link to={`/bug-report/${report.id}`}>
                                  {i18n.t('bugReportId', {id: report.id})}
                                </Link>
                              </h3>
                            </div>
                            <div>
                              <strong>{i18n.t('reporter')}</strong>
                              {report.reporter.firstName} {report.reporter.lastName}
                            </div>
                            <div>
                              <DropDown
                                id={`responder-select-${report.id}`}
                                options={usersForSelect}
                                selected={responderId}
                                label={i18n.t('responder')}
                                rawOptions={true}
                              />
                              <Mutation mutation={ASSIGN_BUG_REPORT_TO_USER}>
                                {(assignBugReportToUser, { loading, error }) => {
                                  return(
                                    <span>
                                      <Button
                                        id="assign-bug-report-to-user"
                                        text={i18n.t("updateResponder")}
                                        clickAction={() => {
                                          let sel = document.getElementById(`responder-select-${report.id}`)
                                          let newUser = null

                                          if (sel.options[sel.selectedIndex].text !== '') {
                                            newUser = parseInt(sel.options[sel.selectedIndex].value, 10)
                                          }

                                          assignBugReportToUser({
                                            variables: {
                                              token,
                                              id: parseInt(report.id, 10),
                                              responderId: newUser,
                                            }
                                          }).then((res) => {
                                            window.flash(i18n.t('successfullyAssignedBugReport'), 'success')
                                            document.getElementById(`state-${report.id}`).value = res.aasmState
                                          })
                                        } }
                                      />
                                      { loading && <p>{i18n.t('processing')}</p> }
                                      { error && <p>{error.message}</p> }
                                    </span>
                                  )
                                } }
                              </Mutation>
                            </div>
                            <div>
                              <strong>{i18n.t('status')}: </strong>
                              <span id={`state-${report.id}`}>{report.aasmState}</span>
                            </div>
                            <div>
                              <strong>{i18n.t('createdAt')}: </strong>
                              <TimeStamp timeToFormat={report.createdAt} />
                            </div>
                            <div>
                              {report.description}
                            </div>
                          </div>
                        )
                      } ) }
                    </div>
                  )
                } }
              </Query>
            </div>
          )
        } }
      </Query>
    )
  }
}

export default AllBugReports
