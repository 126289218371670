import React, { useCallback, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import i18n from 'i18n-js'

import { AUTH_TOKEN, RESET_TOKEN } from '../constants'

import Button from './Button'
import EmailField from './EmailField'
import PasswordField from './PasswordField'

import { SIGN_IN_USER } from '../queries'
import '../styles/signIn.css'

const SignInUser = () => {
  const [email] = useState('')
  const [password] = useState('')

  const onUserSignIn = useCallback(({ signInUser: result } = {}) => {
    const { user, token, errors = [] } = result || {}

    // If we don't have a userId, likely the credentials were wrong
    if (user === null) {
      window.flash(i18n.t('incorrectCredentialsTryAgain'), 'error')

      return errors.forEach((err) => {
        if (err.fieldName !== 'all') {
          document.getElementById(err.fieldName).classList.add('error')

          const errField = document.getElementById(`${err.fieldName}-error`)
          errField.classList.add('show')
          errField.classList.remove('hidden')
          errField.textContent = `x ${err.message}`
        }
      })
    }

    localStorage.setItem(AUTH_TOKEN, token)
    localStorage.removeItem(RESET_TOKEN)

    window.location.href = '/'
  }, [])

  const [signInUser, { loading, error }] = useMutation(SIGN_IN_USER, {
    onCompleted: onUserSignIn,
  })

  const onSignInPress = useCallback(() => {
    const emErr = document.getElementById('email-error')
    emErr.classList.add('hidden')
    emErr.classList.remove('show')

    const pwErr = document.getElementById('password-error')
    pwErr.classList.add('hidden')
    pwErr.classList.remove('show')

    const em = document.getElementById('email')
    em.classList.remove('error')

    const pw = document.getElementById('password')
    pw.classList.remove('error')

    signInUser({ variables: { email: em.value, password: pw.value } })
  }, [signInUser])

  return (
    <div id="sign-in-box">
      <h4>{i18n.t('signIn')}</h4>
      <div>
        <EmailField
          id="email"
          value={email}
          placeholder={i18n.t('placeholderEmail')}
          label={i18n.t('email')}
        />
        <PasswordField
          id="password"
          value={password}
          placeholder={i18n.t('enterYourPassword')}
          label={i18n.t('password')}
        />
      </div>
      <div>
        <Button
          id="sign-in"
          text={i18n.t('signIn')}
          klass="sign-in"
          clickAction={onSignInPress}
        />
        {loading && <p>{i18n.t('processing')}...</p>}
        {error && <p>{error.message}</p>}
      </div>
      <div>
        <Link to="/forgot-password">{i18n.t('forgotPassword')}</Link>
      </div>
    </div>
  )
}

export default SignInUser
