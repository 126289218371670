import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Query, Mutation } from 'react-apollo'
import { withRouter } from 'react-router'
import { object } from 'prop-types'

import {
  CUSTOMER_SEARCH,
  FETCH_CURRENT_USER,
  EQUIPMENT_SEARCH,
  WORK_ORDER_SEARCH,
  CREATE_BUG_REPORT,
} from '../queries'

import {
  AUTH_TOKEN,
  WO_CUSTOMER_ID,
  WO_CUSTOMER_NAME,
  WO_EQUIP_ID,
  WO_EQUIP_SERIAL_NUM,
  WO_INTERNAL_ID,
} from '../constants'

import '../styles/globalNav.css'
import FontAwesome from 'react-fontawesome'
import i18n from 'i18n-js'
import { createBrowserHistory } from 'history'
import bug from '../assets/images/bugicon.png'

import Button from './Button'
import TextArea from './TextArea'
import SERVER_REDIRECTION_URL from '../config/config'

class GlobalNav extends Component {
  constructor(props) {
    super(props)

    this.changeValue = this.changeValue.bind(this)

    this.state = {
      searchValue: null,
      resultHide: true,
      selectedSearch: 'work order',
    }
  }

  changeValue = async (event) => {
    const value = event.target.value

    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout)
    }

    const resultHide = value === ''

    this.setState({
      searchValue: value,
      resultHide: resultHide,
    })
  }

  render() {
    let history = createBrowserHistory()

    const { resultHide, searchValue, selectedSearch } = this.state
    const token = localStorage.getItem(AUTH_TOKEN)
    const globalSearchClass = resultHide ? 'hidden' : 'visible'

    let placeholder = ''
    let searchQuery = null

    if (selectedSearch === 'customer') {
      placeholder = i18n.t('searchCustomerByNameNumber')
      searchQuery = CUSTOMER_SEARCH
    } else if (selectedSearch === 'equipment') {
      placeholder = i18n.t('searchEquipmentByNumber')
      searchQuery = EQUIPMENT_SEARCH
    } else if (selectedSearch === 'work order') {
      placeholder = i18n.t('searchWorkOrderByNumber')
      searchQuery = WORK_ORDER_SEARCH
    }

    if (token === null) {
      return (
        <div id="header">
          <Link to="/">
            <span id="homeNav" className="globalNav barrier">
              <span className="globalNavStyling">
                <FontAwesome name="home" size="2x" /> &nbsp;
                {i18n.t('home')}
              </span>
            </span>
          </Link>
        </div>
      )
    }

    return (
      <Query query={FETCH_CURRENT_USER} variables={{ token }}>
        {({ loading, error, data }) => {
          if (loading) return <div>{i18n.t('fetching')}</div>
          if (error) return <div>{error.message}</div>

          const currentUser = data['currentUser']

          return (
            <div id="header">
              <Link to="/">
                <span id="homeNav" className="globalNav barrier">
                  <span className="globalNavStyling">
                    <FontAwesome name="home" size="2x" /> &nbsp;
                    {i18n.t('home')}
                  </span>
                </span>
              </Link>

              <Link
                to="#"
                onClick={() => {
                  history.back()
                }}
              >
                <span id="backButton" className="globalNav barrier">
                  <span className="globalNavStyling">
                    <FontAwesome name="arrow-circle-left" size="2x" /> &nbsp;
                    {i18n.t('back')}
                  </span>
                </span>
              </Link>

              <Link to="/account">
                <span id="settingsNav" className="globalNav barrier">
                  <span className="globalNavStyling">
                    <FontAwesome name="cog" size="2x" /> &nbsp;
                    {i18n.t('settings')}
                  </span>
                </span>
              </Link>

              <Link
                to="#"
                onClick={() => {
                  localStorage.removeItem(AUTH_TOKEN)
                  localStorage.removeItem(WO_CUSTOMER_ID)
                  localStorage.removeItem(WO_CUSTOMER_NAME)
                  localStorage.removeItem(WO_EQUIP_ID)
                  localStorage.removeItem(WO_EQUIP_SERIAL_NUM)
                  localStorage.removeItem(WO_INTERNAL_ID)

                  this.props.history.push(`/`)
                }}
              >
                <span id="signOutNav" className="globalNav barrier">
                  <span className="globalNavStyling">
                    <FontAwesome name="sign-out" size="2x" /> &nbsp;
                    {i18n.t('signOut')}
                  </span>
                </span>
              </Link>

              <span>
                {currentUser['role'] !== 'customer' && (
                  <span id="globalSearchWrapper">
                    <div id="globalSearchContainer">
                      <input
                        placeholder={placeholder}
                        onChange={this.changeValue.bind(this)}
                      />
                    </div>

                    <div id="globalSearchSelector">
                      <div className="fieldContainer">
                        <select
                          value={selectedSearch}
                          id="global-search-selector-input"
                          onChange={(event) => {
                            this.setState({
                              selectedSearch: event.target.value,
                            })
                          }}
                        >
                          <option value="customer" key="1">
                            {i18n.t('searchByCustomer')}
                          </option>
                          <option value="equipment" key="2">
                            {i18n.t('searchByEquipment')}
                          </option>
                          <option value="work order" key="3">
                            {i18n.t('searchByWorkOrder')}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div id="globalSearchResults" className={globalSearchClass}>
                      <Query
                        query={searchQuery}
                        variables={{ token: token, search: searchValue }}
                      >
                        {({ loading, error, data }) => {
                          if (loading)
                            return (
                              <div className="report">{i18n.t('fetching')}</div>
                            )
                          if (error)
                            return <div className="report">{error.message}</div>

                          let results = null
                          if (selectedSearch === 'customer') {
                            results = data.searchCustomers
                          } else if (selectedSearch === 'equipment') {
                            results = data.searchEquipment
                          } else if (selectedSearch === 'work order') {
                            results = data.searchWorkOrders
                          }

                          if (searchValue.length < 4) {
                            return (
                              <div className="report">
                                {i18n.t('typeFourOrMoreChar')}
                              </div>
                            )
                          }

                          if (results.length === 0) {
                            return (
                              <div className="report">
                                {i18n.t('noResultsFound')}
                              </div>
                            )
                          }

                          return (
                            <div>
                              {results.map((result) => {
                                return (
                                  <div key={result.id} className="searchResult">
                                    {selectedSearch === 'customer' && (
                                      <Link
                                        to={{
                                          pathname: `/customer/${parseInt(
                                            result.id,
                                            10,
                                          )}`,
                                        }}
                                        onClick={() => {
                                          document.getElementById(
                                            'globalSearchContainer',
                                          ).children[0].value = ''
                                          this.setState({ searchValue: '' })
                                          this.setState({ resultHide: true })
                                        }}
                                      >
                                        {
                                          result.parentRelationships[0]
                                            .customerInternalVal
                                        }
                                        <br />
                                        {result.name} - {result.internalName}
                                      </Link>
                                    )}
                                    {selectedSearch === 'equipment' && (
                                      <Link
                                        to={{
                                          pathname: `/equipment/${parseInt(
                                            result.id,
                                            10,
                                          )}`,
                                        }}
                                        onClick={() => {
                                          document.getElementById(
                                            'globalSearchContainer',
                                          ).children[0].value = ''
                                          this.setState({ searchValue: '' })
                                          this.setState({ resultHide: true })
                                        }}
                                      >
                                        {
                                          result.vendorEquipment[0]
                                            .vendorEquipmentId
                                        }{' '}
                                        - {result.serialNumber}
                                      </Link>
                                    )}
                                    {selectedSearch === 'work order' && (
                                      <Link
                                        to={{
                                          pathname: `/work-order/${parseInt(
                                            result.id,
                                            10,
                                          )}`,
                                        }}
                                        // onClick={() => {
                                        //   document.getElementById(
                                        //     'globalSearchContainer',
                                        //   ).children[0].value = ''
                                        //   this.setState({ searchValue: '' })
                                        //   this.setState({ resultHide: true })
                                        // }}
                                        onClick={() => {
                                          window.location.replace(
                                            `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
                                              'auth-token',
                                            )}&location=workorders&id=${
                                              result.id
                                            }`,
                                          )
                                        }}
                                      >
                                        {result.internalId}
                                      </Link>
                                    )}
                                  </div>
                                )
                              })}
                            </div>
                          )
                        }}
                      </Query>
                    </div>
                  </span>
                )}

                <span id="bug-report">
                  <Link
                    to="#"
                    onClick={() => {
                      document
                        .getElementById('bug-report-form')
                        .classList.remove('hidden')
                    }}
                  >
                    <img src={bug} alt="bug-report" />
                    {i18n.t('reportABug')}
                  </Link>
                </span>

                <div id="bug-report-form" className="modal hidden">
                  <div className="modal-content">
                    <div className="modal-header">
                      <span
                        className="close"
                        onClick={() => {
                          document
                            .getElementById('bug-report-form')
                            .classList.add('hidden')
                        }}
                      >
                        &times;
                      </span>
                      <h2>{i18n.t('reportABug')}</h2>
                    </div>
                    <div className="modal-body">
                      <TextArea
                        id="bug-description"
                        placeholder={i18n.t('pleaseDescribeTheError')}
                      />
                      <div>
                        <div className="splitButtons">
                          <Mutation mutation={CREATE_BUG_REPORT}>
                            {(createBugReport) => {
                              return (
                                <div>
                                  <Button
                                    id="submit-bug"
                                    text={i18n.t('submit')}
                                    clickAction={() => {
                                      let description = document.getElementById(
                                        'bug-description',
                                      ).value

                                      if (description === '') {
                                        return ''
                                      }

                                      createBugReport({
                                        variables: {
                                          token,
                                          description,
                                        },
                                      }).then(() => {
                                        window.flash(
                                          i18n.t('bugReportThankYou'),
                                          'success',
                                        )
                                        document.getElementById(
                                          'bug-description',
                                        ).value = ''
                                        document
                                          .getElementById('bug-report-form')
                                          .classList.add('hidden')
                                      })
                                    }}
                                  />
                                </div>
                              )
                            }}
                          </Mutation>
                        </div>
                        <div className="splitButtons">
                          <Button
                            id="cancel-bug"
                            text={i18n.t('cancel')}
                            klass="destroy"
                            clickAction={() => {
                              document
                                .getElementById('bug-report-form')
                                .classList.add('hidden')
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          )
        }}
      </Query>
    )
  }
}

GlobalNav.defaultProps = {
  history: {},
}

GlobalNav.propTypes = {
  history: object,
}

export default withRouter(GlobalNav)
