import React, {Component} from 'react'

import {
  AUTH_TOKEN,
  WO_CUSTOMER_ID,
  WO_CUSTOMER_NAME,
  WO_EQUIP_ID,
  WO_EQUIP_SERIAL_NUM,
  WO_INTERNAL_ID,
} from '../constants'

import {
  FETCH_CURRENT_USER,
  FETCH_EQUIPMENT_BY_CUSTOMER,
  FETCH_EQUIPMENT_BY_VENDOR,
  CREATE_EQUIPMENT,
} from '../queries'

import {
  EQUIPMENT_GROUPS,
  MAINTENANCE_LEVELS
} from '../dropDowns'

import {
  Query,
  Mutation,
} from 'react-apollo'

import { Link, Redirect } from 'react-router-dom'
import { object } from 'prop-types'
import i18n from 'i18n-js'

import Button from '../components/Button'
import CurrencyField from '../components/CurrencyField'
import DropDown from '../components/DropDown'
import NumberField from '../components/NumberField'
import SignInUser from '../components/SignInUser'
import TextArea from '../components/TextArea'
import TextField from '../components/TextField'

import '../styles/invoice.css'

class NewWorkOrder2 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      equipId: localStorage.getItem(WO_EQUIP_ID),
      equipSerialNum: localStorage.getItem(WO_EQUIP_SERIAL_NUM),
      selectedAccordion: 'search',
      searchFilter: '',
    }
  }

  changeSearchFilter(event) {
    const searchFilter = event.target.value
    this.setState({ searchFilter })
  }

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser/>

    if (localStorage.getItem(WO_INTERNAL_ID) !== null) {
      this.props.history.push(`/new-work-order/3`)
    }

    const {
      equipId,
      equipSerialNum,
      selectedAccordion,
      searchFilter
    } = this.state

    const customerId = parseInt(localStorage.getItem(WO_CUSTOMER_ID), 10)
    const customerName = localStorage.getItem(WO_CUSTOMER_NAME)

    const searchAccordionClass = selectedAccordion === 'search' ? 'visible' : 'hidden'
    const createAccordionClass = selectedAccordion === 'create' ? 'visible' : 'hidden'

    return(
      <Query query={FETCH_CURRENT_USER} variables={{token}}>
        {({loading, error, data}) => {
          if (loading) return <div>{i18n.t('fetching')}</div>
          if (error) return <div>{error.message}</div>

          const currentUser = data.currentUser

          if (currentUser.role === 'customer') return <Redirect to='/' />

          const vendorId = parseInt(currentUser.vendorId, 10)

          let equipQuery = FETCH_EQUIPMENT_BY_CUSTOMER
          let id = customerId

          if (currentUser.role === 'vendor') {
            equipQuery = FETCH_EQUIPMENT_BY_VENDOR
            id = vendorId
          }

          return(
            <div>
              <h3>{i18n.t('searchForOrCreateEquipmentToInvoice')}</h3>
              <div>
                <strong>{i18n.t('customerSelected')}: </strong>
                {customerName} (<Link to='/new-work-order/1'>{i18n.t('edit')}</Link>)
              </div>
              { equipId &&
                <div>
                  <div>
                    <strong>{i18n.t('equipmentSelected')}:</strong> {equipSerialNum} (
                    <Link to='#' onClick={async (event) => {
                      event.preventDefault()
                      localStorage.setItem(WO_EQUIP_ID, '')
                      localStorage.setItem(WO_EQUIP_SERIAL_NUM, '')
                      this.setState({
                        equipId: null,
                        equipSerialNum: null,
                      })
                    } }>
                      Clear Selection
                    </Link>)
                  </div>
                  <Button
                    id='nextStep'
                    text={i18n.t('nextStep')}
                    clickAction={() =>{
                      window.location.href='/new-work-order/3'
                    } }
                  />
                </div>
              }
              <div>
                <Link to='#'
                  onClick={() => {
                    const newAccordion = selectedAccordion === 'search' ? 'create' : 'search'
                    this.setState({ selectedAccordion: newAccordion })
                  } }>
                  <div className='title-toggle'>
                    <h4>{i18n.t('searchForEquipment')}</h4>
                  </div>
                </Link>
                <div className={`accordion-form ${searchAccordionClass}`}>
                  <Query query={equipQuery} variables={{token, id}}>
                    {({ loading, error, data }) => {
                      if (loading) return <div className='report'>{i18n.t('processing')}</div>
                      if (error) return <div className='report'>{error.message}</div>

                      let equipment = []

                      if (currentUser.role === 'vendor') {
                        data.customer.childRelationships.map(cr => {
                          cr.childCustomer.equipment.map(equip => {
                            equipment.push(equip)
                            return ''
                          } )
                          return ''
                        } )
                      } else {
                        equipment = data.customer.equipment
                      }

                      return(
                        <div className='row'>
                          <div className='field'>
                            <input
                              id='filter-equipment'
                              type='text'
                              className='text-field'
                              placeholder={i18n.t('filterEquipBySerialNum')}
                              onChange={this.changeSearchFilter.bind(this)}
                              value={searchFilter}
                            />
                          </div>
                          { equipment.map( equip => {
                            let equipRegex = new RegExp(searchFilter, 'i')

                            if ((searchFilter !== '') && (!equipRegex.test(equip.serialNumber))) {
                              return ''
                            }

                            return(
                              <div key={equip.id} className='equip-block'>
                                <h3>
                                  <Link to='#' onClick={(event) => {
                                    event.preventDefault()
                                    localStorage.setItem(WO_EQUIP_ID, equip.id)
                                    localStorage.setItem(WO_EQUIP_SERIAL_NUM, equip.serialNumber)
                                    this.setState({
                                      equipId: equip.id,
                                      equipSerialNum: equip.serialNumber,
                                    })
                                  } } >
                                    {equip.serialNumber}
                                  </Link>
                                </h3>
                                <div>
                                  <strong>{i18n.t('fleetNumber')}:</strong> {equip.fleetNumber}
                                </div>
                                <div>
                                  <strong>{i18n.t('make')}:</strong> {equip.make}
                                </div>
                                <div>
                                  <strong>{i18n.t('model')}:</strong> {equip.model}
                                </div>
                              </div>
                            )
                          } ) }
                        </div>
                      )
                    } }
                  </Query>
                </div>
              </div>
              <div>
                <Link to='#'
                  onClick={() => {
                    const newAccordion = selectedAccordion === 'search' ? 'create' : 'search'
                    this.setState({ selectedAccordion: newAccordion })
                } }>
                  <div className='title-toggle'>
                    <h4>{i18n.t('createNewEquipment')}</h4>
                  </div>
                </Link>
                <div className={`accordion-form ${createAccordionClass}`}>
                  <TextField
                    id='vendorEquipmentId'
                    label={`${i18n.t('vendorEquipmentId')} *`}
                    placeholder={i18n.t('vendorEquipmentId')}
                  />
                  <DropDown
                    id='maintenanceLevel'
                    label={i18n.t('maintenanceLevel')}
                    options={MAINTENANCE_LEVELS}
                    selected='TM'
                    allowNull='false'
                  />
                  <NumberField
                    id='year'
                    label={i18n.t('year')}
                    placeholder={i18n.t('year')}
                    min='1990'
                    step='1'
                  />
                  <TextField
                    id='serialNumber'
                    label={i18n.t('serialNumber')}
                    placeholder={i18n.t('serialNumber')}
                  />
                  <TextField
                    id='fleetNumber'
                    label={i18n.t('fleetNumber')}
                    placeholder={i18n.t('fleetNumber')}
                  />
                  <TextField
                    id='make'
                    label={i18n.t('make')}
                    placeholder={i18n.t('make')}
                  />
                  <TextField
                    id='model'
                    label={i18n.t('model')}
                    placeholder={i18n.t('model')}
                  />
                  <CurrencyField
                    id='acquisitionCost'
                    label={i18n.t('acquisitionCost')}
                    placeholder={i18n.t('acquisitionCost')}
                  />
                  <NumberField
                    id='plannedHours'
                    label={i18n.t('plannedHours')}
                    placeholder={i18n.t('plannedHours')}
                  />
                  <TextArea
                    id='description'
                    label={i18n.t('descriptionOfEquipment')}
                    placeholder={i18n.t('pertinentInformationHere')}
                  />
                  <DropDown
                    id='group'
                    label={i18n.t('group')}
                    options={EQUIPMENT_GROUPS}
                    allowNull='false'
                  />
                  <Mutation mutation={CREATE_EQUIPMENT}>
                    {(createEquipment, { data, loading, error }) => {
                      return(
                        <div>
                          <Button
                            id='submit-equipment'
                            text={i18n.t('submit')}
                            clickAction={() => {
                              const vendorEquipmentId = document.getElementById('vendorEquipmentId').value
                              const veiErr = document.getElementById('vendorEquipmentId-error')

                              if (vendorEquipmentId === '') {
                                document.getElementById('vendorEquipmentId').classList.add('error')
                                veiErr.innerHTML = i18n.t('vendorEquipmentIdError')
                                veiErr.classList.add('show')
                                veiErr.classList.remove('hidden')

                               window.flash(i18n.t('vendorEquipmentIdError'), 'error')
                               return ''
                              }

                              document.getElementById('vendorEquipmentId').classList.remove('error')
                              veiErr.classList.remove('show')
                              veiErr.classList.add('hidden')

                              const maintenanceLevel = document.getElementById('maintenanceLevel').value
                              const fleetNumber = document.getElementById('fleetNumber').value
                              const year = parseInt(document.getElementById('year').value, 10)
                              const model = document.getElementById('model').value
                              const make = document.getElementById('make').value
                              const serialNumber = document.getElementById('serialNumber').value
                              const acquisitionCost = parseFloat(document.getElementById('acquisitionCost').value)
                              const plannedHours = parseInt(document.getElementById('plannedHours').value, 10)
                              const description = document.getElementById('description').value
                              let group = document.getElementById('group').value

                              if (group === '') {
                                group = null
                              }

                              createEquipment({
                                variables: {
                                  token,
                                  vendorId,
                                  customerId,
                                  ownerId: customerId,
                                  vendorEquipmentId,
                                  maintenanceLevel,
                                  fleetNumber,
                                  year,
                                  model,
                                  make,
                                  serialNumber,
                                  acquisitionCost,
                                  plannedHours,
                                  description,
                                  group,
                                }
                              }).then( res => {
                                if (data.errors.length > 0) {
                                  console.log('oops')
                                }

                                const equipment = res.data.createEquipment.equipment
                                const equipId = parseInt(equipment.id, 10)
                                const equipSerialNum = equipment.serialNumber

                                localStorage.setItem(WO_EQUIP_ID, equipId)
                                localStorage.setItem(WO_EQUIP_SERIAL_NUM, serialNumber)

                                this.setState({
                                  equipId,
                                  equipSerialNum,
                                })

                                window.location.href = '/new-work-order/3'
                              })
                            } }
                          />
                          { loading && <p>{i18n.t('processing')}</p> }
                          { error && <p>{error.message}</p> }
                        </div>
                      )
                    } }
                  </Mutation>
                </div>
              </div>
            </div>
          )
        } }
      </Query>
    )
  }
}

NewWorkOrder2.defaultProps = {
  hisotry: {},
}

NewWorkOrder2.propTypes = {
  history: object,
}

export default NewWorkOrder2
