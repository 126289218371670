import React, { Component } from 'react'
import {
  Query,
  Mutation,
} from 'react-apollo'
import {
  Redirect,
} from 'react-router-dom'
import i18n from 'i18n-js'

import Button from './Button'
import DropDown from './DropDown'
import SignInUser from './SignInUser'

import { AUTH_TOKEN } from '../constants'

import {
  USER_TYPES,
} from '../dropDowns'

import {
  FETCH_ALL_USER_GROUPS,
  FETCH_CURRENT_USER,
  CREATE_USER,
} from '../queries'
import '../styles/home.css'

class NewUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
    }
  }

  render() {
    const {
      firstName,
      lastName,
      email,
    } = this.state

    const token = localStorage.getItem(AUTH_TOKEN)

    if (!token) return <SignInUser />

    return(
      <Query query={FETCH_CURRENT_USER} variables={{ token }}>
        {({ loading, error, data }) => {
          if (loading) return <div>{i18n.t('fetching')}</div>
          if (error) return <div>{error.message}</div>

          const currentUser = data['currentUser']

          if (currentUser['role'] !== 'admin') return <Redirect to='/' />

          return(
            <Query query={FETCH_ALL_USER_GROUPS} variables={{ token }}>
              {({ loading, error, data }) => {
                if (loading) return <div>{i18n.t('fetching')}</div>
                if (error) return <div>{error.message}</div>

                const userGroups = data.allUserGroups

                let userGroupsForSelect = []

                userGroups.map((userGroup) => {
                  userGroupsForSelect.push({
                    key: userGroup.id,
                    value: userGroup.id,
                    name: userGroup.name
                  })

                  return ''
                })

                return (
                  <div>
                    <h2>New User</h2>
                    <div className='fieldContainer'>
                      <div className='fieldLabel'>
                        <label htmlFor='first-name'>
                          {i18n.t('firstName')}
                        </label>
                      </div>
                      <div className='field'>
                        <input
                          id='first-name'
                          placeholder={i18n.t('firstName')}
                          value={firstName}
                          className='text-field'
                          onChange={(event) => {
                            this.setState({ firstName: event.target.value })
                          } }
                        />
                        <span id='first-name-error' className='hidden field-error'>
                          {i18n.t('missingFirstName')}
                        </span>
                      </div>
                    </div>
                    <div className='fieldContainer'>
                      <div className='fieldLabel'>
                        <label htmlFor='last-name'>
                          {i18n.t('lastName')}
                        </label>
                      </div>
                      <div className='field'>
                        <input
                          id='last-name'
                          placeholder={i18n.t('lastName')}
                          value={lastName}
                          className='text-field'
                          onChange={(event) => {
                            this.setState({ lastName: event.target.value })
                          } }
                        />
                        <span id='last-name-error' className='hidden field-error'>
                          {i18n.t('missingLastName')}
                        </span>
                      </div>
                    </div>
                    <div className='fieldContainer'>
                      <div className='fieldLabel'>
                        <label htmlFor='email'>
                          {i18n.t('email')}
                        </label>
                      </div>
                      <div className='field'>
                        <input
                          id='email'
                          placeholder={i18n.t('email')}
                          value={email}
                          className='text-field'
                          onChange={(event) => {
                            this.setState({ email: event.target.value })
                          } }
                        />
                        <span id='email-error' className='hidden field-error'>
                          {i18n.t('missingEmail')}
                        </span>
                      </div>
                    </div>
                    <DropDown
                      id='user-group-select'
                      options={userGroupsForSelect}
                      label={i18n.t('userGroup')}
                      selected={userGroupsForSelect[0].value}
                      rawOptions={true}
                      allowNull={'false'}
                    />
                    <DropDown
                      id='role-select'
                      options={USER_TYPES}
                      label={i18n.t('role')}
                      selected={USER_TYPES[2].value}
                      rawOptions={true}
                      allowNull={'false'}
                    />
                    <Mutation mutation={CREATE_USER}>
                      {(createUser, {loading, error}) => {
                        return(
                          <div>
                            <Button
                              id="create-user"
                              text={i18n.t('createUser')}
                              clickAction={() => {
                                const ugSel = document.getElementById('user-group-select')

                                let userGroupId = null

                                if (ugSel.options[ugSel.selectedIndex].text !== '') {
                                  userGroupId = parseInt(
                                    ugSel.options[ugSel.selectedIndex]['value'],
                                    10,
                                  )
                                }

                               const roleSel = document.getElementById('role-select')

                                let role = null

                                if (roleSel.options[roleSel.selectedIndex].text !== '') {
                                  role = roleSel.options[roleSel.selectedIndex]['value']
                                }

                                const fnErr = document.getElementById('first-name-error')
                                const lnErr = document.getElementById('last-name-error')
                                const emailErr = document.getElementById('email-error')

                                const fnField = document.getElementById('first-name')
                                const lnField = document.getElementById('last-name')
                                const emailField = document.getElementById('email')

                                if ( (firstName === '') ||
                                  (lastName === '') ||
                                  (email === '')
                                ) {
                                  if (firstName === '') {
                                    fnErr.classList.add('show')
                                    fnErr.classList.remove('hidden')
                                    fnField.classList.add('error')
                                  }

                                  if (lastName === '') {
                                    lnErr.classList.add('show')
                                    lnErr.classList.remove('hidden')
                                    lnField.classList.add('error')
                                  }

                                  if (email === '') {
                                    emailErr.classList.add('show')
                                    emailErr.classList.remove('hidden')
                                    emailField.classList.add('error')
                                  }
                                  window.flash(i18n.t('validationsNotMet'), 'error')
                                } else {
                                  fnErr.classList.add('hidden')
                                  fnErr.classList.remove('show')
                                  lnErr.classList.add('hidden')
                                  lnErr.classList.remove('show')
                                  emailErr.classList.add('hidden')
                                  emailErr.classList.remove('show')
                                  fnField.classList.remove('error')
                                  lnField.classList.remove('error')
                                  emailField.classList.remove('error')

                                  createUser({
                                    variables: {
                                      firstName,
                                      lastName,
                                      email,
                                      userGroupId,
                                      role,
                                      token,
                                    },
                                  }).then(() => {
                                    this.setState({
                                      firstName: '',
                                      lastName: '',
                                      email: '',
                                    })

                                    window.flash(i18n.t('userCreated'), 'success')
                                  })
                                }
                              } }
                            />
                            {loading && <p>{i18n.t('processing')}...</p>}
                            {error && <p>{error.message}</p>}
                          </div>
                        )
                      } }
                    </Mutation>
                  </div>
                )
              } }
            </Query>
          )
        } }
      </Query>
    )
  }
}

export default NewUser
