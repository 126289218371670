import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './styles/index.css'
import App from './components/App'
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import './localize'

const link = createHttpLink({
  //uri: 'http://10.17.10.89/graphql'
  //uri: 'https://tdwdev.ngrok.io/graphql'
  //uri: 'http://localhost:3000/graphql',
  //uri: 'https://api.transparencyfleet.com/graphql',
  //uri: 'http://ec2-18-218-145-33.us-east-2.compute.amazonaws.com/graphql',
  uri: 'https://sandbox-api.transparencyfleet.com/graphql',
})

const cache = new InMemoryCache()

const client = new ApolloClient({
  link,
  cache,
})

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root'),
)
