import React, { Component } from 'react'
import { Query, Mutation } from 'react-apollo'
import { Link, Redirect } from 'react-router-dom'
import { object } from 'prop-types'
import i18n from 'i18n-js'
import FontAwesome from 'react-fontawesome'

import AllLogs from '../components/AllLogs'
import AllNotes from '../components/AllNotes'
import AllReviews from '../components/AllReviews'
import Button from '../components/Button'
import CurrencyField from '../components/CurrencyField'
import DropDown from '../components/DropDown'
import LineChart from '../components/LineChart'
import NumberField from '../components/NumberField'
import SignInUser from '../components/SignInUser'
import TextArea from '../components/TextArea'
import TextField from '../components/TextField'
import TimeStamp from '../components/TimeStamp'

import { AUTH_TOKEN } from '../constants'

import { EQUIPMENT_GROUPS, MAINTENANCE_LEVELS } from '../dropDowns'

import { LIMITED_ROLES } from '../globals'

import {
  FETCH_CURRENT_USER,
  FETCH_EQUIPMENT,
  UPDATE_EQUIPMENT,
  UPDATE_WORK_ORDER,
} from '../queries'
import SERVER_REDIRECTION_URL from '../config/config'

class Equipment extends Component {
  constructor(props) {
    super(props)

    this.woChangeSortBy = this.woChangeSortBy.bind(this)

    let id = null
    if (typeof props.id !== 'object') {
      id = props.id
    } else if (props.match.params.id !== null) {
      id = props.match.params.id
    }

    this.state = {
      id: id,
      equipment: [],
      workOrders: [],
      hmm: [],
      hmManager: 'hidden',
      equipEditManager: 'hidden',
      allWorkOrders: 'hidden',
      woSortBy: 'dateDesc',
    }
  }

  woChangeSortBy(val) {
    this.setState({ woSortBy: val })
  }

  render() {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token === null) return <SignInUser />

    const id = parseInt(this.state.id, 10)

    const {
      equipment,
      workOrders,
      hmm,
      hmManager,
      equipEditManager,
      allWorkOrders,
      woSortBy,
    } = this.state

    return (
      <Query query={FETCH_CURRENT_USER} variables={{ token }}>
        {({ loading, error, data }) => {
          if (loading) return <div>{i18n.t('fetching')}</div>
          if (error) return <div>{error.message}</div>

          const currentUser = data['currentUser']

          return (
            <Query query={FETCH_EQUIPMENT} variables={{ token, id }}>
              {({ loading, error, data }) => {
                if (loading) return <div>{i18n.t('fetching')}</div>
                if (error) return <div>{error.message}</div>

                if (data.equipment === null) return <Redirect to="/" />

                equipment.year = data.equipment.year
                equipment.model = data.equipment.model
                equipment.make = data.equipment.make
                equipment.fleetNumber = data.equipment.fleetNumber
                equipment.serialNumber = data.equipment.serialNumber
                equipment.acquisitionCost = data.equipment.acquisitionCost
                equipment.maintenanceLevel = data.equipment.maintenanceLevel
                equipment.plannedHours = data.equipment.plannedHours
                equipment.description = data.equipment.description
                equipment.group = data.equipment.group

                const customer = data.equipment.customer

                const isCustomer = customer.parentRelationships.length > 0

                let customerName = customer.name

                if (customer.internalName !== null) {
                  customerName += ` - ${customer.internalName}`
                }

                if (isCustomer) {
                  customerName += ` (${customer.parentRelationships[0].customerInternalVal})`
                }

                const ownerName = data.equipment.owner.name
                const vendorId =
                  data.equipment.vendorEquipment[0].vendorEquipmentId
                const logs = data.equipment.logs
                const notes = data.equipment.notes
                const reviews = data.equipment.reviews

                let hmData = []
                let driveData = []
                let pumpData = []

                if (workOrders.length === 0) {
                  data.equipment.workOrders.map((wo) => {
                    workOrders.push(wo)
                    hmm.push(wo)
                    return ''
                  })
                }

                hmm.sort((a, b) => (a.startedAt < b.startedAt ? 1 : -1))

                switch (woSortBy) {
                  case 'dateAsc':
                    workOrders.sort((a, b) =>
                      a.startedAt > b.startedAt ? 1 : -1,
                    )
                    break
                  case 'serviceTypeAsc':
                    workOrders.sort((a, b) =>
                      a.serviceType > b.serviceType ? 1 : -1,
                    )
                    break
                  case 'serviceTypeDesc':
                    workOrders.sort((a, b) =>
                      a.serviceType < b.serviceType ? 1 : -1,
                    )
                    break
                  default:
                    workOrders.sort((a, b) =>
                      a.startedAt < b.startedAt ? 1 : -1,
                    )
                    break
                }

                workOrders.map((wo) => {
                  let date = wo.startedAt.split(' ')[0]
                  let year = parseInt(date.split('-')[0], 10)
                  // The form requires month data to start at 0
                  let month = parseInt(date.split('-')[1], 10) - 1
                  let day = parseInt(date.split('-')[2], 10)

                  let finalDate = new Date(year, month, day)
                  let keyOnMeter = 0

                  if (parseInt(wo.keyOnMeter, 10) > 0) {
                    keyOnMeter = parseInt(wo.keyOnMeter, 10)
                  }

                  hmData.push({
                    x: finalDate,
                    y: keyOnMeter,
                    click: function () {
                      document.location.href = '/work-order/' + wo.id
                    },
                  })

                  if (wo.driveMeter !== null) {
                    const driveMeter = parseInt(wo.driveMeter, 10)
                    driveData.push({
                      x: finalDate,
                      y: driveMeter,
                      click: function () {
                        document.location.href = '/work-order/' + wo.id
                      },
                    })
                  }

                  if (wo.pumpMeter !== null) {
                    const pumpMeter = parseInt(wo.pumpMeter, 10)
                    pumpData.push({
                      x: finalDate,
                      y: pumpMeter,
                      click: function () {
                        document.location.href = '/work-order/' + wo.id
                      },
                    })
                  }

                  return ''
                })

                return (
                  <div>
                    <h3>{i18n.t('equipmentId', { id: vendorId })}</h3>
                    <div className="fieldContainer">
                      <div className="fieldLabel">
                        <label>{i18n.t('owner')}</label>
                      </div>
                      {ownerName}
                    </div>
                    <div className="fieldContainer">
                      <div className="fieldLabel">
                        <label>{i18n.t('customer')}</label>
                      </div>
                      {currentUser['role'] === 'customer' ? (
                        <div>{customerName}</div>
                      ) : (
                        <Link to={{ pathname: `/customer/${customer.id}` }}>
                          {customerName}
                        </Link>
                      )}
                    </div>
                    <LineChart
                      hmData={hmData}
                      driveData={driveData}
                      pumpData={pumpData}
                    />
                    {!LIMITED_ROLES.includes(currentUser['role']) && (
                      <div>
                        <Link
                          to="#"
                          onClick={() => {
                            let css = hmManager === 'hidden' ? 'show' : 'hidden'
                            this.setState({ hmManager: css })
                          }}
                        >
                          <div className="title-toggle">
                            <h4>{i18n.t('hourMeterManager')}</h4>
                          </div>
                        </Link>
                        <table className={hmManager}>
                          <thead>
                            <tr>
                              <th>{i18n.t('internalId')}</th>
                              <th>{i18n.t('startedAt')}</th>
                              <th>{i18n.t('keyOnMeter')}</th>
                              <th>{i18n.t('driveMeter')}</th>
                              <th>{i18n.t('pumpMeter')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {hmm.map((wo) => {
                              return (
                                <tr key={wo.internalId}>
                                  <td>{wo.internalId}</td>
                                  <td>
                                    <TimeStamp timeToFormat={wo.startedAt} />
                                  </td>
                                  <td>
                                    <Mutation mutation={UPDATE_WORK_ORDER}>
                                      {(updateWorkOrder) => {
                                        return (
                                          <div
                                            className="fieldContainer"
                                            style={{ margin: '0px' }}
                                          >
                                            <div className="field">
                                              <input
                                                type="number"
                                                className="text-field table-field"
                                                value={wo.keyOnMeter}
                                                placeholder="0"
                                                onChange={(event) => {
                                                  let val = parseInt(
                                                    event.target.value,
                                                    10,
                                                  )
                                                  if (isNaN(val)) {
                                                    window.flash(
                                                      i18n.t(
                                                        'keyOnMeterCanOnlySupportIntegers',
                                                      ),
                                                      'error',
                                                    )
                                                    return ''
                                                  }

                                                  updateWorkOrder({
                                                    variables: {
                                                      token,
                                                      id: parseInt(wo.id, 10),
                                                      keyOnMeter: parseInt(
                                                        val,
                                                        10,
                                                      ),
                                                    },
                                                  })
                                                  wo.keyOnMeter = val
                                                  this.setState({ workOrders })
                                                }}
                                              />
                                            </div>
                                          </div>
                                        )
                                      }}
                                    </Mutation>
                                  </td>
                                  <td>
                                    <Mutation mutation={UPDATE_WORK_ORDER}>
                                      {(updateWorkOrder) => {
                                        return (
                                          <div
                                            className="fieldContainer"
                                            style={{ margin: '0px' }}
                                          >
                                            <div className="field">
                                              <input
                                                type="number"
                                                className="text-field table-field"
                                                value={
                                                  wo.driveMeter === null
                                                    ? ''
                                                    : wo.driveMeter
                                                }
                                                placeholder="0"
                                                onChange={(event) => {
                                                  let val = parseInt(
                                                    event.target.value,
                                                    10,
                                                  )
                                                  if (isNaN(val)) {
                                                    window.flash(
                                                      i18n.t(
                                                        'driveMeterCanOnlySupportIntegers',
                                                      ),
                                                      'error',
                                                    )
                                                    return ''
                                                  }

                                                  updateWorkOrder({
                                                    variables: {
                                                      token,
                                                      id: parseInt(wo.id, 10),
                                                      driveMeter: parseInt(
                                                        val,
                                                        10,
                                                      ),
                                                    },
                                                  })
                                                  wo.driveMeter = val
                                                  this.setState({ workOrders })
                                                }}
                                              />
                                            </div>
                                          </div>
                                        )
                                      }}
                                    </Mutation>
                                  </td>
                                  <td>
                                    <Mutation mutation={UPDATE_WORK_ORDER}>
                                      {(updateWorkOrder) => {
                                        return (
                                          <div
                                            className="fieldContainer"
                                            style={{ margin: '0px' }}
                                          >
                                            <div className="field">
                                              <input
                                                type="number"
                                                className="text-field table-field"
                                                value={
                                                  wo.pumpMeter === null
                                                    ? ''
                                                    : wo.pumpMeter
                                                }
                                                placeholder="0"
                                                onChange={(event) => {
                                                  let val = parseInt(
                                                    event.target.value,
                                                    10,
                                                  )
                                                  if (isNaN(val)) {
                                                    window.flash(
                                                      i18n.t(
                                                        'pumpMeterCanOnlySupportIntegers',
                                                      ),
                                                      'error',
                                                    )
                                                    return ''
                                                  }
                                                  updateWorkOrder({
                                                    variables: {
                                                      token,
                                                      id: parseInt(wo.id, 10),
                                                      pumpMeter: parseInt(
                                                        val,
                                                        10,
                                                      ),
                                                    },
                                                  })
                                                  wo.pumpMeter = val
                                                  this.setState({ workOrders })
                                                }}
                                              />
                                            </div>
                                          </div>
                                        )
                                      }}
                                    </Mutation>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {LIMITED_ROLES.includes(currentUser['role']) ? (
                      <div>
                        <h4>{i18n.t('equipmentInformation')}</h4>
                        <div>
                          <strong>{i18n.t('year')}:</strong> {equipment.year}
                        </div>
                        <div>
                          <strong>{i18n.t('fleetNumber')}:</strong>{' '}
                          {equipment.fleetNumber}
                        </div>
                        <div>
                          <strong>{i18n.t('serialNumber')}:</strong>{' '}
                          {equipment.serialNumber}
                        </div>
                        <div>
                          <strong>{i18n.t('make')}:</strong> {equipment.make}
                        </div>
                        <div>
                          <strong>{i18n.t('model')}:</strong> {equipment.model}
                        </div>
                        {currentUser['role'] === 'service' && (
                          <div>
                            <div>
                              <strong>{i18n.t('acquisitionCost')}:</strong>{' '}
                              {equipment.acquisitonCost}
                            </div>
                            <div>
                              <strong>{i18n.t('plannedHours')}:</strong>{' '}
                              {equipment.plannedHours}
                            </div>
                            <div>
                              <strong>{i18n.t('maintenanceLevel')}:</strong>{' '}
                              {equipment.maintenanceLevel}
                            </div>
                            <div>
                              <strong>{i18n.t('description')}:</strong>{' '}
                              {equipment.description}
                            </div>
                            <div>
                              <strong>{i18n.t('group')}:</strong>{' '}
                              {equipment.group}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <Link
                          to="#"
                          onClick={() => {
                            let css =
                              equipEditManager === 'hidden' ? 'show' : 'hidden'
                            this.setState({ equipEditManager: css })
                          }}
                        >
                          <div className="title-toggle">
                            <h4>{i18n.t('editEquipment')}</h4>
                          </div>
                        </Link>
                        <div className={equipEditManager}>
                          <NumberField
                            id="year"
                            label={i18n.t('year')}
                            placeholder={i18n.t('year')}
                            value={equipment.year}
                            min={1990}
                            step="1"
                          />
                          <TextField
                            id="fleetNumber"
                            label={i18n.t('fleetNumber')}
                            placeholder={i18n.t('fleetNumber')}
                            value={equipment.fleetNumber}
                          />
                          <TextField
                            id="serialNumber"
                            label={i18n.t('serialNumber')}
                            placeholder={i18n.t('serialNumber')}
                            value={equipment.serialNumber}
                          />
                          <TextField
                            id="make"
                            label={i18n.t('make')}
                            placeholder={i18n.t('make')}
                            value={equipment.make}
                          />
                          <TextField
                            id="model"
                            label={i18n.t('model')}
                            placeholder={i18n.t('model')}
                            value={equipment.model}
                          />
                          <CurrencyField
                            id="acquisitionCost"
                            label={i18n.t('acquisitionCost')}
                            placeholder={i18n.t('acquisitionCost')}
                            value={equipment.acquisitionCost}
                          />
                          <NumberField
                            id="plannedHours"
                            label={i18n.t('plannedHours')}
                            placeholder={i18n.t('plannedHours')}
                            value={equipment.plannedHours}
                          />
                          <DropDown
                            id="maintenanceLevel"
                            label={i18n.t('maintenanceLevel')}
                            options={MAINTENANCE_LEVELS}
                            selected={equipment.maintenanceLevel}
                            allowNull="false"
                          />
                          <TextArea
                            id="description"
                            placeholder={i18n.t('descriptionOfEquipment')}
                            label={i18n.t('equipment')}
                            content={equipment.description}
                          />
                          <DropDown
                            id="group"
                            label={i18n.t('group')}
                            options={EQUIPMENT_GROUPS}
                            selected={equipment.group}
                            allowNull="false"
                          />
                          <Mutation mutation={UPDATE_EQUIPMENT}>
                            {(updateEquipment, { loading, error }) => {
                              return (
                                <div>
                                  <Button
                                    id="update-equipment"
                                    text={i18n.t('updateEquipment')}
                                    clickAction={() => {
                                      const newYear = parseInt(
                                        document.getElementById('year').value,
                                        10,
                                      )
                                      const newFleetNo = document.getElementById(
                                        'fleetNumber',
                                      ).value
                                      const newSerialNo = document.getElementById(
                                        'serialNumber',
                                      ).value
                                      const newMake = document.getElementById(
                                        'make',
                                      ).value
                                      const newModel = document.getElementById(
                                        'model',
                                      ).value
                                      const newAcqCost = parseFloat(
                                        document.getElementById(
                                          'acquisitionCost',
                                        ).value,
                                      )
                                      const newPlannedHours = parseInt(
                                        document.getElementById('plannedHours')
                                          .value,
                                        10,
                                      )
                                      const newMaintenanceLevel = document.getElementById(
                                        'maintenanceLevel',
                                      ).value
                                      const newDescription = document.getElementById(
                                        'description',
                                      ).value
                                      let sel = document.getElementById('group')
                                      let newGroup = null
                                      if (
                                        sel.options[sel.selectedIndex].text !==
                                        ''
                                      ) {
                                        newGroup =
                                          sel.options[sel.selectedIndex][
                                            'value'
                                          ]
                                      }

                                      updateEquipment({
                                        variables: {
                                          token,
                                          id,
                                          year: newYear,
                                          fleetNumber: newFleetNo,
                                          serialNumber: newSerialNo,
                                          make: newMake,
                                          model: newModel,
                                          acquisitionCost: newAcqCost,
                                          plannedHours: newPlannedHours,
                                          maintenanceLevel: newMaintenanceLevel,
                                          description: newDescription,
                                          group: newGroup,
                                        },
                                      }).then(() => {
                                        window.flash(
                                          i18n.t('updatedEquipment'),
                                          'success',
                                        )
                                      })
                                    }}
                                  />
                                  {loading && <p>{i18n.t('processing')}...</p>}
                                  {error && <p>{error.message}</p>}
                                </div>
                              )
                            }}
                          </Mutation>
                        </div>
                      </div>
                    )}
                    {currentUser['role'] !== 'customer' && (
                      <div>
                        <div>
                          <Link
                            to="#"
                            onClick={() => {
                              let css =
                                allWorkOrders === 'hidden'
                                  ? 'visible'
                                  : 'hidden'
                              this.setState({ allWorkOrders: css })
                            }}
                          >
                            <div className="title-toggle">
                              <h4>{i18n.t('workOrders')}</h4>
                            </div>
                          </Link>
                          <div className={allWorkOrders}>
                            <div className="visible">
                              <div>
                                <div className="review-search-results">
                                  <table id="wo-table" border="1">
                                    <thead>
                                      <tr>
                                        <th>
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              this.woChangeSortBy(
                                                woSortBy === 'dateAsc'
                                                  ? 'dateDesc'
                                                  : 'dateAsc',
                                              )
                                            }}
                                          >
                                            {i18n.t('startedAt')} &nbsp;
                                            <FontAwesome
                                              name={
                                                woSortBy === 'dateAsc'
                                                  ? 'sort-down'
                                                  : 'sort-up'
                                              }
                                            />
                                          </Link>
                                        </th>
                                        <th>{i18n.t('noPeriod')}</th>
                                        <th>
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              this.woChangeSortBy(
                                                woSortBy === 'serviceTypeAsc'
                                                  ? 'serviceTypeDesc'
                                                  : 'serviceTypeAsc',
                                              )
                                            }}
                                          >
                                            {i18n.t('serviceType')} &nbsp;
                                            <FontAwesome
                                              name={
                                                woSortBy === 'serviceTypeAsc'
                                                  ? 'sort-down'
                                                  : 'sort-up'
                                              }
                                            />
                                          </Link>
                                        </th>
                                        <th>{i18n.t('serviceDescription')}</th>
                                        <th>{i18n.t('meter')}</th>
                                        <th>{i18n.t('amount')}</th>
                                        <th>{i18n.t('downtime')}</th>
                                        <th>{i18n.t('invoiceType')}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {workOrders.map((wo) => {
                                        let pmCost = 0.0
                                        let damageCost = 0.0
                                        let tiresCost = 0.0
                                        let repairCost = 0.0
                                        let requestCost = 0.0
                                        let attachCost = 0.0

                                        wo.lineItems.map((li) => {
                                          const amount = parseFloat(li.amount)

                                          switch (li.workCategory.value) {
                                            case 'PM':
                                              pmCost += amount
                                              break
                                            case 'Damage':
                                              damageCost += amount
                                              break
                                            case 'Tires':
                                              tiresCost += amount
                                              break
                                            case 'Repair':
                                              repairCost += amount
                                              break
                                            case 'Request':
                                              requestCost += amount
                                              break
                                            case 'Attachment':
                                              attachCost += amount
                                              break
                                            default:
                                              break
                                          }

                                          return ''
                                        })

                                        let pmCostParts = parseFloat(pmCost)
                                          .toFixed(2)
                                          .split('.')
                                        pmCostParts[0] = pmCostParts[0].replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ',',
                                        )
                                        const pmCostFin = `$${pmCostParts.join(
                                          '.',
                                        )}`

                                        let repairCostParts = parseFloat(
                                          repairCost,
                                        )
                                          .toFixed(2)
                                          .split('.')
                                        repairCostParts[0] = repairCostParts[0].replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ',',
                                        )
                                        const repairCostFin = `$${repairCostParts.join(
                                          '.',
                                        )}`

                                        let tiresCostParts = parseFloat(
                                          tiresCost,
                                        )
                                          .toFixed(2)
                                          .split('.')
                                        tiresCostParts[0] = tiresCostParts[0].replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ',',
                                        )
                                        const tiresCostFin = `$${tiresCostParts.join(
                                          '.',
                                        )}`

                                        let damageCostParts = parseFloat(
                                          damageCost,
                                        )
                                          .toFixed(2)
                                          .split('.')
                                        damageCostParts[0] = damageCostParts[0].replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ',',
                                        )
                                        const damageCostFin = `$${damageCostParts.join(
                                          '.',
                                        )}`

                                        let requestCostParts = parseFloat(
                                          requestCost,
                                        )
                                          .toFixed(2)
                                          .split('.')
                                        requestCostParts[0] = requestCostParts[0].replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ',',
                                        )
                                        const requestCostFin = `$${requestCostParts.join(
                                          '.',
                                        )}`

                                        let attachCostParts = parseFloat(
                                          attachCost,
                                        )
                                          .toFixed(2)
                                          .split('.')
                                        attachCostParts[0] = attachCostParts[0].replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ',',
                                        )
                                        const attachCostFin = `$${attachCostParts.join(
                                          '.',
                                        )}`

                                        return (
                                          <tr key={wo.id} className="wo-row">
                                            <td>
                                              <TimeStamp
                                                timeToFormat={wo.startedAt}
                                              />
                                            </td>
                                            <td>
                                              <Link
                                                to={{
                                                  pathname: `/work-order/${wo.id}`,
                                                }}
                                                onClick={() => {
                                                  window.location.replace(
                                                    `${SERVER_REDIRECTION_URL}?token=${localStorage.getItem(
                                                      'auth-token',
                                                    )}&location=workorders&id=${
                                                      wo.id
                                                    }`,
                                                  )
                                                }}
                                              >
                                                {wo.internalId}
                                              </Link>
                                            </td>
                                            <td>{wo.serviceType}</td>
                                            <td className="wo-desc">
                                              {wo.serviceDescription}
                                            </td>
                                            <td>{wo.keyOnMeter}</td>
                                            <td>
                                              {parseFloat(pmCost) > 0 && (
                                                <div>
                                                  {`${i18n.t(
                                                    'pm',
                                                  )}: ${pmCostFin}`}
                                                </div>
                                              )}
                                              {parseFloat(repairCost) > 0 && (
                                                <div>
                                                  {`${i18n.t(
                                                    'repair',
                                                  )}: ${repairCostFin}`}
                                                </div>
                                              )}
                                              {parseFloat(tiresCost) > 0 && (
                                                <div>
                                                  {`${i18n.t(
                                                    'tires',
                                                  )}: ${tiresCostFin}`}
                                                </div>
                                              )}
                                              {parseFloat(damageCost) > 0 && (
                                                <div>
                                                  {`${i18n.t(
                                                    'damage',
                                                  )}: ${damageCostFin}`}
                                                </div>
                                              )}
                                              {parseFloat(requestCost) > 0 && (
                                                <div>
                                                  {`${i18n.t(
                                                    'request',
                                                  )}: ${requestCostFin}`}
                                                </div>
                                              )}
                                              {parseFloat(attachCost) > 0 && (
                                                <div>
                                                  {`${i18n.t(
                                                    'attachment',
                                                  )}: ${attachCostFin}`}
                                                </div>
                                              )}
                                            </td>
                                            <td>{wo.downtime}</td>
                                            <td>{wo.invoiceType}</td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {currentUser['role'] !== 'service' && (
                          <AllReviews reviews={reviews} />
                        )}
                        <AllLogs logs={logs} />
                        <AllNotes
                          notes={notes}
                          notableType="Equipment"
                          notableId={this.state.id}
                        />
                      </div>
                    )}
                  </div>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

Equipment.defaultProps = {
  id: {},
  match: {},
}

Equipment.propTypes = {
  id: object,
  match: object,
}

export default Equipment
