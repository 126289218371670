import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import FontAwesome from 'react-fontawesome'
import { createBrowserHistory } from 'history'
import { object } from 'prop-types'
import i18n from 'i18n-js'

import SignInUser from './SignInUser'
import TimeStamp from './TimeStamp'

import { AUTH_TOKEN } from '../constants'

class ReportEquip extends Component {
  constructor(props) {
    super(props)

    let history = createBrowserHistory()
    history.replace(history.location.pathname, {
      selectedNav: 'report-equipment',
    })

    this.changeLocalSort = this.changeLocalSort.bind(this)
    this.monetize = this.monetize.bind(this)

    this.state = {
      customers: props.customers,
      settings: props.settings,
      disallowedEquip: props.disallowedEquip,
      excludedWO: props.excludedWO,
      localSort: 'eobjAsc',
      selectedCustomers: props.selectedCustomers,
    }
  }

  changeLocalSort(val) {
    this.setState({ localSort: val })
  }

  monetize(val, defaultVal='N/C', includeCents=true) {
    if (isNaN(parseFloat(val))) {
      return defaultVal
    } else {
      let finParts = parseFloat(val).toFixed(2).split(".")
      finParts[0] = finParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
      if (includeCents) {
        return `$${finParts.join(".")}`
      } else {
        return `$${finParts[0]}`
      }    
    }    
  }

  render() {
    const authToken = localStorage.getItem(AUTH_TOKEN)

    if (authToken === null) return <SignInUser />

    const {
      customers,
      settings,
      disallowedEquip,
      excludedWO,
      localSort,
      selectedCustomers,
    } = this.state

    let hpd = 8
    let dpw = 5

    if (customers[0]) {
      hpd = parseInt(customers[0].hpd, 10)
      dpw = parseInt(customers[0].dpw, 10)
    }

    let deltaYear = 250
    let deltaMonth = 21

    if (dpw === 7) {
      deltaYear = 365
      deltaMonth = 30
    }

    const numDays =
      (settings.endRange.split('-')[0] -
        settings.startRange.split('-')[0]) *
        deltaYear +
      (settings.endRange.split('-')[1] -
        settings.startRange.split('-')[1]) *
        deltaMonth +
      (settings.endRange.split('-')[2] -
        settings.startRange.split('-')[2])

    let equipments = []

    customers.map((customer) => {
      if (!(selectedCustomers.includes(customer.id))) {
        return ''
      }

      equipments = equipments.concat(customer.equipment)

      return ''
    })

    let allEquipments = []

    equipments.map((equip) => {

      equip.pmCost = 0
      equip.damageCost = 0
      equip.tiresCost = 0
      equip.repairCost = 0
      equip.requestCost = 0
      equip.attachCost = 0
      equip.pmCph = null
      equip.repairCph = null
      equip.tiresCph = null
      equip.damageCph = null
      equip.requestCph = null
      equip.attachCph = null
      equip.totalCph = null
      equip.pmCount = 0
      equip.avgPmInterval = 0
      equip.mfcCount = 0
      equip.avgMfcInterval = 0
      equip.periodHours = 0
      equip.annualHours = 0
      equip.startHM = 0
      equip.endHM = 0
      equip.inactive = false
      equip.lastWorkOrder = null

      let previousPmHm = null
      let totalMfcInterval = 0
      let totalPmInterval = 0
      let previousMfcHm = null
      let hoursPerDay = 0
      let workOrders = equip.workOrders
      let totalDowntime = 0

      workOrders.sort((a, b) => (a.invoicedAt < b.invoicedAt ? 1 : -1))

      equip.firstWorkOrder = workOrders[workOrders.length - 1]

      workOrders.map((wo) => {
        if (
          (wo.customer.id !== equip.customer.id) ||
          (excludedWO.indexOf(wo.id) !== -1)) {
          return ''
        }

        if (
          wo.startedAt >= settings.startRange &&
          wo.startedAt <= settings.endRange
        ) {
          if (equip.lastWorkOrder === null) {
            equip.lastWorkOrder = wo
          }

          if (wo.downtime !== null) {
            totalDowntime += parseInt(wo.downtime, 10)
          }

          if (wo.countAsPm) {
            equip.pmCount += 1
            if (previousPmHm !== null) {
              totalPmInterval += previousPmHm - wo.keyOnMeter
            }
            previousPmHm = wo.keyOnMeter
          }

          if (wo.countAsFmc) {
            equip.mfcCount += 1
            if (previousMfcHm !== null) {
              totalMfcInterval += previousMfcHm - wo.keyOnMeter
            }
            previousPmHm = wo.keyOnMeter
          }
        }

        if (
           wo.invoicedAt >= settings.startRange &&
           wo.invoicedAt <= settings.endRange
        ) {
          if (
            (wo.invoiceType === 'Internal') ||
            (wo.invoiceType === 'Extra Care') ||
            (wo.invoiceType === 'Total Care')
          ) {
            return ''
          }

          wo.lineItems.map((li) => {
            const amount = parseFloat(li.amount)

            switch (li.workCategory.value) {
              case 'PM':
                equip.pmCost += amount
                break
              case 'Damage':
                equip.damageCost += amount
                break
              case 'Tires':
                equip.tiresCost += amount
                break
              case 'Repair':
                equip.repairCost += amount
                break
              case 'Request':
                equip.requestCost += amount
                break
              case 'Attachment':
                equip.attachCost += amount
                break
              default:
                break
            }
            return ''
          })
        }

        return ''
      })

      let wayback = settings.endRange.split('-')
      let waybackDay = parseInt(wayback[2], 10)
      let waybackMonth = 0
      let waybackYear = 0

      if (parseInt(wayback[1], 10) - 3 < 1) {
        waybackMonth = 12 + (parseInt(wayback[1], 10) - 3)
        waybackYear = parseInt(wayback[0], 10) - 2
      } else {
        waybackMonth = parseInt(wayback[1], 10) - 3
        waybackYear = parseInt(wayback[0], 10) - 1
      }

      let oneLastCheck = false

      if (!equip.lastWorkOrder) {
        equip.lastWorkOrder = workOrders[0]
        oneLastCheck = true
      }

      if (typeof equip.lastWorkOrder === 'undefined') {
        return ''
      }

      let lastWODate = equip.lastWorkOrder.startedAt.split(' ')[0]
      let lastWOYear = lastWODate.split('-')[0]
      let lastWOMonth = lastWODate.split('-')[1]
      let lastWODay = lastWODate.split('-')[2]

      if (
        oneLastCheck &&
         (waybackYear > lastWOYear ||
         (waybackYear === lastWOYear && waybackMonth > lastWOMonth) ||
         (waybackYear === lastWOYear &&
         waybackMonth === lastWOMonth &&
         waybackDay > lastWODay))
      ) {
        equip.inactive = true
      }

      let woPointer = workOrders.indexOf(equip.lastWorkOrder)
      let lateWO = equip.lastWorkOrder

      if (woPointer !== 0) {
        lateWO = workOrders[woPointer - 1]
      }

      let deltaT = 1
      let woMonth = 0
      let woYear = 0
      let woDay = 0

      while (
        deltaT <= numDays * 1.2 &&
        workOrders[woPointer + 1] !== undefined
      ) {
        woPointer += 1
        let wo = workOrders[woPointer]
        let woDate = wo.startedAt.split(' ')[0]
        woYear = woDate.split('-')[0]
        woMonth = woDate.split('-')[1]
        woDay = woDate.split('-')[2]

        deltaT =
          (lastWOYear - woYear) * deltaYear +
          (lastWOMonth - woMonth) * deltaMonth +
          (lastWODay - woDay)
      }

      let earlyWO = workOrders[woPointer]
      let lateKeyOnMeter = 0
      let earlyKeyOnMeter = 0

      if (lateWO.keyOnMeter !== null) {
        lateKeyOnMeter = parseInt(lateWO.keyOnMeter, 10)
      }

      if (earlyWO.keyOnMeter !== null) {
        earlyKeyOnMeter = parseInt(earlyWO.keyOnMeter, 10)
      }

      if (deltaT === 0) {
        hoursPerDay = 0
      } else {
        hoursPerDay = (lateKeyOnMeter - earlyKeyOnMeter) / deltaT
      }

      let daysBeforeNA = 0
      let daysAfterNA = 0

      if (
        earlyWO === equip.firstWorkOrder &&
        earlyWO.startedAt >= settings.startRange
       ) {
        daysBeforeNA =
          (woYear - settings.startRange.split('-')[0]) * deltaYear +
          (woMonth - settings.startRange.split('-')[1]) * deltaMonth +
          (woDay - settings.startRange.split('-')[2])
      }

      let rangeSinceLastWO =
        (settings.endRange.split('-')[0] - lastWOYear) * deltaYear +
          (settings.endRange.split('-')[1] - lastWOMonth) * deltaMonth +
          (settings.endRange.split('-')[2] - lastWODay)

      if (rangeSinceLastWO > deltaYear) {
        daysAfterNA = rangeSinceLastWO - (3 * deltaMonth)
      }
  
      equip.periodHours = parseInt(
        hoursPerDay * (numDays - daysBeforeNA - daysAfterNA),
        10,
      )

      equip.annualHours = parseInt((hoursPerDay * deltaYear), 10)

      if (equip.pmCount > 1) {
        equip.avgPmInterval = parseFloat(totalPmInterval) / equip.pmCount
      }

      if (equip.mfcCount > 1) {
        equip.avgMfcInterval = parseFloat(totalMfcInterval) / equip.mfcCount
      }

      let deltaHours =
        ((woYear -
        settings.startRange.split('-')[0]) * deltaYear +
        (woMonth - settings.startRange.split('-')[1]) * deltaMonth +
        (woDay - settings.startRange.split('-')[2])) *
        hoursPerDay

      equip.startHM = earlyKeyOnMeter - deltaHours

      if (equip.startHM < 0) {
        equip.startHM = 0
      } else {
        equip.startHM = parseInt(equip.startHM, 10)
      }

      equip.endHM = parseInt((equip.startHM + equip.periodHours), 10)

      let utilization = 0

      if (equip.plannedHours) {
        utilization = equip.annualHours / equip.plannedHours
      } else {
        utilization = (equip.annualHours / (hpd * dpw * 50))
      }

      equip.aur = (utilization * 100).toFixed(1) + '%'
      equip.availability = (1 - totalDowntime / (hpd * numDays)) * 100

      equip.totalCost =
        equip.pmCost +
        equip.damageCost +
        equip.tiresCost +
        equip.repairCost +
        equip.requestCost +
        equip.attachCost

      if (equip.periodHours > 0) {
        equip.pmCph = equip.pmCost / equip.periodHours
        equip.repairCph = equip.repairCost / equip.periodHours
        equip.tiresCph = equip.tiresCost / equip.periodHours
        equip.damageCph = equip.damageCost / equip.periodHours
        equip.requestCph = equip.requestCost / equip.periodHours
        equip.attachCph = equip.attachCost / equip.periodHours
        equip.totalCph = equip.totalCost / equip.periodHours
      }

      allEquipments.push(equip.id)
      return ''
    })

    switch (localSort) {
      case 'eobjDesc':
        equipments.sort((a, b) =>
          a.vendorEquipment[0].vendorEquipmentId <
          b.vendorEquipment[0].vendorEquipmentId
            ? 1
            : -1,
          )
        break
      case 'makeAsc':
        equipments.sort((a, b) => (a.make > b.make ? 1 : -1))
        break
      case 'makeDesc':
        equipments.sort((a, b) => (a.make < b.make ? 1 : -1))
        break
      case 'modelAsc':
        equipments.sort((a, b) => (a.model > b.model ? 1 : -1))
        break
      case 'modelDesc':
        equipments.sort((a, b) => (a.model < b.model ? 1 : -1))
        break
      case 'totalCostAsc':
        equipments.sort((a, b) => (a.totalCost < b.totalCost ? 1 : -1))
        break
      case 'totalCostDesc':
        equipments.sort((a, b) => (a.totalCost > b.totalCost ? 1 : -1))
        break
      case 'pmCostAsc':
        equipments.sort((a, b) => (a.pmCost < b.pmCost ? 1 : -1))
        break
      case 'pmCostDesc':
        equipments.sort((a, b) => (a.pmCost > b.pmCost ? 1 : -1))
        break
      case 'repairCostAsc':
        equipments.sort((a, b) => (a.repairCost < b.repairCost ? 1 : -1))
        break
      case 'repairCostDesc':
        equipments.sort((a, b) => (a.repairCost > b.repairCost ? 1 : -1))
        break
      case 'tiresCostAsc':
        equipments.sort((a, b) => (a.tiresCost < b.tiresCost ? 1 : -1))
        break
      case 'tiresCostDesc':
        equipments.sort((a, b) => (a.tiresCost > b.tiresCost ? 1 : -1))
        break
      case 'damageCostAsc':
        equipments.sort((a, b) => (a.damageCost < b.damageCost ? 1 : -1))
        break
      case 'damageCostDesc':
        equipments.sort((a, b) => (a.damageCost > b.damageCost ? 1 : -1))
        break
      case 'requestCostAsc':
        equipments.sort((a, b) => (a.requestCost < b.requestCost ? 1 : -1))
        break
      case 'requestCostDesc':
        equipments.sort((a, b) => (a.requestCost > b.requestCost ? 1 : -1))
        break
      case 'attachCostAsc':
        equipments.sort((a, b) => (a.attachCost < b.attachCost ? 1 : -1))
        break
      case 'attachCostDesc':
        equipments.sort((a, b) => (a.attachCost > b.attachCost ? 1 : -1))
        break
      case 'mcCostAsc':
        equipments.sort((a, b) => (a.mcCost < b.mcCost ? 1 : -1))
        break
      case 'mcCostDesc':
        equipments.sort((a, b) => (a.mcCost > b.mcCost ? 1 : -1))
        break
      case 'pmCphAsc':
        equipments.sort((a, b) => (a.pmCph < b.pmCph ? 1 : -1))
        break
      case 'pmCphDesc':
        equipments.sort((a, b) => (a.pmCph > b.pmCph ? 1 : -1))
        break
      case 'repairCphAsc':
        equipments.sort((a, b) => (a.repairCph < b.repairCph ? 1 : -1))
        break
      case 'repairCphDesc':
        equipments.sort((a, b) => (a.repairCph > b.repairCph ? 1 : -1))
        break
      case 'tiresCphAsc':
        equipments.sort((a, b) => (a.tiresCph < b.tiresCph ? 1 : -1))
        break
      case 'tiresCphDesc':
        equipments.sort((a, b) => (a.tiresCph > b.tiresCph ? 1 : -1))
        break
      case 'damageCphAsc':
        equipments.sort((a, b) => (a.damageCph < b.damageCph ? 1 : -1))
        break
      case 'damageCphDesc':
        equipments.sort((a, b) => (a.damageCph > b.damageCph ? 1 : -1))
        break
      case 'requestCphAsc':
        equipments.sort((a, b) => (a.requestCph < b.requestCph ? 1 : -1))
        break
      case 'requestCphDesc':
        equipments.sort((a, b) => (a.requestCph > b.requestCph ? 1 : -1))
        break
      case 'attachCphAsc':
        equipments.sort((a, b) => (a.attachCph < b.attachCph ? 1 : -1))
        break
      case 'attachCphDesc':
        equipments.sort((a, b) => (a.attachCph > b.attachCph ? 1 : -1))
        break
      case 'mcCphAsc':
        equipments.sort((a, b) => (a.mcCph < b.mcCph ? 1 : -1))
        break
      case 'mcCphDesc':
        equipments.sort((a, b) => (a.mcCph > b.mcCph ? 1 : -1))
        break
      case 'totalCphAsc':
        equipments.sort((a, b) => (a.totalCph < b.totalCph ? 1 : -1))
        break
      case 'totalCphDesc':
        equipments.sort((a, b) => (a.totalCph > b.totalCph ? 1 : -1))
        break
      case 'careAsc':
        equipments.sort((a, b) => {
          let aVal = 0
          let bVal = 0
          switch (a.maintenanceLevel) {
            case 'TC':
              aVal = 4
              break
            case 'EC':
              aVal = 3
              break
            case 'PM':
              aVal = 2
              break
            default:
              aVal = 1
              break
          }

          switch (b.maintenanceLevel) {
            case 'TC':
              bVal = 4
              break
            case 'EC':
              bVal = 3
              break
            case 'PM':
              bVal = 2
              break
            default:
              bVal = 1
              break
          }

          if (aVal < bVal) {
            return 1
          } else {
            return -1
          }
        })
        break
      case 'careDesc':
        equipments.sort((a, b) => {
          let aVal = 0
          let bVal = 0
          switch (a.maintenanceLevel) {
            case 'TC':
              aVal = 4
              break
            case 'EC':
              aVal = 3
              break
            case 'PM':
              aVal = 2
              break
            default:
              aVal = 1
              break
          }

          switch (b.maintenanceLevel) {
            case 'TC':
              bVal = 4
              break
            case 'EC':
              bVal = 3
              break
            case 'PM':
              bVal = 2
              break
            default:
              bVal = 1
              break
          }

          if (aVal > bVal) {
            return 1
          } else {
            return -1
          }
        })
        break
      default:
        equipments.sort((a, b) =>
          a.vendorEquipment[0].vendorEquipmentId >
          b.vendorEquipment[0].vendorEquipmentId
            ? 1
            : -1,
        )
        break
    }

    return (
      <div>
        <table border="1">
          <thead>
            <tr>
              <th>{i18n.t('useInSummary')}</th>
              <th>
                <Link
                  to="#"
                  onClick={() =>
                    this.changeLocalSort(
                      localSort === 'eobjAsc' ? 'eobjDesc' : 'eobjAsc',
                    )
                  }
                >
                  {i18n.t('eobj')} &nbsp;
                  <FontAwesome
                    name={localSort === 'eobjAsc' ? 'sort-down' : 'sort-up'}
                  />
                </Link>
              </th>
              <th>
                <Link
                  to="#"
                  onClick={() =>
                    this.changeLocalSort(
                      localSort === 'careAsc' ? 'careDesc' : 'careAsc',
                    )
                  }
                >
                  {i18n.t('maintenanceLevel')} &nbsp;
                  <FontAwesome
                    name={localSort === 'careAsc' ? 'sort-down' : 'sort-up'}
                  />
                </Link>
              </th>
              <th>
                <Link
                  to="#"
                  onClick={() =>
                    this.changeLocalSort(
                      localSort === 'makeAsc' ? 'makeDesc' : 'makeAsc',
                    )
                  }
                >
                  {i18n.t('make')} &nbsp;
                  <FontAwesome
                    name={localSort === 'makeAsc' ? 'sort-down' : 'sort-up'}
                  />
                </Link>
              </th>
              <th>
                <Link
                  to="#"
                  onClick={() =>
                    this.changeLocalSort(
                      localSort === 'modelAsc' ? 'modelDesc' : 'modelAsc',
                    )
                  }
                >
                  {i18n.t('model')} &nbsp;
                  <FontAwesome
                    name={localSort === 'modelAsc' ? 'sort-down' : 'sort-up'}
                  />
                </Link>
              </th>
              {selectedCustomers.length > 1 && <th>{i18n.t('customer')}</th>}
              <th>{i18n.t('serial')}</th>
              <th>{i18n.t('fleetNo')}</th>
              <th>{i18n.t('firstWoDate')}</th>
              <th>{i18n.t('lastWoDate')}</th>
              <th>{i18n.t('startHM')}</th>
              <th>{i18n.t('endHM')}</th>
              <th>{i18n.t('periodHours')}</th>
              <th>{i18n.t('annualHours')}</th>
              <th>{i18n.t('annualUtilizationRate')}</th>
              <th>{i18n.t('availability')}</th>
              <th>{i18n.t('pmCount')}</th>
              <th>{i18n.t('avgPmInterval')}</th>
              <th>{i18n.t('mfcCount')}</th>
              <th>{i18n.t('avgMfcInterval')}</th>
              <th>
                <Link
                  to="#"
                  onClick={() =>
                    this.changeLocalSort(
                      localSort === 'totalCostAsc' ? 'totalCostDesc' : 'totalCostAsc'
                    )
                  }
                >
                  {i18n.t('totalCost')} &nbsp;
                  <FontAwesome
                    name={localSort === 'totalCostAsc' ? 'sort-down' : 'sort-up'}
                  />
                </Link>
              </th>
              <th>
                <Link
                  to="#"
                  onClick={() =>
                    this.changeLocalSort(
                      localSort === 'pmCostAsc' ? 'pmCostDesc' : 'pmCostAsc'
                    )
                  }
                >
                  {i18n.t('pm')} &nbsp;
                  <FontAwesome
                    name={localSort === 'pmCostAsc' ? 'sort-down' : 'sort-up'}
                  />
                </Link>
              </th>
              <th>
                <Link
                  to="#"
                  onClick={() =>
                    this.changeLocalSort(
                      localSort === 'repairCostAsc' ? 'repairCostDesc' : 'repairCostAsc'
                    )
                  }
                >
                  {i18n.t('repair')} &nbsp;
                  <FontAwesome
                    name={localSort === 'repairCostAsc' ? 'sort-down' : 'sort-up'}
                  />
                </Link>
              </th>
              <th>
                <Link
                  to="#"
                  onClick={() =>
                    this.changeLocalSort(
                      localSort === 'tiresCostAsc' ? 'tiresCostDesc' : 'tiresCostAsc'
                    )
                  }
                >
                  {i18n.t('tires')} &nbsp;
                  <FontAwesome
                    name={localSort === 'tiresCostAsc' ? 'sort-down' : 'sort-up'}
                  />
                </Link>
              </th>
              <th>
                <Link
                  to="#"
                  onClick={() =>
                    this.changeLocalSort(
                      localSort === 'damageCostAsc' ? 'damageCostDesc' : 'damageCostAsc'
                    )
                  }
                >
                  {i18n.t('damage')} &nbsp;
                  <FontAwesome
                    name={localSort === 'damageCostAsc' ? 'sort-down' : 'sort-up'}
                  />
                </Link>
              </th>
              <th>
                <Link
                  to="#"
                  onClick={() =>
                    this.changeLocalSort(
                      localSort === 'requestCostAsc' ? 'requestCostDesc' : 'requestCostAsc'
                    )
                  }
                >
                  {i18n.t('request')} &nbsp;
                  <FontAwesome
                    name={localSort === 'requestCostAsc' ? 'sort-down' : 'sort-up'}
                  />
                </Link>
              </th>
              <th>
                <Link
                  to="#"
                  onClick={() =>
                    this.changeLocalSort(
                      localSort === 'attachCostAsc' ? 'attachCostDesc' : 'attachCostAsc'
                    )
                  }
                >
                  {i18n.t('attachment')} &nbsp;
                  <FontAwesome
                    name={localSort === 'attachCostAsc' ? 'sort-down' : 'sort-up'}
                  />
                </Link>
              </th>
              <th>
                <Link
                  to="#"
                  onClick={() =>
                    this.changeLocalSort(
                      localSort === 'pmCphAsc' ? 'pmCphDesc' : 'pmCphAsc'
                    )
                  }
                >
                  {i18n.t('pmCPH')} &nbsp;
                  <FontAwesome
                    name={localSort === 'pmCphAsc' ? 'sort-down' : 'sort-up'}
                  />
                </Link>
              </th>
              <th>
                <Link
                  to="#"
                  onClick={() =>
                    this.changeLocalSort(
                      localSort === 'repairCphAsc' ? 'repairCphDesc' : 'repairCphAsc'
                    )
                  }
                >
                  {i18n.t('repairCPH')} &nbsp;
                  <FontAwesome
                    name={localSort === 'repairCphAsc' ? 'sort-down' : 'sort-up'}
                  />
                </Link>
              </th>
              <th>
                <Link
                  to="#"
                  onClick={() =>
                    this.changeLocalSort(
                      localSort === 'tiresCphAsc' ? 'tiresCphDesc' : 'tiresCphAsc'
                    )
                  }
                >
                  {i18n.t('tiresCPH')} &nbsp;
                  <FontAwesome
                    name={localSort === 'tiresCphAsc' ? 'sort-down' : 'sort-up'}
                  />
                </Link>
              </th>
              <th>
                <Link
                  to="#"
                  onClick={() =>
                    this.changeLocalSort(
                      localSort === 'damageCphAsc' ? 'damageCphDesc' : 'damageCphAsc'
                    )
                  }
                >
                  {i18n.t('damageCPH')} &nbsp;
                  <FontAwesome
                    name={localSort === 'damageCphAsc' ? 'sort-down' : 'sort-up'}
                  />
                </Link>
              </th>
              <th>
                <Link
                  to="#"
                  onClick={() =>
                    this.changeLocalSort(
                      localSort === 'requestCphAsc' ? 'requestCphDesc' : 'requestCphAsc'
                    )
                  }
                >
                  {i18n.t('requestCPH')} &nbsp;
                  <FontAwesome
                    name={localSort === 'requestCphAsc' ? 'sort-down' : 'sort-up'}
                  />
                </Link>
              </th>
              <th>
                <Link
                  to="#"
                  onClick={() =>
                    this.changeLocalSort(
                      localSort === 'attachCphAsc' ? 'attachCphDesc' : 'attachCphAsc'
                    )
                  }
                >
                  {i18n.t('attachmentCPH')} &nbsp;
                  <FontAwesome
                    name={localSort === 'attachCphAsc' ? 'sort-down' : 'sort-up'}
                  />
                </Link>
              </th>
              <th>
                <Link
                  to="#"
                  onClick={() =>
                    this.changeLocalSort(
                      localSort === 'totalCphAsc' ? 'totalCphDesc' : 'totalCphAsc'
                    )
                  }
                >
                  {i18n.t('totalCPH')} &nbsp;
                  <FontAwesome
                    name={localSort === 'totalCphAsc' ? 'sort-down' : 'sort-up'}
                  />
                </Link>
              </th>
            </tr>
          </thead>
          <tbody>
            {equipments.map((equip) => {
              return (
                <tr key={equip.id}>
                  <td>
                    <label>
                      <input
                        id={`equip=user-${equip.id}`}
                        type="checkbox"
                        className="form-check-input"
                        name={equip.id}
                        checked={disallowedEquip.indexOf(equip.id) === -1}
                        onChange={(e) => {
                          if (e.target.checked === false) {
                            disallowedEquip.push(equip.id)
                          } else {
                            disallowedEquip.splice(
                              disallowedEquip.indexOf(equip.id),
                              1,
                            )
                          }

                          this.setState(disallowedEquip)
                        }}
                      />
                    </label>
                  </td>
                  <td>
                    <Link to={{ pathname: `/equipment/${equip.id}` }}>
                      {equip.vendorEquipment[0].vendorEquipmentId}
                    </Link>
                  </td>
                  <td>{equip.maintenanceLevel}</td>
                  <td>{equip.make}</td>
                  <td>{equip.model}</td>
                  {selectedCustomers.length > 1 &&
                    <td>{equip.customer.internalName}</td>
                  }
                  <td>{equip.serialNumber}</td>
                  <td>{equip.fleetNumber}</td>
                  <td>
                    {equip.firstWorkOrder && (
                      <TimeStamp timeToFormat={equip.firstWorkOrder.invoicedAt} />
                    )}
                  </td>
                  <td>
                    {equip.lastWorkOrder && (
                      <TimeStamp timeToFormat={equip.lastWorkOrder.invoicedAt} />
                    )}
                  </td>
                  <td>{equip.inactive ? '-' : equip.startHM}</td>
                  <td>{equip.inactive ? '-' : equip.endHM}</td>
                  <td>{equip.inactive ? '-' : equip.periodHours}</td>
                  <td>{equip.inactive ? '-' : equip.annualHours}</td>
                  <td>{equip.inactive ? '-' : equip.aur}</td>
                  <td>
                    {
                      (equip.inactive || (equip.availability === undefined)) ?
                      '-' :
                      equip.availability.toFixed(1) + '%'
                    }
                  </td>
                  <td>{equip.inactive ? '-' : equip.pmCount}</td>
                  { equip.inactive ? 
                    <td>-</td> :
                    <td>
                      {(equip.avgPmInterval === 0) ? 'N/C' : parseInt(equip.avgPmInterval, 10)}
                    </td>
                  }
                  <td>{equip.inactive ? '-' : equip.mfcCount}</td>
                  { equip.inactive ? 
                    <td>-</td> :
                    <td>
                      {(equip.avgMfcInterval === 0) ? 'N/C' : parseInt(equip.avgMfcInterval, 10)}
                    </td>
                  }
                  <td className="financial">
                    {this.monetize(equip.totalCost)}
                  </td>
                  <td className="financial">
                    {this.monetize(equip.pmCost)}
                  </td>
                  <td className="financial">
                    {this.monetize(equip.repairCost)}
                  </td>
                  <td className="financial">
                    {this.monetize(equip.tiresCost)}
                  </td>
                  <td className="financial">
                    {this.monetize(equip.damageCost)}
                  </td>
                  <td className="financial">
                    {this.monetize(equip.requestCost)}
                  </td>
                  <td className="financial">
                    {this.monetize(equip.attachCost)}
                  </td>
                  <td className="finanical">
                    {this.monetize(equip.pmCph)}
                  </td>
                  <td>
                    {this.monetize(equip.repairCph)}
                  </td>
                  <td>
                    {this.monetize(equip.tiresCph)}
                  </td>
                  <td>
                    {this.monetize(equip.damageCph)}
                  </td>
                  <td>
                    {this.monetize(equip.requestCph)}
                  </td>
                  <td>
                    {this.monetize(equip.attachCph)}
                  </td>
                  <td>
                    {this.monetize(equip.totalCph)}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div>
          <label>
            <input
              id="selectEmAll"
              type="checkbox"
              name="checkAll"
              className="form-check-input"
              checked={allEquipments.length !== disallowedEquip.length}
              onChange={(e) => {
                if (e.target.checked === false) {
                  disallowedEquip.splice(0, disallowedEquip.length)
                  disallowedEquip.push.apply(disallowedEquip, allEquipments)
                } else {
                  disallowedEquip.splice(0, disallowedEquip.length)
                }

                this.setState(disallowedEquip)
              }}
            />
            {i18n.t(
              allEquipments.length === disallowedEquip.length
                ? 'selectAll'
                : 'deselectAll',
            )}
          </label>
        </div>
      </div>
    )
  }
}

ReportEquip.defaultProps = {
  customers: {},
  settings: {},
  disallowedEquip: {},
  excludedWO: {},
  selectedCustomers: {},
}

ReportEquip.propTypes = {
  customers: object,
  settings: object,
  disallowedEquip: object,
  excludedWO: object,
  selectedCustomers: object,
}

export default ReportEquip
